import React from "react"
import {api} from "../../lib/axios";
import {Segment, Dropdown, Menu, Message, Checkbox} from "semantic-ui-react";
import SnackBar from "../../components/Snack";
import Confirm from "../../components/Confirm";
import Container from "../../components/Container";
import MenuButton from "../../lib/table-v2/menuButton";
import UITable from "../../lib/table-v2";
import Paper from '@material-ui/core/Paper';
import Button from "../../components/Button";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {formatDate} from "../../helpers/dateHelper";
import EsotiqInput from "../../components/EsotiqInput";
import PromotionCreatorBoxRow from "./promotionCreatorBoxRow";
import {exportToFile} from "../../controller/exportController";
import CsvImport from "../parlours";
import TableMenu from "../../components/TableMenu";

export default class PromotionCodes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dateTo: {},
            dateFrom: {},
            data: null,
            websiteFilter: [],
            dataCount: null,
            tableSelectable: false,
            tableSelectableState: [],
            tableExportCols: []
        }

        this.exportFields = [
            {
                field: "promotion",
                label: "Promocja"
            },
            {
                field: "code",
                label: "Kod"
            },
            {
                field: "qty",
                label: "Ilość"
            },
            {
                field: "limit",
                label: "Limit"
            },
            {
                field: "from",
                label: "Od"
            },
            {
                field: "to",
                label: "Do"
            },
        ]
    }

    componentDidMount() {
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api().post("/api/promotions/getCodes", {promotion: this.props.promotion})
                .then(r => {
                    return r.data.data
                })
        })

        return api().post("/api/promotions/getCodes", {promotion: this.props.promotion, limit, currentPage, filter: this.state.appliedFilters || null, onlyWithProducts: this.state.onlyWithProducts})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    saveCode() {
        const codeData = {
            code: this.state.code,
            promotion: this.props.promotion,
            promotionName: this.props.promotionName,
            qty: this.state.qty || null,
            limit: this.state.limit || null,
            from: this.state.dateFrom.from,
            to: this.state.dateTo.to,
        }

        if (!codeData.from) {
            return SnackBar("Nie podałeś daty od.", "error")
        }

        if (!codeData.to) {
            return SnackBar("Nie podałeś daty do.", "error")
        }

        if (!codeData.code) {
            return SnackBar("Nie treści kodu.", "error")
        }

        api().post("/api/promotions/setCodes", {codes: codeData, promotionId: this.props.promotion})
            .then(r => {
                if (r.data.error) {
                    return SnackBar(`Ten kod już został przypisany do promocji ${r.data.result.map(e => `${e.promotionName || e.promotion} `)}`, "error")
                }

                SnackBar("Dodano nowy kod");

                this.orderTable.setState({
                    data: null
                });

                this.orderTable.init()
            })
    }

    uniExport() {
        if (!this.orderTable.getSelectedState().length) {
            return SnackBar("Nie wybrano żadnego wiersza", "error")
        }

        return exportToFile(
            this.orderTable.getSelectedState(),
            "uniExport",
            this.state.tableExportCols,
            this.exportFields
        )
    }

    render() {
        return (
            <Container>
                <div style={{height: 10}}></div>

                <Paper>
                    <Message
                        header={"Nowy kod promocyjny"}
                        content={"Dodaj pojedynczy kod promocyjny"}
                        info
                    />

                    <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <PromotionCreatorBoxRow
                                label={"Data od"}
                                content={() => (
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Wybierz..."
                                        format="dd-MM-yyyy"
                                        value={this.state.dateFrom.from}
                                        onChange={(date) => {
                                            const filterValue = formatDate(date).dateNowSystem

                                            const fv = {
                                                from: filterValue,
                                                to: formatDate().dateNowSystem
                                            }

                                            this.setState({
                                                refresh: true,
                                                dateFrom: fv
                                            })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                )}
                            />

                            <PromotionCreatorBoxRow
                                label={"Data do"}
                                content={() => (
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Wybierz..."
                                        format="dd-MM-yyyy"
                                        value={this.state.dateTo.to}
                                        onChange={(date) => {
                                            const filterValue = formatDate(date).dateNowSystem

                                            const fv = {
                                                to: filterValue,
                                                from: formatDate().dateNowSystem
                                            }

                                            this.setState({
                                                refresh: true,
                                                dateTo: fv
                                            })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                )}
                            />

                            <PromotionCreatorBoxRow
                                label={"Kod promocyjny"}
                                content={() => (
                                    <EsotiqInput
                                        onChange={v => {
                                            this.setState({code: v})
                                        }}
                                        width={210}
                                    />
                                )}
                            />

                            <PromotionCreatorBoxRow
                                label={"Ilość"}
                                content={() => (
                                    <EsotiqInput
                                        onChange={v => {
                                            this.setState({qty: v})
                                        }}
                                        width={210}
                                    />
                                )}
                            />

                            <PromotionCreatorBoxRow
                                label={"Limit wg. użytkownika"}
                                content={() => (
                                    <EsotiqInput
                                        onChange={v => {
                                            this.setState({limit: v})
                                        }}
                                        width={210}
                                    />
                                )}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <div style={{
                        marginTop: 10
                    }}>
                        <Button
                            onClick={() => this.saveCode()}
                        >
                            Zapisz
                        </Button>
                    </div>
                </Paper>

                <Menu className={"order-menu"} attached='top'>
                    <TableMenu item icon='wrench' simple>
                        <TableMenu.Menu>
                            <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                            <TableMenu.Divider />
                            <TableMenu.Header>Export</TableMenu.Header>
                            <TableMenu.Item>
                                <span className='text'>Raport uniwersalny</span>

                                <TableMenu.Menu>
                                    <div className={"uni-report-container"}>
                                        <div className={"uni-report-header"}>Wybierz kolumny do raportu</div>

                                        <div className={"uni-report-content-box"}>
                                            <div className={"uni-report-column"}>
                                                <ul>
                                                    {this.exportFields.map((fieldValues, key) => {
                                                        return (
                                                            <li>
                                                                <Checkbox key={key} onClick={(e) => {
                                                                    const field = fieldValues.field

                                                                    return !this.state.tableExportCols.includes(field) ? this.state.tableExportCols.push(field) : this.state.tableExportCols = this.state.tableExportCols.filter(col => col != field)
                                                                }} label={fieldValues.label}/>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>

                                        <div className={"uni-report-actions"}>
                                            <Button
                                                color='blue'
                                                content='Pobierz'
                                                icon='download'
                                                label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                onClick={() => this.uniExport()}
                                            />
                                        </div>
                                    </div>
                                </TableMenu.Menu>
                            </TableMenu.Item>
                        </TableMenu.Menu>
                    </TableMenu>

                    <Menu.Menu position='right'>
                        <MenuButton
                            icon={"cloud download"}
                            label={"Import z .csv"}
                            csvStructure={[
                                {column: "code", placeholder: "TestowyKod"},
                                {column: "qty", placeholder: 10},
                                {column: "limit", placeholder: 10},
                                {column: "from", placeholder: "2021-04-01"},
                                {column: "to", placeholder: "2021-04-05"},
                            ]}
                            csvImport={true}
                            csvImportOnUpdate={(data) => api().post("/api/promotions/setCodes", {codes: data, promotionId: this.props.promotion})
                                .then((data) => {
                                    if (data.data.error) {
                                        return SnackBar(`Ten kod już został przypisany do promocji ${data.data.result.map(e => `${e.promotionName || e.promotion} `)}`, "error")
                                    }

                                    this.setState({
                                        data: null,
                                        websiteFilter: [],
                                        dataCount: null,
                                        tableSelectable: false,
                                        tableSelectableState: [],
                                        tableExportCols: []
                                    });

                                    this.orderTable.setState({
                                        data: null
                                    });

                                    this.orderTable.init();

                                    return data
                                })}
                        />

                    </Menu.Menu>
                </Menu>

                <Segment className={"order-table-segment"} attached='bottom'>
                    <UITable
                        dataFetch={{
                            url: "/api/promotions/getCodes",
                            options: {promotion: this.props.promotion, limit: 25, currentPage: 1, onlyWithProducts: this.state.onlyWithProducts}
                        }}
                        ref={table => this.orderTable = table}
                        selectable={this.state.tableSelectable}
                        provider={this}
                        columns={[
                            {
                                label: "Id promocji",
                                field: "promotion",
                                center: true,
                                width: 1,
                            },
                            {
                                label: "Kod",
                                field: "code",
                                center: true,
                                width: 1,
                                filters: [
                                    {label: "Kod", condition: "like", type: "text"},
                                ],
                            },
                            {
                                label: "Ilość",
                                field: "qty",
                                center: true,
                                width: 1,
                                render: row => (
                                    <div>
                                        <span style={{
                                            fontSize: "0.85em",
                                            color: "#a6a6a6",
                                            fontWight: 500
                                        }}>(użyto: {row.uses}) / </span>
                                        {row.qty || <span>&#8734;</span>}
                                    </div>
                                ),
                                filters: [
                                    {label: "Ilość", condition: "like", type: "text"},
                                ],
                            },
                            {
                                label: "Limit wg. użytkownika",
                                field: "limit",
                                center: true,
                                width: 1,
                                render: row => row.limit || "---",
                                filters: [
                                    {label: "Limit", condition: "like", type: "text"},
                                ],
                            },
                            {
                                label: "Start",
                                field: "from",
                                center: true,
                                width: 1,
                                filters: [
                                    {label: "Start", condition: "like", type: "text"},
                                ],
                            },
                            {
                                label: "Stop",
                                field: "to",
                                center: true,
                                width: 1,
                                filters: [
                                    {label: "Stop", condition: "like", type: "text"},
                                ],
                            },
                            {
                                label: "Usuń",
                                field: "name",
                                center: true,
                                width: 1,
                                render: row => {
                                    return (
                                        <div style={{
                                            cursor: "pointer"
                                        }} onClick={() => {
                                            Confirm("Czy na pewno chcesz usunąć pozycję?", "", () => {
                                                api().post("/api/promotions/removeCode", {id: row["_id"]})
                                                    .then(() => {
                                                        SnackBar("Usunięto")
                                                        this.orderTable.setState({
                                                            data: null
                                                        })

                                                        this.orderTable.init()
                                                    })
                                            })
                                        }}>
                                            <i className='trash link icon' />
                                        </div>
                                    )
                                },
                            },
                        ]}
                    />
                </Segment>

                <div style={{height: 150}}></div>

            </Container>
        );
    }
}
