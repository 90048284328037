import React from 'react';
import { Breadcrumb as SemanticBreadcrumb } from 'semantic-ui-react'

function Breadcrumb(props) {
    return (
        <SemanticBreadcrumb>
            <SemanticBreadcrumb.Section link href={"/"}>Home</SemanticBreadcrumb.Section>
            <SemanticBreadcrumb.Divider icon='right angle'/>
            {props.crumbs.map((crumb, key, self) => {
                return (
                    key === (self.length - 1) ?
                        <React.Fragment key={key}>
                            <SemanticBreadcrumb.Section active>{crumb.label}</SemanticBreadcrumb.Section>
                        </React.Fragment> :
                        <React.Fragment key={key}>
                            <SemanticBreadcrumb.Section>{crumb.label}</SemanticBreadcrumb.Section>
                            <SemanticBreadcrumb.Divider icon='right angle'/>
                        </React.Fragment>
                )
            })}
        </SemanticBreadcrumb>
    );
}

export default Breadcrumb
