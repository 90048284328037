import React from "react"
import { Menu } from 'semantic-ui-react'
import CsvImport from "../CsvImport";
import Button from "../../components/Button";
import Dropdown from "../Dropdown";
import {TranslationController} from "../../controller/menager";

export default class ActionPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            importState: false
        }
    }


    handleItemClick = (e, {index, action}) => {
        if (action) {
            action()
        }

        return this.setState({ activeItem: index })
    };

    render() {
        const { activeItem } = this.state

        let actions = this.props.actions

        actions = actions.filter(action => (typeof action === "object" && action != null));

        return (
            <div className={this.props.className}>
                <Menu className={`ui-menu-wrapper`}>
                    {actions.map((action, key) => {
                        return (
                            action.options ?
                                <Dropdown
                                    key={key}
                                    index={key}
                                    item
                                    text={TranslationController.translate(action.label)}
                                >
                                    <Dropdown.Menu>
                                        {action.options.map((option, index) => {
                                            return (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() => {
                                                        if (option.onClick) option.onClick();
                                                    }}
                                                >{TranslationController.translate(option.label)}</Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                : action.csvImport ?
                                    <div>
                                        <Menu.Item
                                            key={key}
                                            index={key}
                                            active={activeItem === key}
                                            action={action.onClick}
                                            onClick={() => {
                                                this.setState({
                                                    importState: !this.state.importState
                                                })
                                            }}
                                        >
                                            {action.label}
                                        </Menu.Item>
                                        {this.state.importState &&
                                        <div className={"action-panel-csv-import-handler"}>
                                            <CsvImport
                                                key={key}
                                                onUpdate={(data) => {
                                                    return action.csvImportOnUpdate(data)
                                                }}
                                            />
                                            <Button
                                                style={{
                                                    marginTop: 10
                                                }}
                                                negative={true}
                                                onClick={() => {
                                                    this.setState({
                                                        importState: false
                                                    })
                                                }}
                                            >{TranslationController.translate("Zamknij")}</Button>
                                        </div>
                                        }
                                    </div>
                                    : <Menu.Item
                                        key={key}
                                        index={key}
                                        active={activeItem === key}
                                        action={action.onClick}
                                        onClick={this.handleItemClick}
                                    >
                                        {TranslationController.translate(action.label)}
                                    </Menu.Item>
                        )
                    })}
                </Menu>
            </div>
        )
    }
}


