import React from "react"
import {api} from "../../../lib/axios";
import ActionPanel from "../../promotions/list";
import Confirm from "../../../components/Confirm";
import SnackBar from "../../../components/Snack";
import Container from "../../../components/Container";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import {Menu, Segment, Icon as SemanticIcon, Modal, Dropdown, Checkbox, Dimmer, Loader, Comment, Form} from 'semantic-ui-react'
import UITable from "../../../lib/table-v2";
import {PDFDownloadLink} from "@react-pdf/renderer";
import PDFDepotCollection, {View} from "./PDFDepotCollection";
import {formatDate} from "../../../helpers/dateHelper";
import { useBarcode } from '@createnextapp/react-barcode';
import {Image} from '@react-pdf/renderer';
import {DATA_OBJECT} from "../../../controller/dataController";
import EsotiqInput from "../../../components/EsotiqInput";
import MenuButton from "../../../lib/table-v2/menuButton";
import {exportToFile, saveToFile} from "../../../controller/exportController";
import PDFSectionsDepotCollection from "./PDFSectionsDepotCollection";
import {TranslationController} from "../../../controller/menager";
import {copyToClipboard} from "../../../lib/globals";
import TableMenu from "../../../components/TableMenu";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import pl from 'date-fns/locale/en-GB';

import PromotionCreatorBoxRow from "../../promotions/promotionCreatorBoxRow";
import Paper from "@material-ui/core/Paper";
import PaperTitle from "../../../components/PaperTitle";
import Icon from '@material-ui/core/Icon';

function BarCode(props) {
    const { inputRef } = useBarcode({
        value: props.id,
        options: {
            height: 50,
            displayValue: false
        }
    });

    return <img id={props.id} ref={inputRef}/>
}

export default class DepotcollectionList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            generatedPdf: {},
            generatedSectionsPdf: {},
            listScanModal: false,
            tableSelectableState: [],
            tableExportCols: [],
            collectionFieldEditable: {},
            userLogins: [],
            dateTo: {},
            dateFrom: {},
        }

        this.exportFields = [
            {
                field: "id",
                label: "Numer list"
            },
            {
                field: "id",
                label: "Data wygenerowania",
                backend_format: "DATE",
            },
            {
                label: "Ilość zamówień",
                field: "orderCount"
            },
            {
                label: "Ilość przedmiotów",
                field: "orderItemCount"
            },
            {
                label: "Salon",
                field: "parlours"
            },
            {
                label: "Osoba zbierająca",
                field: "collectionWorker"
            },
            {
                label: "Start",
                field: "collectionStartTime",
                backend_format: "DATE"
            },
            {
                label: "Stop",
                field: "collectionStopTime",
                backend_format: "DATE"
            },
            {
                label: "Czas zbierania",
                field: "collectionTotalTime",
                backend_format: "DIFF_MINUTES",
            },
        ]
    }

    componentDidMount() {
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api().post("/api/depotCollection/get", {limit: limit, currentPage})
                .then(r => {
                    return r.data.data
                })
        })
    }

    startCollecting(data, forceUser) {
        return api().post("/api/depotCollection/startCollection", {...data, user: (forceUser && forceUser.length > 1) || (this.state.listLogin || DATA_OBJECT.user_login)})
            .then(() => {
               this.orderTable.setState({
                    data: null
                })

                this.orderTable.init()

                SnackBar("Zaktualizowano")
            })
    }

    stopCollecting(data) {
        return api().post("/api/depotCollection/stopCollection", data)
            .then(() => {
               this.orderTable.setState({
                    data: null
                })

                this.orderTable.init()

                SnackBar("Zaktualizowano")
            })
    }

    /**
     * Jeden Time bo na backendzie się obliczyło t1- t2
     * @param time
     * @returns {number}
     */
    diff_minutes(time)
    {
        let diff = time / 1000
        diff /= 60;
        return Math.abs(Math.round(diff));
    }

    convertMinsToHrsMins(mins) {
        let h = Math.floor(mins / 60);
        let m = mins % 60;
        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;
        return `${h}:${m}`;
    }

    uniExport() {
        if (!this.orderTable.getSelectedState().length) {
            return SnackBar("Nie wybrano żadnego wiersza", "error")
        }

        console.log(this.state.tableExportCols)

        return exportToFile(this.orderTable.getSelectedState(), "uniExport", this.state.tableExportCols, this.exportFields)
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    {!this.props.picker &&
                    <Breadcrumb
                        crumbs={[
                            {
                                label: TranslationController.translate("Listy pakowe")
                            }
                        ]}
                    />
                    }

                    <div style={{height: 10}}></div>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item
                                    onClick={() => {
                                        this.setState({
                                            exportModal: true
                                        })
                                    }}
                                >
                                    <span className='text'>{TranslationController.translate("Eksport")}</span>
                                </TableMenu.Item>
                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                            <MenuButton
                                icon={"barcode"}
                                label={"Listy nierozpoczęte"}
                                onClick={() => {
                                    const filters = this.orderTable.state.appliedFilters || [];

                                    filters.push({
                                        condition: "in",
                                        deepFilter: undefined,
                                        field: "notStarted",
                                        label: "Nierozpoczęte",
                                        pattern: undefined,
                                        patternFunc: undefined,
                                        value: true,
                                    })

                                    this.orderTable.applyFilter(filters);
                                }}
                            />

                            <MenuButton
                                icon={"cloud download"}
                                label={this.state.massLoadingText || "Generuj PDF z wybranych"}
                                onClick={() => {
                                    if (!this.orderTable.getSelectedState().length) {
                                        return SnackBar("Nie wybrano żadnego wiersza", "error")
                                    }

                                    this.setState({
                                        massPdf: true,
                                        massLoadingText: "Generowanie, proszę czekać..."
                                    })
                                }}
                            />

                            <MenuButton
                                icon={"cloud download"}
                                label={this.state.massLoadingText || "Generuj PDF Sekcjami z wybranych"}
                                onClick={() => {
                                    if (!this.orderTable.getSelectedState().length) {
                                        return SnackBar("Nie wybrano żadnego wiersza", "error")
                                    }

                                    this.setState({
                                        massPdfSections: true,
                                        massLoadingText: "Generowanie, proszę czekać..."
                                    })
                                }}
                            />

                            {this.state.massPdf &&
                                <div style={{
                                    position: "absolute"
                                }}>
                                    <PDFDownloadLink
                                                     //style={{display: "none"}}
                                                     document={
                                                         <PDFDepotCollection
                                                             data={this.orderTable.getSelectedState().map(e => ({
                                                                 ...e,
                                                                 barcode: document.getElementById(e.id).src
                                                             }))}
                                                             mass={true}
                                                         />
                                                     }
                                                     fileName={`Lista_Pakowa_`}
                                    >
                                        {({blob, url, loading, error}) => {
                                            if (!loading) {
                                                setTimeout(() => {
                                                    document.getElementById("mass_download_link").click();

                                                    this.setState({
                                                        massPdf: false,
                                                        massLoadingText: null,
                                                    })
                                                }, 500)
                                            }

                                            return (loading ?
                                                    <Button
                                                        color='blue'
                                                        loading={true}
                                                        label={{
                                                            basic: true,
                                                            color: 'blue',
                                                            pointing: 'left',
                                                            content: '.pdf'
                                                        }}
                                                    />
                                                    :
                                                    <div>
                                                        <Button
                                                            id={"mass_download_link"}
                                                            color='blue'
                                                            content={<SemanticIcon name={"download"}/>}
                                                            icon='download'
                                                            label={{
                                                                basic: true,
                                                                color: 'blue',
                                                                pointing: 'left',
                                                                content: '.pdf'
                                                            }}
                                                            onClick={(e) => {
                                                                window.open(url, '_blank');

                                                                this.setState({
                                                                    massPdf: false,
                                                                });

                                                                return true
                                                            }}
                                                        />
                                                    </div>
                                            )
                                        }}
                                    </PDFDownloadLink>
                                </div>
                            }

                            {this.state.massPdfSections &&
                                <div style={{
                                    position: "absolute"
                                }}>
                                    <PDFDownloadLink
                                        //style={{display: "none"}}
                                                     document={
                                                         <PDFSectionsDepotCollection
                                                             data={this.orderTable.getSelectedState().map(e => ({
                                                                 ...e,
                                                                 barcode: document.getElementById(e.id).src
                                                             }))}
                                                             mass={true}
                                                         />
                                                     }
                                                     fileName={`Lista_Pakowa_Sekcjami_`}
                                    >
                                        {({blob, url, loading, error}) => {
                                            if (error) {
                                                console.log(error, "ERROR")
                                            }

                                            if (!loading) {
                                                setTimeout(() => {
                                                    document.getElementById("mass_sections_download_link").click();

                                                    this.setState({
                                                        massLoadingText: null,
                                                        massPdfSections: false
                                                    })
                                                }, 500)
                                            }

                                            return (loading ?
                                                    <Button
                                                        color='blue'
                                                        loading={true}
                                                        label={{
                                                            basic: true,
                                                            color: 'blue',
                                                            pointing: 'left',
                                                            content: '.pdf'
                                                        }}
                                                    />
                                                    :
                                                    <div>
                                                        <Button
                                                            id={"mass_sections_download_link"}
                                                            color='blue'
                                                            content={<SemanticIcon name={"download"}/>}
                                                            icon='download'
                                                            label={{
                                                                basic: true,
                                                                color: 'blue',
                                                                pointing: 'left',
                                                                content: '.pdf'
                                                            }}
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                window.open(url, '_blank');

                                                                return true
                                                            }}
                                                        />
                                                    </div>
                                            )
                                        }}
                                    </PDFDownloadLink>
                                </div>
                            }

                            <MenuButton
                                icon={"barcode"}
                                label={"Skanowanie listy"}
                                onClick={() => {
                                    this.setState({
                                        massLoadingText: true
                                    })

                                    api().post("/api/users", {limit: 999, currentPage: 1})
                                        .then((resp) => {
                                            this.setState({
                                                massLoadingText: false
                                            })

                                            if (resp.data && resp.data.data && resp.data.data.length) {
                                                this.setState({
                                                    listScanModal: true,
                                                    userLogins:  resp.data.data.filter(user => user.accessGroup === "6041586c48f6e82945c60d99").map(user => user.login)
                                                })
                                            }
                                        })
                                }}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            selectable={true}
                            dataFetch={{
                                url: "/api/depotCollection/get",
                                options: {limit: 25, currentPage: 1},
                                callback: async data => {
                                    await (() => new Promise(resolve => {
                                        api().post("/api/depotCollection/bindComments",  {
                                            ids: data.data.map(e => e.id)
                                        })
                                            .then(r => {
                                                if (r && r.data && r.data.result) {
                                                    for (let [orderId, comments] of Object.entries(r.data.result)) {
                                                        comments.forEach(comm => {
                                                            data.data.map(e => {
                                                                if (comm.packListId === e.id) {
                                                                    e.commentCount = (e.commentCount || 0) + 1;
                                                                    e.commentValue = comm.value;
                                                                }
                                                            })
                                                        })
                                                    }
                                                }

                                                resolve();
                                            })
                                    }))();

                                    if (this.orderTable) {
                                        this.orderTable.setState({
                                            data: data.data
                                        });
                                    }
                                }
                            }}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    label: "Numer listy",
                                    field: "id",
                                    center: true,
                                    clickable: true,
                                    width: 2,
                                    render: row => (
                                        <div>
                                            <div style={{
                                                display: "none"
                                            }}>
                                                <BarCode id={row.id}/>
                                            </div>

                                            {row.isOutletList &&
                                                <div className={"pack-list-outlet-label"}>
                                                    outlet
                                                </div>
                                            }

                                            {row.isSalonList && !row.isE99List &&
                                            <div className={"pack-list-outlet-label"}>
                                                salon
                                            </div>
                                            }

                                            {row.isE99List && !row.isSalonList &&
                                                <div className={"pack-list-outlet-label"}>
                                                    esotiq omni
                                                </div>
                                            }

                                            {
                                                row.isE99List && row.isSalonList &&
                                                <div className={"pack-list-outlet-label"}>
                                                    esotiq salony omni
                                                </div>
                                            }

                                            <div>
                                                <SemanticIcon style={{cursor: "pointer"}} className={"hover-lb"} name={"copy"} onClick={() => {
                                                    copyToClipboard(row.id);
                                                }}/> <SemanticIcon style={{cursor: "pointer"}} className={"hover-lb"} onClick={() => {
                                                    this.props.history.push({pathname: "/orders", state: {
                                                            orderIds: row.page.map(e => e.header.orderId)
                                                        }})
                                            }} name={"filter"}/> <span style={{cursor: "pointer"}} className={"hover-lb"} onClick={() => {
                                                this.props.history.push({pathname: "/orders", state: {
                                                        orderIds: row.page.map(e => e.header.orderId)
                                                    }})
                                            }}>{row.id}</span>
                                            </div>
                                        </div>
                                    ),
                                    filters: [
                                        {label: "Numer listy", condition: "like", type: "text"},
                                        {label: "Typ", condition: "in", type: "checkbox", forceField: "type", options: [
                                                {
                                                    value: "esotiq",
                                                    label: "Esotiq"
                                                },
                                                {
                                                    value: "outlet",
                                                    label: "Outlet"
                                                },
                                                {
                                                    value: "parlour",
                                                    label: "Salon"
                                                },
                                                {
                                                    value: "k99",
                                                    label: "Esotiq Omni"
                                                },
                                                {
                                                    value: "esotiq-salony-omni",
                                                    label: "Esotiq Salony Omni"
                                                }
                                            ]}
                                    ],
                                },
                                {
                                    label: "PDF",
                                    field: "name",
                                    width: 1,
                                    render: row => {
                                        return (

                                            this.state.generatedPdf[row["_id"]] ?
                                                <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                 document={
                                                                     <PDFDepotCollection
                                                                         data={row}
                                                                         barcode={document.getElementById(row.id).src}
                                                                     />
                                                                 }
                                                                 fileName={`Lista_Pakowa_${row.id}`}
                                                >
                                                    {({blob, url, loading, error}) => {
                                                        if (!loading && url) {
                                                            window.open(url, '_blank');

                                                            this.state.generatedPdf[row["_id"]] = false;
                                                        }

                                                        return (
                                                            (loading ?
                                                                    <Button
                                                                        color='blue'
                                                                        loading={true}
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                    />
                                                                    :
                                                                    <div>
                                                                        <Button
                                                                            color='blue'
                                                                            content={""}
                                                                            icon='download'
                                                                            label={{
                                                                                basic: true,
                                                                                color: 'blue',
                                                                                pointing: 'left',
                                                                                content: '.pdf'
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();

                                                                                window.open(url, '_blank')

                                                                                this.state.generatedPdf[row["_id"]] = false;

                                                                                return true
                                                                            }}
                                                                        />
                                                                    </div>
                                                            )
                                                        )
                                                    }}
                                                </PDFDownloadLink> :

                                                <Button
                                                    color='blue'
                                                    content='Generuj'
                                                    icon='download'
                                                    label={{
                                                        basic: true,
                                                        color: 'blue',
                                                        pointing: 'left',
                                                        content: '.pdf'
                                                    }}
                                                    onClick={() => {
                                                        this.state.generatedPdf[row["_id"]] = true

                                                        this.setState({
                                                            refresh: new Date().getTime()
                                                        })
                                                    }}
                                                >

                                                </Button>

                                    )}
                                },
                                {
                                    label: "PDF Sekcjami",
                                    field: "name",
                                    width: 1,
                                    render: row => {
                                        return (

                                            this.state.generatedSectionsPdf[row["_id"]] ?
                                                <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                 document={
                                                                     <PDFSectionsDepotCollection
                                                                         data={row}
                                                                         barcode={document.getElementById(row.id).src}
                                                                     />
                                                                 }
                                                                 fileName={`Lista_Pakowa_Sekcjami_${row.id}`}
                                                >
                                                    {({blob, url, loading, error}) => {
                                                        if (!loading && url) {
                                                            window.open(url, '_blank');

                                                            this.state.generatedSectionsPdf[row["_id"]] = false;
                                                        }

                                                        return (loading ?
                                                                <Button
                                                                    color='blue'
                                                                    loading={true}
                                                                    label={{
                                                                        basic: true,
                                                                        color: 'blue',
                                                                        pointing: 'left',
                                                                        content: '.pdf'
                                                                    }}
                                                                />
                                                                :
                                                                <div>
                                                                    <Button
                                                                        color='blue'
                                                                        content={""}
                                                                        icon='download'
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();

                                                                            window.open(url, '_blank');

                                                                            this.state.generatedSectionsPdf[row["_id"]] = false;

                                                                            return true
                                                                        }}
                                                                    />
                                                                </div>
                                                        )
                                                    }}
                                                </PDFDownloadLink> :

                                                <Button
                                                    color='blue'
                                                    content='Generuj'
                                                    icon='download'
                                                    label={{
                                                        basic: true,
                                                        color: 'blue',
                                                        pointing: 'left',
                                                        content: '.pdf'
                                                    }}
                                                    onClick={() => {
                                                        this.state.generatedSectionsPdf[row["_id"]] = true;

                                                        this.setState({
                                                            refresh: new Date().getTime()
                                                        })
                                                    }}
                                                >

                                                </Button>

                                        )}
                                },
                                {
                                    label: "Data wygenerowania",
                                    field: "created",
                                    center: true,
                                    width: 2,
                                    render: row => formatDate(row.created).dateTimeSystem,
                                    filters: [
                                        {label: "Sortuj", condition: "in", type: "radio", forceField: "sort_created", options: [
                                                {
                                                    value: "asc",
                                                    label: "Rosnąco"
                                                }, {
                                                    value: "desc",
                                                    label: "Malejąco"
                                                }
                                            ]
                                        },
                                        {label: "Data wygenerowania", condition: "between", type: "date", forceField: "created"}
                                    ],
                                },
                                {
                                    label: "Ilość zamówień",
                                    field: "orderCount",
                                    center: true,
                                    width: 1,
                                    render: row => row.orderCount,
                                    filters: [
                                        {label: "Większa niż", condition: "greater", type: "text"},
                                        {label: "Mniejsza niż", condition: "less", type: "text"},
                                        {label: "Pomiędzy", condition: "between", type: "between"}
                                    ],
                                },
                                {
                                    label: "Ilość przedmiotów",
                                    field: "orderItemCount",
                                    center: true,
                                    width: 1,
                                    render: row => row.orderItemCount,
                                    filters: [
                                        {label: "Większa niż", condition: "greater", type: "text"},
                                        {label: "Mniejsza niż", condition: "less", type: "text"},
                                        {label: "Pomiędzy", condition: "between", type: "between"}
                                    ],
                                },
                                {
                                    label: "Salon",
                                    field: "parlours",
                                    center: true,
                                    width: 2,
                                    render: row => row.parlours ? <div className={"parlour-completation-place"}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }} className={"place-value"}>
                                            {row.parlours.length ? row.parlours.map((service, serviceKey, self) => {
                                                return (
                                                    <span key={serviceKey}>{service}{(serviceKey < self.length - 1) ? ", " : null}</span>
                                                )
                                            }) : <div>---</div>}
                                        </div>
                                    </div> : "---",
                                    filters: [
                                        {label: "Salon", condition: "like", type: "text"}
                                    ],
                                },
                                {
                                    label: "Osoba zbierająca",
                                    field: "collectionWorker",
                                    center: true,
                                    onClick: row => {
                                        if (!this.state.collectionFieldEditable[row["$loki"]]) {
                                            this.state.collectionFieldEditable[row["$loki"]] = true
                                            this.forceUpdate()
                                        }
                                    },
                                    width: 2,
                                    filters: [
                                        {label: "Sortuj", condition: "in", type: "radio", forceField: "sort_collectionWorker", options: [
                                                {
                                                    value: "asc",
                                                    label: "Rosnąco"
                                                }, {
                                                    value: "desc",
                                                    label: "Malejąco"
                                                }
                                            ]
                                        },
                                        {label: "Osoba zbierająca", condition: "like", type: "text"}
                                    ],
                                    render: row => {
                                        if (this.state.collectionFieldEditable[row["$loki"]]) {
                                            return (
                                                <div>
                                                    <EsotiqInput
                                                        onChange={value => {
                                                            this.setState({
                                                                newCollectionWorker: value
                                                            })
                                                        }}
                                                    />

                                                    <div style={{
                                                        display: "flex",
                                                        marginTop: 5,
                                                        justifyContent: "flex-end"
                                                    }}>
                                                        <Button style={{
                                                            marginRight: 5
                                                        }} onClick={() => {
                                                            setTimeout(() => {
                                                                api().post("/api/depotCollection/startCollection", {...row, user: this.state.newCollectionWorker})
                                                                    .then(() => {
                                                                        this.orderTable.setState({
                                                                            data: null
                                                                        })

                                                                        this.orderTable.init()

                                                                        SnackBar("Zaktualizowano")
                                                                    })

                                                                this.state.collectionFieldEditable[row["$loki"]] = false
                                                                this.setState({
                                                                    newCollectionWorker: null
                                                                })
                                                            }, 300)
                                                        }}>
                                                            Zapisz
                                                        </Button>

                                                        <Button negative={true} onClick={() => {
                                                            setTimeout(() => {
                                                                this.state.collectionFieldEditable[row["$loki"]] = false
                                                                this.setState({
                                                                    newCollectionWorker: null
                                                                })
                                                            }, 300)
                                                        }}>
                                                            Anuluj
                                                        </Button>
                                                    </div>

                                                </div>
                                            )
                                        }

                                        return (
                                            row.collectionWorker || "---"
                                        )
                                    }
                                },
                                {
                                    label: "Start",
                                    field: "collectionStartTime",
                                    center: true,
                                    width: 1,
                                    filters: [
                                        {label: "Start", condition: "between", type: "date", forceField: "created"},
                                    ],
                                    render: row => row.collectionStartTime ? formatDate(row.collectionStartTime).dateTimeSystem : <Button onClick={() => this.startCollecting(row)} positive={true}>Start</Button>
                                },
                                {
                                    label: "Stop",
                                    field: "collectionStopTime",
                                    center: true,
                                    width: 1,
                                    filters: [
                                        {label: "Stop", condition: "between", type: "date", forceField: "created"},
                                    ],
                                    render: row => row.collectionStopTime ? formatDate(row.collectionStopTime).dateTimeSystem : <Button onClick={() => this.stopCollecting(row)} negative={true}>Stop</Button>
                                },
                                {
                                    label: "Czas zbierania",
                                    field: "collectionTotalTime",
                                    center: true,
                                    filters: [
                                        {label: "Większa niż", condition: "greater", type: "text"},
                                        {label: "Mniejsza niż", condition: "less", type: "text"},
                                    ],
                                    width: 1,
                                    render: row => row.collectionTotalTime ? `${this.diff_minutes(row.collectionTotalTime)}m` : "---"
                                },
                                {
                                    label: "Komentarze",
                                    field: "comments",
                                    filters: [
                                        {label: "Dowolny komentarz", condition: "exists", type: "toggle", forceField: "hasAnyComment"}
                                    ],
                                    width: 1,
                                    center: true,
                                    onClick: row => {
                                        api().post("/api/depotCollection/comments/get", {packListId: row.id})
                                            .then((result) => {
                                                this.setState({
                                                    orderData: row,
                                                    commnetModal: {
                                                        comments: result.data.comments,
                                                        id: row.id
                                                    }
                                                })
                                            })
                                    },
                                    render: row => <div className={"order-comment-table-wrapper"}>
                                        <Icon style={{
                                            color: row.hasRevertComment ? "#ff0000" : null
                                        }}>comment</Icon>
                                        <div style={{
                                            color: row.hasRevertComment ? "#a10000" : null
                                        }}>({row.commentCount || 0})</div>
                                    </div>
                                },
                                {
                                    label: "Usuń",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                Confirm("Czy na pewno chcesz usunąć pozycję?", "", () => {
                                                    api().post("/api/depotCollection/remove", {_id: row._id, page: row.page})
                                                        .then(() => {
                                                            SnackBar("Usunięto")
                                                            this.orderTable.setState({
                                                                data: null
                                                            })

                                                            this.orderTable.init()
                                                        })
                                                })
                                            }}>
                                                <i className='trash link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                            ]}
                        />
                    </Segment>
                </Container>

                {this.state.commnetModal &&
                <Modal dimmer={"inverted"} open={this.state.commnetModal}
                       onClose={() => this.setState({commnetModal: false, orderData: null})}>
                    <Modal.Header></Modal.Header>
                    <Modal.Content>
                        <div>
                            <Paper>
                                <PaperTitle title={"Komentarze " + this.state.commnetModal.id}/>

                                {this.state.commnetModal.comments ?
                                    <Comment.Group>
                                        {this.state.commnetModal.comments.map((comment, key) => {
                                            return (
                                                <Comment key={key}>
                                                    <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
                                                    <Comment.Content>
                                                        <Comment.Author as='a'>{comment.user}</Comment.Author>
                                                        <Comment.Metadata>
                                                            <div>{formatDate(comment.timestamp).dateTimeSystem}</div>
                                                        </Comment.Metadata>
                                                        <Comment.Text>{comment.value}</Comment.Text>
                                                        <Comment.Actions>
                                                            <Comment.Action
                                                                onClick={() => {
                                                                    if (DATA_OBJECT.user_login !== comment.user) {
                                                                        return SnackBar("Możesz usuwać tylko własne komenatrze", "error")
                                                                    }

                                                                    api().post("/api/depotCollection/comments/remove", {...comment})
                                                                        .then(() => {
                                                                            this.setState({
                                                                                refresh: new Date().getTime(),
                                                                            });

                                                                            SnackBar("Pomyślnie zaktualizowano.")
                                                                        })

                                                                    this.setState({
                                                                        commnetModal: false,
                                                                        orderData: null
                                                                    })
                                                                }}
                                                            >{TranslationController.translate("Usuń")}</Comment.Action>
                                                        </Comment.Actions>
                                                    </Comment.Content>
                                                </Comment>
                                            )
                                        })}
                                    </Comment.Group>
                                    : <div style={{
                                        textAlign: "center",
                                        color: "#c6c6c6",
                                        margin: 20
                                    }}>{TranslationController.translate("Jeszcze nikt nie dodał żadnego komentarza")}</div>
                                }


                                <div id={"letter-counter"}>
                                    Pozostało znaków: {100 - (this.state.newCommentValue ? this.state.newCommentValue.length : 0)}
                                </div>
                                <Form
                                    reply
                                    onSubmit={(value) => {
                                        if (this.state.commnetModal.comments && this.state.commnetModal.comments.length) {
                                            return SnackBar("Komentarz został już wprowadzony, usuń istniejący aby dodać nowy.", "error")
                                        }

                                        if (!this.state.newCommentValue) {
                                            return SnackBar("Wprowadzona wartość nie może być pusta.", "error")
                                        }

                                        if (this.state.newCommentValue.length < 1) {
                                            return SnackBar("Wprowadzony tekst jest za krótki.", "error")
                                        }

                                        if (this.state.newCommentValue.length > 99) {
                                            return SnackBar("Wprowadzony tekst jest za długi. Max 100 znaków", "error")
                                        }

                                        api().post("/api/depotCollection/comments/add", {
                                            value: this.state.newCommentValue,
                                            timestamp: new Date().getTime(),
                                            user: DATA_OBJECT.user_login,
                                            packListId: this.state.orderData.id
                                        })
                                            .then(() => {
                                                this.setState({
                                                    newCommentValue: "",
                                                })

                                                SnackBar("Pomyślnie zaktualizowano.")
                                            })

                                        this.setState({
                                            commnetModal: false,
                                            orderData: null
                                        })
                                    }}
                                >
                                    <Form.TextArea
                                        placeholder={TranslationController.translate("Treść...")}
                                        onChange={(e, v) => {
                                            this.state.newCommentValue = e.target.value;

                                            let count = 100 - (this.state.newCommentValue ? this.state.newCommentValue.length : 0);
                                            const element = document.getElementById("letter-counter");

                                            if (count < 0) {
                                                count = 0;
                                                element.style.color = "red";
                                            } else {
                                                element.style.color = "black";
                                            }

                                            element.innerText = `Pozostało znaków: ${count}`;
                                        }}
                                    />
                                    <Button content={TranslationController.translate("Dodaj komentarz")} labelPosition='left' icon='edit' primary />
                                </Form>
                            </Paper>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({commnetModal: false, orderData: null})} negative>Zamknij</Button>
                    </Modal.Actions>
                </Modal>
                }

                {this.state.exportModal &&
                    <Modal dimmer={"inverted"} size={"small"} open={this.state.exportModal} onClose={() => this.setState({exportModal: null})}>
                        <Modal.Header>Utwórz plik eksportu</Modal.Header>

                        <Modal.Content>
                            <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                                    <PromotionCreatorBoxRow
                                        label={"Data od"}
                                        content={() => (
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Wybierz..."
                                                format="dd-MM-yyyy"
                                                value={this.state.dateFrom.from}
                                                onChange={(date) => {
                                                    const filterValue = formatDate(date).dateNowSystem

                                                    const fv = {
                                                        from: filterValue,
                                                        to: formatDate().dateNowSystem
                                                    }

                                                    this.setState({
                                                        refresh: true,
                                                        dateFrom: fv
                                                    })
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        )}
                                    />

                                    <PromotionCreatorBoxRow
                                        label={"Data do"}
                                        content={() => (
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Wybierz..."
                                                format="dd-MM-yyyy"
                                                value={this.state.dateTo.to}
                                                onChange={(date) => {
                                                    const filterValue = formatDate(date).dateNowSystem

                                                    const fv = {
                                                        to: filterValue,
                                                        from: formatDate().dateNowSystem
                                                    }

                                                    this.setState({
                                                        dateTo: fv
                                                    })
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        )}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Modal.Content>

                        <Modal.Actions>
                            <Button onClick={() => {
                                if (!this.state.dateFrom.from) {
                                    return SnackBar("Nie podałeś daty od.", "error")
                                }

                                if (!this.state.dateTo.to) {
                                    return SnackBar("Nie podałeś daty do.", "error")
                                }

                                this.setState({exportModal: null, massLoadingText: true});

                                api().post("/api/depotCollection/get", {
                                    limit: 9999,
                                    currentPage: 1,
                                    isExport: true,
                                    filter: [
                                        {
                                            label: "Data wygenerowania",
                                            condition: "between",
                                            field: "created",
                                            type: "date",
                                            value: {
                                                from: this.state.dateFrom.from,
                                                to: this.state.dateTo.to
                                            }
                                        }
                                    ]
                                })
                                    .then(r => {
                                        this.setState({
                                            massLoadingText: false
                                        })

                                        if (r.data.error) {
                                            return SnackBar(r.data.message, "error")
                                        }

                                        return saveToFile(r.data);
                                    })
                            }} positive>Generuj</Button>
                            <Button onClick={() => this.setState({exportModal: null})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                <Modal dimmer={"inverted"} size={"small"} open={this.state.listScanModal} onClose={() => this.setState({listScanModal: false, listLogin: null, listData: null, newCollectionWorker: null})}>
                    <Modal.Header>Skanowanie list</Modal.Header>
                    <Modal.Content>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}>
                            <div className={"list-scan-wrapper"}>

                            {/*1604745673212-1*/}
                            {this.state.listData &&
                                <React.Fragment>
                                    {this.state.listData.collectionStartTime ?
                                        <React.Fragment>
                                            <div className={"list-scan-data-wrapper"}>
                                                <div className={"list-scan-fields"}>
                                                    <div className={"list-scan-field"}>Lista</div>
                                                </div>

                                                <div className={"list-scan-values"}>
                                                    <div className={"list-scan-value"}>{this.state.listData.id}</div>
                                                </div>
                                            </div>

                                            <div className={"list-scan-data-wrapper"}>
                                                <div className={"list-scan-fields"}>
                                                    <div className={"list-scan-field"}>Rozpoczęto</div>
                                                </div>

                                                <div className={"list-scan-values"}>
                                                    <div className={"list-scan-value"}>{formatDate(this.state.listData.collectionStartTime).dateTimeSystem}</div>
                                                </div>
                                            </div>

                                            <div className={"list-scan-data-wrapper"}>
                                                <div className={"list-scan-fields"}>
                                                    <div className={"list-scan-field"}>Zakończono</div>
                                                </div>

                                                <div className={"list-scan-values"}>
                                                    <div className={"list-scan-value"}>{formatDate(this.state.listData.collectionEndTime).dateTimeSystem}</div>
                                                </div>
                                            </div>

                                            <div className={"list-scan-data-wrapper"}>
                                                <div className={"list-scan-fields"}>
                                                    <div className={"list-scan-field"}>Użytkownik</div>
                                                </div>

                                                <div className={"list-scan-values"}>
                                                    <div className={"list-scan-value"}>{this.state.listData.collectionWorker}</div>
                                                </div>
                                            </div>

                                            <div className={"list-scan-data-wrapper"}>
                                                <div className={"list-scan-fields"}>
                                                    <div className={"list-scan-field"}>Czas pracy</div>
                                                </div>

                                                <div className={"list-scan-values"}>
                                                    <div className={"list-scan-value"}>{this.convertMinsToHrsMins(this.diff_minutes(this.state.listData.collectionTotalTime))}</div>

                                                    <div style={{
                                                        marginLeft: 10
                                                    }}>
                                                        {!this.state.listData.collectionStartTime &&
                                                            <Button onClick={() => this.startCollecting(this.state.listData)} positive={true}>Start</Button>
                                                        }
                                                        {!this.state.listData.collectionStopTime &&
                                                            <Button onClick={() => this.stopCollecting(this.state.listData)} negative={true}>Stop</Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className={"list-scan-data-wrapper"}>
                                                <div className={"list-scan-data-not-started-warpper"}>
                                                    <div className={"list-scan-info"}>Jeszcze nie rozpoczęto zbierania tej listy.</div>

                                                    <EsotiqInput
                                                        onChange={value => {
                                                            this.state.newCollectionWorker = value;
                                                        }}
                                                        label={"Osoba zbierająca"}
                                                        width={220}
                                                    />

                                                    <Button
                                                        style={{marginTop: 10}}
                                                        onClick={() => {
                                                            this.startCollecting(this.state.listData, this.state.newCollectionWorker);

                                                            this.setState({
                                                                listScanModal: false,
                                                                listLogin: null,
                                                                listData: null,
                                                                newCollectionWorker: null
                                                            })
                                                        }}
                                                        primary
                                                    >
                                                        Rozpocznij
                                                    </Button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                            </div>

                            <EsotiqInput
                                label={"Wprowadź numer listy"}
                                placeholder={"Numer listy"}
                                width={220}
                                onChange={v => this.state.listNumber = v}
                            />

                            <EsotiqInput
                                label={"Wybierz login"}
                                placeholder={"Wybierz login"}
                                minWidth={220}
                                options={this.state.userLogins.map(user => ({key: user, text: user, value: user}))}
                                onChange={v => this.setState({
                                    listLogin: v
                                })}
                            />

                            <Button
                                style={{
                                    marginTop: 10,
                                    width: 220
                                }}
                                primary
                                onClick={() => {
                                    if (this.state.listNumber) {
                                        api().post("/api/depotCollection/getList", {id: this.state.listNumber})
                                            .then(r => {
                                                this.state.listData = null;

                                                if (!r.data) {
                                                    return SnackBar("Nie znaleziono list", "error")
                                                }

                                                if (!r.data.data) {
                                                    return SnackBar("Nie znaleziono list", "error")
                                                }

                                                this.setState({
                                                    listData: r.data.data
                                                })
                                            })
                                    } else {
                                        return SnackBar("Wprowadź numer list", "error")
                                    }
                                }}
                            >
                                Sprawdź
                            </Button>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({listScanModal: false, listLogin: null, listData: null, newCollectionWorker: null})} negative>Zamknij</Button>
                    </Modal.Actions>
                </Modal>

                {this.state.massLoadingText &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać...")}</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        );
    }
}
