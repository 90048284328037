import React, {useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {DATA_OBJECT} from "../../controller/dataController";
import SnackBar from "../../components/Snack";
import {api, controlPanelApi} from "../../lib/axios";
import { withRouter } from "react-router-dom";
import io from 'socket.io-client';
import {NotificationController} from "../../controller/menager";
import {ControlPanelUrls, MonobackendUrls} from "../../api/urls";

function Login(props) {
    let remindLoginValue = null;
    const [values, setValues] = React.useState({
        login: '',
        password: '',
        showPassword: false,
        remember: false
    });
    const [isRemindView, setIsRemindView] = React.useState(false);

    const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangeEmail = () => event => {
        remindLoginValue = event.target.value;
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const getToken = (login, password) => {
        return controlPanelApi.post(ControlPanelUrls.SIGN_IN, {login, password}).then(result => {
            const token = result.data?.accessToken2;
            if(token) {
                window.localStorage.setItem("token", result.data.accessToken2);
                return token;
            } else {
                return false;
            }
        })
    }

    const getDbUser = (login) => {
        return api().post(MonobackendUrls.ME, {login})
            .then(result => {
                if (result.data) {
                    return result.data
                } else {
                    return false
                }
            })
    }

    const handleLogin = async (log, pass) => {
        let successLogin = false;

        const login = log || values.login;
        const password = pass || values.password;

        if (!login) {
            return SnackBar("Login is not specified", "error")
        }

        if (!password) {
            return SnackBar("Password is not specified", "error")
        }

        if (false) {
            props.provider.setState({loginAllowed: true})

            successLogin = {
                isRoot: true,
                login: "root",
                country: "pl"
            }
        } else {
            props.provider.setState({loginAllowed: false})
        }

        const token = await getToken(login, password);

        if(token) {
            successLogin = await getDbUser(login)
        }

        if (!successLogin) {
            return SnackBar("Incorrect password or login", "error")
        }

        if (successLogin.error) {
            return SnackBar("Incorrect password or login", "error")
        }

        if (login === "root") {
            DATA_OBJECT["selected_parlour"] = "root"
        }

        if (successLogin.parlour) {
            DATA_OBJECT["selected_parlour"] = successLogin.parlour
        }

        DATA_OBJECT["accessGroup"] = successLogin.accessGroup;
        DATA_OBJECT["user_login"] = login;
        DATA_OBJECT["user"] = successLogin;
        DATA_OBJECT["logout"] = () => {
            props.provider.setState({loginAllowed: false});
            localStorage.removeItem("login");
            localStorage.removeItem("pass");

            window.open("/", "_self");
        }

        if (values.remember) {
            localStorage.setItem("login", login)
            localStorage.setItem("pass", password)
        }

        if (DATA_OBJECT["user"]["initUrl"] && DATA_OBJECT["user"]["initUrl"].length > 1) {
            props.history.push(DATA_OBJECT["user"]["initUrl"])
        }

        DATA_OBJECT["user"]["id"] = successLogin._id;

        props.provider.setState({loginAllowed: true});

        // const socket = io("https://notification.esotiq.com");
        //
        // socket.on("connect", () => {
        //     socket.emit("login", {
        //         user: DATA_OBJECT["user"],
        //         accessGroup: DATA_OBJECT["accessGroup"]
        //     })
        // })
        //
        // socket.on("notify", () => {
        //     NotificationController.get();
        // })
        //
        // NotificationController.get();
    }

    useEffect(() => {
        const login = localStorage.getItem("login");
        const pass = localStorage.getItem("pass");

        if (login && pass) {
            handleLogin(login, pass)
        }
    }, [])

    const handleRemember = prop => event => {
        setValues({ ...values, [prop]: event.target.checked });
    };

    const remindLogin = () => {
        if (!remindLoginValue) {
            return SnackBar("Wprowadź poprawny adres e-mail.", "error");
        }

        if (remindLoginValue.length < 5) {
            return SnackBar("Wprowadź poprawny adres e-mail.", "error");
        }

        SnackBar("Nowe hasło zostało wysłane na podany adres email");

        return api().post("/api/users/remind", {remindLoginValue})
            .then(result => {
                if (result.data) {
                    return result.data
                } else {
                    return false
                }
            })
    };

    return (
        <div className={"app-login-container"}>
            <div className={"app-login-box"}>
                <div className={"app-login-box-header"}></div>

                <div className={"app-login-box-form"}>
                    {isRemindView
                        ?
                        <React.Fragment>
                            <div>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="E-mail"
                                    margin="normal"
                                    variant="outlined"
                                    type={"email"}
                                    onChange={handleChangeEmail()}
                                />
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Login"
                                    margin="normal"
                                    variant="outlined"
                                    onChange={handleChange('login')}
                                />
                            </div>

                            <div>
                                <TextField
                                    required
                                    id="outlined-adornment-password"
                                    variant="outlined"
                                    type={values.showPassword ? 'text' : 'password'}
                                    label="Password"
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    }

                    <div>
                        <Button onClick={() => isRemindView ? remindLogin() : handleLogin()} variant="contained" color="primary" className={"app-login-btn"} >
                            {isRemindView ? "Resetuj hasło" : "Zaloguj się"}
                            <Icon>send</Icon>
                        </Button>
                    </div>

                    <div
                        className={"app-login-remind"}
                        onClick={() => {
                            setIsRemindView(!isRemindView);
                        }}
                    >
                        {isRemindView ? "Zaloguj się" : "Przypomnij hasło"}
                    </div>

                    <div className={"app-login-remember"}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={values.remember} onChange={handleRemember('remember')} value="remember" />
                            }
                            label="Zapamiętaj logowanie"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Login)
