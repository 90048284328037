import React from "react"
import { Dropdown, Icon as IconSemantic, Menu, Segment, Checkbox, Button } from 'semantic-ui-react'
import {api} from "../../lib/axios";
import Confirm from "../../components/Confirm";
import SnackBar from "../../components/Snack";
import MenuButton from "../../lib/table-v2/menuButton";
import Breadcrumb from "../../components/Breadcrumb";
import Container from "../../components/Container";
import UITable from "../../lib/table-v2";
import {exportToFile} from "../../controller/exportController";
import {formatDate} from "../../helpers/dateHelper";
import TableMenu from "../../components/TableMenu";

export default class AllegroDrafts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            websiteFilter: [],
            dataCount: null,
            tableSelectable: false,
            tableSelectableState: [],
            tableExportCols: [],
            onlyWithProducts: true
        }
    }

    componentDidMount() {
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api(true).post("/api/allegro/getDrafts", {onlyWithProducts: this.state.onlyWithProducts})
                .then(r => {
                    return r.data.data
                })
        })

        return api(true).post("/api/allegro/getDrafts", {limit, currentPage, filter: this.state.appliedFilters || null, onlyWithProducts: this.state.onlyWithProducts})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    render() {
        return (
            <div>
                <React.Fragment>
                    <Container>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `Drafty Allegro`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>

                        <Menu className={"order-menu"} attached='top'>
                            <TableMenu item icon='wrench' simple>
                                <TableMenu.Menu>
                                    <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                    <TableMenu.Divider />
                                    <TableMenu.Header>Export</TableMenu.Header>
                                    <TableMenu.Item>
                                        <span className='text'>Raport uniwersalny</span>

                                        <TableMenu.Menu>
                                            <div className={"uni-report-container"}>
                                                <div className={"uni-report-header"}>Wybierz kolumny do raportu</div>

                                                <div className={"uni-report-content-box"}>
                                                    <div className={"uni-report-column"}>
                                                        <ul>

                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className={"uni-report-actions"}>
                                                    <Button
                                                        color='blue'
                                                        content='Pobierz'
                                                        icon='download'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                        onClick={() => this.uniExport()}
                                                    />
                                                </div>
                                            </div>
                                        </TableMenu.Menu>
                                    </TableMenu.Item>
                                </TableMenu.Menu>
                            </TableMenu>

                            <Menu.Menu position='left'>
                                <Dropdown item text={"Wystaw wybrane"}>
                                    <Dropdown.Menu>
                                        {
                                            [{
                                                label: "SMA-Esotiq",
                                                key: "esotiq"
                                            }, {
                                                label: "SMA-Henderson",
                                                key: "henderson"
                                            }, {
                                                label: "SMA-Finalsale",
                                                key: "finalsale"
                                            }].map((brand, k) => {
                                                return (
                                                    <Dropdown.Item
                                                        key={k}
                                                        onClick={() => {
                                                            if (!this.orderTable.getSelectedState().length) {
                                                                return SnackBar("Nie wybrano żadnego draftu", "error")
                                                            }

                                                            Confirm("Czy na pewno chcesz wystawić wybrane oferty?", "", () => {
                                                                const offers = []

                                                                this.orderTable.getSelectedState().forEach(e => {
                                                                    if (e.brand === brand.key) {
                                                                        offers.push(e.id)
                                                                    }
                                                                })

                                                                if (!offers.length) {
                                                                    return SnackBar("Brak możliwych ofert do wystawienia.", "error")
                                                                }

                                                                api(true).post("/api/allegro/manageOfferts", {offers, action: "ACTIVATE", brand: brand.key})
                                                                    .then(() => {
                                                                        SnackBar(`Poprawnie wystawiono ${offers.length} ofert.`)

                                                                        this.setState({
                                                                            data: null,
                                                                            websiteFilter: [],
                                                                            dataCount: null,
                                                                            tableSelectable: false,
                                                                            tableSelectableState: [],
                                                                            tableExportCols: [],
                                                                            onlyWithProducts: !this.state.onlyWithProducts
                                                                        }, () => {
                                                                            this.orderTable.setState({
                                                                                data: null
                                                                            })

                                                                        })

                                                                        offers.forEach(e => {
                                                                            api(true).post("/api/allegro/successDraft", {id: e})
                                                                                .then(() => {
                                                                                    this.orderTable.setState({
                                                                                        data: null
                                                                                    })

                                                                                    this.orderTable.init()
                                                                                })
                                                                        })
                                                                    })
                                                            })
                                                        }}
                                                    >
                                                        {brand.label}
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>

                                <MenuButton
                                    icon={"close"}
                                    label={"Zakończ wybrane"}
                                    onClick={() => {
                                        if (!this.orderTable.getSelectedState().length) {
                                            return SnackBar("Nie wybrano żadnego draftu", "error")
                                        }

                                        Confirm("Czy napewno chcesz zakończyć wybrane drafty?", "", () => {
                                            SnackBar("Trwa usuwanie, proszę czekać.", "warning");

                                            const offers = []

                                            this.orderTable.getSelectedState().forEach(e => {
                                                offers.push(e.id)
                                            })

                                            const promises = [];
                                            offers.forEach((e) => {
                                                promises.push(new Promise(resolve => {
                                                    api(true).post("/api/allegro/removeDraft", {id: e})
                                                        .then(() => {
                                                            resolve();
                                                        })
                                                }))
                                            })

                                            Promise.all(promises)
                                                .then(() => {
                                                    this.orderTable.setState({
                                                        data: null
                                                    })

                                                    this.orderTable.init();

                                                    SnackBar("Usunięto wybrane drafty");
                                                })
                                        })
                                    }}
                                />

                                <MenuButton
                                    icon={"close"}
                                    label={"Usuń wszystkie"}
                                    onClick={() => {
                                        Confirm("Czy napewno chcesz usunać wszystkie?", "", () => {
                                            api(true).post("/api/allegro/removeDraft", {all: true})
                                                .then(() => {
                                                    this.orderTable.setState({
                                                        data: null
                                                    })

                                                    this.orderTable.init();

                                                    SnackBar("Usunięto drafty");
                                                })
                                        })
                                    }}
                                />
                            </Menu.Menu>
                        </Menu>

                        <Segment className={"order-table-segment"} attached='bottom'>
                            <UITable
                                dataFetch={{
                                    url: "/api/allegro/getDrafts",
                                    useApi2: true,
                                    options: {limit: 25, currentPage: 1, onlyWithProducts: this.state.onlyWithProducts}
                                }}
                                ref={table => this.orderTable = table}
                                selectable={this.state.tableSelectable}
                                provider={this}
                                columns={[
                                    {
                                        label: "Id",
                                        field: "id",
                                        center: true,
                                        width: 1,
                                        filters: [
                                            {label: "ID", condition: "like", type: "text"},
                                        ],
                                        render: row => {
                                            return (
                                                row.isSuccess
                                                ?
                                                    <div>
                                                        <a
                                                            style={{
                                                                color: "#0075d6",
                                                                display: "block"
                                                            }}
                                                            target={"_blank"}
                                                            href={`http://allegro.pl/show_item.php?item=${row.id}`}
                                                        >
                                                            <img src={require("../../resources/allegrologo.png")} style={{
                                                                width: 30
                                                            }}/>
                                                            {row.id}
                                                        </a>
                                                    </div>
                                                    :
                                                    <div>{row.id}</div>

                                            )
                                        }
                                    },
                                    {
                                        label: "Nazwa",
                                        field: "name",
                                        center: true,
                                        width: 2,
                                        filters: [
                                            {label: "Nazwa", condition: "like", type: "text"},
                                            {label: "EAN", condition: "like", type: "text", forceField: "ean"},
                                            {label: "Stworzono", condition: "between", type: "date", forceField: "created"}
                                        ],
                                        render: row => {
                                            return (
                                                <div>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#5d5d5d",
                                                        marginBottom: 5
                                                    }}>{row.name}</div>

                                                    <div style={{
                                                        padding: 5,
                                                        background: "#efefef",
                                                        borderRadius: 5,
                                                        marginBottom: 5
                                                    }}>
                                                        <div style={{
                                                            fontSize: "0.9em",
                                                            color: "#898989",
                                                            display: "flex"
                                                        }}>
                                                            Użytkownik Allegro:
                                                        </div>

                                                        <div style={{
                                                            fontSize: "0.9em",
                                                            color: "#003731",
                                                            display: "flex",
                                                            textTransform: "capitalize"
                                                        }}>
                                                            {row.brand ? row.brand: "---"}
                                                        </div>
                                                    </div>

                                                    <div style={{
                                                        padding: 5,
                                                        background: "#efefef",
                                                        borderRadius: 5,
                                                        marginBottom: 5
                                                    }}>
                                                        <div style={{
                                                            fontSize: "0.9em",
                                                            color: "#898989",
                                                            display: "flex"
                                                        }}>
                                                            Stworzono:
                                                        </div>

                                                        <div style={{
                                                            fontSize: "0.9em",
                                                            color: "#003731",
                                                            display: "flex",
                                                            textTransform: "capitalize"
                                                        }}>
                                                            {formatDate(row.createdAt).dateTimeSystem}
                                                        </div>
                                                    </div>

                                                    <div style={{
                                                        padding: 5,
                                                        background: "#efefef",
                                                        borderRadius: 5,
                                                        marginBottom: 5
                                                    }}>
                                                        <div style={{
                                                            fontSize: "0.9em",
                                                            color: "#898989",
                                                            display: "flex"
                                                        }}>
                                                            EAN:
                                                        </div>

                                                        <div style={{
                                                            fontSize: "0.9em",
                                                            color: "#003731",
                                                            display: "flex"
                                                        }}>
                                                            {row.ean ? row.ean : "---"}
                                                        </div>
                                                    </div>

                                                    <div style={{
                                                        padding: 5,
                                                        background: "#efefef",
                                                        borderRadius: 5,
                                                        marginBottom: 5
                                                    }}>
                                                        <div style={{
                                                            fontSize: "0.9em",
                                                            color: "#898989",
                                                            display: "flex"
                                                        }}>
                                                            SKU:
                                                        </div>

                                                        <div style={{
                                                            fontSize: "0.9em",
                                                            color: "#003731",
                                                            display: "flex"
                                                        }}>
                                                            {row.sku ? row.sku.sku : "---"}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        label: "Walidacja",
                                        field: "validation",
                                        render: row => {
                                            return (
                                                row.isSuccess
                                                ?
                                                <div style={{
                                                    background: "#b7f3ba",
                                                    padding: 15,
                                                }}>
                                                    <div style={{
                                                        color: "#007105"
                                                    }}>Oferta wystawiona poprawnie.</div>
                                                </div>
                                                :
                                                <div style={{
                                                    background: "#282828",
                                                    padding: 15,
                                                    maxWidth: "80vw",
                                                    overflow: "scroll"
                                                }}>
                                                    {row.errors &&
                                                        <pre style={{
                                                            color: "#ffbdb3"
                                                        }}>
                                                            {JSON.stringify(row.errors, undefined, 2)}
                                                        </pre>
                                                    }

                                                    <pre style={{
                                                        color: "#d6d6d6"
                                                    }}>
                                                        {JSON.stringify(row.validation, undefined, 2)}
                                                    </pre>

                                                    {row.dictionaries &&
                                                        <div>
                                                            <div style={{
                                                                color: "#fff",
                                                                marginBottom: 10,
                                                                fontWeight: 700
                                                            }}>
                                                                Słowniki:
                                                            </div>

                                                            <div>
                                                                {row.dictionaries.map((dictionary, key) => {
                                                                    return (
                                                                        <div>
                                                                            <div style={{
                                                                                color: "#00ff32",
                                                                                marginBottom: 10,
                                                                                fontSize: "0.9em"
                                                                            }}>
                                                                                {dictionary.param}:
                                                                            </div>

                                                                            <div style={{
                                                                                color: "#d6d6d6",
                                                                                fontSize: "0.9em",
                                                                                display: "flex",
                                                                                flexWrap: "wrap",
                                                                                marginBottom: 15
                                                                            }}>
                                                                                {dictionary.dictionary.map((dictVal, dictKey) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={dictKey}
                                                                                            className={"draft-param-dict"}
                                                                                            onClick={() => {
                                                                                                const input = document.createElement('input');
                                                                                                input.setAttribute('value', dictVal.value);
                                                                                                document.body.appendChild(input);
                                                                                                input.select();
                                                                                                const result = document.execCommand('copy');
                                                                                                document.body.removeChild(input);

                                                                                                SnackBar("Skopiowano do schowka")
                                                                                            }}
                                                                                        >
                                                                                            {dictVal.value}
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    }

                                                    <div style={{
                                                        color: "#888888",
                                                        fontSize: "0.8em"
                                                    }}>
                                                        * Sekcja Errors musi być czysta (równa []) aby wytawić ofertę
                                                    </div>
                                                    <div style={{
                                                        color: "#888888",
                                                        fontSize: "0.8em"
                                                    }}>
                                                        * Sekcja Warnings to zalecenia Allegro, może zostać pominięta
                                                    </div>
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        label: "Usuń",
                                        field: "name",
                                        center: true,
                                        width: 1,
                                        render: row => {
                                            return (
                                                <div style={{
                                                    cursor: "pointer"
                                                }} onClick={() => {
                                                    Confirm("Czy na pewno chcesz usunąć pozycję?", "", () => {
                                                        api(true).post("/api/allegro/removeDraft", {id: row["id"]})
                                                            .then(() => {
                                                                SnackBar("Usunięto")
                                                                this.orderTable.setState({
                                                                    data: null
                                                                })

                                                                this.orderTable.init()
                                                            })
                                                    })
                                                }}>
                                                    <i className='trash link icon' />
                                                </div>
                                            )
                                        },
                                    },
                                ]}
                            />
                        </Segment>
                    </Container>
                </React.Fragment>
            </div>
        );
    }
}
