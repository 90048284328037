import React from 'react';
import Form from '@rjsf/semantic-ui';
import validator from '@rjsf/core/lib/validate'
import {TranslationController} from "../../controller/menager";

export default class CRUDForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: this.props.formData || null,
            uiSchema: this.props.uiSchema || {},
        }
    }

    handleFormChange = (e) => {
        this.setState({formData: e.formData})
    }

    handleOnSubmit = (e) => {
        const { formData } = this.state;

        this.props.onSubmit && this.props.onSubmit(formData);
        this.props.doneClick();
    }

    render() {
        const { formData } = this.state;
        const { schema, uiSchema } = this.props;
        const { handleFormChange, handleOnSubmit} = this;

        return (
            <Form onSubmit={handleOnSubmit} uiSchema={uiSchema} schema={schema} formData={formData} validator={validator} onChange={handleFormChange}>
                <button type="submit" className="ui button">{TranslationController.translate("Zapisz")}</button>
            </Form>
        )
    }
}
