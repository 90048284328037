import React from "react";
import {Modal, Segment} from "semantic-ui-react";
import PaperComponent from "../../../components/Paper";
import UITable from "../../../lib/table-v2";
import Button from "../../../components/Button";

export default class PersonalizedItems extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imageModal: false
        }
    }

    render() {
        return (
            <PaperComponent>
                <div>
                    <UITable
                        data={this.props.orderData.itemConfigs}
                        ref={table => this.orderTable = table}
                        provider={this}
                        columns={[
                            {
                                label: "SKU",
                                field: "sku",
                                center: true,
                                width: 1
                            },
                            {
                                label: "Zdjęcie",
                                field: "id",
                                width: 1,
                                center: true,
                                render: (row, key) => {
                                    const item = this.props.orderData.items.filter(e => e.sku === row.sku);

                                    if (item && item.length) {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                if (!item[0].image) return false

                                                this.setState({
                                                    imageModal: `${item[0].image}`
                                                })
                                            }}>
                                                {item[0].image && !this.props.noImages ?
                                                    <img
                                                        style={{
                                                            width: 60
                                                        }}
                                                        src={`${item[0].image}`}
                                                        alt=""
                                                    /> : "---"}
                                            </div>
                                        )
                                    } else {
                                        return "---"
                                    }
                                }
                            },
                            {
                                label: "Nazwa",
                                field: "sku",
                                width: 1,
                                render: (row, key) => {
                                    const item = this.props.orderData.items.filter(e => e.sku === row.sku);

                                    if (item && item.length) {
                                        return item[0].name
                                    } else {
                                        return "---";
                                    }
                                }
                            },
                            {
                                label: "Pierwsza linia",
                                field: "sku",
                                width: 1,
                                render: (row) => {
                                    return (row.params.find(e => e.type === 1) || {}).value || "---"
                                }
                            },
                            {
                                label: "Druga linia",
                                field: "sku",
                                width: 1,
                                render: (row) => {
                                    return (row.params.find(e => e.type === 2) || {}).value || "---"
                                }
                            },
                            {
                                label: "Logo",
                                field: "sku",
                                width: 1,
                                render: (row) => {
                                    return (row.params.find(e => e.type === 3) || {}).value || "---"
                                }
                            }
                        ]}
                    />
                </div>

                {this.state.imageModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal}
                           onClose={() => this.setState({imageModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <img style={{
                                    maxWidth: 500,
                                    margin: "0 auto",
                                    display: "block"
                                }} src={this.state.imageModal} alt=""/>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </PaperComponent>
        );
    }
}