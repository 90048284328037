import React from "react";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {TranslationController} from "../../controller/menager";
import {Dimmer, Loader, Menu, Message, Segment} from "semantic-ui-react";
import TableMenu from "../../components/TableMenu";
import {Paper} from "@material-ui/core";
import PromotionCreatorBoxRow from "../promotions/promotionCreatorBoxRow";
import EsotiqInput from "../../components/EsotiqInput";
import Button from "../../components/Button";
import {api, notificationClient} from "../../lib/axios";
import Confirm from "../../components/Confirm";
import SnackBar from "../../components/Snack";

export default class UserNotifications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accessGroups: [],
            accessGroupsMap: {},
            isLoading: true,
            formAccessGroup: "all",
            formPriority: "low",
            formText: ""
        }
    }

    componentDidMount() {
        api().post("/api/users/getAccessGroups", {currentPage: 1, limit: 2000})
            .then((data) => {
                const map = {}

                if (data.data && data.data.data) {
                    data.data.data.forEach(e => {
                        map[e._id] = e.name
                    })
                }

                data.data.data.unshift({
                    _id: "all",
                    name: "Wszyscy użytkownicy"
                })

                this.setState({
                    accessGroups: data.data.data,
                    accessGroupsMap: map,
                    isLoading: false
                })
            })
    }

    render() {
        return (
            <div>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Powiadomienia")}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Import</TableMenu.Header>
                                <TableMenu.Item>
                                </TableMenu.Item>
                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <Paper>
                            <Message
                                header={"Powiadomienia"}
                                content={"Stwórz treść powiadomienia i wybierz grupę docelową aby wyświetlić użytkownikom"}
                                info
                            />

                            <PromotionCreatorBoxRow
                                label={"Grupa docelowa"}
                                content={() => {
                                    return (
                                        <EsotiqInput
                                            width={"100%"}
                                            placeholder={"Wybierz grupę"}
                                            defValue={"all"}
                                            options={this.state.accessGroups.map((e, k) => ({
                                                key: e._id, text: e.name, value: e._id
                                            }))}
                                            onChange={value => {
                                                this.state.formAccessGroup = value;
                                            }}
                                        />
                                    )
                                }}
                            />

                            <PromotionCreatorBoxRow
                                label={"Priorytet"}
                                desc={"Priorytet określa ważność powiadomienia, będzie miał wpływ na wygląd okienka z tekstem."}
                                content={() => {
                                    return (
                                        <EsotiqInput
                                            width={"100%"}
                                            placeholder={"Priorytet"}
                                            defValue={"low"}
                                            options={[
                                                {key: 0, value: "high", text: "Wysoki"},
                                                {key: 1, value: "mid", text: "Średni"},
                                                {key: 2, value: "low", text: "Niski"},
                                            ]}
                                            onChange={value => {
                                                this.state.formPriority = value;
                                            }}
                                        />
                                    )
                                }}
                            />

                            <PromotionCreatorBoxRow
                                label={"Treść powiadomienia"}
                                content={() => {
                                    return (
                                        <EsotiqInput
                                            textArea={true}
                                            placeholder={"Wpisz tekst..."}
                                            defValue={this.state.formText}
                                            onChange={(value) => {
                                                this.state.formText = value;
                                            }}
                                        />
                                    )
                                }}
                            />

                            <PromotionCreatorBoxRow
                                label={"Stwórz"}
                                content={() => {
                                    return (
                                        <Button
                                            primary={true}
                                            style={{
                                                marginRight: 20
                                            }}
                                            onClick={() => {
                                                Confirm("Czy na pewno chcesz wysłać powiadomienie?", null, () => {
                                                    if (!this.state.formAccessGroup) {
                                                        return SnackBar("Nie wybrałeś grupy odbiorców", "error");
                                                    }

                                                    if (!this.state.formPriority) {
                                                        return SnackBar("Nie wybrałeś priorytetu", "error");
                                                    }

                                                    if (this.state.formText.length < 5) {
                                                        return SnackBar("Wprowadzony tekst jest zbyt krótki", "error");
                                                    }

                                                    this.setState({
                                                        isLoading: true
                                                    })

                                                    notificationClient.post("/api/notification/set", {
                                                        formAccessGroup: this.state.formAccessGroup,
                                                        formPriority: this.state.formPriority,
                                                        formText: this.state.formText
                                                    })
                                                        .then(() => {
                                                            this.setState({
                                                                isLoading: false
                                                            })

                                                            SnackBar("Wysłano powiadomienie");
                                                        })
                                                })
                                            }}
                                        >
                                            {TranslationController.translate("Wyślij powiadomienie")}
                                        </Button>
                                    )
                                }}
                            />
                        </Paper>
                    </Segment>

                    {this.state.isLoading &&
                        <Dimmer active inverted style={{
                            position: "fixed",
                            zIndex: 99999
                        }}>
                            <Loader size='medium'>{TranslationController.translate("Proszę czekać..")}</Loader>
                        </Dimmer>
                    }
                </Container>
            </div>
        );
    }
}
