import {api} from "../lib/axios";
import fileDownload  from "js-file-download"
import SnackBar from "../components/Snack";

export function saveToFile(data) {
    const csvString = data;
    const universalBOM = "\uFEFF";
    let a = window.document.createElement('a');
    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
    a.setAttribute('download', 'export.csv');
    window.document.body.appendChild(a);
    a.click();

    //fileDownload(data, 'export.csv')
    SnackBar("Pobrano pomyślnie.")
}

export function exportToFile(data, exportType, additionalData, exportFields, fileName) {
    return api().post("/api/export", {
        data: data,
        exportType,
        additionalData,
        exportFields
    })
        .then(r => {
            const data = r.data;
            const csvString = data;
            const universalBOM = "\uFEFF";
            let a = window.document.createElement('a');
            a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
            a.setAttribute('download', fileName || 'export.csv');
            window.document.body.appendChild(a);
            a.click();

            //fileDownload(data, 'export.csv')
            SnackBar("Pobrano pomyślnie.")
        })
}

export const Download = (data, filename) => {
    return fileDownload(data, filename || "export.csv")
}
