import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core";

export let GLOBAL_MODAL_UNSET = null

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        position: "relative",
        marginBottom: 15,
    },
    formControl: {
        width: "100%",
        minWidth: 500
    },
    orderHeader: {
        display: "flex",
        alignItems: "center",
        color: "#727272",
        fontWeight: 300,
        fontSize: "1.3em",
        marginLeft: 15,
        marginBottom: 15,
        paddingBottom: 10,
        borderBottom: "1px solid #DADADA"
    },
    orderHeaderSpan: {
        marginLeft: 10
    },
    dialogContent: {
        minWidth: "700px"
    },
    addProductBtn: {
        marginBottom: 10
    }
}));

function FormDialog(props) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(props.opennned);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        GLOBAL_MODAL_UNSET()
    }


    return (
        <div>
            <Dialog maxWidth={false} className={classes.dialogContent} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    {props.content}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Anuluj
                    </Button>
                    {props.onSubscribe &&
                    <Button onClick={() => {
                        props.onSubscribe()
                        GLOBAL_MODAL_UNSET()
                    } } color="primary">
                        Zapisz
                    </Button>
                    }

                </DialogActions>
            </Dialog>
        </div>
    );
}

export default function Modal(title, content, onSubscribe) {
    const DOMElement = document.getElementById("modal-root")

    const ModalContent = () => (
        <FormDialog
            opennned={true}
            content={content}
            onSubscribe={onSubscribe}
            title={title}
        />
    )

    const removeModal = async () => {
        ReactDOM.unmountComponentAtNode(DOMElement)
    };

    GLOBAL_MODAL_UNSET = removeModal

    return ReactDOM.render(ModalContent(), DOMElement);
}
