import React from "react"
import PropTypes from 'prop-types'
import {TranslationController} from "../../controller/menager";

function PaperTitle(props) {
    return (
        <div className={"app-paper-title"}>
            {TranslationController.translate(props.title)}
        </div>
    )
}

PaperTitle.propTypes = {
    title: PropTypes.string
}

export default PaperTitle
