import React from 'react'
import {Checkbox, Dimmer, Loader, Menu, Message} from "semantic-ui-react";
import ActionPanel from "../../components/ActionPanel";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import Paper from '@material-ui/core/Paper';
import {api, STATIC_URL} from "../../lib/axios";
import Button from "../../components/Button";
import { Dropdown, Table, Modal } from 'semantic-ui-react'
import UITable from "../../lib/table-v2";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import Confirm from "../../components/Confirm";
import {formatDate} from "../../helpers/dateHelper";
import SnackBar from "../../components/Snack";
import {DATA_OBJECT} from "../../controller/dataController";
import {TranslationController} from "../../controller/menager";
import TableMenu, {closeTableMenu, preventCloseTableMenu} from "../../components/TableMenu";
import MenuButton from "../../lib/table-v2/menuButton";
import {PDFDownloadLink} from "@react-pdf/renderer";
import PDFDepotCollection from "../warehouse/depotCollection/PDFDepotCollection";
import MultiDeliveryPDF from "../orders/orderPreview/multiDeliveryPDF";
import {internalOrderRow} from "../../helpers/internalOrderRow";
import {saveToFile} from "../../controller/exportController";


export default class OnlineWarehouseShippingInternal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parlours: null,
            selectedParlour: null,
            orderServiceData: null,
            generateProtocol: false,
            newServices: null,
            acceptedServices: null,
            showParlourBeam: null,
            internalOrders: true,
            generatedPdf: {},
            pdfData: null,
            multiDeliveryPdf: {},
            appliedFilters: [],
            tableExportCols: []
        };

        this.orderTable = null;
        this.protocolTable = null;
        this.glsProtocolTable = null;

        this.exportFields = [
            {
                field: "orderId",
                label: "Numer zamówienia",
            },
            {
                field: "status",
                label: "Status",
            },
            {
                field: "Numer list przewozowego",
                label: "pickupNumber"
            },
            {
                field: "Numer mm",
                label: "numberMM",
            },
            {
                field: "p_sku",
                label: "Produkt SKU",
            },
            {
                field: "p_qty",
                label: "Produkt ilość",
            }
        ];

        this.uniExportFields = [
            {
                field: "orderId",
                label: "Numer zamówienia",
            },
            {
                field: "status",
                label: "Status",
            },
            {
                field: "pickupNumber",
                label: "Numer list przewozowego"
            },
            {
                field: "numberMM",
                label: "Numer mm",
            },
            {
                field: "p_sku",
                label: "Produkt SKU",
            },
            {
                field: "p_qty",
                label: "Produkt ilość",
            }
        ];
    }

    componentDidMount() {
        api().post("/api/parlours", {
            filter: [
                {
                    condition: "like",
                    field: "id",
                    label: "ID",
                    value: this.props.match.params.id || DATA_OBJECT.selected_parlour || null
                },
            ],
        })
            .then(r => {
                this.setState({
                    parlours: r.data.data.map(parlour => ({
                        key: parlour.id,
                        text: parlour.id,
                        value: parlour.id
                    }))
                })
            })
    }

    uniExport() {
        this.setState({
            loading: true
        });

        preventCloseTableMenu(false);
        closeTableMenu();

        return api().post("/api/orderService", {
            isExport: {
                columns: this.state.tableExportCols,
                fields: this.uniExportFields
            },
            limit: 35000,
            currentPage: 1,
            filter: this.orderTable.state.appliedFilters,
            internalOrders: this.state.internalOrders,
            initSlug: "e99_157_parlour"
        })
            .then(r => {
                this.setState({
                    loading: false,
                    tableExportCols: []
                });

                if (r.data.error) {
                    return SnackBar(r.data.message, "error")
                }

                return saveToFile(r.data);
            });
    }

    getProtocolData() {
        return this.orderTable.state.data.filter(e => e.pickupNumber && !e.protocol)
    }

    generateProtocol() {
        const data = this.protocolTable.getSelectedState();

        if (!data.length) {
            return SnackBar("Nie wybrano żadnej pozycji", "error")
        }

        api().post("/api/orderService/generateProtocol", {
            orderId: data[0].order.id || data[0].orderId,
            parlour: data[0].parlour,
            loki: data[0].$loki,
            data: data
        }).then((r) => {

            if (!r.data.error) {
                window.open(`${STATIC_URL}${r.data.file}`)
            } else {
                SnackBar(r.data.message, "error")
            }

            this.setState({
                refresh: new Date().getTime()
            })
        })
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api().post("/api/orderService", {
                filter: [
                    {
                        condition: "eq",
                        field: "initSlug",
                        label: "initSlug",
                        value: "e99_157_parlour"
                    }
                ]
            })
                .then(r => {
                    return r.data.data
                })
        })

        return api().post("/api/orderService", {
            limit,
            currentPage,
            filter: [...this.state.appliedFilters, {
                condition: "eq",
                field: "initSlug",
                label: "initSlug",
                value: "e99_157_parlour"
            }],
            internalOrders: this.state.internalOrders,
            user: DATA_OBJECT["user"],
        })
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    generateGLSProtocol() {
        const data = this.protocolTable.getSelectedState();
        const parlour = data[0].parlour;

        if (!data.length) {
            return SnackBar("Nie wybrano żadnej pozycji", "error")
        }

        if(data.some(e => e.provider !== "gls")) {
            return SnackBar("Nie można wygenerować protokołu dla zamówień z innym kurierem niż GLS", "error")
        }

        if(data.some(e => e.parlour !== parlour)) {
            return SnackBar("Nie można wygenerować protokołu dla zamówień z różnych salonów", "error")
        }

        api().post("/api/orderService/generateGlsProtocol", {
            orderServices: data,
            parlour,
        })
        .then(r => {
            if(r.data.error) {
                return SnackBar(r.data.error, "error")
            }
            const arrayBuffer = Uint8Array.from(r.data.protocol.data).buffer;

            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, "_blank")

            this.setState({
                refresh: new Date().getTime()
            })
        })
    }

    async acceptOrders() {
        try {
            for(let orderService of this.orderTable.getSelectedState()) {
                orderService.sending = orderService.sending.map(item => ({...item, ...{status: "accepted"}}));
                orderService.status = "accepted";
                orderService.acceptedTime = formatDate().dateNowSystem;

                const result = await api().post("/api/orderService/accept", {
                    orderId: orderService.orderId,
                    parlour: orderService.parlour,
                    orderService: orderService
                });

                if (result.data.error) {
                    return SnackBar(result.data.message, "error");
                }
            }
            this.setState({
                loading: false
            })
        } catch (e) {
            this.setState({
                loading: false
            })
            return SnackBar("Wystąpił błąd podczas akceptacji zamówień", "error");
        }
    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[
                    ]}
                />

                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Salon - Obsługa zamówienia`
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <Paper>
                        <Message
                            icon='inbox'
                            header='Aktywne zamówienia'
                        />

                        {<Menu className={"order-menu"} attached='top'>
                            <TableMenu icon='wrench'>
                                <TableMenu.Menu>
                                    <TableMenu.Item onClick={() => this.setState({
                                        tableSelectable: !this.state.tableSelectable,
                                        tableSelectableState: []
                                    })}>Zaznaczanie</TableMenu.Item>


                                    {DATA_OBJECT.user.login === "root" && <TableMenu.Item onClick={() => {
                                        return Confirm(
                                            "Czy na pewno chcesz usunąć zaznaczone recordy?",
                                            "",
                                            () => {
                                                return this.orderTable.getSelectedState().map(row => {
                                                    return api().post("/api/orderService/remove", {
                                                        id: row._id,
                                                    }).then((r) => {
                                                        this.orderTable.setState({
                                                            data: null
                                                        });

                                                        this.orderTable.init()
                                                    })
                                                })
                                            })
                                        }

                                        }>Usuń zaznaczone</TableMenu.Item>
                                    }

                                    <TableMenu.Divider />
                                    <TableMenu.Header>Export</TableMenu.Header>
                                    <TableMenu.Item>
                                        <span className='text'>Raport uniwersalny</span>

                                        <TableMenu.Menu>
                                            <div className={"uni-report-container"}>
                                                <div className={"uni-report-header"}>Wybierz kolumny do raportu</div>

                                                <div className={"uni-report-content-box"}>
                                                    <div className={"uni-report-column"}>
                                                        <ul>
                                                            {this.uniExportFields.map((fieldValues, key) => {
                                                                return (
                                                                    <li key={key}>
                                                                        <Checkbox key={key} onClick={(e) => {
                                                                            const field = fieldValues.field

                                                                            return !this.state.tableExportCols.includes(field) ? this.state.tableExportCols.push(field) : this.state.tableExportCols = this.state.tableExportCols.filter(col => col != field)
                                                                        }} label={fieldValues.label}/>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className={"uni-report-actions"}>
                                                    <Button
                                                        color='blue'
                                                        content='Pobierz'
                                                        icon='download'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                        onClick={() => this.uniExport()}
                                                    />
                                                </div>
                                            </div>
                                        </TableMenu.Menu>
                                    </TableMenu.Item>
                                </TableMenu.Menu>
                            </TableMenu>

                            <Menu.Menu position='left'>
                                <MenuButton
                                    icon={"list"}
                                    onClick={() => {
                                        this.setState({
                                            generateProtocol: this.getProtocolData()
                                        })
                                    }}
                                    label={"Generuj raport dla kuriera"}
                                />

                                {process.env.REACT_APP_RUN_GLS_PICKUP && <MenuButton
                                    onClick={() => {
                                        this.setState({
                                            tableSelectable: false,
                                            tableSelectableState: [],
                                            glsProtocol: true
                                        });
                                    }
                                    }
                                    icon={"list"}
                                    label={"Pobierz protokół kuriera gls"}
                                />}
                            </Menu.Menu>

                            <Menu.Menu position='right'>
                                {<MenuButton
                                    icon={"warehouse"}
                                    label={"Asystent realizacji"}
                                    style={{
                                        width: 400,
                                    }}
                                    optionsHeight={45}
                                    options={[{
                                        label: "Generuj listę pakową dla zaznaczonych zamówień",
                                        onClick: () => {
                                            if (this.orderTable.getSelectedState().length === 0) {
                                                SnackBar("Nie zanzaczyłeś żadnego wiersza!", "error");
                                                return;
                                            }

                                            this.setState({
                                                loading: true
                                            }, () => {
                                                api().post("/api/depotCollection/createMany", {
                                                    orderIds: this.orderTable.getSelectedState().map(row => row.orderId),
                                                    parlour: this.orderTable.getSelectedState()[0].parlour
                                                }).then((r) => {
                                                    this.setState({
                                                        loading: false
                                                    }, () => {
                                                        this.orderTable.setState({
                                                            data: null
                                                        });
                                                        this.orderTable.init()

                                                        if (!r.data.success) {
                                                            return SnackBar(r.data.message, "error")
                                                        }

                                                        r.data.pages[0].page.forEach((page) => {
                                                            page.items.forEach((item) => {
                                                                item.attributes = {};
                                                            });
                                                        });

                                                        this.setState({
                                                            pdfData: r.data.pages[0]
                                                        })
                                                    })
                                                })
                                            })
                                        }
                                    }, {
                                        label: "Generuj MM dla zaznaczonych zamówień",
                                        onClick: async () => {
                                            if (this.orderTable.getSelectedState().length === 0) {
                                                SnackBar("Nie zanzaczyłeś żadnego wiersza!", "error");
                                                return;
                                            }

                                            for (let row of this.orderTable.getSelectedState()) {
                                                if (row.numberMM) {
                                                    SnackBar(`Jedno z zaznaczonych zamówień (${row.orderId}) posiada już wygenerowany dokument MM (${row.numberMM}).`, "error");
                                                    return;
                                                }
                                            }

                                            const acceptedOrderServicesForParlour = await (() => {
                                                return new Promise(resolve => {
                                                    api().post("/api/orderService", {
                                                        limit: 9999,
                                                        currentPage: 1,
                                                        filter: [{
                                                            field: "status",
                                                            value: "accepted",
                                                            condition: "eq",
                                                        }, {
                                                            field: "initSlug",
                                                            condition: "pureFilter",
                                                            value: true,
                                                            customFilter: {
                                                                initSlug: "e99_157_parlour"
                                                            }
                                                        }],
                                                    })
                                                        .then(r => {
                                                            resolve(r.data.data);
                                                        })
                                                })
                                            })();

                                            if (this.orderTable.getSelectedState().filter(row => !["accepted", "send"].includes(row.status)).length > 0) {
                                                SnackBar("Zaznaczono zamówienia o statusie innym niż Zaakceptowano lub Wysłano.", "error");
                                                return;
                                            }

                                            const uniqueOrderIds = [...new Set(this.orderTable.getSelectedState().map(row => row.orderId))];
                                            const orderIdsString = uniqueOrderIds.join(', ');
                                            let resultString = `Zamierzasz wygenerować dokument MM- do zamówień:\n\n${orderIdsString}`;

                                            if (acceptedOrderServicesForParlour.length > this.orderTable.getSelectedState().length) {
                                                resultString = `Zamierzasz wygenerować dokument MM- do ${this.orderTable.getSelectedState().length} Zaakceptowanego zamówienia:\n\n${orderIdsString}.\n\nPosiadasz łącznie ${acceptedOrderServicesForParlour.length} Zaakceptowanych zamówień.`;
                                            }

                                            Confirm(resultString, "", () => {
                                                this.setState({
                                                    loading: true
                                                }, () => {
                                                    const sendingItems = [];
                                                    const orderIds = [];
                                                    const rowIds = [];

                                                    this.orderTable.getSelectedState().forEach(row => {
                                                        row.sending.forEach(item => sendingItems.push(item));

                                                        orderIds.push(row.orderId);
                                                        rowIds.push(row._id);
                                                    })

                                                    api().post("/api/orderService/createMmDocument", {
                                                        items: sendingItems,
                                                        orderId: orderIds,
                                                        rowId: rowIds,
                                                        parlour: this.orderTable.getSelectedState()[0].parlour
                                                    })
                                                        .then((r) => {
                                                            if (r.data.error) {
                                                                SnackBar(r.data.message, "error")
                                                                this.setState({
                                                                    loading: false
                                                                }, () => {
                                                                    this.orderTable.setState({
                                                                        data: null
                                                                    });
                                                                    this.orderTable.init();
                                                                })
                                                            }
                                                            return this.acceptOrders();
                                                        }).then(() => {
                                                        this.setState({
                                                            loading: false
                                                        }, () => {
                                                            this.orderTable.setState({
                                                                data: null
                                                            });
                                                            this.orderTable.init();
                                                        })
                                                    })
                                                })
                                            })
                                        }
                                    }, {
                                        label: "Generuj list przewozowy dla zaznaczonych zamówień",
                                        onClick: () => {
                                            if (this.orderTable.getSelectedState().length === 0) {
                                                SnackBar("Nie zanzaczyłeś żadnego wiersza!", "error");
                                                return;
                                            }

                                            if (this.orderTable.getSelectedState().filter(row => row.status !== "accepted").length > 0) {
                                                SnackBar("Zaznaczono zamówienia o statusie innym niż Zaakceptowano.", "error");
                                                return;
                                            }

                                            const uniqueOrderIds = [...new Set(this.orderTable.getSelectedState().map(row => row.orderId))];
                                            const orderIdsString = uniqueOrderIds.join(', ');
                                            const resultString = `Zamierzasz wygenerować list przewozowy do zamówień:\n\n${orderIdsString}`;

                                            Confirm(resultString, "", () => {
                                                this.setState({
                                                    loading: true
                                                }, () => {
                                                    const promises = [];

                                                    promises.push(new Promise(resolve => {
                                                        api().post("/api/orderService/generatePickup", {
                                                            orderId: this.orderTable.getSelectedState()[0].orderId,
                                                            parlour: this.orderTable.getSelectedState()[0].parlour,
                                                            id: this.orderTable.getSelectedState()[0]._id,
                                                            multiCreate: true,
                                                            rowIds: this.orderTable.getSelectedState().map(row => row._id)
                                                        }).then((r) => {
                                                            resolve();
                                                        })
                                                    }))

                                                    promises.push(new Promise(resolve => {
                                                        api().post("/api/orderService/handleMultiDelivery", {
                                                            orderIds: this.orderTable.getSelectedState().map(row => row.orderId)
                                                        }).then((r) => {
                                                            resolve();
                                                        })
                                                    }))

                                                    Promise.all(promises).then(() => {
                                                        this.setState({
                                                            loading: false
                                                        }, () => {
                                                            this.orderTable.setState({
                                                                data: null
                                                            });
                                                            this.orderTable.init()
                                                        })
                                                    })
                                                })
                                            })
                                        }
                                    }, {
                                        label: "Oznacz zaznaczone jako wysłane",
                                        onClick: () => {
                                            if (this.orderTable.getSelectedState().length === 0) {
                                                SnackBar("Nie zanzaczyłeś żadnego wiersza!", "error");
                                                return;
                                            }

                                            const unacceptedRows = this.orderTable.getSelectedState().filter(row => row.status !== "accepted");
                                            if (unacceptedRows.length) {
                                                SnackBar(`Zamówienia o numerach ${unacceptedRows.map(row => row.orderId).join(", ")} nie są w statusie "Zaakceptowano"!`, "error");
                                                return;
                                            }

                                            const noPickupNumberRows = this.orderTable.getSelectedState().filter(row => !row.pickupNumber);
                                            if (noPickupNumberRows.length) {
                                                SnackBar(`Zamówienia o numerach ${noPickupNumberRows.map(row => row.orderId).join(", ")} nie posiadają jeszcze listu przewozowego!`, "error");
                                                return;
                                            }

                                            const noMMDocuments = this.orderTable.getSelectedState().filter(row => !row.numberMM);
                                            if (noMMDocuments.length) {
                                                SnackBar(`Zamówienia o numerach ${noPickupNumberRows.map(row => row.orderId).join(", ")} nie posiadają jeszcze dokumentu MM-!`, "error");
                                                return;
                                            }



                                            this.setState({
                                                loading: true
                                            }, () => {
                                                const promises = [];

                                                this.orderTable.getSelectedState().forEach(row => {
                                                    promises.push(new Promise(resolve => {
                                                        api().post("/api/orderService/markSend", {
                                                            orderId: row.order.id || row.orderId,
                                                            parlour: row.parlour,
                                                            id: row.id
                                                        }).then((r) => {
                                                            resolve();
                                                        })
                                                    }))
                                                })

                                                Promise.all(promises).then(() => {
                                                    this.setState({
                                                        loading: false
                                                    }, () => {
                                                        this.orderTable.setState({
                                                            data: null
                                                        });
                                                        this.orderTable.init()
                                                    })
                                                })
                                            })
                                        }
                                    }]}
                                />}
                            </Menu.Menu>
                        </Menu>}

                        {
                            this.state.pdfData &&
                            <div style={{
                                display: "none"
                            }}>
                                {this.state.generatedPdf ?
                                    <PDFDownloadLink ref={e => this.downloadLink = e}
                                                     document={
                                                         <PDFDepotCollection
                                                             data={this.state.pdfData}
                                                             barcode={null}
                                                             isGeneratedFromInternalView={true}
                                                         />
                                                     }
                                                     fileName={`Lista_Pakowa_Salony`}
                                    >
                                        {({blob, url, loading, error}) => {
                                            if (!loading && url) {
                                                window.open(url, '_blank');

                                                this.state.generatedPdf = {};
                                                this.state.pdfData = null;
                                            }

                                            return (
                                                (loading ?
                                                        <Button
                                                            color='blue'
                                                            loading={true}
                                                            label={{
                                                                basic: true,
                                                                color: 'blue',
                                                                pointing: 'left',
                                                                content: '.pdf'
                                                            }}
                                                        />
                                                        :
                                                        <div>
                                                            <Button
                                                                color='blue'
                                                                content={""}
                                                                icon='download'
                                                                label={{
                                                                    basic: true,
                                                                    color: 'blue',
                                                                    pointing: 'left',
                                                                    content: '.pdf'
                                                                }}
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    window.open(url, '_blank')

                                                                    this.state.generatedPdf = {};
                                                                    this.state.pdfData = null;

                                                                    return true
                                                                }}
                                                            />
                                                        </div>
                                                )
                                            )
                                        }}
                                    </PDFDownloadLink> :

                                    <Button
                                        color='blue'
                                        content='Generuj'
                                        icon='download'
                                        label={{
                                            basic: true,
                                            color: 'blue',
                                            pointing: 'left',
                                            content: '.pdf'
                                        }}
                                        onClick={() => {
                                            this.state.generatedPdf = true

                                            this.setState({
                                                refresh: new Date().getTime()
                                            })
                                        }}
                                    >

                                    </Button>}
                            </div>
                        }

                        <UITable
                            dataFetch={{
                                url: "/api/orderService",
                                options: {
                                    limit: 25,
                                    currentPage: 1,
                                    parlour: this.props.match.params.id || DATA_OBJECT.selected_parlour || null,
                                    proceed: DATA_OBJECT.user_login === "root",
                                    isWeb: true,
                                    internalOrders: this.state.internalOrders,
                                    initSlug: "e99_157_parlour"
                                },
                                callback: (result) => {
                                    this.setState({
                                        newServices: result.newServices,
                                        acceptedServices: result.acceptedServices,
                                        showParlourBeam: true,
                                    })
                                }
                            }}
                            ref={table => this.orderTable = table}
                            selectable={this.state.tableSelectable}
                            provider={this}
                            rowStyle={(row) => {
                                return internalOrderRow[row.status] ?? null
                                // return ((row.status === "accepted") ? {
                                //     background: '#F4B30A29'
                                // } : null)
                            }}
                            columns={[
                                {
                                    field: "order",
                                    label: "Zamówienie",
                                    center: true,
                                    render: row => {
                                        let isExternalOrder = row.orderId.toString().charAt(0) === "8";
                                        let isDEOrder = row.orderId.toString().charAt(0) === "4";

                                        return (
                                            <div>
                                                <div style={{
                                                    fontWeight: 600,
                                                    fontSize: 16
                                                }}>{row.orderId}</div>
                                                <div>
                                                    {formatDate(row.created).dateTimeSystem}
                                                </div>
                                                <div style={{
                                                    fontWeight: 600,
                                                    color: "#767676"
                                                }}>{isExternalOrder ? TranslationController.translate("Przesunięcie do E99") : isDEOrder ? TranslationController.translate("Przesunięcie do D99") : TranslationController.translate("Przesunięcie do E99")}</div>
                                            </div>
                                        )
                                    },
                                    filters: [
                                        {label: "Numer zamówienia (część lub pełny numer)", condition: "in", type: "text", objectField: "part_of_integer"},
                                        {label: "Numer zamówienia", condition: "in", forceField: "orderId", type: "in", regexp: true},
                                    ],
                                },
                                {
                                    field: "chunks",
                                    label: "Salon",
                                    center: true,
                                    render: row => (
                                        <div>
                                            {row.parlour}
                                        </div>
                                    )
                                },
                                {
                                    field: "chunks",
                                    label: "Status",
                                    center: true,
                                    width: 1,
                                    render: row => (
                                        <div>
                                            <div className={"es-order-satus-display"}><span style={{background: new OrderStatusesHelper().getColor(row.status), color: new OrderStatusesHelper().getFontColor(row.status)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.status)}</span></div>

                                            {DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root" &&
                                                <div>
                                                    {row.proceed ? "[Dev] Proceed true" : "[Dev] Proceed false"}
                                                </div>
                                            }
                                        </div>
                                    ),
                                    filters: [
                                        {label: "Status", condition: "in", type: "checkbox", forceField: "status", options: [
                                                {
                                                    value: "new",
                                                    label: new OrderStatusesHelper().statuses.new.label
                                                }, {
                                                    value: "send",
                                                    label: new OrderStatusesHelper().statuses.send.label
                                                }, {
                                                    value: "partialSended",
                                                    label: new OrderStatusesHelper().statuses.partialSended.label
                                                }, {
                                                    value: "accepted",
                                                    label: new OrderStatusesHelper().statuses.accepted.label
                                                }, {
                                                    value: "canceled",
                                                    label: new OrderStatusesHelper().statuses.canceled.label
                                                }, {
                                                    value: "rejected",
                                                    label: new OrderStatusesHelper().statuses.rejected.label
                                                }
                                            ]
                                        },
                                    ]
                                },
                                {
                                    field: "sending",
                                    label: "Do wysyłki",
                                    filters: [
                                        {label: "SKU", condition: "in", forceField: "sending.sku", type: "in", regexp: true},
                                    ],
                                    render: row => (
                                        <div>

                                            <Table compact>
                                                <Table.Body>
                                                    {row.sending.filter(e => e.qty > 0).map((item, key) => {
                                                        return (
                                                            <Table.Row key={key}>
                                                                <Table.Cell width={3}>
                                                                    <div style={{
                                                                        color: "#787878",
                                                                        textAlign: "center"
                                                                    }}>
                                                                        {formatDate(item.timestamp).dateTimeSystem}
                                                                    </div>
                                                                </Table.Cell>
                                                                <Table.Cell width={3}>
                                                                    <div style={{
                                                                        cursor: "pointer"
                                                                    }} onClick={() => {
                                                                        this.setState({
                                                                            imageModal: `${item.image}`
                                                                        })
                                                                    }}>
                                                                        <img style={{
                                                                            width: 60
                                                                        }} src={`${item.image}`} alt=""/>
                                                                    </div>
                                                                </Table.Cell>
                                                                <Table.Cell width={4}>
                                                                    <div>
                                                                        {DATA_OBJECT["user"] && (DATA_OBJECT["user"]["country"] === "pl" ? item.name : (DATA_OBJECT["user"]["country"] === "de" && item.deName) ? item.deName : "")}
                                                                    </div>
                                                                </Table.Cell>
                                                                <Table.Cell width={3}>
                                                                    <div style={{position: "relative", top: -2}}>
                                                                        {item.sku}
                                                                        {item.status &&
                                                                            item.status === "rejected" ? <div style={{background: "#f76364"}} className={"order-management-status"}>Odrzucono ({item.rejectedCount} z {item.beforeRejectedQty} szt)</div> : item.status === "canceled" ? <span style={{background: "#409ff7"}} className={"order-management-status"}>Anulowano</span> : ""
                                                                        }
                                                                    </div>
                                                                </Table.Cell>
                                                                <Table.Cell>Do wysyłki: {item.qty} szt.</Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    )
                                },
                                {
                                    field: "numberMM",
                                    label: "Akcje",
                                    center: true,
                                    width: 4,
                                    filters: [
                                        {label: "Stworzono (Dokument MM)", condition: "between", type: "date", forceField: "mmDocument.createdAt"},
                                        {label: "Dokument MM", condition: "exists", type: "exists", forceField: "mmDocument"},
                                    ],
                                    render: row => (
                                        <div>
                                            {(row.numberMM && row.mmDocument) &&
                                                <React.Fragment>
                                                    <div style={{
                                                        fontWeight: 600,
                                                        marginBottom: 15
                                                    }}>
                                                        <div style={{
                                                            fontWeight: 600,
                                                            color: "#848484"
                                                        }}>{TranslationController.translate("Numer dokumentu MM")}:</div>
                                                        {row.numberMM}
                                                    </div>

                                                    <div style={{
                                                        fontWeight: 600,
                                                        marginBottom: 15
                                                    }}>
                                                        <div style={{
                                                            fontWeight: 600,
                                                            color: "#848484"
                                                        }}>{TranslationController.translate("Data aktualizacji")}:</div>
                                                        {formatDate(row.mmDocument.createdAt).dateTimeSystem}
                                                    </div>

                                                    <div style={{
                                                        marginTop: 15
                                                    }}>
                                                        <Button
                                                            color='blue'
                                                            content='Pobierz dokument MM'
                                                            icon='download'
                                                            label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                            onClick={() => {
                                                                window.open(row.mmDocument.pdf)
                                                            }}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            }

                                            {row.status === "send" &&
                                                <div style={{
                                                    fontWeight: 600,
                                                    marginTop: 15
                                                }}>
                                                    <div style={{
                                                        fontWeight: 600,
                                                        color: "#20ca45"
                                                    }}>{TranslationController.translate("Zamówienie zostało wysłane.")}</div>
                                                </div>
                                            }

                                            {row.status === "rejected" &&
                                                <div style={{
                                                    fontWeight: 600,
                                                    marginTop: 15
                                                }}>
                                                    <div style={{
                                                        fontWeight: 600,
                                                        color: "#b23530"
                                                    }}>{TranslationController.translate("Zamówienie zostało anulowane z powodu braku dostępności towarów.")}</div>
                                                </div>
                                            }

                                            <React.Fragment>
                                                {DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root" &&
                                                    <div style={{
                                                        marginTop: 15
                                                    }}>
                                                        <Button onClick={() => {
                                                            return Confirm(
                                                                "Czy na usunąć ten record?",
                                                                "",
                                                                () => {
                                                                    api().post("/api/orderService/remove", {
                                                                        id: row._id,
                                                                    }).then((r) => {
                                                                        this.orderTable.setState({
                                                                            data: null
                                                                        });

                                                                        this.orderTable.init()
                                                                    })
                                                                })
                                                        }
                                                        }>[Dev] Usuń record</Button>
                                                    </div>
                                                }

                                                {!["rejected", "send", "canceled"].includes(row.status) &&
                                                    <div>
                                                        <div className={"ui button force-roboto"} style={{marginTop: 15}} onClick={() =>  window.open("#/warehouse/shipping-internal-preview/" + row._id, '_self')}>{
                                                            row.status === "accepted" ? "Obsłuż ponownie" : "Obsłuż"
                                                        }</div>
                                                    </div>
                                                }

                                                {row.status === "accepted" &&
                                                    <div style={{
                                                        marginTop: 15
                                                    }}>
                                                        <Button onClick={() => {
                                                            if (!row.pickupNumber) {
                                                                return SnackBar("W pierwszej kolejności wygeneruj list przewozowy.", "warning")
                                                            }

                                                            if (!row.numberMM) {
                                                                return SnackBar("W pierwszej kolejności wygeneruj dokument MM-", "warning")
                                                            }

                                                            

                                                            return Confirm(
                                                                "Czy na pewno chcesz oznaczyć to zamówienie jako wysłane?",
                                                                "",
                                                                () => {
                                                                    api().post("/api/orderService/markSend", {
                                                                        orderId: row.order.id || row.orderId,
                                                                        parlour: row.parlour,
                                                                        id: row.id
                                                                    }).then((r) => {
                                                                        row.status = "send"

                                                                        this.setState({
                                                                            refresh: new Date().getTime()
                                                                        })
                                                                    })
                                                                })
                                                        }
                                                        }>{TranslationController.translate("Oznacz jako wysłane")}</Button>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        </div>
                                    ),
                                },
                                {
                                    field: "pickupNumber",
                                    label: "List przewozowy",
                                    center: true,
                                    width: 4,
                                    filters: [
                                        {label: "Stworzono", condition: "between", type: "date", forceField: "pickupCreateDate"},
                                    ],
                                    render: row => (
                                        <div>
                                            {row.pickupNumber &&
                                                <div style={{
                                                    fontWeight: 600,
                                                    marginBottom: 15
                                                }}>
                                                    <div style={{
                                                        fontWeight: 600,
                                                        color: "#848484"
                                                    }}>{TranslationController.translate("Numer listu przewozowego")}:</div>
                                                    {row.pickupNumber}
                                                </div>
                                            }

                                            {row.pickupCreateDate &&
                                                <div style={{
                                                    fontWeight: 600,
                                                    marginBottom: 15
                                                }}>
                                                    <div style={{
                                                        fontWeight: 600,
                                                        color: "#848484"
                                                    }}>{TranslationController.translate("Data utworzenia")}:</div>
                                                    {formatDate(row.pickupCreateDate).dateTimeSystem}
                                                </div>
                                            }

                                            {(row.pickupFile || row.pickupNumber) &&
                                                <div style={{
                                                    marginTop: 15
                                                }}>
                                                    <Button
                                                        color='blue'
                                                        content='List przewozowy'
                                                        icon='download'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                        onClick={() => {
                                                            if (row.parlour.charAt(0) === "D") {
                                                                window.open(row.pickupFile)
                                                            }
                                                            else if (process.env.REACT_APP_RUN_GLS_PICKUP === "true") {
                                                                const format = ["K99", "E99"].includes(DATA_OBJECT.selected_parlour) ? 'smallPdf' : 'a4';
                                                                api().get(`/api/orders/delivery/getLabel/${row.pickupNumber}/gls?format=${format}&parlour=${DATA_OBJECT.selected_parlour}&protocol=${row.protocol ?? ''}`).then(
                                                                    (result) => {
                                                                        if(result.data.error) {
                                                                            return SnackBar(result.data.error, "error")
                                                                        }
                                                                        const arrayBuffer = Uint8Array.from(result.data.label.data).buffer;
                                                                        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                                        const blobUrl = URL.createObjectURL(blob);
                                                                        window.open(blobUrl, "_blank")
                                                                    }
                                                                )
                                                            }
                                                            else {
                                                                window.open(`${STATIC_URL}${row.pickupFile}`)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }

                                            <React.Fragment>
                                                {(row.status === "accepted" || row.status === "send") && (!row.pickupFile && !row.pickupNumber) &&
                                                    <div style={{
                                                        marginTop: 15
                                                    }}>
                                                        <Button onClick={() => {
                                                            return Confirm(
                                                                "Czy na pewno chcesz wygenerować nowy list przewozowy?",
                                                                "",
                                                                () => {
                                                                    this.setState({
                                                                        loading: true
                                                                    })

                                                                    api().post("/api/orderService/generatePickup", {
                                                                        orderId: row.orderId,
                                                                        parlour: row.parlour,
                                                                        id: row._id,
                                                                    }).then((r) => {
                                                                        this.setState({
                                                                            loading: false
                                                                        })

                                                                        if (r.data.validation && r.data.validation.ValidationInfo && r.data.validation.ValidationInfo.length) {
                                                                            r.data.validation.ValidationInfo.forEach((e) => {
                                                                                SnackBar(e.Info, "error")
                                                                            })
                                                                        } else {
                                                                            row.pickupNumber = r.data.data
                                                                            row.pickupFile = r.data.file
                                                                            row.pickupCreateDate = r.data.pickupCreateDate
                                                                            row.pickupSessionPackageId = r.data.pickupSessionPackageId

                                                                            if (row.parlour.charAt(0) === "D") {
                                                                                window.open(r.data.file)
                                                                            } else if (process.env.REACT_APP_RUN_GLS_PICKUP === "true") {
                                                                                const format = ["K99", "E99"].includes(DATA_OBJECT.selected_parlour) ? 'smallPdf' : 'a4';
                                                                                api().get(`/api/orders/delivery/getLabel/${row.pickupNumber}/gls?format=${format}&parlour=${DATA_OBJECT.selected_parlour}`).then((r) => {
                                                                                    if(r.data.error) {
                                                                                        return SnackBar(r.data.error, "error")
                                                                                    }
                                                                                    const arrayBuffer = Uint8Array.from(r.data.label.data).buffer;

                                                                                    const blob = new Blob([arrayBuffer], {type: 'application/pdf'});
                                                                                    const blobUrl = URL.createObjectURL(blob);
                                                                                    window.open(blobUrl, "_blank")
                                                                                })
                                                                            } else {
                                                                                window.open(`${STATIC_URL}${r.data.file}`)
                                                                            }

                                                                            this.setState({
                                                                                refresh: new Date().getTime(),
                                                                                loading: false
                                                                            })
                                                                        }
                                                                    })
                                                                })
                                                        }
                                                        }>{TranslationController.translate("Generuj list przewozowy")}</Button>
                                                    </div>
                                                }
                                            </React.Fragment>

                                            <React.Fragment>
                                                {(row.multiDelivery && row.multiDelivery.length) &&
                                                    <div style={{
                                                        marginTop: 5
                                                    }}>
                                                        {this.state.multiDeliveryPdf[`${row.orderId}_${row.parlour}`]
                                                            ?
                                                            <PDFDownloadLink
                                                                document={<MultiDeliveryPDF orders={row.multiDelivery}/>}
                                                            >
                                                                {({ blob, url, loading, error }) => {
                                                                    return (
                                                                        <Button
                                                                            loading={loading}
                                                                            disabled={loading}
                                                                            secondary
                                                                            onClick={() => {
                                                                                this.state.multiDeliveryPdf[`${row.orderId}_${row.parlour}`] = false;
                                                                                window.open(url, '_blank')
                                                                            }}
                                                                        >
                                                                            Etykieta zbiorcza
                                                                        </Button>
                                                                    )
                                                                }}
                                                            </PDFDownloadLink>
                                                        : <Button
                                                            onClick={() => {
                                                                this.state.multiDeliveryPdf[`${row.orderId}_${row.parlour}`] = true;

                                                                this.setState({
                                                                    refresh: new Date().getTime
                                                                })
                                                            }}
                                                            >
                                                                Pobierz etykietę zbiorczą
                                                            </Button>
                                                        }

                                                    </div>
                                                }
                                            </React.Fragment>
                                        </div>
                                    )
                                }
                            ]}
                        />

                        {this.state.showParlourBeam &&
                            <div className={"ps-bar"}>
                                <div className={"ps-bar-row"} style={{background: "#8b0000"}}>
                                    <span>{TranslationController.translate("Nowe zamówienia")}:</span>
                                    <span>{this.state.newServices}</span>
                                </div>

                                <div className={"ps-bar-row"} style={{background: "#ff8c00"}}>
                                    <span>{TranslationController.translate("Zamówienia do wysłania")}:</span>
                                    <span>{this.state.acceptedServices}</span>
                                </div>
                            </div>
                        }
                    </Paper>
                </Container>

                {this.state.generateProtocol &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.generateProtocol} onClose={() => this.setState({generateProtocol: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <UITable
                                    data={this.state.generateProtocol}
                                    ref={table => this.protocolTable = table}
                                    selectable={true}
                                    columns={[
                                        {
                                            field: "numberMM",
                                            label: "Data wygenerowania listu przewozowego",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {formatDate(row.pickupCreateDate).dateTimeSystem}
                                                </div>
                                            )
                                        },
                                        {
                                            field: "numberMM",
                                            label: "Numer listu przewozowego",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {row.pickupNumber}
                                                </div>
                                            )
                                        },
                                        {
                                            field: "numberMM",
                                            label: "Numer zamówienia",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {row.order.id}
                                                </div>
                                            )
                                        },
                                        {
                                            field: "provider",
                                            label: "provider",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {row.provider.toUpperCase() ?? "---"}
                                                </div>
                                            )
                                        },
                                    ]}
                                />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({generateProtocol: false})} negative>Zamknij</Button>
                            <Button onClick={() => {
                                this.generateProtocol()
                                this.setState({generateProtocol: false})
                            }} positive>Generuj</Button>
                            {process.env.REACT_APP_RUN_GLS_PICKUP && <Button onClick={() => {
                                this.generateGLSProtocol()
                                this.setState({generateProtocol: false})
                            }} positive>Generuj GLS</Button>}
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.imageModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal} onClose={() => this.setState({imageModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <img style={{
                                    maxWidth: 500,
                                    margin: "0 auto",
                                    display: "block"
                                }} src={this.state.imageModal} alt=""/>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {(this.state.glsProtocol && process.env.REACT_APP_RUN_GLS_PICKUP) &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.glsProtocol} onClose={() => this.setState({glsProtocol: false, generateProtocolData: null, generateProtocolDataSelected: []})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content scrolling>
                            <div>
                                <Breadcrumb
                                    crumbs={[
                                        {
                                            label: `Protokół przekazania ${this.state.dataProtocolCount ? `(${this.state.dataProtocolCount})` : ""}`
                                        }
                                    ]}
                                />

                                <UITable
                                    ref={table => this.glsProtocolTable = table}
                                    dataFetch={{
                                        url: "/api/orders/delivery/getGlsProtocols",
                                        options: {limit: 25, currentPage: 1, filter: [
                                                {
                                                    label: "Zamówienie salonowe",
                                                    condition: "existsWithBoolean",
                                                    value: true,
                                                    field: "orderServices"
                                                },
                                                ... (DATA_OBJECT.user_login !== "root" ? [{
                                                    label: "salon",
                                                    condition: "like",
                                                    value: DATA_OBJECT.selected_parlour,
                                                    field: "parlour",
                                                }] : [])
                                            ]},
                                        callback: (result, count) => {
                                            this.setState({
                                                dataProtocolCount: count
                                            })
                                        }
                                    }}
                                    onPage={50}
                                    selectable={true}
                                    provider={{
                                        setState: value => true
                                    }}
                                    columns={[
                                        {
                                            field: "createdAt",
                                            label: "Stworzono",
                                            center: true,
                                            filters: [
                                                {label: "Sortuj data wygenerowania", condition: "in", type: "radio", forceField: "createdAt", options: [
                                                        {
                                                            value: "asc",
                                                            label: "Rosnąco"
                                                        }, {
                                                            value: "desc",
                                                            label: "Malejąco"
                                                        }
                                                    ]
                                                },
                                                {label: "Stworzono", condition: "between", type: "date", forceField: "createdAt"},
                                            ],
                                            render: row => (
                                                <div>
                                                    {formatDate(row.createdAt).dateTimeSystem}
                                                </div>
                                            )
                                        },
                                        {
                                            field: "pickupId",
                                            label: "Numer protokołu",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {row.pickupId}
                                                </div>
                                            ),
                                            filters: [
                                                {label: "Pickup", condition: "in", forceField: "pickupId", type: "in"},
                                            ],
                                        },
                                        {
                                            field: "orderServices",
                                            label: "Liczba zamówień w protokole",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {row.orderServices ? row.orderServices.length : '---'}
                                                </div>
                                            )
                                        },
                                        {
                                            label: "Akcje",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    <Button onClick={()=>{
                                                        api().get(`/api/orders/delivery/getProtocolReceipt/${row.pickupId}/gls?parlour=${DATA_OBJECT.selected_parlour}`)
                                                        .then(r => {
                                                            if(!r.data || !r.data.protocol || !r.data.protocol.data) {
                                                                return SnackBar("Błąd wygenerowania protokołu", "error")
                                                            }
                                                            const arrayBuffer = Uint8Array.from(r.data.protocol.data).buffer;

                                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                            const blobUrl = URL.createObjectURL(blob);
                                                            window.open(blobUrl, "_blank")
                                                        })

                                                    }}> Pobierz protokół</Button>
                                                </div>
                                            )
                                        },
                                    ]}
                                />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({glsProtocol: false, generateProtocolData: null, generateProtocolDataSelected: []})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.loading &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać...")}</Loader>
                    </Dimmer>
                }

            </React.Fragment>
        );
    }
}
