export default class InMemoryScanState {
    constructor() {
        if (InMemoryScanState.instance) {
            return InMemoryScanState.instance;
        }
        this.cache = new Map();
        InMemoryScanState.instance = this;
    }

    static getInstance() {
        if (!InMemoryScanState.instance) {
            InMemoryScanState.instance = new InMemoryScanState();
        }
        return InMemoryScanState.instance;
    }

    set(key, value) {
        this.cache.set(key, value);
    }

    get(key) {
        return this.cache.get(key);
    }

    deleteByPartialKey(partialKey) {
        for (let key of this.cache.keys()) {
            if (key.includes(partialKey)) {
                this.cache.delete(key);
            }
        }
    }

    has(key) {
        return this.cache.has(key);
    }
}