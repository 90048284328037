import React, { Component } from 'react';
import Button from "../Button";
// import axios from "axios";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";


class Uploader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            filesBin: [],
            removeFlag: false,
        }
    }

    uploadFile = async (e) => {
        if(!e.target.files[0]) return;
        const file = e.target.files[0];
        const name = e.target.files[0].name;
        this.setState({files: [...this.state.files, file]});
        for await (let img of e.target.files) {
            let b64result = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (readerEvent) => resolve(btoa(readerEvent.target.result));
                reader.readAsBinaryString(img);
            });

            this.state.filesBin.push({imageId: this.props.id, name: name, imageData: b64result})
        }
    }

  sendImage(e) {
        e.preventDefault();
            this.state.filesBin.map(item => {
                console.log(item)
           })
      if(this.state.filesBin.length === 0) return SnackBar("Nie wybrano pliku", "error")
      api(undefined, "http://localhost:50005" ).post(`${this.props.url}`, {
          data: JSON.stringify(this.state.filesBin)
      })
                .then(r => SnackBar("Dodano pliki."))
                .then(()=>this.props.refresh())
       };


    removeFile = (fileName) => {
        this.setState({
            files: this.state.files.filter( file => file.name !== fileName),
            filesBin: this.state.filesBin.filter(file => file.name !== fileName)
        })
    }

    render() {

        return (
            <>
                <div className="uploadFile-container">
                    <div className="uploadFile-input-container">
                        <label htmlFor="upload-input" className="custom-file-input">
                            <input id="upload-input" multiple accept='application/pdf, image/png' type="file" onChange={(e) => (this.uploadFile(e))} />
                            Kliknij i wybierz plik...
                        </label>
                    </div>
                    <ul className={"uploadFileComponent-list"}>
                        {this.state.files.length ?
                            this.state.files.map(file => (
                                <li key={file.name} >
                                    <span>{file.name}</span>
                                    <span><Button icon="delete" onClick={()=>this.removeFile(file.name)}></Button></span>
                                </li>
                            )
                        )
                        :
                        "Nie wybrano pliku"}
                    </ul>
                    <Button
                    type="submit"
                    onClick={(e)=> this.sendImage(e)}
                    >Wyślij</Button>
                </div>
            </>
        )
    }
}

export default Uploader;

