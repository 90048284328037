import {TranslationController} from "../controller/menager";

export const paymentMethodTypes = [
    {key: "online", value: "online", text: TranslationController ? TranslationController.translate("Online") : "Online"},
    {key: "transfer", value: "transfer", text: TranslationController ? TranslationController.translate("Przelew") : "Przelew"},
    {key: "on_delivery", value: "on_delivery", text: TranslationController ? TranslationController.translate("Pobranie") : "Pobranie"},
];

export const paymentMethodTypesMap = () => {
    const map = {};

    paymentMethodTypes.forEach(e => map[e.key] = e.text);

    return map;
};
