import React from "react";
import {Icon} from "semantic-ui-react";
import {closeTableMenu, preventCloseTableMenu} from "./index";

export default class TTableItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            renderChildren: [],
            subRenderChildren: null,
            renderSubChildren: false
        }
    }

    componentDidMount() {
        if (Array.isArray(this.props.children)) {
            this.setState({
                renderChildren: this.props.children[0],
                subRenderChildren: this.props.children.filter((e, k) => k !== 0)
            })
        } else {
            this.setState({
                renderChildren: this.props.children
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div
                    className={"tm-item"}
                    onClick={() => {
                        if (this.props.onClick) {
                            this.props.onClick();
                        }

                        if (this.state.subRenderChildren) {
                            this.setState({
                                renderSubChildren: !this.state.renderSubChildren
                            })
                        } else {
                            if (this.state.renderChildren.props && this.state.renderChildren.props.csvStructure) {
                                return;
                            }

                            closeTableMenu();
                        }
                    }}
                >
                    <Icon className={"tm-item-chevron"} name={"chevron right"}/>

                    {this.state.renderChildren}
                </div>

                {this.state.renderSubChildren &&
                    <div
                        className={"tm-item-children-wrapper"}
                        onMouseEnter={() => preventCloseTableMenu(true)}
                        onMouseLeave={() => preventCloseTableMenu(false)}
                    >
                        {this.state.subRenderChildren}
                    </div>
                }
            </React.Fragment>
        );
    }
}