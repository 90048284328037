import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PaperTitle from "../../components/PaperTitle";
import Container from "../../components/Container";
import {api} from "../../lib/axios";
import Button from "../../components/Button";



class defectSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            path: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1],
        }
    }

    componentDidMount() {
        this.init()
    }
    init() {
        api(undefined, undefined, undefined, true).post("/complaints/defects/get", {
            currentPage: 1,
            limit: 25,
            filter: [{
                condition: 'in',
                field: 'defectId',
                Label: 'defectId',
                value: this.state.path.split("-").join("/"),
            }]
        })
            .then(r => this.setState({data: r.data.data[0]}))

    }

    render() {
        return(

            <Container>
                {this.state.data &&
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <PaperTitle title={"Podsumowanie wady"}/>
                                <p>{`Stworzono nową wadę: ${this.state.path.split("-").join("/")}`}</p>
                                <Button onClick={()=>window.open(`#/defects/defectDetails/${this.state.data.defectId.split("/").join("-")}`, "_self")}>Pokaż szczegóły</Button>
                                <Button onClick={()=>window.open("#/defects", "_self")}>Wróć do listy</Button>
                            </Paper>
                        </Grid>
                    </Grid>
                }
            </Container>
        )
    }
}

export default defectSummary;