import React from "react";
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {TranslationController} from "../../controller/menager";
import {Menu, Modal, Segment} from "semantic-ui-react";
import TableMenu from "../../components/TableMenu";
import UITable from "../../lib/table-v2";
import Button from "../../components/Button";

export default class ProductImages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            appliedFilters: null
        }
    }

    init(limit, currentPage) {
        return api().post("/api/products/imagesView", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data});

                return r.data.data
            })
    }

    render() {
        return (
            <div>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Zdjęcia")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/products/imagesView",
                                options: {limit: 20, currentPage: 1}
                            }}
                            ref={table => this.userTable = table}
                            provider={this}
                            columns={[
                                {
                                    width: 3,
                                    field: "model_color_id",
                                    label: "Model",
                                    filters: [
                                        {label: "Model", condition: "likeEnd", type: "text"}
                                    ]
                                },
                                {
                                    field: "photos",
                                    label: "Zdjęcia",
                                    render: row => {
                                        return (
                                            <div>
                                                <div>
                                                    {row.photos.length ? row.photos.map((e, k) => (
                                                        <img onClick={() => {
                                                            this.setState({
                                                                imageModal: e
                                                            })
                                                        }} className={"pi-img"} key={k} src={e} alt=""/>
                                                    )) : "---"}
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            ]}
                        />
                    </Segment>
                </Container>

                {this.state.imageModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal}
                           onClose={() => this.setState({imageModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <img style={{
                                    maxWidth: 500,
                                    margin: "0 auto",
                                    display: "block"
                                }} src={this.state.imageModal} alt=""/>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </div>
        );
    }
}