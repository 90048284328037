import React from "react";

export default class TTableHeader extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className={"tm-header"}>
                {this.props.children}
            </div>
        );
    }
}