import React from "react"
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {TranslationController} from "../../controller/menager";
import {Dimmer, Loader, Message, Radio, Segment} from 'semantic-ui-react'
import Button from "../../components/Button";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";
import {Paper} from "@material-ui/core";
import PromotionCreatorBoxRow from "../promotions/promotionCreatorBoxRow";
import EsotiqInput from "../../components/EsotiqInput";
import UITable from "../../lib/table-v2";
import {saveToFile} from "../../controller/exportController";

export default class SKUSearchScene extends React.Component {
    StockSource = {
        OP: 'order_picker',
        SMT: 'smt'
    }
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            inputData: "",
            details: [],
            searchIn: this.StockSource.SMT
        }
    }


    changeSearchIn({ value }) {
        this.setState({
            searchIn: value
        })
    };

    async requestOrderPickerRawStock() {
       const opStock = await api().post('/api/states/raw-order-picker', {skuList: this.state.inputData.split()})
        this.setState({details: opStock.data.data})
    }

    getData() {
        if (!this.state.inputData) {
            SnackBar("Wpisz numer SKU", "error")

            return;
        }

        if (this.state.inputData.length < 5) {
            SnackBar("Numer SKU jest zbyt krótki", "error")

            return;
        }

        this.setState({
            details: []
        }, async () => {
            this.setState({
                loading: true
            })
            if (this.state.searchIn === this.StockSource.OP) {
                await this.requestOrderPickerRawStock()
                this.setState({loading: false})
                if (this.state.details && this.state.details.length) {
                    SnackBar("Pobrano stan magazynowy");
                } else {
                    SnackBar("Brak wyników", "error");
                }
            } else {
                api().post("/api/states/skuSearch", {sku: this.state.inputData})
                    .then((data) => {
                        return data
                    })
                    .then(r => {
                        this.setState({
                            loading: false
                        });

                        if (r.data.error) {
                            SnackBar(r.data.message, "error");

                            this.setState({
                                details: null
                            })
                        } else {
                            this.setState({
                                details: r.data.result
                            })

                            if (r.data.result && r.data.result.length) {
                                SnackBar("Pobrano stan magazynowy");
                            } else {
                                SnackBar("Brak wyników", "error");
                            }
                        }
                    })
            }
        })
    }

    async downloadCsv() {
        const opStock = await api().post('/api/states/raw-order-picker', {skuList: this.state.inputData.split(), isExport: true})
        saveToFile(opStock.data, null, null, null, "sku_search.csv");
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Wyszukaj towar")}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Segment className={"order-table-segment"} attached='bottom'>
                       <Paper>
                           <Message
                               header={"Wyszukaj towar"}
                               content={"Wprowadź SKU aby przeszukać wszystkie stany magazynowe"}
                               info
                           />

                           <PromotionCreatorBoxRow
                               label={"SKU"}
                               desc={"Pełny numer SKU"}
                               content={() => {
                                   return (
                                       <EsotiqInput
                                           width={"100%"}
                                           placeholder={"Wpisz tekst"}
                                           onChange={value => {
                                               this.state.inputData = value;
                                           }}
                                       />
                                   )
                               }}
                           />
                           <PromotionCreatorBoxRow label="Szukaj w" content={() => {
                               return (<>
                                       <Radio
                                           label='SMT'
                                           name='radioGroup'
                                           value={this.StockSource.SMT}
                                           checked={this.state.searchIn === this.StockSource.SMT}
                                           onChange={(_, value) => this.changeSearchIn(value)}
                                           style={{ marginRight: '1rem' }}
                                       />
                                       <Radio
                                           label='Order Picker'
                                           name='radioGroup'
                                           value={this.StockSource.OP}
                                           checked={this.state.searchIn === this.StockSource.OP}
                                           onChange={(_, value) => this.changeSearchIn(value)}
                                       /></>)
                           }}>

                           </PromotionCreatorBoxRow>
                           <PromotionCreatorBoxRow
                               label={""}
                               content={() => {
                                   return (
                                       <>
                                           <Button
                                               primary={true}
                                               style={{
                                                   marginRight: 20
                                               }}
                                               onClick={() => this.getData()}
                                           >
                                               {TranslationController.translate("Szukaj")}
                                           </Button>
                                           <Button
                                               primary={true}
                                               style={{
                                                   marginRight: 20
                                               }}
                                               disabled={this.state.details.length === 0 || this.state.searchIn === this.StockSource.SMT}
                                               onClick={() => this.downloadCsv()}
                                           >
                                               Eksportuj do .csv
                                           </Button>
                                       </>

                                   )
                               }}
                           />
                           {this.state.details && this.state.details.length > 0 && this.state.searchIn === this.StockSource.OP &&                                    <UITable
                               data={this.state.details}
                               showPagination={false}
                               columns={[
                                   {
                                       label: "SKU",
                                       field: "productSku",
                                       width: 1
                                   },
                                   {
                                       label: "Magazyn",
                                       field: "warehouseId",
                                       width: 1
                                   },
                                   {
                                       label: "Stan magazynowy",
                                       field: "quantity",
                                       width: 1
                                   },
                                   {
                                       label: "Rezerwacja",
                                       field: "reservedQuantity",
                                       width: 1
                                   },
                                   {
                                       label: "Dług",
                                       field: "quantityDebt",
                                       width: 1
                                   },

                               ]}
                           />}

                           {(this.state.details && this.state.details.length && this.state.searchIn === this.StockSource.SMT) ?
                               <React.Fragment>
                                   <UITable
                                       data={this.state.details}
                                       columns={[
                                           {
                                               label: "SKU",
                                               field: "sku",
                                               width: 1
                                           },
                                           {
                                               label: "Magazyn",
                                               field: "locId",
                                               width: 1
                                           },
                                           {
                                               label: "Dostępność",
                                               field: "qty",
                                               width: 1
                                           }
                                           ]}
                                       />
                               </React.Fragment> : <React.Fragment/>
                           }
                       </Paper>
                    </Segment>
                </Container>

                {this.state.loading &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać..")}</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        );
    }
}
