import React from "react"
import {Button as SemanticButton} from 'semantic-ui-react'
import {AccessController, TranslationController} from "../../controller/menager";
import { withRouter } from "react-router-dom";
import {jsonCopy} from "../../helpers/lib";

class Button extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            props: {
                ...this.props,
                content: this.props.content,
            }
        }
    }

    async componentDidMount() {
        let actionAllowed = true;
        const buttonLabel = this.props.children || this.props.content;

        if (!this.props.hideInAccessMap) {
            AccessController.setActionPoint(this.props.match, buttonLabel);

            actionAllowed = await AccessController.checkActionAccess(this.props.match, buttonLabel)
        }

        if (!buttonLabel) {
            actionAllowed = true
        }

        if (actionAllowed) {
            this.setState({
                visible: true
            })
        }

        this.state.props.content = TranslationController.translate(this.state.props.content);

        this.forceUpdate();
    }

    render() {
        return (
            this.state.visible ? <SemanticButton {...this.props}>
                {typeof this.props.children === "string" ? TranslationController.translate(this.props.children) : this.props.children}
            </SemanticButton> : null
        );
    }
}

export default withRouter(Button)
