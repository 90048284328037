import React from "react"
import ActionPanel from "../../components/ActionPanel";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import {Segment, Menu, Modal, Loader, Icon as SemanticIcon} from "semantic-ui-react";
import UITable from "../../lib/table-v2";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {formatDate} from "../../helpers/dateHelper";
import {api} from "../../lib/axios";
import Icon from '@material-ui/core/Icon';
import SnackBar from "../../components/Snack";
import Confirm from "../../components/Confirm";
import MenuButton from "../../lib/table-v2/menuButton";
import Dropdown from "../../components/Dropdown";
import {DATA_OBJECT} from "../../controller/dataController";
import {getCurrencyString} from "../../lib/currency";
import {TranslationController} from "../../controller/menager";
import TableMenu from "../../components/TableMenu";

export default class ErpListComplaints extends React.Component {
    constructor(props) {
        super(props);

        this.downloadLink = {}

        this.state = {
            generatedPdf: {},
            tableSelectable: false,
            tableSelectableState: [],
            paymentMethods: null,
            paymentMethodsList: null,
            loading: true
        }

        this.ALLOWED_REVERT_PAYMENT_METHODS = ["bluemedia", "paypo", "klarna"]
    }

    componentDidMount() {
        api().post("/api/system/payment-methods/get", {limit: 99999, currentPage: 1})
            .then(r => {
                const dataMap = {};

                r.data.data.map(method => {
                    dataMap[method._id] = method;
                    dataMap[method.externalId] = method;
                });

                this.setState({
                    paymentMethods: dataMap,
                    paymentMethodsList: r.data.data,
                    loading: false
                })
            });
    }

    init(limit, currentPage) {
        return api().post("/api/complaints/getErp", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    getRevertPrice(data) {
        let result = 0

        data.forEach(e => {
            result = result + parseFloat(e.revertProduct.finalQtyPrice)
        })

        return result
    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[]}
                />


                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: "ERP Zwroty"
                            }
                        ]}
                    />

                    <div style={{height: 10}}/>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                            <MenuButton
                                icon={"mail"}
                                onClick={() => {
                                    if (!this.orderTable.getSelectedState().length) {
                                        return SnackBar("Nie wybrano żadnego zgłoszenia.", "error")
                                    }

                                    return Confirm("Czy na pewno chcesz wysłać powiadomienie dla wybranych zgłoszeń?", "", () => {
                                        api().post("/api/complaints/notify", {complaints: this.orderTable.getSelectedState(), user: DATA_OBJECT.user_login})
                                            .then(r => {
                                                this.setState({
                                                    tableSelectableState: [],
                                                    tableSelectable: false
                                                });

                                                this.orderTable.setState({
                                                    data: null
                                                });

                                                this.orderTable.init()
                                            })
                                    })
                                }}
                                label={"Wyślij powiadomienie"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        {this.state.paymentMethodsList &&
                            <UITable
                                dataFetch={{
                                    url: "/api/complaints/getErp",
                                    options: {limit: 25, currentPage: 1},
                                    callback: async (orders) => {
                                        await (() => new Promise(resolve => {
                                            api(true).post("/api/complaints/bindOrderPaymentHistory",  {
                                                ordersIds: orders.data.map(e => e.orderId)
                                            })
                                                .then(r => {
                                                    if (r && r.data && r.data.result) {
                                                        orders.data.map(order => {
                                                            if (r.data.result[order.orderId]) {
                                                                order.paymentHistory = r.data.result[order.orderId];

                                                                if (order.amount && order.paymentHistory) {
                                                                    order.paymentHistory.forEach(ph => {
                                                                        if (ph.additionalData && ph.additionalData.updateData && ph.additionalData.updateData.price && ph.additionalData.updateData.status === "revert") {
                                                                            if (parseFloat(Math.abs(order.amount)) === parseFloat(ph.additionalData.updateData.price)) {
                                                                                order.markAsRed = true;
                                                                            }
                                                                        }
                                                                    })
                                                                }

                                                                if (this.orderTable) {
                                                                    this.orderTable.setState({
                                                                        data: orders.data
                                                                    });
                                                                }
                                                            }
                                                        })
                                                    }
                                                })
                                        }))();
                                    }
                                }}
                                selectable={this.state.tableSelectable}
                                ref={table => this.orderTable = table}
                                provider={this}
                                columns={[
                                    {
                                        label: "Numer zgłoszenia",
                                        field: "complaintNo",
                                        onClick: row => {
                                            window.open("#/complaint-list-warehouse/" + row._id, '_blank')
                                        },
                                        filters: [
                                            {label: "Numer zgłoszenia", condition: "like", type: "text"}
                                        ],
                                        render: row => row.complaintNo
                                            || "---"
                                    },
                                    {
                                        label: "Data",
                                        field: "data_spz_oper",
                                        render: row => formatDate(row.created).dateTimeSystem,
                                        center: true,
                                        filters: [
                                            {label: "Data", condition: "between", type: "date", forceField: "data_spz_oper"},
                                        ],
                                    },
                                    {
                                        label: "Sklep",
                                        field: "m_symbol",
                                        center: true,
                                        filters: [
                                            {
                                                label: "Sklep",
                                                condition: "like",
                                                type: "radio",
                                                options: [
                                                    {
                                                        value: "x99",
                                                        label: "X99"
                                                    },
                                                    {
                                                        value: "k99",
                                                        label: "K99"
                                                    },
                                                    {
                                                        value: "out",
                                                        label: "OUT"
                                                    },
                                                    {
                                                        value: "d99",
                                                        label: "D99"
                                                    }
                                                ]
                                            }
                                        ],
                                        render: row => (
                                            <div>
                                                {row.revertSource}
                                            </div>
                                        )
                                    },
                                    {
                                        label: "Typ",
                                        field: "revertType",
                                        render: row => !row.revertType ? "---" : row.revertType === "complaint" ? "Reklamacja" : row.revertType === "revert" ? "Zwrot" : "Zwrot + reklamacja",
                                        filters: [
                                            {label: "Typ", condition: "in", type: "checkbox", forceField: "revertType", options: [
                                                    {
                                                        value: "revert",
                                                        label: "Zwrot"
                                                    }, {
                                                        value: "complaint",
                                                        label: "Reklamacja"
                                                    }, {
                                                        value: "revert_complaint",
                                                        label: "Zwrot + reklamacja"
                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        label: "Zamówienie",
                                        field: "nr_zod_ich",
                                        filters: [
                                            {label: "Numer zamówienia", condition: "like", type: "text"},
                                            {label: "Metoda płatności", condition: "in", type: "checkbox", forceField: "paymentMethodId", options: this.state.paymentMethodsList.map(method => ({
                                                    value: method.externalId,
                                                    label: `${method.name} (${method.externalId})`
                                                }))
                                            },
                                        ],
                                        render: row => {
                                            return (
                                                <div>
                                                    <div>
                                                        <div className={"erp-order-present-label"}>Numer zamówienia:</div>
                                                        <a href={"#/order/" + row.orderId} target={"_blank"} className={"erp-order-present-value"}>{row.orderId || "---"}</a>
                                                    </div>

                                                    {row.orderData &&
                                                    <div>
                                                        <div className={"erp-order-present-label"}>Metoda płatności:</div>
                                                        <div className={"erp-order-present-value"}>{row.orderData.paymentMethod}</div>
                                                        {row.orderData.paymentProvider &&
                                                        <div className={"es-order-paymentProvider"}>{row.orderData.paymentProvider}</div>
                                                        }
                                                    </div>
                                                    }

                                                    {row.orderData && row.orderData.paymentMethodId && row.orderData.paymentMethodId.includes("paypal")
                                                        && row.orderData.paymentData && row.orderData.paymentData.extra_data &&
                                                        <div>
                                                            <div className={"erp-order-present-label"}>Numer płatności:</div>
                                                            <div className={"erp-order-present-value"}>{row.orderData.paymentData.extra_data.transaction_id}</div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        label: "Status",
                                        field: "status",
                                        render: row => {
                                            return (
                                                <div>
                                                    <div>
                                                        {row.orderData &&
                                                        <div className={"es-order-satus-display"}><span className={"label"}>Zamówienie:</span> <span style={{background: new OrderStatusesHelper().getColor(row.orderData.status), color: new OrderStatusesHelper().getFontColor(row.orderData.status)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.orderData.status)}</span></div>
                                                        }

                                                        {row.status ?
                                                            <div className={"es-order-satus-display"}><span className={"label"}>Zwrot:</span> <span style={{background: new OrderStatusesHelper().getColor(row.status), color: new OrderStatusesHelper().getFontColor(row.status)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.status)}</span></div>
                                                            : "---"}
                                                    </div>
                                                </div>
                                            )
                                        },
                                        filters: [
                                            {label: "Status zwrotu", condition: "in", type: "checkbox", forceField: "status", options: [
                                                    {
                                                        value: "complaintNew",
                                                        label: new OrderStatusesHelper().statuses.complaintNew.label
                                                    }, {
                                                        value: "complaintProgress",
                                                        label: new OrderStatusesHelper().statuses.complaintProgress.label
                                                    }, {
                                                        value: "complaintAccepted",
                                                        label: new OrderStatusesHelper().statuses.complaintAccepted.label
                                                    }, {
                                                        value: "complaintRejected",
                                                        label: new OrderStatusesHelper().statuses.complaintRejected.label
                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        label: "Zapytanie",
                                        field: "notifySended",
                                        filters: [
                                            {label: "Data zapytania", condition: "exists", type: "exists", forceField: "notifySended"},
                                            {label: "Data zapytania", condition: "between", type: "date", forceField: "notifySended"},
                                        ],
                                        render: row => {
                                            return (
                                                <div style={{color: "#545454"}} className={"value"}>
                                                    {row.notifySended ? <span className={"date-value"}>{formatDate(row.notifySended).dateTimeSystem}</span> : "---"}
                                                </div>
                                            )
                                        },
                                    },
                                    {
                                        label: "Odpowiedź",
                                        field: "notifyConfirmed",
                                        filters: [
                                            {label: "Data odpowiedzi", condition: "exists", type: "exists", forceField: "notifyConfirmed"},
                                            {label: "Data odpowiedzi", condition: "between", type: "date", forceField: "notifyConfirmed"},
                                        ],
                                        render: row => {
                                            return (
                                                <div style={{color: "#545454"}} className={"value"}>
                                                    {row.notifyConfirmed ? <span className={"date-value"}>{formatDate(row.notifyConfirmed).dateTimeSystem}</span> : "---"}
                                                </div>
                                            )
                                        },
                                    },
                                    {
                                        label: "Zlecony zwrot",
                                        field: "no",
                                        center: true,
                                        filters: [

                                            {label: "Zlecony zwrot", condition: "like", type: "radio", forceField: "revertOrder", options: [
                                                    {
                                                        value: true,
                                                        label: "Tak"
                                                    },
                                                    {
                                                        value: false,
                                                        label: "Nie"
                                                    },
                                                ]
                                            }
                                        ],
                                        onClick: row => {
                                            Confirm("Czy na pewno chcesz zaktualizować zlecenie zwrotu?", null, () => {
                                                api().post("/api/complaints/setRevertOrder", {mysqlId: row.mysqlId, complaintNo: row.complaintNo, rowId: row._id, user: DATA_OBJECT.user_login, revertOrder: row.revertOrder ? false : true})
                                                    .then(r => {
                                                        this.orderTable.setState({
                                                            data: null
                                                        });

                                                        this.orderTable.init()
                                                    })
                                            })
                                        },
                                        render: row => {
                                            return (
                                                <div style={{color: "#545454"}} className={"value"}>
                                                    {row.revertOrder ? <span style={{color: "#45b85d"}}><SemanticIcon name={"check circle outline"}/></span> : <span style={{color: "#df3b4e"}}><SemanticIcon name={"times circle outline"}/></span>}
                                                </div>
                                            )
                                        },
                                    },
                                    // {
                                    //     label: "Komunikaty",
                                    //     field: "notifySended",
                                    //     filters: [
                                    //         {label: "Data zapytania", condition: "exists", type: "exists", forceField: "notifySended"},
                                    //         {label: "Data zapytania", condition: "between", type: "date", forceField: "notifySended"},
                                    //         {label: "Data odpowiedzi", condition: "exists", type: "exists", forceField: "notifyConfirmed"},
                                    //         {label: "Data odpowiedzi", condition: "between", type: "date", forceField: "notifyConfirmed"},
                                    //         {label: "Zlecony zwrot", condition: "exists", type: "exists", forceField: "revertOrder"},
                                    //     ],
                                    //     onClick: row => {
                                    //         if (!row.complaintNo) return false;
                                    //
                                    //         Confirm("Czy na pewno chcesz zaktualizować zlecenie zwrotu?", null, () => {
                                    //             api().post("/api/complaints/setRevertOrder", {complaintNo: row.complaintNo, user: DATA_OBJECT.user_login, revertOrder: row.revertOrder ? false : true})
                                    //                 .then(r => {
                                    //                     this.orderTable.setState({
                                    //                         data: null
                                    //                     });
                                    //
                                    //                     this.orderTable.init()
                                    //                 })
                                    //         })
                                    //     },
                                    //     render: row => {
                                    //         return (
                                    //             <div>
                                    //                 <div>
                                    //                     <div className={"erp-notify-status"}>
                                    //                         <span className={"label"}>Zlecony zwrot:</span>
                                    //
                                    //                         <div style={{color: "#545454"}} className={"value"}>
                                    //                             {row.complaintNo ? row.revertOrder ? <span className={"date-value"}>Tak</span> : <span className={"date-value"}>Nie</span> : "---"}
                                    //                         </div>
                                    //                     </div>
                                    //
                                    //                     <div className={"erp-notify-status"}>
                                    //                         <span className={"label"}>Zapytanie:</span>
                                    //
                                    //                         <div style={{color: "#545454"}} className={"value"}>
                                    //                             {row.notifySended ? <span className={"date-value"}>{formatDate(row.notifySended).dateTimeSystem}</span> : "---"}
                                    //                         </div>
                                    //                     </div>
                                    //
                                    //                     <div className={"erp-notify-status"}>
                                    //                         <span className={"label"}>Odpowiedź:</span>
                                    //
                                    //                         <div style={{color: "#545454"}} className={"value"}>
                                    //                             {row.notifyConfirmed ? <span className={"date-value"}>{formatDate(row.notifyConfirmed).dateTimeSystem}</span> : "---"}
                                    //                         </div>
                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //         )
                                    //     },
                                    // },
                                    {
                                        label: "Faktura",
                                        width: 1,
                                        center: true,
                                        field: "numer",
                                        filters: [
                                            {label: "Faktura", condition: "like", type: "text"}
                                        ],
                                        onClick: row => {
                                            if (row.invoiceNo) {
                                                api().post("/api/orders/downloadInvoice",
                                                    {
                                                        forceDocumentNumber: row.invoiceNo.toString().replace(/\//g, '_')
                                                    }).then((r) => {
                                                    if (r.data.error) {
                                                        return SnackBar(r.data.message, "error")
                                                    } else {
                                                        if (r.data.fullpath) {
                                                            window.open(r.data.fullpath, "_blank")
                                                        }
                                                    }
                                                })
                                            }
                                        },
                                        render: row => <div style={{
                                            fontWeight: 500,
                                            color: "rgb(0, 117, 214)"
                                        }}>
                                            {row.invoiceNo
                                                ? <div>
                                                    {row.invoiceNo}
                                                </div>
                                                : <div>---</div>
                                            }

                                        </div>
                                    },
                                    {
                                        label: "Wartość",
                                        field: "wart_brt_pln",
                                        filters: [
                                            {label: "Większa niż", condition: "greater", type: "text"},
                                            {label: "Mniejsza niż", condition: "less", type: "text"},
                                            {label: "Pomiędzy", condition: "between", type: "between"}
                                        ],
                                        render: row => {
                                            return (
                                                <div>
                                                    {row.amount &&
                                                    <div style={{
                                                        marginBottom: 10
                                                    }}>
                                                        <div className={"erp-order-present-label"}>Wartość zwrotu:</div>
                                                        <div style={{
                                                            fontSize: "1.2em",
                                                            fontWeight: 600
                                                        }} className={"es-order-priceBox"}>{row.amount.toFixed(2)} <span>{row.orderData ? row.orderData.currency : "PLN"}</span></div>

                                                        {row.orderData && row.orderData.paymentMethodId && this.ALLOWED_REVERT_PAYMENT_METHODS.some(method => row.orderData.paymentMethodId.includes(method)) && row.orderData.paymentData && row.orderData.paymentData.external_id &&
                                                            <div>
                                                                {row.revertType === "complaint" &&
                                                                    <div style={{
                                                                        fontWeight: 600,
                                                                        color: "red",
                                                                        marginTop: 3,
                                                                        marginBottom: 5
                                                                    }}>
                                                                        Reklamacja
                                                                    </div>
                                                                }

                                                                <Button
                                                                    negative={(row.orderData && row.orderData.refound) || row.markAsRed}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            loadingData: true
                                                                        })

                                                                        api().post("/api/orders/payments/checkForRevert", {orderId: row.orderId})
                                                                            .then(r => {
                                                                                this.setState({
                                                                                    loadingData: false
                                                                                })

                                                                                let confirmText = r.data.isRefound ? `${TranslationController.translate("Dokonano już zwrotu na kwotę ")} ${r.data.refound.amount} ${TranslationController.translate(". Czy chcesz dokonać kolejnego zwrotu?")}` : "Czy na pewno chcesz dokonać zwrotu transakcji?";

                                                                                Confirm(<div style={r.data.isRefound ? {color: "#ff4141", fontWeight: "bold"} : null}>{confirmText}</div>, "", () => {
                                                                                    api().post("/api/orders/payments/bluemedia/revert", {provider: "bluemedia", rowId: row._id, paymentId: row.orderData.paymentData.payment_id, transactionId: row.orderData.paymentData.external_id, orderId: row.orderId, amount: Math.abs(row.amount.toFixed(2))})
                                                                                        .then(r => {
                                                                                            if (r.data.error) {
                                                                                                return SnackBar(r.data.message, "error")
                                                                                            }

                                                                                            SnackBar("Poprawnie dokonano zwrotu")
                                                                                        })
                                                                                }, true)
                                                                            })
                                                                    }}
                                                                >
                                                                    Zwrot
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                                    }
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        label: "Podgląd",
                                        width: 1,
                                        center: true,
                                        onClick: row => {
                                            if (row._id) {
                                                window.open("#/complaint-list-warehouse/" + row._id, '_blank')
                                            } else {
                                                SnackBar("To zgłoszenie jest jeszcze niedostępne do podglądu. Wyślij powiadomienie aby utworzyć numer zgłoszenia.", "error")
                                            }
                                        },
                                        render: row => <div>
                                            <Icon>search</Icon>
                                        </div>
                                    },
                                ]}
                            />
                        }
                    </Segment>

                    {this.state.loadingData &&
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}>
                        <div style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            background: "#fff",
                            opacity: 0.8
                        }}/>


                        <div style={{
                            width: "100%",
                            textAlign: "center",
                            position: "relative",
                            top: "40%",
                            fontSize: "1.6em",
                            color: "#2d2d2d",
                            fontWeight: 300
                        }}>
                            <div><Loader active></Loader></div>

                            <div style={{
                                width: "100%",
                                textAlign: "center",
                                position: "relative",
                                top: 50
                            }}>
                                {TranslationController.translate("Proszę czekać...")}
                            </div>
                        </div>
                    </div>
                    }

                    {this.state.loading &&
                        <div style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}>
                            <div style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                background: "#fff",
                                opacity: 0.8
                            }}/>


                            <div style={{
                                width: "100%",
                                textAlign: "center",
                                position: "relative",
                                top: "40%",
                                fontSize: "1.6em",
                                color: "#2d2d2d",
                                fontWeight: 300
                            }}>
                                <div><Loader active></Loader></div>

                                <div style={{
                                    width: "100%",
                                    textAlign: "center",
                                    position: "relative",
                                    top: 50
                                }}>
                                    {TranslationController.translate("Generowanie dokumentów...")}
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.imageModal &&
                        <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal}
                               onClose={() => this.setState({imageModal: false})}>
                            <Modal.Header></Modal.Header>
                            <Modal.Content>
                                <div>
                                    <img style={{
                                        maxWidth: 500,
                                        margin: "0 auto",
                                        display: "block"
                                    }} src={this.state.imageModal} alt=""/>
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                            </Modal.Actions>
                        </Modal>
                    }

                </Container>
            </React.Fragment>
        );
    }
}
