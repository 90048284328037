import React, {useEffect}  from "react";
import {makeStyles} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ReactDOM from "react-dom";
import Button from '@material-ui/core/Button';
import {TranslationController} from "../../controller/menager";
import {Icon, Input} from "semantic-ui-react";
import Confirm from "../Confirm";
import SnackBar from "../Snack";
import {api} from "../../lib/axios";

export let GLOBAL_GIFT_CARD_POPUP__UNSET = null

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        position: "relative",
        marginBottom: 15,
    },
    formControl: {
        width: "100%",
        minWidth: 500
    },
    orderHeader: {
        display: "flex",
        alignItems: "center",
        color: "#727272",
        fontWeight: 300,
        fontSize: "1.3em",
        marginLeft: 15,
        marginBottom: 15,
        paddingBottom: 10,
        borderBottom: "1px solid #DADADA"
    },
    orderHeaderSpan: {
        marginLeft: 10
    },
    dialogContent: {
        minWidth: "700px"
    },
    addProductBtn: {
        marginBottom: 10
    }
}));

function FormDialog(props) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(props.opennned);

    function handleClose() {
        setOpen(false);
        GLOBAL_GIFT_CARD_POPUP__UNSET()
    }

    function onScanner() {
        let input = document.getElementById("gift-card-scan-input");

        document.addEventListener("keypress", (e) => {
            if (e.target.tagName !== "INPUT") {
                input.focus();
            }
        });
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const input = document.getElementById("gift-card-scan-input");

            if (input) {
                onScanner();
                clearInterval(intervalId);
            }
        }, 100);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div>
            <Dialog maxWidth={false} className={classes.dialogContent} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <div>
                        <div className={"cart-title"}>
                            <Icon name={"barcode"}/>
                            {TranslationController.translate("Zeskanuj kartę podarunkową")}
                        </div>

                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <div>
                                <Input
                                    style={{
                                        width: 450
                                    }}
                                    placeholder={TranslationController.translate("Wprowadź numer karty podarunkowej")}
                                    id={"gift-card-scan-input"}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Anuluj
                    </Button>
                        <Button onClick={() => {
                            Confirm("Czy na pewno chcesz doładować kartę podarunkową?", "", () => {
                                const input = document.getElementById("gift-card-scan-input");

                                api().post("/api/gift-card/recharge", {
                                    cardNumber: input.value,
                                    orderId: props.options.orderId,
                                    userId: props.options.userId,
                                    sku: props.options.sku
                                })
                                    .then(result => {
                                        if (result.data.error) {
                                            return SnackBar(result.data.message, "error");
                                        }

                                        SnackBar("Doładowano kartę podarunkową.");
                                    })

                                GLOBAL_GIFT_CARD_POPUP__UNSET();
                            });
                        } } color="primary">
                            Doładuj
                        </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default function GiftCardRechargePopup(options) {
    const DOMElement = document.getElementById("modal-root");

    const ModalContent = () => (
        <FormDialog
            opennned={true}
            options={options}
        />
    )

    const removeModal = async () => {
        ReactDOM.unmountComponentAtNode(DOMElement)
    };

    GLOBAL_GIFT_CARD_POPUP__UNSET = removeModal;

    return ReactDOM.render(ModalContent(), DOMElement);
}