import React from 'react';
import Container from "../Container";
import Breadcrumb from "../Breadcrumb";
import { Menu, Pagination, Table } from "semantic-ui-react";
import ModalExampleModal from "./modal";
import CRUDForm from "./form";
import RemoveConfirm from "./removeConfirm";

export default class CRUDTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [...this.props.tableFields, {label: 'Akcje', key: 'actions'}],
        }
    }

    handlePaginationChange = (e, { activePage }) => {
        this.props.onPageChange(activePage);
    }

    render() {
        const { columns } = this.state;
        const { tableFields, schema, uiSchema, data,  totalPages, currentPage, label, createCallback, deleteCallback, editCallback } = this.props;

        return (
            <React.Fragment>
                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: label
                            }
                        ]}
                    />

                    <Menu floated='right'>
                        <Menu.Item>
                            <ModalExampleModal label="Dodaj" buttonColor='blue'>
                                <CRUDForm onSubmit={createCallback} schema={schema} uiSchema={uiSchema}/>
                            </ModalExampleModal>
                        </Menu.Item>
                    </Menu>

                    <Table className={"table-v2"} celled>
                        <Table.Header >
                            <Table.Row>
                                { columns.map((column) => {
                                    return (
                                        <Table.HeaderCell key={column.key}>{column.label}</Table.HeaderCell>
                                    )
                                })}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body >
                            {
                                data.map((row, index) => {
                                    return (
                                        <Table.Row key={index}>
                                            {
                                                tableFields.map((column, index) => {
                                                    return (
                                                        <Table.Cell key={column.label + index}>{column.getValue(row)}</Table.Cell>
                                                    )
                                                })
                                            }
                                            <Table.Cell>
                                                <ModalExampleModal label="Edytuj">
                                                    <CRUDForm
                                                        onSubmit={editCallback}
                                                        formData={row}
                                                        schema={schema}
                                                        uiSchema={uiSchema}
                                                    />
                                                </ModalExampleModal>
                                                <RemoveConfirm onClick={() => deleteCallback(row)} id={row.id}/>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                        {
                            data.length > 0 &&
                            <Table.Footer>
                                <Table.Row>
                                    <Pagination  totalPages={ totalPages } activePage={ currentPage } onPageChange={(e, page) => this.handlePaginationChange(e, page)}/>
                                </Table.Row>
                            </Table.Footer>
                        }
                    </Table>
                </Container>
            </React.Fragment>
        )
    }
}
