import React from "react";
import { Icon } from 'semantic-ui-react'

const GalleryModal = ({
                   clickedImg,
                   setClickedImg,
                   handelRotationRight,
                   handelRotationLeft
               }) => {
    const handleClick = (e) => {
        if (e.target.classList.contains("dismissGallery")) {
            setClickedImg(null);
        }
    };

    return (
        <>
            <div className="container-gallery-image dismissGallery" onClick={handleClick}>
                <img src={clickedImg} alt="image big" />
                <span className="dismissGallery" onClick={handleClick}>
         <Icon link name='close' />
        </span>
                <div  className="gallery-arrows_left">
                    <div>
                        <Icon onClick={handelRotationLeft} style={{cursor: "pointer"}} className="gallery-icon-arrows_left" size="huge" name="arrow alternate circle left"/>
                    </div>
                </div>
                <div onClick={handelRotationRight} className="gallery-arrows_right">
                    <div>
                        <Icon onClick={handelRotationLeft} style={{cursor: "pointer"}} className="overlay-icon-arrows_right" size="huge" name="arrow alternate circle right"/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GalleryModal;