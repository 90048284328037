import React, {useEffect} from "react"
import {
    getCategory,
    getProductCategory,
    getTree
} from "../../../controller/dataController";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {baseXHR} from "../../../lib/axios";
import AddIcon from '@material-ui/icons/Add';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import DataPresentation from "../../../components/DataPresentation";
import PaperTitle from "../../../components/PaperTitle";
import ActionPanel from "../../../components/ActionPanel";
import Chip from '@material-ui/core/Chip';
import Modal from "../../../components/Modal";
import Tree from "../../../components/Tree";
import Confirm from "../../../components/Confirm";
import SnackBar from "../../../components/Snack";
import Breadcrumb from "../../../components/Breadcrumb";
import DisplayPrice from "../../../components/display/DisplayPrice";
import Media from "../../../components/Media";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: "10px 0" }}>
            {props.children}
        </Typography>
    );
}

const data = {
    name: 'root',
    toggled: true,
    children: [
        {
            name: 'parent',
            children: [
                { name: 'child1' },
                { name: 'child2' }
            ]
        },
        {
            name: 'loading parent',
            loading: true,
            children: []
        },
        {
            name: 'parent',
            children: [
                {
                    name: 'nested parent',
                    children: [
                        { name: 'nested child 1' },
                        { name: 'nested child 2' }
                    ]
                }
            ]
        }
    ]
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        position: "relative",
        marginBottom: 15,
    },
    formControl: {
        width: "100%",
        minWidth: 500
    },
    orderHeader: {
        display: "flex",
        alignItems: "center",
        color: "#727272",
        fontWeight: 300,
        fontSize: "1.3em",
        marginLeft: 15,
        marginBottom: 15,
        paddingBottom: 10,
        borderBottom: "1px solid #DADADA"
    },
    orderHeaderSpan: {
        marginLeft: 10
    },
    dialogContent: {
        minWidth: "700px"
    },
    addProductBtn: {
        marginBottom: 10
    }
}));

function ProductPreview(props) {
    const productData = props.location.state

    const classes = useStyles()

    const [state, setState] = React.useState({});
    const [treeData, setTreeData] = React.useState(null)
    const [tab, setTab] = React.useState(0);
    const [productCategory, setProductCategory] = React.useState(null)

    const handleDeleteCategory = chipToDelete => () => {
        setProductCategory(chips => chips.filter(chip => chip.name !== chipToDelete.name));
    };

    function handleChangeTab(event, newValue) {
        setTab(newValue);
    }

    function onToggle(node, toggled){
        if(state.cursor){state.cursor.active = false;}
        node.active = true;
        if(node.nodes){ node.toggled = toggled; }
        setState({ cursor: node });

        if (!node.children_count) {
            let categories = productCategory
            categories.push(node)

            setProductCategory(categories)
        }

        return SnackBar()
    }

    useEffect(() => {

        getProductCategory(productData.entity_id)
            .then((r) => {
                let requestData = r.data.records

                requestData.forEach((e, k) => {
                    getCategory(e.category_id)
                        .then((req) => {
                            if (req.data.records) {
                                const data = req.data.records[0]
                                const categoryName = data.catalog_category_entity_varchar.find(item => item.attribute_id === 45).value

                                requestData[k]["name"] = categoryName
                            }
                        })
                        .then(() => setProductCategory(requestData))
                        .then(() => {
                            getTree("catalog_category_entity")
                                .then(r => {
                                    setTimeout(() => setTreeData(r), 300)
                                })
                        })
                })
            })
    }, [])


    return (
        <React.Fragment>
            <Breadcrumb
                crumbs={[
                    {
                        label: "Produkty",
                        href: "/products"
                    },
                    {
                        label: `${productData.product_name || ""}`,
                        icon: "search"
                    }
                ]}
            />

            <Tabs className={classes.root} value={tab} onChange={handleChangeTab}>
                <Tab label="Dane podstawowe" />
                <Tab label="Stany magazynowe" />
                <Tab label="Historia" />
                <Tab label="Obrazy" />
                <Tab label="Allegro" />
            </Tabs>

            {tab === 0 &&
                <TabContainer>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Paper className={classes.paper}>
                                <PaperTitle title={"Dane podstawowe"}/>

                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <DataPresentation
                                            label={"Aktywny"}
                                            data={productData.is_active}
                                            switch={true}
                                            onSwitch={() => {
                                                baseXHR().put("/catalog_product_entity_int" + `/${productData.VALUE_ID_catalog_product_entity_int}`, {value: productData.is_active ? 0 : 1})
                                                    .then(r => {
                                                        console.log(r, "done sales order")
                                                    })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <DataPresentation
                                            label={"Nowość"}
                                            data={productData.is_active}
                                            switch={true}
                                            onSwitch={() => {
                                                baseXHR().put("/catalog_product_entity_int" + `/${productData.VALUE_ID_catalog_product_entity_int}`, {value: productData.is_active ? 0 : 1})
                                                    .then(r => {
                                                        console.log(r, "done sales order")
                                                    })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <DataPresentation
                                            label={"Wyprzedaż"}
                                            data={productData.is_active}
                                            switch={true}
                                            onSwitch={() => {
                                                baseXHR().put("/catalog_product_entity_int" + `/${productData.VALUE_ID_catalog_product_entity_int}`, {value: productData.is_active ? 0 : 1})
                                                    .then(r => {
                                                        console.log(r, "done sales order")
                                                    })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <DataPresentation
                                            label={"Comming soon"}
                                            data={productData.is_active}
                                            switch={true}
                                            onSwitch={() => {
                                                baseXHR().put("/catalog_product_entity_int" + `/${productData.VALUE_ID_catalog_product_entity_int}`, {value: productData.is_active ? 0 : 1})
                                                    .then(r => {
                                                        console.log(r, "done sales order")
                                                    })
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <DataPresentation
                                            label={"Nazwa"}
                                            data={productData.product_name}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DataPresentation
                                            label={"SKU"}
                                            data={productData.sku}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <DataPresentation
                                            label={"Cena regularna"}
                                            data={<DisplayPrice value={productData.original_price}/>}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DataPresentation
                                            label={"Cena"}
                                            data={<DisplayPrice value={productData.price}/>}
                                        />
                                    </Grid>
                                </Grid>

                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className={classes.paper}>
                                <PaperTitle title={"Kategorie"}/>

                                <ActionPanel
                                    actions={[
                                        {
                                            icon: <AddIcon/>,
                                            label: "Dodaj koategorie",
                                            onClick: () => Modal(
                                                "Wybierz kategorię",
                                                <div style={{
                                                    width: "500px",
                                                    height: "400px"
                                                }}>
                                                    {treeData &&
                                                    <Tree callback={(data) => Confirm(
                                                        "",
                                                        "Czy na pewno chcesz dodać tą kategorię?",
                                                        () => onToggle(data)
                                                    )} data={treeData}/>
                                                    }
                                                </div>
                                            )
                                        }
                                    ]}
                                />

                                {productCategory &&
                                    productCategory.map((e, k) => {
                                        return (
                                            <Chip
                                                key={k}
                                                label={e.name}
                                                onDelete={handleDeleteCategory(e)}
                                                className={classes.chip}
                                            />
                                        )
                                    })
                                }
                            </Paper>

                            <Paper className={classes.paper}>
                                <PaperTitle title={"Cechy"}/>

                                <ActionPanel
                                    actions={[
                                        {
                                            icon: <AddIcon/>,
                                            label: "Dodaj cechę",
                                            onClick: () => Modal(
                                                "Wybierz cechę",
                                                    "In progress..."
                                            )
                                        }
                                    ]}
                                />
                            </Paper>

                            <Paper className={classes.paper}>
                                <PaperTitle title={"Media"}/>

                                <ActionPanel
                                    actions={[
                                        {
                                            icon: <AddIcon/>,
                                            label: "Dodaj media",
                                            onClick: () => Modal(
                                                "Wybierz plik",
                                                "In progress..."
                                            )
                                        }
                                    ]}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </TabContainer>
            }
        </React.Fragment>
    )
}

export default ProductPreview
