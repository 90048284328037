import React from "react"

export default class Container extends React.Component {
    render() {
        return (
            <div className={"app-layout-container"}>
                {this.props.children}
            </div>
        )
    }
}
