import React, { Component } from "react";
import UITable from "../../lib/table-v2";
import {formatDate} from "../../helpers/dateHelper";
import Icon from "@material-ui/core/Icon";
import Breadcrumb from "../../components/Breadcrumb";
import Container from "../../components/Container";
import TableMenu, {closeTableMenu, preventCloseTableMenu} from "../../components/TableMenu";
import {
    Menu,
    Segment,
    Checkbox,
} from 'semantic-ui-react'
import MenuButton from "../../lib/table-v2/menuButton";
import SnackBar from "../../components/Snack";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import ActionPanel from "../../components/ActionPanel";
import {api} from "../../lib/axios";
import Button from "../../components/Button";
import {saveToFile} from "../../controller/exportController";
import Confirm from "../../components/Confirm";



class ComplaintsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableSelectable: false,
            tab: 0,
            tabs: [],
            tabsInitialized: false,
            complaintsData: [],
            tableExportCols: [],
        }

        this.exportFields = [
            {
                field: "complaintId",
                label: "Numer reklamacji",
            },
            {
                field: "complaintData.receiptNumber",
                label: "Numer paragonu"
            },
            {
                field: "complaintData.price",
                label: "Cena"
            },
            {
                field: "complaintData.description",
                label: "Opis"
            },
            {
                field: "complaintData.index",
                label: "Index",
            },
            {
                field: "complaintData.complaintDate",
                label: "Data reklamacji",
                backend_format: "TIMESTAMP",
            },
            {
                field: "complaintData.subCategory",
                label: "Kategoria wady"
            },
            {
                field: "complaintData.demands",
                label: "Żądania klienta"
            },
            {
                field: "complaintData.appeal",
                label: "Liczba odwołań"
            },
            {
                field: "complaintData.status.mainStatus",
                label: "Status główny"
            },
            {
                field: "complaintData.status.subStatus",
                label: "Status dod."
            },
            {
                field: "clientData.names",
                label: "Imię i nazwisko"
            },
            {
                field: "clientData.phoneNumber",
                label: "Nr tel."
            },
            {
                field: "clientData.email",
                label: "Email"
            },
            {
                field: "clientData.street",
                label: "Ulica"
            },
            {
                field: "clientData.city",
                label: "Miasto"
            },
            {
                field: "clientData.postCode",
                label: "Kod pocztowy"
            },
            {
                field: "parlourData.parlourId",
                label: "Salon"
            },
            {
                field: "parlourData.rks",
                label: "RKS"
            },
            {
                field: "parlourData.city",
                label: "Kod pocztowy"
            },
        ]
    }

    componentDidMount() {
        this.init();
    }

    init() {
        api(undefined, "http://localhost:50005").post('complaints/get', {
            currentPage: 1,
            limit: 25,
            filter: [],
        })
            .then(r => this.setState({
                complaintsData: r.data.data
            }))
    }


    uniExport() {
        this.setState({
            loading: true
        });

        preventCloseTableMenu(false);
        closeTableMenu();

        return api(undefined, undefined, undefined, true).post("/complaints/csv", {
            isExport: {
                columns: this.state.tableExportCols,
                fields: this.exportFields
            },
            limit: 35000,
            currentPage: 1,
            filter: this.orderTable.state.appliedFilters,

        })
            .then(r => {
                this.setState({
                    loading: false,
                    tableExportCols: []
                });

                if (r.data.error) {
                    return SnackBar(r.data.message, "error")
                }

                return saveToFile(r.data);
            });
    }

    render() {
        return(
            <>
                { this.state.complaintsData &&
                    <><ActionPanel
                        actions={[]}
                    />
                        <Container>
                            <Breadcrumb
                                crumbs={[
                                    {
                                        label: `Reklamacje`
                                    }
                                ]
                                }
                            />
                        </Container>
                        <Container>
                            <Menu className={"order-menu"} attached='top'>
                                <TableMenu icon='wrench'>
                                    <TableMenu.Menu>
                                        <TableMenu.Item onClick={() => this.setState({
                                            tableSelectable: !this.state.tableSelectable,
                                            tableSelectableState: []
                                        })}>Zaznaczanie</TableMenu.Item>

                                        <TableMenu.Divider/>
                                        <TableMenu.Header>Export</TableMenu.Header>
                                        <TableMenu.Item>
                                            <span className='text'>Raport uniwersalny</span>

                                            <TableMenu.Menu>
                                                <div className={"uni-report-container"}>
                                                    <div className={"uni-report-header"}>Wybierz kolumny do raportu
                                                    </div>

                                                    <div className={"uni-report-content-box"}>
                                                        <div className={"uni-report-column"}>
                                                            <ul>
                                                                {this.exportFields.map((fieldValues, key) => {
                                                                    return (
                                                                        <li key={key}>
                                                                            <Checkbox key={key} onClick={(e) => {
                                                                                const field = fieldValues.field

                                                                                return !this.state.tableExportCols.includes(field) ? this.state.tableExportCols.push(field) : this.state.tableExportCols = this.state.tableExportCols.filter(col => col != field)
                                                                            }} label={fieldValues.label}/>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className={"uni-report-actions"}>
                                                        <Button
                                                            color='blue'
                                                            content='Pobierz'
                                                            icon='download'
                                                            label={{
                                                                basic: true,
                                                                color: 'blue',
                                                                pointing: 'left',
                                                                content: '.csv'
                                                            }}
                                                            onClick={() => this.uniExport()}
                                                        />
                                                    </div>
                                                </div>
                                            </TableMenu.Menu>
                                        </TableMenu.Item>
                                    </TableMenu.Menu>
                                </TableMenu>
                                <Menu.Menu position='left'>
                                    <MenuButton
                                        icon={"plus"}
                                        onClick={() => window.open("#/complaints/addComplaint", "_blank")}
                                        label={"Dodaj reklamację"}
                                    />
                                    <MenuButton
                                        icon={"file"}
                                        onClick={() => api(undefined, undefined, undefined, true).post("/complaints/csv", {
                                            isExport: {
                                                columns: ["complaintId", "complaintData.index", "parlourData.parlourId", "complaintData.complaintDate", "complaintData.subCategory"],
                                                fields: this.exportFields
                                            },
                                            limit: 35000,
                                            currentPage: 1,
                                            filter: this.orderTable.state.appliedFilters,
                                        }).then(r => {
                                            this.setState({
                                                loading: false,
                                                tableExportCols: []
                                            });

                                            if (r.data.error) {
                                                return SnackBar(r.data.message, "error")
                                            }

                                            return saveToFile(r.data);
                                        })
                                        }
                                        label={"Raport"}
                                    />
                                </Menu.Menu>
                            </Menu>
                            <Segment className={"order-table-segment"} attached='bottom'>
                                <UITable
                                    dataFetch={{
                                        url: "http://localhost:50005/complaints/get",
                                        options: {limit: 25, currentPage: 1},
                                        // useService: true
                                    }}
                                    provider={this}
                                    ref={table => this.orderTable = table}
                                    selectable={this.state.tableSelectable}
                                    rowStyle={(row) => {
                                        return ((row.complaintData.status.mainStatus === "complaintRejected") ? {
                                            opacity: 0.6
                                        } : null)
                                    }}
                                    columns={[{
                                        label: "Id",
                                        width: 1,
                                        field: "complaintId",
                                        filtersWidth: 300,
                                        filters: [
                                            {label: "Id", condition: "like", type: "text"},
                                        ],
                                        render: row =>
                                            <div>
                                                {row.complaintId}
                                            </div>

                                    },
                                        {
                                            width: 1,
                                            label: "RKS",
                                            field: "rks",
                                            filtersWidth: 300,
                                            filters: [
                                                {label: "Podaj inicjały", condition: "like", type: "text"},
                                            ],
                                            render: row => (
                                                <div>
                                                    {row.parlourData.rks}
                                                </div>
                                            )
                                        },
                                        {
                                            width: 1,
                                            label: "Status",
                                            field: "complaintData.status.mainStatus",
                                            filtersWidth: 300,
                                            filters: [
                                                {
                                                    label: "Status",
                                                    condition: "in",
                                                    type: "checkbox",
                                                    options: [
                                                        {
                                                            value: "complaintParlourNew",
                                                            label: "Przyjęta"
                                                        },
                                                        {
                                                            value: "complaintParlourAccepted",
                                                            label: "Zaakceptowana"
                                                        },
                                                        {
                                                            value: "complaintRejected",
                                                            label: "Nieuznana"
                                                        },
                                                        {
                                                            value: "complaintAppeal",
                                                            label: "Odwołanie"
                                                        },
                                                        {
                                                            value: "complaintExpert",
                                                            label: "Rzeczoznawca"
                                                        }
                                                    ]
                                                },
                                            ],
                                            render: row => (<div>
                                                    {row.complaintData.status.mainStatus &&
                                                        <div className={"es-order-satus-display"}><span style={{
                                                            background: new OrderStatusesHelper().getColor(row.complaintData.status.mainStatus),
                                                            color: new OrderStatusesHelper().getFontColor(row.complaintData.status.mainStatus)
                                                        }} className={"value"}>{new OrderStatusesHelper().getLabel(row.complaintData.status.mainStatus)}</span>
                                                        </div>
                                                    }
                                                    {/*{row.complaintData.status.subStatus &&*/}
                                                    {/*    <div className={"es-order-satus-display"}><span*/}
                                                    {/*        className={"value"}*/}
                                                    {/*        style={{*/}
                                                    {/*            background: new OrderStatusesHelper().getColor(row.complaintData.status.subStatus),*/}
                                                    {/*            color: new OrderStatusesHelper().getFontColor(row.complaintData.status.subStatus)*/}
                                                    {/*        }}>*/}
                                                    {/*     {new OrderStatusesHelper().getLabel(row.complaintData.status.subStatus)}</span>*/}
                                                    {/*    </div>}*/}
                                                    {(row.complaintData.status.steps && row.complaintData.status.steps.length !== 0) &&
                                                        <div className={"es-order-satus-display"}><span
                                                                className={"value"}
                                                                style={{
                                                                    background: new OrderStatusesHelper().getColor(row.complaintData.status.steps[row.complaintData.status.steps.length-1]),
                                                                    color: new OrderStatusesHelper().getFontColor(row.complaintData.status.steps[row.complaintData.status.steps.length-1])
                                                                }}>
                                                         {new OrderStatusesHelper().getLabel(row.complaintData.status.steps[row.complaintData.status.steps.length-1])}</span>
                                                            </div>
                                                    }
                                                </div>
                                            )
                                        },
                                        {
                                            label: "Paragon",
                                            field: "complaintData.receiptNumber",
                                            filters: [
                                                {label: "Paragon", condition: "like", type: "text"},
                                            ],
                                            render: row => (
                                                <div>
                                                    {row.complaintData.receiptNumber}
                                                </div>
                                            )
                                        },
                                        {
                                            label: "Index",
                                            field: "index",
                                            filters: [
                                                {
                                                    label: "Index",
                                                    condition: "like",
                                                    forceField: "complaintData.index",
                                                    type: "text"
                                                },
                                            ],
                                            render: row => (
                                                <div>
                                                    {row.complaintData.index}
                                                </div>
                                            )
                                        },
                                        {
                                            label: "D.rozpatrzenia",
                                            center: true,
                                            field: "complaintData.resolutionDate",
                                            filters: [
                                                {
                                                    label: "Data rozwązania sprawy",
                                                    condition: "between",
                                                    type: "date",
                                                },
                                            ],
                                            render: row => (
                                                <div>
                                                    {formatDate(row.complaintData.resolutionDate).dateNow}
                                                </div>
                                            )
                                        },
                                        {
                                            label: "D.reklamacji",
                                            field: "complaintData.complaintDate",
                                            center: true,
                                            filters: [
                                                {
                                                    label: "Data reklamacji",
                                                    condition: "between",
                                                    type: "date",
                                                },
                                            ],
                                            render: row => (
                                                <div>
                                                    {formatDate(row.complaintData.complaintDate).dateNow}
                                                </div>
                                            )
                                        },
                                        {
                                            label: "Szczegóły",
                                            render: row => (
                                                <div>
                                                    {row.complaintData.description}
                                                </div>
                                            )
                                        },
                                        {
                                            label: "Podgląd",
                                            width: 1,
                                            center: true,
                                            onClick: row => {
                                                window.open("#/complaints/complaintDetails/" + row.complaintId.split("/").join('-'), '_blank')
                                            },
                                            render: row => <div>
                                                <Icon>search</Icon>
                                            </div>
                                        },
                                        {
                                            label: "Usuń",
                                            width: 1,
                                            center: true,
                                            render: row =>  <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                Confirm("Czy na pewno chcesz usunąć pozycję?", "", () => {
                                                    api(undefined, undefined, undefined, true).post("/complaints/remove", {
                                                        data: {
                                                            id: row._id
                                                        }
                                                    })
                                                        .then(() => {
                                                            SnackBar("Usunięto")
                                                            this.orderTable.setState({
                                                                data: null
                                                            })

                                                            this.orderTable.init()
                                                        })
                                                })
                                            }}>
                                                <i className='trash link icon' />
                                            </div>
                                        },
                                    ]
                                    }
                                />
                            </Segment>
                        </Container>
                    </>
                }
            </>
        )
    }
}

export default ComplaintsList;