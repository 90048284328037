import React from 'react'
import { Button, Modal } from 'semantic-ui-react'


export default class ModalForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        open: false
    }
  }

  closeModal = () => {
    this.setState({open: false})
  }

  render() {
    const { open } = this.state;
    const { label, buttonColor, children } = this.props;
    const childrenWithProps = React.Children.map(children, child => {
      return React.cloneElement(child, { doneClick: this.closeModal });
    });

    return (
      <Modal
        onClose={() => this.setState({open: false})}
        onOpen={() => this.setState({open: true})}
        open={open}
        trigger={<Button color={ buttonColor }>{ label }</Button>}
      >
        <Modal.Content>
          { childrenWithProps }
        </Modal.Content>
      </Modal>
    )
  }
}
