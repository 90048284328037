import React, { Component } from 'react';
import PaperTitle from "../../components/PaperTitle";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DataPresentation from "../../components/DataPresentation";
import {Button as SemanticButton, Comment, Form, Icon, Loader, Menu, Modal, Table} from "semantic-ui-react";
import Confirm from "../../components/Confirm";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {formatDate} from "../../helpers/dateHelper";
import Button from "../../components/Button";
import {PDFDownloadLink} from "@react-pdf/renderer";
import PDFcomplaintParlour from "./PDFcomplaintParlour";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";
import Uploader from "../../components/UploadFile";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {DATA_OBJECT} from "../../controller/dataController";
import EsotiqInput from "../../components/EsotiqInput";
import Gallery from "../../components/Gallery";


const statusMap = {
    complaintParlourNew: [
        {status: "complaintParlourAccepted",label: "Zaakceptowana"},
        {status: "complaintRejected",label: "Nieuznana"},
        {status: "complaintExpert",label: "Rzeczoznawca"},
        {status: "complaintAppeal",label: "Odwołanie"},
    ],
    complaintParlourAccepted: [
        {status: "complaintRepair", label: "Naprawa"},
        {status: "complaintChange", label: "Wymiana"},
        {status: "complaintRefund", label: "Zwrot pieniędzy/karta podarunkowa"}],
    complaintSubRejected: [
        {status: "customerNotified", label: "Klient powiadomiony"},
        {status: "complaintEnd", label: "Reklamacja zakończona"}
    ],
    complaintRejected: [
        {status: "complaintSubRejected", label: "Reklamacja nie została uznana"},
    ],
    complaintExpert: [
        {status: "complaintHandOverToExpert", label: "Przekazano"},
        {status: "complaintAnswer", label: "Odpowiedź"}
    ],
    complaintAppeal: [],
    complaintRepair: [
        {status: "handOverForRepair", label: "Przekazano do naprawy"},
        {status: "handOverFromRepair", label: "Przyjęto z naprawy"},
        {status: "customerNotified", label: "Klient powiadomiony"},
        {status: "complaintEnd", label: "Reklamacja zakończona"}
    ],
    complaintChange: [
        {status: "customerNotified", label: "Klient powiadomiony"},
        {status: "complaintEnd", label: "Reklamacja zakończona"}
    ],
    complaintRefund: [
        {status: "customerNotified", label: "Klient powiadomiony"},
        {status: "complaintEnd", label: "Reklamacja zakończona"}
    ]
}

class ComplaintDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showStatusModal: false,
            loading: false,
            isEditableState: false,
            changeStatus: null,
            selectedStatus: null,
            subStatus: null,
            setSubStatus: false,
            selectedSubStatus: null,
            commentValue: "",
            statusDescription: "",
            rksMail: "",
            indexData: {},
            indexOptions: [],
            path: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1]
        }
    }

    componentDidMount() {
        api(undefined, "http://localhost:50005").post('/complaints/get', {
            currentPage: 1,
            limit: 25,
            filter:[{
                condition: 'in',
                field: 'complaintId',
                Label: 'complaintId',
                value:  this.state.path.split("-").join("/"),
            }]
        }).then( r => this.setState({data: r.data.data[0]}))
        this.getIndex();
    }

     getIndex() {
         api().post("/api/products/getEan", {})
            .then(r => {
                this.setState({indexData: r.data.data})
                this.state.indexData.map(row => {
                    this.state.indexOptions.push({text: row.sku, value: row.sku})
                })
            })

    }

    changeStatus() {
        if (this.state.selectedSubStatus === "complaintSubRejected") {
            if (!this.state.statusDescription) {
                return SnackBar("Nie podałeś powodu odrzucenia.", "error")
            }

            if (this.state.statusDescription.length < 4) {
                return SnackBar("Wprowadzony tekst jest za krótki.", "error")
            }
        }

        const complaint = this.state.data
        let historyValue;
        complaint.complaintData.status.statusDescription = null;
        if(this.state.selectedSubStatus === "complaintRepair" || this.state.selectedSubStatus === "complaintChange" || this.state.selectedSubStatus === "complaintRefund") {
                historyValue = "Zmieniono status zgłoszenia z " + new OrderStatusesHelper().getLabel(complaint.complaintData.status.mainStatus) + " na " + new OrderStatusesHelper().getLabel(this.state.selectedSubStatus) + "."
                complaint.complaintData.status.subStatus = this.state.selectedSubStatus;
                complaint.complaintData.status.mainStatus = "complaintParlourAccepted";
            if(complaint.complaintData.status.steps) complaint.complaintData.status.steps.length = 0;
            }
            if(this.state.selectedSubStatus === "complaintHandOverToExpert" || this.state.selectedSubStatus === "complaintAnswer" ){
                historyValue = "Zmieniono status zgłoszenia z " + new OrderStatusesHelper().getLabel(complaint.complaintData.status.mainStatus) + " na " + new OrderStatusesHelper().getLabel(this.state.selectedSubStatus) + "."
                complaint.complaintData.status.subStatus = this.state.selectedSubStatus;
                complaint.complaintData.status.mainStatus = "complaintExpert";
                if(complaint.complaintData.status.steps) complaint.complaintData.status.steps.length = 0;
            }

        if(this.state.selectedSubStatus === "complaintSubRejected"){
            if(complaint.complaintData.status.mainStatus && !complaint.complaintData.status.subStatus) {
                historyValue = "Zmieniono status zgłoszenia z " + new OrderStatusesHelper().getLabel(complaint.complaintData.status.mainStatus) + " na " + new OrderStatusesHelper().getLabel(this.state.selectedSubStatus) + "."
                complaint.complaintData.status.subStatus = this.state.selectedSubStatus;
                complaint.complaintData.status.mainStatus = "complaintRejected";
                if(complaint.complaintData.status.steps) complaint.complaintData.status.steps.length = 0 ;
            } else {
                historyValue = "Zmieniono status zgłoszenia z " + new OrderStatusesHelper().getLabel(complaint.complaintData.status.subStatus) + " na " + new OrderStatusesHelper().getLabel(this.state.selectedSubStatus) + "."
                complaint.complaintData.status.subStatus = this.state.selectedSubStatus;
                complaint.complaintData.status.mainStatus = "complaintRejected";
                if(complaint.complaintData.status.steps) complaint.complaintData.status.steps.length = 0;
            }
        }
        if(this.state.selectedStatus === "complaintAppeal" ){
            historyValue = "Zmieniono status zgłoszenia z " + new OrderStatusesHelper().getLabel(complaint.complaintData.status.mainStatus) + " na " + new OrderStatusesHelper().getLabel(this.state.selectedStatus) + "."
            complaint.complaintData.status.mainStatus = "complaintAppeal";
            complaint.complaintData.status.subStatus = null;
            if(complaint.complaintData.status.steps) complaint.complaintData.status.steps.length = 0;
            complaint.complaintData.appeal += 1;
        }

        if(this.state.statusDescription && complaint.complaintData.status.mainStatus === "complaintRejected"){
            historyValue += " Reklamacja odrzucona z powodu: " + this.state.statusDescription;
            complaint.complaintData.status.statusDescription = this.state.statusDescription;
            this.state.statusDescription = null;
        } else if(this.state.statusDescription){
            historyValue += " Opis zmiany statusu: " + this.state.statusDescription;
            complaint.complaintData.status.statusDescription = this.state.statusDescription;
            this.state.statusDescription = null;
        }

        complaint.history.push({
            value: historyValue, timestamp: new Date().getTime(), subValue: DATA_OBJECT["user"].login
        })

        if(this.state.selectedStatus === "complaintAppeal") {
            api(undefined, undefined, undefined, true).post("/complaints/sendMail",
                {
                    complaintId: complaint.complaintId,
                    rksMail: "pbrzezinski@esotiq.com",
                    appeal: true,
                })
                .then(result => {
                    if (result.data.success) {
                        SnackBar("Wysłano powiadomienie e-mail");
                    } else {
                        SnackBar("Błąd podczas wysyłania powiadomienia. Sprawdź konfigurację.", "error");
                    }
                })
        }


        api(undefined, undefined, undefined, true).post("/complaints/update", {
                data: {
                    id: complaint._id,
                    update: {
                        complaintData: complaint.complaintData,
                        history: complaint.history
                    }
                }
            }
        ).then(() =>
            this.setState({
                changeStatus: null,
                selectedStatus: null,
            }),
            SnackBar("Pomyślnie zaktualizowano.")
        )
    }

    addComment() {
        if (!this.state.commentValue) {
            return SnackBar("Wprowadzona wartość nie może być pusta.", "error")
        }

        if (this.state.commentValue.length < 4) {
            return SnackBar("Wprowadzony tekst jest za krótki.", "error")
        }

        const complaint = this.state.data
        if (!complaint.comments) {
            complaint.comments = []
        }

        complaint.comments.push({
            value: this.state.commentValue,
            timestamp: new Date().getTime(),
            user: DATA_OBJECT.user_login,
        })

        api(undefined, "http://localhost:50005").post("/complaints/comments/add", {
        data: {
            id: complaint._id,
            update: {
                comments: complaint.comments
            }
        }
        })
            .then(() => {
                this.setState({
                    commentValue: "",
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    removeComment(comment) {
        if (DATA_OBJECT.user_login !== comment.user) {
            return SnackBar("Możesz usuwać tylko własne komenatrze", "error")
        }

        const complaint = this.state.data

        complaint.comments = complaint.comments.filter(comm => comm !== comment)

        api(undefined, "http://localhost:50005").post("/complaints/comments/remove", {
            data: {
                id: complaint._id,
                update: {
                    comments: complaint.comments
                }
            }
        })
            .then(() => {
                this.setState({
                    refresh: new Date().getTime(),
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    update() {

        api(undefined, "http://localhost:50005").post("/complaints/update", {
            data: {
                id: this.state.data._id,
                update: {
                    complaintData: this.state.data.complaintData,
                    clientData: this.state.data.clientData,
                },
            }
        })
            .then(() => {
                this.setState({
                    changeStatus: null,
                    selectedStatus: null,
                    rejectedReason: null
                })
                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    galleryRefresh() {
        api(undefined, "http://localhost:50005").post('/complaints/get', {
            currentPage: 1,
            limit: 25,
            filter:[{
                condition: 'in',
                field: 'complaintId',
                Label: 'complaintId',
                value:  this.state.path.split("-").join("/"),
            }]
        }).then( r => this.setState({data: r.data.data[0]}))
    }

    changeSteps(step, k) {
        let historyValue;
        const complaint = this.state.data;
        const stepsLength = complaint.complaintData.status.steps ? complaint.complaintData.status.steps.length : 0;
        if(stepsLength !== k) return SnackBar("Nieprawidłowa kolejność statusów!", "error");
        if(complaint.complaintData.status.steps && complaint.complaintData.status.steps[k] === step) return SnackBar("Ten status już został wybrany", "error");
        if(!complaint.complaintData.status.steps || complaint.complaintData.status.steps.length === 0) {
            historyValue = "Zmieniono status zgłoszenia z " + new OrderStatusesHelper().getLabel(complaint.complaintData.status.subStatus) + " na " + new OrderStatusesHelper().getLabel(step) + "."
            if(!complaint.complaintData.status.steps || complaint.complaintData.status.steps.length === 0) {
                const stepsArr = [];
                stepsArr.push(step);
                complaint.complaintData.status.steps = stepsArr;
            } else {
                complaint.complaintData.status.steps.push(step);
            }
        } else {
            historyValue = "Zmieniono status zgłoszenia z " + new OrderStatusesHelper().getLabel(complaint.complaintData.status.steps[complaint.complaintData.status.steps.length-1]) + " na " + new OrderStatusesHelper().getLabel(step) + ".";
             if(!complaint.complaintData.status.steps || complaint.complaintData.status.steps.length === 0) {
                 const stepsArr = [];
                 stepsArr.push(step);
                 complaint.complaintData.status.steps = stepsArr;
             } else {
                 complaint.complaintData.status.steps.push(step);
             }
        }

        complaint.history.push({
            value: historyValue, timestamp: new Date().getTime(), subValue: DATA_OBJECT["user"].login
        })

        api(undefined, undefined, undefined, true).post("/complaints/update", {
                data: {
                    id: complaint._id,
                    update: {
                        complaintData: complaint.complaintData,
                        history: complaint.history
                    }
                }
            }
        ).then(() =>
                this.setState({
                    changeStatus: null,
                    selectedStatus: null,
                }),
            SnackBar("Pomyślnie zaktualizowano.")
        )

    }


    render() {
        const {data} = this.state;

        return(
            <>
                {data && this.state.data &&
                    <Container>
                        < Breadcrumb
                            crumbs={[
                                {
                                    label: "Podgląd zgłoszenia"
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>

                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Paper>
                                    <PaperTitle title={"Dane podstawowe"}/>
                                    <div style={{display: "flex"}}>
                                        <div>
                                            {!this.state.isEditableState ?
                                                <Button
                                                    style={{
                                                        marginBottom: 5,
                                                        marginRight: 5,
                                                        position: "relative",
                                                        top: -10
                                                    }}
                                                    onClick={() => (this.setState({isEditableState: true}))}
                                                >
                                                    {"Edycja"}
                                                </Button>
                                                :
                                                <>
                                                    <Button
                                                        style={{
                                                            marginBottom: 5,
                                                            marginRight: 5,
                                                            position: "relative",
                                                            top: -10
                                                        }}
                                                        onClick={() => (this.setState({isEditableState: false}), this.update())}
                                                    >
                                                        {"Zapisz"}
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            marginBottom: 5,
                                                            marginRight: 5,
                                                            position: "relative",
                                                            top: -10
                                                        }}
                                                        onClick={() => this.setState({isEditableState: false})}
                                                    >
                                                        {"Anuluj"}
                                                    </Button>
                                                </>
                                            }
                                        </div>
                                        <div style={{
                                            position: "relative",
                                            top: -10
                                        }}>
                                            <div style={{marginBottom: 10}}>
                                                {
                                                    <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                     document={<PDFcomplaintParlour data={this.state.data}/>}
                                                                     fileName={`Formularz reklamacji`}>
                                                        {({blob, url, loading, error}) => (loading ? <Button
                                                            color='blue'
                                                            loading={true}
                                                            label={{
                                                                basic: true,
                                                                color: 'blue',
                                                                pointing: 'left',
                                                                content: '.pdf'
                                                            }}
                                                        /> : <div>
                                                            <Button
                                                                color='blue'
                                                                content='Drukuj'
                                                                icon='download'
                                                                label={{
                                                                    basic: true,
                                                                    color: 'blue',
                                                                    pointing: 'left',
                                                                    content: '.pdf'
                                                                }}
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    window.open(url, '_blank')

                                                                    return true
                                                                }}
                                                            />
                                                        </div>)}
                                                    </PDFDownloadLink>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <Grid container spacing={2}>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    label={"Id"}
                                                    data={data.complaintId}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                {
                                                    <DataPresentation
                                                    label={"Status"}
                                                    data={
                                                        <div style={{display: "flex", flexDirection: "row"}}>
                                                            {(this.state.data.complaintData.status.mainStatus === "complaintParlourNew" || this.state.data.complaintData.status.mainStatus === "complaintAppeal") &&
                                                                <div className={"es-order-satus-display"}><span
                                                                    className={"value"}
                                                                    style={{
                                                                        background: new OrderStatusesHelper().getColor(data.complaintData.status.mainStatus),
                                                                        color: new OrderStatusesHelper().getFontColor(data.complaintData.status.mainStatus)
                                                                    }}>
                                                                {new OrderStatusesHelper().getLabel(data.complaintData.status.mainStatus)}</span>
                                                                </div>
                                                            }
                                                            {this.state.data.complaintData.status.subStatus &&
                                                                <div className={"es-order-satus-display"}><span
                                                                    className={"value"}
                                                                    style={{
                                                                        background: new OrderStatusesHelper().getColor(data.complaintData.status.subStatus),
                                                                        color: new OrderStatusesHelper().getFontColor(data.complaintData.status.subStatus)
                                                                    }}>
                                                        {new OrderStatusesHelper().getLabel(data.complaintData.status.subStatus)}</span>
                                                                </div>}</div>
                                                    }
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={6} style={{position: "relative"}}>
                                                    <Button style={{position: "absolute", top: "25px", right: "30px"}}
                                                            onClick={() => (this.setState({showStatusModal: true}))}>Zmień
                                                        status</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid xs={6}>
                                                <DataPresentation label="Kolejne kroki: " data={
                                                    this.state.data.complaintData.status.steps &&
                                                    <div className={"es-order-satus-display"}><span
                                                        className={"value"}
                                                        style={{
                                                            background: new OrderStatusesHelper().getColor(data.complaintData.status.steps[data.complaintData.status.steps.length-1]),
                                                            color: new OrderStatusesHelper().getFontColor(data.complaintData.status.steps[data.complaintData.status.steps.length-1])
                                                        }}>
                                                        {new OrderStatusesHelper().getLabel(data.complaintData.status.steps[data.complaintData.status.steps.length-1])}</span>
                                                    </div>}/>
                                                {data.complaintData.status.statusDescription &&
                                                    <DataPresentation label="Opis zmiany statusu" data={
                                                        <span>{data.complaintData.status.statusDescription}</span>
                                                    } />
                                                }
                                            </Grid>
                                            <Grid xs={6}>
                                                {this.state.data.complaintData.status.subStatus &&
                                                    <div className="nextStep-list" style={{width: "100%"}}>
                                                        <div>
                                                            <SemanticButton.Group style={{
                                                                display: "flex", flexDirection: "column", maxWidth:"100%", marginTop: "25px"
                                                            }}>
                                                                {
                                                                    statusMap[data.complaintData.status.subStatus]?.map((e, k) => {
                                                                        return (
                                                                            <SemanticButton
                                                                                active={ data.complaintData.status.steps && data.complaintData.status.steps[k]=== e.status }
                                                                                key={k}
                                                                                onClick={() => Confirm("Czy na pewno chcesz zmienić status", "", ()=>
                                                                                    this.changeSteps(e.status, k)
                                                                                )}

                                                                            >{e.label}
                                                                            </SemanticButton>
                                                                        )
                                                                    })
                                                                }
                                                            </SemanticButton.Group>
                                                        </div>
                                                    </div>}
                                            </Grid>
                                        </Grid>

                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    label="Ilość odwołań"
                                                    data={data.complaintData.appeal}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={(v) => this.state.data.complaintData.price = v}
                                                    label="Cena reklamowanej rzeczy"
                                                    data={data.complaintData.price}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    options={this.state.indexOptions}
                                                    onChange={(v) => this.state.data.complaintData.index = v}
                                                    label="Index"
                                                    data={data.complaintData.index}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={(v) => this.state.data.complaintData.receiptNumber = v}
                                                    label="Paragon"
                                                    data={data.complaintData.receiptNumber}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    label="Data zakupu"
                                                    data={formatDate(data.complaintData.purchaseDate).dateNow}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    label="Data reklamacji"
                                                    data={formatDate(data.complaintData.complaintDate).dateNow}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    label="Żądanie klienta"
                                                    data={data.complaintData.demands}
                                                    options={[
                                                        {text: "Nieodpłatna naprawa", value: "Nieodpłatna naprawa"},
                                                        {text: "Wymiana na nowy", value: "Wymiana na nowy"},
                                                        {text: "Zwrot zapłaconej ceny", value: "Zwrot zapłaconej ceny"},
                                                        {
                                                            text: "Obniżenie zapłaconej ceny",
                                                            value: "Obniżenie zapłaconej ceny"
                                                        }
                                                    ]
                                                    }
                                                    onChange={(v) => this.state.data.complaintData.demands = v}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={(v) => this.state.data.complaintData.description = v}
                                                    inputType="textArea"
                                                    label="Opis"
                                                    data={data.complaintData.description}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    onChange={(v) => this.state.rksMail = v}
                                                    label="RKS"
                                                    data={data.parlourData.rksMail}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Paper>
                                <Paper>
                                    <PaperTitle title={"Dołączone pliki"}/>
                                    {data.images &&
                                        <Gallery images={this.state.data.images } urlPath={"complaints/public/uploads"} useService={false}/>
                                    }
                                            <PaperTitle title={"Załącz pliki"}/>
                                            <Uploader useService={true} id={data.complaintId} data={data} refresh={()=>(this.galleryRefresh())} url={"/complaints/upload"}/>
                                        </Paper>
                            </Grid>

                            <Grid item xs={6}>
                                <Paper>
                                    <PaperTitle title={"Dane klienta"}/>
                                    <Grid container spacing={2}>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={(v) => this.state.data.clientData.names = v}
                                                    label={"Imię i nazwisko"}
                                                    data={data.clientData.names}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={(v) => this.state.data.clientData.phoneNumber = v}
                                                    label={"Telefon"}
                                                    data={data.clientData.phoneNumber}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={(v) => this.state.data.clientData.email = v}
                                                    label="Email"
                                                    data={data.clientData.email}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={(v) => this.state.data.clientData.address = v}
                                                    label="Adres"
                                                    data={data.clientData.address}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={(v) => this.state.data.clientData.city = v}
                                                    label="Miasto"
                                                    data={data.clientData.city}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={(v) => this.state.data.clientData.postcode = v}
                                                    label="Kod-pocztowy"
                                                    data={data.clientData.postCode}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper>
                                    <PaperTitle title={"Komentarze"}/>
                                    {data.comments ?
                                        <Comment.Group>
                                            {data.comments.map((comment, key) => {
                                                return (
                                                    <Comment key={key}>
                                                        <Comment.Avatar
                                                            src='https://react.semantic-ui.com/images/avatar/small/matt.jpg'/>
                                                        <Comment.Content>
                                                            <Comment.Author as='a'>{comment.user}</Comment.Author>
                                                            <Comment.Metadata>
                                                                <div>{formatDate(comment.timestamp).dateTimeSystem}</div>
                                                            </Comment.Metadata>
                                                            <Comment.Text>{comment.value}</Comment.Text>
                                                            <Comment.Actions>
                                                                <Comment.Action
                                                                    onClick={() => this.removeComment(comment)}
                                                                >Usuń</Comment.Action>
                                                            </Comment.Actions>
                                                        </Comment.Content>
                                                    </Comment>
                                                )
                                            })}
                                        </Comment.Group>
                                        : <div style={{
                                            textAlign: "center",
                                            color: "#c6c6c6",
                                            margin: 20
                                        }}>Jeszcze nikt nie dodał żadnego komentarza</div>
                                    }


                                    <Form
                                        reply
                                        onSubmit={() => {
                                            (this.addComment())
                                        }}
                                    >
                                        <Form.TextArea
                                            placeholder={"Treść..."}
                                            onChange={(e, v) =>
                                                this.state.commentValue = e.target.value
                                            }
                                        />
                                        <Button content='Dodaj komentarz' labelPosition='left' icon='edit' primary/>
                                    </Form>
                                </Paper>
                                <Paper>
                                    <PaperTitle title={"Historia"}/>
                                    {data.history ?
                                        data.history.sort((a, b) => b.timestamp - a.timestamp).map((history, key) => {
                                            return (
                                                <div className={"complaint-preview-history-box"} key={key}>
                                                    <div className={"complaint-preview-history-box-icon"}>
                                                        <Icon name={"caret right"}/>
                                                    </div>
                                                    <div className={"complaint-preview-history-box-data"}>
                                                        <div
                                                            className={"complaint-preview-history-box-data-date"}>{formatDate(history.timestamp).dateTimeSystem}</div>
                                                        <div className={"complaint-preview-history-box-data-value"}>
                                                            {history.subValue &&
                                                                <span>({history.subValue})</span>
                                                            }

                                                            {history.value}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <div style={{
                                            textAlign: "center",
                                            color: "#c6c6c6",
                                            margin: 20
                                        }}>Brak danych</div>}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                }
                {this.state.showStatusModal &&
                    <Modal dimmer={"inverted"} size={"small"} open={this.state.showStatusModal} onClose={() => this.setState({showStatusModal: false, statusDescription: ""})}>
                        <Modal.Header>Statusy reklamacji</Modal.Header>
                        <Modal.Content>
                            <div>
                                <SemanticButton.Group style={{
                                    display: "flex", flexDirection: "column", maxWidth:"100%"
                                }}>
                                    { this.state.data &&
                                        statusMap["complaintParlourNew"]?.map((e, k) => {
                                            const active = this.state.selectedStatus === "complaintAppeal" && this.state.selectedStatus === e.status;
                                            return (<>
                                                    <SemanticButton
                                                        primary={active ? false : true}
                                                        toggle
                                                        color={active ? 'red' : null}
                                                        key={k}
                                                        onClick={() => {
                                                            this.setState({
                                                                selectedStatus: e.status
                                                            })
                                                        }}

                                                    >{e.label}
                                                    </SemanticButton>
                                                    <SemanticButton.Group style={{
                                                        display: "flex", flexDirection: "column", maxWidth:"100%"
                                                    }}>
                                                        {
                                                            statusMap[e.status]?.map((e, k) => {
                                                                return (
                                                                    <SemanticButton
                                                                        active={ this.state.selectedSubStatus === e.status}
                                                                        key={k}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                selectedSubStatus: e.status
                                                                            })
                                                                        }}

                                                                    >{e.label}
                                                                    </SemanticButton>
                                                                )
                                                            })
                                                        }
                                                    </SemanticButton.Group>
                                                </>
                                            )
                                        })
                                    }
                                </SemanticButton.Group>

                            </div>
                            <div>
                                    <EsotiqInput label="Opis" textArea placeholder="Wymagany tylko w przypadku odmowy"
                                              onChange={(v) => this.state.statusDescription = v}/>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => (this.setState({showStatusModal: false}), this.changeStatus())
                            }>Zapisz</Button>
                            <Button onClick={() => this.setState({showStatusModal: false, statusDescription: ""})}>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </>
        )
    }
}

export default ComplaintDetails;