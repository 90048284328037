import React from "react"
import {Icon, Menu, Segment, Loader, Dimmer} from 'semantic-ui-react'
import Paper from '@material-ui/core/Paper';
import PaperTitle from "../../../components/PaperTitle";
import {api, STATIC_URL} from "../../../lib/axios";
import UITable from "../../../lib/table-v2";
import {formatDate} from "../../../helpers/dateHelper";
import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import {TranslationController} from "../../../controller/menager";
import MenuButton from "../../../lib/table-v2/menuButton";
import {DATA_OBJECT} from "../../../controller/dataController";
import SnackBar from "../../../components/Snack";

export default class OrderHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            visibleData: {}
        }
    }

    componentDidMount() {
        api().post("/api/orders/history", {orderId: this.props.orderId})
            .then(r => {
                this.setState({
                    data: r.data
                })
            })
    }

    prettifyXml(sourceXml)
    {
        let xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
        let xsltDoc = new DOMParser().parseFromString([
            // describes how we want to modify the XML - indent everything
            '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
            '  <xsl:strip-space elements="*"/>',
            '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
            '    <xsl:value-of select="normalize-space(.)"/>',
            '  </xsl:template>',
            '  <xsl:template match="node()|@*">',
            '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
            '  </xsl:template>',
            '  <xsl:output indent="yes"/>',
            '</xsl:stylesheet>',
        ].join('\n'), 'application/xml');

        let xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xsltDoc);
        let resultDoc = xsltProcessor.transformToDocument(xmlDoc);
        let resultXml = new XMLSerializer().serializeToString(resultDoc);
        return resultXml;
    };

    render() {
        const {orderData} = this.props

        return (
            <React.Fragment>
                <div style={{height: 10}}></div>

                <div>
                    <Menu attached='top'>
                        <Menu.Menu position='left'>
                        </Menu.Menu>
                    </Menu>

                    <Segment attached='bottom'>
                        {this.state.data ?
                            <div>
                                <UITable
                                    data={this.state.data}
                                    columns={[
                                        {
                                            label: "Użytkownik",
                                            field: "userId",
                                            width: 1,
                                            center: true,
                                            render: row => {
                                                return (
                                                    <div className={"order-history-user"}>
                                                        <Icon name={"user"}/>

                                                        {row.additionalData.updateData ? row.additionalData.updateData.userId ?? row.additionalData.updateData.user : ( row.userId || "---" )}
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            label: "Komunikat",
                                            field: "title",
                                            render: row => {
                                                return (
                                                    <div
                                                        className={"order-history-wrapper"}
                                                    >
                                                        <div
                                                            className={"order-history-action-wrapper"}
                                                            onClick={() => {
                                                                if (this.state.visibleData[row.createdAt]) {
                                                                    delete this.state.visibleData[row.createdAt]
                                                                } else {
                                                                    this.state.visibleData[row.createdAt] = true
                                                                }
                                                                this.forceUpdate()
                                                            }}
                                                        >
                                                            <div className={"order-history-date"}>
                                                                <Icon name={"time"}/>

                                                                {formatDate(row.createdAt).dateTimeSystem}
                                                            </div>
                                                            <div
                                                                className={"order-history-title"}
                                                            >
                                                                <Icon name={"angle right"}/>

                                                                {row.title}
                                                            </div>
                                                        </div>

                                                        {row.additionalData && this.state.visibleData[row.createdAt] &&
                                                            <div style={{
                                                                background: "#282828",
                                                                padding: "5px 10px",
                                                                fontSize: "0.85em",
                                                                marginTop: 10
                                                            }}>
                                                                {row.additionalData.xml
                                                                    ?
                                                                    <pre style={{
                                                                        color: "#d6d6d6"
                                                                    }}>
                                                                      {this.prettifyXml(row.additionalData.xml)}
                                                                    </pre>
                                                                    :
                                                                    <div>
                                                                        {(row.additionalData.updateData && row.additionalData.updateData.delivery) &&
                                                                            <div style={{
                                                                                marginTop: 5
                                                                            }}>
                                                                                <Button primary onClick={() => {
                                                                                    switch (row.additionalData.updateData.delivery.type) {
                                                                                        case "gls":
                                                                                            if(row.additionalData.updateData.delivery && row.additionalData.updateData.delivery.pickupId) {
                                                                                                api().get(`/api/orders/delivery/getLabel/${orderData.delivery.pickupId}/gls?parlour=${DATA_OBJECT.selected_parlour}`).then(
                                                                                                    (result) => {
                                                                                                        const arrayBuffer = Uint8Array.from(result.data.label.data).buffer;

                                                                                                        const blob = new Blob([arrayBuffer], {type: 'application/pdf'});
                                                                                                        const blobUrl = URL.createObjectURL(blob);
                                                                                                        window.open(blobUrl, "_blank")
                                                                                                    }
                                                                                                )
                                                                                            } else {
                                                                                                SnackBar("Etykieta nie istnieje")
                                                                                            }
                                                                                            break;
                                                                                        case "dpd":
                                                                                            if (row.additionalData.updateData.delivery && row.additionalData.updateData.delivery.data && row.additionalData.updateData.delivery.data.pickupFile) {
                                                                                                window.open(`${STATIC_URL}${row.additionalData.updateData.delivery.data.pickupFile}`)
                                                                                            } else {
                                                                                                SnackBar("Etykieta nie istnieje")
                                                                                            }

                                                                                            break;
                                                                                        case "inpost":
                                                                                            if (row.additionalData.updateData.delivery && row.additionalData.updateData.delivery.data && row.additionalData.updateData.delivery.data.status === "created") {
                                                                                                api().post("/api/orders/delivery/getLabel", {orderId: orderData.id, provider: "inpost", forceId: row.additionalData.updateData.delivery.data.id})
                                                                                                    .then(result => {
                                                                                                        if (result.data) {
                                                                                                            if (result.data.error) {
                                                                                                                return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                                                            }

                                                                                                            fetch(result.data.urlParams.url, {
                                                                                                                method: result.data.urlParams.method,
                                                                                                                headers: result.data.urlParams.headers
                                                                                                            })
                                                                                                                .then(res => {
                                                                                                                    return res.blob()
                                                                                                                })
                                                                                                                .then(blob => {
                                                                                                                    const file = window.URL.createObjectURL(blob);

                                                                                                                    window.open(file, "_blank")
                                                                                                                })
                                                                                                        }
                                                                                                    })
                                                                                            } else {
                                                                                                SnackBar("Etykieta nie istnieje")
                                                                                            }

                                                                                            break;
                                                                                        case "dhl":
                                                                                            if (row.additionalData.updateData.delivery && row.additionalData.updateData.delivery.data && row.additionalData.updateData.delivery.data.CreationState && row.additionalData.updateData.delivery.data.CreationState[0] && row.additionalData.updateData.delivery.data.CreationState[0].shipmentNumber) {
                                                                                                window.open(row.additionalData.updateData.delivery.data.CreationState[0].LabelData.labelUrl)
                                                                                            } else {
                                                                                                SnackBar("Etykieta nie istnieje")
                                                                                            }

                                                                                            break;
                                                                                        case "ruch":
                                                                                            if (row.additionalData.updateData.delivery && row.additionalData.updateData.delivery.data && row.additionalData.updateData.delivery.data.fileName) {
                                                                                                window.open(`${STATIC_URL}${row.additionalData.updateData.delivery.data.fileName}`)
                                                                                            } else {
                                                                                                SnackBar("Etykieta nie istnieje")
                                                                                            }

                                                                                            break;

                                                                                    }
                                                                                }}>
                                                                                    Pobierz etykietę
                                                                                </Button>
                                                                            </div>
                                                                        }
                                                                        <pre style={{
                                                                            color: "#d6d6d6"
                                                                        }}>
                                                                          {JSON.stringify(row.additionalData, undefined, 2)}
                                                                        </pre>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        }
                                    ]}
                                />

                                {!this.state.isFullGet &&
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    data: null,
                                                    isFullGet: true
                                                }, () => {
                                                    api().post("/api/orders/history-older", {orderId: this.props.orderId})
                                                        .then(r => {
                                                            this.setState({
                                                                data: r.data
                                                            })
                                                        })
                                                })
                                            }}
                                        >
                                            Pobierz więcej
                                        </Button>
                                    </div>
                                }
                            </div>
                            :
                            <div className={"loader-box"}>
                                <div style={{marginTop: 20}}/>
                                <Loader active>{TranslationController.translate("Pobieranie historii...")}</Loader>
                            </div>
                        }
                    </Segment>
                </div>

                <div style={{height: 10}}/>

            </React.Fragment>
        )
    }
}
