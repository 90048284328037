import React from "react"
import Breadcrumb from "../../components/Breadcrumb";
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import UITable from "../../lib/table-v2";
import {Segment, Menu, Dimmer, Loader, Label, Table, Modal} from 'semantic-ui-react'
import Dropdown from "../../components/Dropdown";
import {TranslationController} from "../../controller/menager";
import {countries} from "../../lib/currency";
import MenuButton from "../../lib/table-v2/menuButton";
import SnackBar from "../../components/Snack";
import Confirm from "../../components/Confirm";
import {saveToFile} from "../../controller/exportController";
import TableMenu from "../../components/TableMenu";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import CsvImport from "../../components/CsvImport";
import EsotiqInput from "../../components/EsotiqInput";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import Button from "../../components/Button";
import {DATA_OBJECT} from "../../controller/dataController";
import {productsImport} from "../../scenes/products/import";

class Products extends React.Component {
    state = {
        data: null,
        websiteFilter: [],
        dataCount: null,
        tableSelectable: false,
        tableSelectableState: [],
        importProductModal: false,
    }

    componentDidMount() {
        this.init(20, 1)
    }

    init(pageSize, currentPage) {
        this.setState({
            noLimitCall: api(false, undefined, false, true).post("/products")
                .then(r => {
                    return r.data.data
                })
        })

        return api(false, undefined, false, true).post("/products", {pageSize, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    render() {
        const columns = [
            {
                label: "Zdjęcie",
                width: "1",
                field: "isImage",
                render: (row) => {
                    return <img src={((row.images && row.images[0]) ? row.images[0].file : null)} alt=""/>
                },
            },
            {
                field: "name",
                label: "Nazwa",
                filters: [
                    {label: "Nazwa", condition: "like", type: "text"},
                ],
                render: row => (
                    <div>
                        {row.countries && Object.keys(row.countries).map(country => {
                            return row.countries[country].name ? <div>{`(${country.split("_")[1].toUpperCase()}) ${row.countries[country].name}`}</div> : null
                        })}
                    </div>
                )
            },
            {
                field: "model",
                label: "Model",
                filters: [
                    {label: "Model", condition: "in", type: "in", regexp: true}
                ]
            },
            {
                field: "none",
                label: "Ceny",
                center: true,
                filters: [
                    {label: "Brand", condition: "eq", type: "checkbox", forceField: "price_brand", options: [
                            {
                                value: "esotiq",
                                label: "Esotiq"
                            },
                            {
                                value: "henderson",
                                label: "Henderson"
                            },
                            {
                                value: "finalsale",
                                label: "Finalsale"
                            },
                            {
                                value: "vosedo",
                                label: "Vosedo"
                            }
                        ]
                    },
                    {label: "Kraj", condition: "eq", type: "checkbox", forceField: "price_country", options: countries.map(e => ({
                            value: e.value,
                            label: e.text
                        }))},
                ],
                render: row => (
                    <div>
                        <Table compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Brand")}</Table.HeaderCell>
                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Kraj")}</Table.HeaderCell>
                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Cena")}</Table.HeaderCell>
                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Cena bazowa")}</Table.HeaderCell>
                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Cena referencyjna")}</Table.HeaderCell>
                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Przecena")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {row.countries && Object.keys(row.countries).map((item, index) => {
                                    return (
                                        <Table.Row
                                            key={index}
                                            className={"currency-prices-table"}
                                        >
                                            <Table.Cell style={{
                                                textTransform: "capitalize"
                                            }}>
                                                {item.split("_")[0]}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.split("_")[1].toUpperCase()}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {row.countries[item].price}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {row.countries[item].base_price}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {row.countries[item].reference_price}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {row.countries[item].discount_percentage ? `${row.countries[item].discount_percentage.toFixed(1)}%` : "---"}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                )
            },
            // {
            //     field: "season",
            //     label: "Pobierz zdj.",
            //     center: true,
            //     onClick: (row) => {
            //         Confirm("Czy na pewno chcesz rozpocząć proces importu?", null, () => {
            //             SnackBar("Pobrano zdjęcia. Odśwież produkty.")
            //
            //             api().post("/api/products/import-images", {
            //                 models: [row.sku]
            //             })
            //                 .then(() => {
            //                 })
            //         });
            //     },
            //     render: row => (<div>
            //         <Icon name={"sync"}/>
            //     </div>)
            // },
        ]

        return (
            <React.Fragment>
                <Container>
                    {!this.props.simple &&
                        <React.Fragment>
                            <Breadcrumb
                                crumbs={[
                                    {
                                        label: `Produkty ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                    }
                                ]}
                            />

                            <div style={{height: 10}}></div>
                        </React.Fragment>
                    }


                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Import</TableMenu.Header>
                                <TableMenu.Item>
                                    <CsvImport
                                        csvStructure={[
                                            {column: "model*"},
                                        ]}
                                        onUpdate={(data) => {
                                            this.setState({
                                                loading: true
                                            });

                                            api().post("/api/products/syncCurrentProducts", {data})
                                                .then((data) => {
                                                    if (data.data.error) {
                                                        return SnackBar(data.data.message, "error")
                                                    } else {
                                                        this.orderTable.setState({
                                                            data: null
                                                        });

                                                        this.orderTable.init();

                                                        this.setState({
                                                            loading: false
                                                        });

                                                        return data
                                                    }
                                                })
                                        }}
                                    />
                                </TableMenu.Item>

                                {/*<TableMenu.Divider />*/}
                                {/*<TableMenu.Header>Import</TableMenu.Header>*/}
                                {/*<TableMenu.Item>*/}
                                    {/*<CsvImport*/}
                                        {/*onUpdate={(data) => {*/}
                                            {/*return api().post("/api/products/massUpdate", {data})*/}
                                                {/*.then((data) => {*/}
                                                    {/*this.orderTable.setState({*/}
                                                        {/*data: null*/}
                                                    {/*});*/}

                                                    {/*this.orderTable.init();*/}

                                                    {/*return data*/}
                                                {/*})*/}
                                        {/*}}*/}
                                    {/*/>*/}
                                {/*</TableMenu.Item>*/}
                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                            <MenuButton
                                label={"Pobierz produkty bez zdjęć"}
                                icon={"upload"}
                                onClick={() => {
                                    api().post("/api/products/downloadNoImageProducts", {data: null})
                                        .then((data) => {
                                            if (data.data.error) {
                                                return SnackBar(data.data.message, "error")
                                            } else {
                                                if (data.data) {
                                                    saveToFile(data.data, null, null, null, "no_imgs_products.csv");
                                                }
                                            }
                                        })
                                }}
                            />
                            <MenuButton
                                label={"Aktualizuj produkty z CSV"}
                                icon={"download"}
                                onClick={()=>this.setState({importProductModal: true})}
                            />

                            {/*<MenuButton*/}
                                {/*label={"Synchronizuj produkty z AppChance"}*/}
                                {/*icon={"sync"}*/}
                                {/*onClick={() => {*/}
                                    {/*this.setState({*/}
                                        {/*loading: true*/}
                                    {/*});*/}

                                    {/*api().post("/api/products/syncExternalProducts", {data: null})*/}
                                        {/*.then((data) => {*/}
                                            {/*this.orderTable.setState({*/}
                                                {/*data: null*/}
                                            {/*});*/}

                                            {/*this.orderTable.init();*/}

                                            {/*this.setState({*/}
                                                {/*loading: false*/}
                                            {/*});*/}

                                            {/*return data*/}
                                        {/*})*/}
                                {/*}}*/}
                            {/*/>*/}
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/products",
                                options: {pageSize: 25, currentPage: 1},
                                useService: true
                            }}
                            ref={table => this.orderTable = table}
                            selectable={this.state.tableSelectable}
                            provider={this}
                            columns={this.props.addColumns ? [...columns, ...this.props.addColumns] : columns}
                        />
                    </Segment>
                </Container>

                {this.state.importProductModal &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.importProductModal} onClose={() => {
                        this.setState({
                            importProductModal: false,
                        });
                    }}>
                        <Modal.Header>{TranslationController.translate("Wybierz plik CSV")}</Modal.Header>
                        <Modal.Content>
                            <CsvImport
                                csvStructure={[
                                    {column: "model*"},
                                    {column: "brand*"},
                                    {column: "name"},
                                    {column: "currency*"},
                                    {column: "price"},
                                    {column: "base_price*"},
                                    {column: "reference_price"},
                                ]}

                                requiredCols={[
                                    {column: "model"},
                                    {column: "brand"},
                                    {column: "currency"},
                                    {column: "base_price"},
                                ]}

                                onUpdate={(data)=>{
                                    productsImport(data).then(r => {
                                        this.orderTable.setState({
                                            data: null
                                        });


                                        this.orderTable.init();

                                        SnackBar("Produkty zostały zaktualizowane")

                                    })
                                }}
                            />
                            <span>Pola z * są wymagane!</span>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {

                                this.setState({
                                    importProductModal: false,
                                });
                            }} negative>Anuluj</Button>
                            {/*<Button*/}
                            {/*    positive*/}
                            {/*    icon='checkmark'*/}
                            {/*    labelPosition='right'*/}
                            {/*    content='Zapisz'*/}
                            {/*    onClick={()=>{}}*/}
                            {/*/>*/}
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.loading &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać...")}</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        )
    }
}

export default Products
