import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";

export const productsImport = async (data) => {
    const modelIndex = data[0].data.indexOf('model');
    const brandIndex = data[0].data.indexOf('brand');
    const rows = data.filter((row, index ) => index != 0 && row.data.length > 1);
    const cols = data[0].data;
    const modelValues = [];

    await rows.map((el, i) => {
        if (el.data[modelIndex] !== "") return modelValues.push(el.data[modelIndex])
    });

    const products = await api(undefined, undefined, undefined, true).post('/products', {
        limit: rows.length,
        currentPage: 1,
        filter: [{field: "model", value: modelValues, condition: "in"}]
    })

    const newProducts = products.data.data.map((product, index) => {
        rows.map((row, k) => {
            if(product.model === row.data[modelIndex]) {

                row.data.map((r, i) => {
                    if(i != modelIndex && i != brandIndex ){
                        if(!product.countries[row.data[brandIndex]]) {
                            product.countries[row.data[brandIndex]] = {};
                            product.countries[row.data[brandIndex]][cols[i]] = r
                        }
                        product.countries[row.data[brandIndex]][cols[i]] = r
                    }
                })

            }
        })
        return product
    })

    const update = await api(undefined, undefined, undefined, true).post("/products/update-many", {
        products: newProducts
    })

    if(update.status === 200) {
        return update;
    } else {
        return SnackBar("Błąd aktualizacji", "error");
    }
}