import React from "react";
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import {TranslationController} from "../../controller/menager";
import Breadcrumb from "../../components/Breadcrumb";
import {Modal, Icon, Segment, Menu, Checkbox, List} from 'semantic-ui-react'
import UITable from "../../lib/table-v2";
import TableMenu from "../../components/TableMenu";

export default class StatesRawScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            appliedFilters: null
        }
    }

    init(limit, currentPage) {
        return api().post("/api/states/get-raw", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data});

                return r.data.data
            })
    }

    render() {
        return (
            <div>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Surowe stany mag.")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Import</TableMenu.Header>
                                <TableMenu.Item>
                                </TableMenu.Item>
                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/states/get-raw",
                                options: {limit: 25, currentPage: 1}
                            }}
                            ref={table => this.userTable = table}
                            provider={this}
                            columns={[
                                {
                                    field: "sku",
                                    label: "SKU",
                                    filters: [
                                        {label: "SKU", condition: "in", type: "text"}
                                    ]
                                },
                                {
                                    field: "all",
                                    label: "Stan"
                                },
                                {
                                    field: "k99",
                                    label: "Sklep",
                                    sublabel: "(K99)",
                                },
                                {
                                    field: "157",
                                    label: "Główny",
                                    sublabel: "(157)",
                                },
                                {
                                    field: "n32",
                                    label: "N32",
                                },
                                {
                                    field: "out",
                                    label: "Outlet",
                                },
                                {
                                    field: "de",
                                    label: "Niemcy",
                                    sublabel: "(D01-D11)",
                                },
                                {
                                    field: "sal",
                                    label: "Salony",
                                },
                                {
                                    field: "x99",
                                    label: "X99",
                                },
                                {
                                    field: "prib",
                                    label: "PRIB",
                                },
                                {
                                    field: "ukr",
                                    label: "UKR",
                                }
                            ]}
                        />
                    </Segment>
                </Container>
            </div>
        );
    }
}
