import React, { Component } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Breadcrumb from "../../components/Breadcrumb";
import Container from "../../components/Container";
import PaperTitle from "../../components/PaperTitle";
import EsotiqInput from "../../components/EsotiqInput";
import {TranslationController} from "../../controller/menager";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import pl from "date-fns/locale/en-GB";
import Button from "../../components/Button";
import {formatDate} from "../../helpers/dateHelper";
import {api} from "../../lib/axios";
import {DATA_OBJECT} from "../../controller/dataController";
import SnackBar from "../../components/Snack";

const category = {
    bra: [
        { text: 'wychodząca fiszbina', value: 'wychodząca fiszbina' },
        { text: 'pęknieta fiszbina', value: 'pęknieta fiszbina' },
        { text: 'odkształcenie miski', value: 'odkształcenie miski' },
        { text: 'urwane ramiączko', value: 'urwane ramiączko' },
        { text: 'urwany zaczep od ramiączka przy misce', value: 'urwany zaczep od ramiączka przy misce' },
        { text: 'dziurki na szwach', value: 'dziurki na szwach' },
        { text: 'odprucie', value: 'odprucie' },
        { text: 'dyskomfort w noszeniu', value: 'dyskomfort w noszeniu' },
        { text: 'ocierające ciało fiszbiny,przebicie/rozdarcie', value: 'ocierające ciało fiszbiny,przebicie/rozdarcie' },
        { text: 'ogólne zniszczenie', value: 'ogólne zniszczenie'},
        { text: 'źle dobrany rozmiar', value: 'źle dobrany rozmiar' },
        { text: 'zmechacenie', value: 'zmechacenie' },
        { text: 'odbarwienie', value: 'odbarwienie' },
        { text: 'plamy', value: 'plamy' },
        { text: 'rozciągnięcie', value: 'rozciągnięcie' },
        { text: 'inne', value: 'inne' },
    ],
    panties:[
        { text: 'rozpruta koronka', value: 'inne' },
        { text: 'zmechacenie', value: 'zmechacenie' },
        { text: 'odbarwienie', value: 'odbarwienie' },
        { text: 'plamy', value: 'plamy' },
        { text: 'inne', value: 'inne' },
    ],
    clothes: [
        { text: 'dziury', value: 'inne' },
        { text: 'plamy', value: 'plamy' },
        { text: 'odbarwienia', value: 'odbarwienia' },
        { text: 'przetarcie', value: 'przetarcie' },
        { text: 'rozciągnięcie', value: 'rozciągnięcie' },
        { text: 'rozejście szwów', value: 'rozejście szwów' },
        { text: 'wyrwanie zatrzasku', value: 'wyrwanie zatrzasku' },
        { text: 'zmechacenie', value: 'zmechacenie' },
        { text: 'spranie nadruku', value: 'spranie nadruku' },
        { text: 'inne', value: 'inne' },
    ],
    beachwear: [
        { text: 'dziury', value: 'inne' },
        { text: 'odbarwienia', value: 'odbarwienia' },
        { text: 'pozaciągany materiał', value: 'pozaciągany materiał' },
        { text: 'spranie nadruku', value: 'spranie nadruku' },
        { text: 'farbowanie', value: 'farbowanie' },
        { text: 'zmechacenie', value: 'zmechacenie' },
        { text: 'inne', value: 'inne' },
    ]

}


const mainCategory = [
    {text: "kostiumy plażowe", value: "beachwear"},
    {text: "piżamy/odzież", value: "clothes"},
    {text: "Figi/Stringi", value: "panties"},
    {text: "Biustonosz", value: "bra"},
]



class AddDefect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            frontId: "",
            id: null,
            index: "",
            status: "defectNew",
            date: "",
            description: "",
            categoryMain: "",
            categoryMainIsSet: false,
            subCategory: "",
            indexData: [],
            indexOptions: [],
            parlourData: [],
            parloursData: [],
            userParlourId: "",
            rks: null,
            rksMail: null,
            historyDefaultValue: "Utworzono zgłoszenie",
            idString: '',

        }
    }

    componentDidMount() {
        this.init();
        this.setState({ userParlourId: DATA_OBJECT['user'].parlour !== 'root' ?  DATA_OBJECT['user'].parlour : "K48" })
    }

    async init() {
        await  api().post("/api/products/getEan", {})
            .then(r => {
                this.setState({indexData: r.data.data})
                this.state.indexData.map(row => {
                    this.state.indexOptions.push({ text: row.sku, value: row.sku })
                })
            });
        await api().post('/api/parlours', {})
            .then(r => this.setState({parloursData: r.data.data}));
        await this.getParlourData();
    }

    getParlourData() {
        const arr = this.state.parloursData.filter(parlour => {
            return parlour.id === this.state.userParlourId
        })
        this.setState({
            parlourData: arr[0]
        })
    }

    toggleCategoryMainIsSet() {
        this.state.categoryMain !== "" ? this.setState({ categoryMainIsSet: true}) : this.setState({ categoryMainIsSet: false})
    }

    inputValidation() {
        if(this.state.description === null || this.state.description === "") return false;
        if(this.state.index === null || this.state.index === "") return false;
        if(this.state.categoryMain === null || this.state.categoryMain === "") return false;
        if(this.state.subCategory === null || this.state.subCategory === "") return false;

        return true
    }

    async addNewComplaint() {
        const nowDate = new Date().getTime();

        const validResult = await this.inputValidation();
        if(validResult) {
            await api(undefined, "http://localhost:50005").post('/complaints/defects/addDefect', {
                    data: {
                        defectData: {
                            createdAt: nowDate,
                            description: this.state.description,
                            index: this.state.index,
                            mainCategory: this.state.categoryMain,
                            subCategory: this.state.subCategory,
                            status: {
                                mainStatus: this.state.status,
                            }
                        },
                        parlourData: {
                            parlourId: this.state.userParlourId,
                            rks: this.state.parlourData.rks,
                            rksMail: this.state.parlourData.rksMail,
                            street: this.state.parlourData.street,
                            city: this.state.parlourData.city
                        },
                        history: [
                            {
                                value: this.state.historyDefaultValue,
                                timestamp: nowDate,
                                subValue: DATA_OBJECT['user'].login
                            },
                            {
                                value: `Wysłano informację do ${this.state.parlourData.rks} [${this.state.parlourData.rksMail}]`,
                                timestamp: nowDate,
                            }
                        ],

                    }
                }
            )
                .then(r => {
                    api(undefined, undefined,undefined, true).post("/complaints/defects/sendMail",
                        {
                            defectId: r.data.data,
                            rksMail: "pbrzezinski@esotiq.com",//this.state.parlourData.rksMail,
                        })
                        .then(result => {
                            if (result.data.success) {
                                SnackBar("Wysłano powiadomienie e-mail");
                            } else {
                                SnackBar("Błąd podczas wysyłania powiadomienia. Sprawdź konfigurację.", "error");
                            }
                        });
                    window.open(`#/defects/defectSummary/${r.data.data.split("/").join("-")}`, '_blank')
                })
        } else {
            SnackBar("Wypełnij wszystkie pola!", "error")
        }
    }

    render() {
        return(
            <>
                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Dodaj wadę`
                            }
                        ]
                        }
                    />
                </Container>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <PaperTitle title={"Dane wady"}/>
                                <div className="complaint-row--container">
                                    <div className="complaint-input-container">
                                        <EsotiqInput options={this.state.indexOptions} label="Index" onChange={(v)=> this.state.index = v}/>
                                    </div>
                                </div>
                                <div className="complaint-row--container">
                                    <div style={{width: "85%", marginLeft: "5%"}}>
                                        <EsotiqInput textArea label="Opis wady" onChange={(v)=> this.state.description = v }/>
                                    </div>
                                </div>
                                <div className="complaint-row--container">
                                    <div className="complaint-input-container">
                                        <EsotiqInput options={mainCategory} label="Kategoria rzeczy" onChange={(v)=> (this.state.categoryMain = v,  this.toggleCategoryMainIsSet() )}/>
                                    </div>

                                </div>
                                <div className="complaint-row--container">
                                    {this.state.categoryMainIsSet &&
                                        <div className="complaint-input-container">
                                            <EsotiqInput options={category[this.state.categoryMain]} label="Kategoria wady"
                                                         onChange={(v) => this.state.subCategory = v}/>
                                        </div>
                                    }
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <div className="button-wrapper">
                                    <Button primary onClick={()=> (this.addNewComplaint())}>Dodaj</Button>
                                    <Button onClick={() => window.open("#/defects", "_self")}>Anuluj</Button>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </>
        )
    }
}

export default AddDefect;