import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {inAppRoutes} from "../../router";
import {Link} from "react-router-dom"
import {DATA_OBJECT} from "../../controller/dataController";
import Tooltip from '@material-ui/core/Tooltip';
import {api, controlPanelApi} from "../../lib/axios";
import {AccessController, RouterController, TranslationController} from "../../controller/menager";
import { withRouter } from "react-router-dom";
import Dropdown from "../../components/Dropdown";
import {Button, Modal} from "semantic-ui-react";
import SnackBar from "../../components/Snack";
import EsotiqInput from "../../components/EsotiqInput";
import Confirm from "../../components/Confirm";
import AppTabs from "../../router/tabs";
import {ControlPanelUrls} from "../../api/urls";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    rootBar: {
        minHeight: "55px!important",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: "#000000!important",
        color: "#f2f2f2!important",
        maxHeight: "55px!important",
        boxShadow: "none"
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: "36px!important",
        background: "#0078d7!important"
    },
    menuIcon: {
        fontSize: "0.8em!important"
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        top: "40px!important",
        zIndex: 10000
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(1) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
        top: "40px!important"
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
    },
}));

function PersistentDrawerLeft(props) {
    let state = {};

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState(" ");
    const [refresh, setRefresh] = React.useState();
    const [parlours, setParlours] = React.useState();
    const [branches, setBranches] = React.useState();
    const [modalOpened, setModalOpened] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isDevStatus, setIsDevStatus] = React.useState(false);

    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        setOpen(false);
    }

    async function performRoutes() {
        const promises = []

        inAppRoutes.forEach(route => {
            promises.push(new Promise(resolve => {
                AccessController.checkAccess(route.path, true)
                    .then(result => {
                        if (!result) {
                            route.hidden = true
                        }

                        resolve(true)
                    })
            }))
        })

        Promise.all(promises)
            .then(() => {
                const branches = [
                    {id: "store", label: "Twój sklep", children: [], icon: "shopping_cart"},
                    {id: "promotions", label: "Promocje", children: [], icon: "money_off"},
                    {id: "cms", label: "Allegro", children: [], icon: "loyalty"},
                    {id: "parlour", label: "Salon", children: [], icon: "store"},
                    {id: "warehouse", label: "Magazyn", children: [], icon: "local_shipping"},
                    {id: "erp", label: "ERP", children: [], icon: "shop"},
                    {id: "parlour-management", label: "Zarządzanie salonami", children: [], icon: "domain"},
                    {id: "integration", label: "Integracje", children: [], icon: "integration_instructions" },
                    {id: "settings", label: "System", children: [], icon: "settings"},
                ]

                inAppRoutes.forEach((route, k) => {
                    const reciver = branches.find(branch => branch.id === route.parent)

                    if (reciver && !route.hidden) {
                        reciver.children.push(route)
                    }
                })

                for (let [key, values] of Object.entries(branches)) {
                    if (!values.children.length) {
                        values.hidden = true
                    }
                }

                setBranches(branches)
            })
    }

    useEffect(() => {
        const checkForUser = () => {
            if (!DATA_OBJECT["user"]) {
                return setTimeout(() => {
                    checkForUser();
                }, 500)
            } else {
                performRoutes();
            }
        }

        checkForUser()
    }, [])

    function getParlours() {
        return api().post("/api/parlours")
            .then(r => {
                DATA_OBJECT.parlours = r.data.data

                setParlours({
                    parlours: r.data.data.map(parlour => ({
                        key: parlour.id,
                        text: parlour.id,
                        value: parlour.id
                    }))
                })
            })
    }

    function getProducts() {
        if (DATA_OBJECT["user"] && DATA_OBJECT["user"]["country"] === "pl") {
            return true;
        }

        return api().post("/api/products", {filters: [], limit: 9999, currentPage: 1})
            .then(r => {
                if (r.data && r.data.data) {
                    TranslationController.setTranslateProducts(r.data.data);
                }
            })
    }

    useEffect(() => {
        getParlours()
            .then(() => getProducts())
            .then(() => {
                RouterController.onRouteChange(props.location);

                props.history.listen((location, action) => {
                    AccessController.checkAccess(location.pathname);
                    RouterController.onRouteChange(location);
                });

                AccessController.checkAccess(props.location.pathname);

                setIsLoading(false);
            })
    }, [])

    return (
        <div className={`${classes.root} app-drawer`} style={{
            position: "relative"
        }}>
            <CssBaseline />
            <div style={{
                position: "fixed",
                top: 0,
                width: "100%",
                zIndex: 9999
            }}>
                <AppTabs/>

                <AppBar
                    position="relative"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                    style={{
                        top: 0
                    }}
                >
                    <Toolbar className={classes.rootBar}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon className={classes.menuIcon} />
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            {title}
                        </Typography>
                    </Toolbar>

                    <div className={"app-drawer-user"}>
                        {/*<div className={"app-version"}>*/}
                        {/*<div>{process.env.REACT_APP_NAME} v.{process.env.REACT_APP_VERSION}</div>*/}
                        {/*</div>*/}

                        {((DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root") || (DATA_OBJECT["user"] && DATA_OBJECT["accessGroup"] === "604158b948f6e82945c60e60")) &&
                        <div style={{
                            marginRight: 25,
                            position: "relative"
                        }}>
                            <div className={"drawer-dropdown-label"}>{TranslationController.translate("Użytkownik")}:</div>

                            {parlours ? <Dropdown
                                placeholder={"Select..."}
                                fluid
                                search
                                selection
                                defaultValue={DATA_OBJECT["selected_parlour"] || null}
                                className={"drawer-dropdown"}
                                onChange={(e, v) => {
                                    DATA_OBJECT["selected_parlour"] = v.value

                                    if (v.value === "root") {
                                        localStorage.removeItem("selected_parlour")
                                    } else {
                                        localStorage.setItem("selected_parlour", v.value)
                                    }

                                    setOpen(false)
                                }}
                                options={[...[{key: "xd", text: "root", value: "root"}], ...parlours.parlours]}
                            /> : <div>Ładowanie...</div>}
                        </div>
                        }

                        <div style={{
                            marginRight: 15,
                            position: "relative"
                        }}>
                            <div className={"drawer-dropdown-label"}>{TranslationController.translate("Język")}:</div>

                            <Dropdown
                                placeholder={"Select..."}
                                fluid
                                search
                                selection
                                className={"drawer-dropdown"}
                                defaultValue={DATA_OBJECT["selected_lang"] || null}
                                onChange={(e, v) => {
                                    localStorage.setItem("selected_lang", v.value);

                                    window.location.reload();
                                }}
                                options={[
                                    {key: "en", text: "English", value: "en"},
                                    {key: "de", text: "German", value: "de"},
                                    {key: "pl", text: "Polish", value: "pl"}
                                ]}
                            />
                        </div>

                        <div>
                            {DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root" &&
                            <React.Fragment>
                                {(localStorage.getItem("api_type") && localStorage.getItem("api_type") === "dev")
                                    ?
                                    <Button
                                        onClick={() => {
                                            localStorage.setItem("api_type", "prod");

                                            SnackBar("Switched to prod api");

                                            setIsDevStatus(!isDevStatus);
                                        }}
                                        className={"drawer-button"}
                                    >
                                        Switch to PROD
                                    </Button>
                                    :
                                    <Button
                                        onClick={() => {
                                            localStorage.setItem("api_type", "dev");

                                            SnackBar("Switched to dev api");

                                            setIsDevStatus(!isDevStatus);
                                        }}
                                        className={"drawer-button"}
                                    >
                                        Switch to DEV
                                    </Button>
                                }
                            </React.Fragment>
                            }
                        </div>

                        <div onClick={() => {
                            state = {};

                            setModalOpened(true);
                        }} className={"app-drawer-login"}>
                            <Tooltip title={DATA_OBJECT.user_login}>
                                <Icon>account_circle</Icon>
                            </Tooltip>
                        </div>
                        <div className={"app-drawer-logout"}
                             onClick={() => DATA_OBJECT.logout()}
                        >
                            <Tooltip title="Wylouj się">
                                <Icon>exit_to_app</Icon>
                            </Tooltip>
                        </div>
                    </div>
                </AppBar>
            </div>

            <Drawer
                variant="permanent"
                anchor="left"
                open={open}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                style={{
                    top: 50
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {branches && branches.map((branch, index) => {
                        if (branch.hidden) {
                            return false
                        }

                        return (
                            <React.Fragment
                                  key={index}
                                  >
                                <ListItem
                                    button
                                    key={index}
                                    className={"app-drawer-branch"}
                                >
                                    <ListItemIcon><Icon className={"app-drawer-icon"}>{branch.icon}</Icon></ListItemIcon>
                                    <ListItemText className={`app-drawer-label ${!open && "app-drawer-labels-hidden"}`} primary={branch.label} />

                                    <div className={"app-drawer-branch-list"}>
                                        <div className={"app-drawer-branch-title"}>{TranslationController.translate(branch.label)}</div>

                                        {branch.children.map((child, key) => {
                                            if (child.hidden) {
                                                return false
                                            }

                                            return (
                                                <div className={"app-drawer-branch-list-item"} key={key}>
                                                    <Link
                                                        style={{
                                                            textDecoration: "none",
                                                            color: "#636363"
                                                        }}
                                                        key={key}
                                                        to={() => {
                                                            // if (child.path === "/shipping/:id") {
                                                            //     console.log(DATA_OBJECT, "DATA object")
                                                            //     child.path = child.path.replace(":id", DATA_OBJECT["selected_parlour"])
                                                            // }


                                                            return child.path
                                                        }}
                                                    >
                                                        <ListItem
                                                            onClick={() => {
                                                                setTitle(() => child.name)
                                                            }}
                                                            button key={key}
                                                        >
                                                            <ListItemIcon><Icon>navigate_next</Icon></ListItemIcon>
                                                            <ListItemText className={"app-drawer-label"} primary={TranslationController.translate(child.name)} />
                                                        </ListItem>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </ListItem>

                                {/*<Link style={{*/}
                                {/*textDecoration: "none",*/}
                                {/*color: "#636363"*/}
                                {/*}}*/}
                                {/*key={index}*/}
                                {/*to={route.path}>*/}
                                {/*<ListItem*/}
                                {/*onClick={() => setTitle(() => route.name)}*/}
                                {/*button key={index}>*/}
                                {/*<ListItemIcon>{route.drawerIcon ||  <InboradiusxIcon /> }</ListItemIcon>*/}
                                {/*<ListItemText primary={route.name} />*/}
                                {/*</ListItem>*/}
                                {/*</Link>*/}
                            </React.Fragment>


                    )})}
                </List>
                <Divider />
                {/*<List>*/}
                    {/*{['Ustawienia', 'Wyloguj'].map((text, index) => (*/}
                        {/*<ListItem button key={index}>*/}
                            {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
                            {/*<ListItemText primary={text} />*/}
                        {/*</ListItem>*/}
                    {/*))}*/}
                {/*</List>*/}
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />

                {isLoading ?
                    <React.Fragment>
                        <div style={{
                            position: "absolute",
                            right: 0,
                            left: 0,
                            top: "35vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: "9999"
                        }} className={"app-loading"}>
                        <span style={{
                            position: "relative",
                            color: "#acacac",
                            fontWeight: 100,
                            fontSize: "1.15em"
                        }}>Preparing resources... Just one moment...</span>

                        </div>
                    </React.Fragment>
                    : props.routes()
                }
            </main>

            {modalOpened &&
                <Modal dimmer={"inverted"} size={"mini"} open={modalOpened} onClose={() => setModalOpened(false)}>
                    <Modal.Header>Twoje konto</Modal.Header>
                    <Modal.Content>
                        <EsotiqInput
                            label={"Użytkownik"}
                            placeholder={"Wpisz..."}
                            defValue={DATA_OBJECT["user"].login}
                            disabled={true}
                            onChange={(value) => {

                            }}
                        />

                        <EsotiqInput
                            label={"Nowe hasło"}
                            placeholder={"Wpisz..."}
                            onChange={(value) => {
                                state.newPass = value;
                            }}
                        />

                        <EsotiqInput
                            label={"Powtórz nowe hasło"}
                            placeholder={"Wpisz..."}
                            onChange={(value) => {
                                state.newPassConfirm = value;
                            }}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setModalOpened(false)} negative>Anuluj</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Zapisz'
                            onClick={() => {
                                if (state.newPass && state.newPassConfirm) {
                                    if (state.newPass !== state.newPassConfirm) {
                                        return SnackBar("Wprowadzone hasła nie są zgodne", "error")
                                    }

                                    if (state.newPass.length < 4) {
                                        return SnackBar("Wprowadzone hasło jest zbyt krótkie", "error")
                                    }

                                    Confirm("Na pewno chcesz zmienić dotychczasowe hasło na nowe?", "", () => {
                                        const login = DATA_OBJECT["user"].login;
                                        const password = state.newPass;
                                        controlPanelApi.patch(`${ControlPanelUrls.USERS}/${login}`, { password })
                                            .then(() => {
                                                state = {};

                                                SnackBar("Hasło zostało zmienione");

                                                setModalOpened(false);
                                            })
                                    })
                                }
                            }}
                        />
                    </Modal.Actions>
                </Modal>
            }
        </div>
    );
}

export default withRouter(PersistentDrawerLeft)
