import React from 'react'
import {Icon as IconSemantic, Message} from "semantic-ui-react";
import ActionPanel from "../../components/ActionPanel";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import Paper from '@material-ui/core/Paper';
import {api} from "../../lib/axios";
import Button from "../../components/Button";
import { Table, Modal, Icon, Label, Confirm, Loader, Step } from 'semantic-ui-react'
import UITable from "../../lib/table-v2";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {formatDate} from "../../helpers/dateHelper";
import SnackBar from "../../components/Snack";
import EsotiqInput from "../../components/EsotiqInput";
import PickupPDF from "./PickupPDF";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {TranslationController} from "../../controller/menager";
import {DATA_OBJECT} from "../../controller/dataController";

export default class OnlineParlourPickup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parlours: null,
            selectedParlour: null,
            orderServiceData: null,
            confirm: false,
            loading: false
        }
    }

    componentDidMount() {
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api().post("/api/orderService/orderPickup")
                .then(r => {
                    return r.data.data
                })
        });

        return api().post("/api/orderService/orderPickup", {
            limit,
            currentPage,
            filter: this.state.appliedFilters || null,
            internalOrders: this.state.internalOrders,
            user: DATA_OBJECT["user"]
        })
            .then(r => {
                this.setState({data: r.data.data});

                return r.data.data
            })
    }

    markDelivered({data, key, type, isParlourOrder, orderId}) {
        this.setState({
            loading: true
        });

        api().post("/api/orderService/markDelivered", {data, key, type, isParlourOrder, orderId})
            .then(r => {
                this.orderTable.setState({
                    data: null,
                });

                this.orderTable.init()

                this.setState({
                    loading: false
                })
            })
    }

    markFullDelivered({data, type, isParlourOrder, orderId}) {
        this.setState({
            loading: true
        });

        api().post("/api/orderService/markFullDelivered", {data, type, isParlourOrder, orderId, userId:  DATA_OBJECT.user_login,})
            .then(r => {
                this.orderTable.setState({
                    data: null,
                });

                this.orderTable.init()

                this.setState({
                    loading: false
                })
            })
    }

    markPickup(data) {
        this.setState({
            loading: true
        });

        api().post("/api/orderService/markPickup", {data, orderPickupCustomerEmail: this.state.orderPickupCustomerEmail, orderPickupCustomerPhone: this.state.orderPickupCustomerPhone})
            .then(r => {
                this.orderTable.setState({
                    data: null,
                });

                this.orderTable.init()

                this.setState({
                    loading: false
                })
            })
    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[
                    ]}
                />

                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Odbiór zamówienia`
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <Paper>
                        <Message
                            icon='inbox'
                            header='Aktywne zamówienia'
                        />

                        <UITable
                            dataFetch={{
                                url: "/api/orderService/orderPickup",
                                options: {
                                    limit: 20,
                                    currentPage: 1,
                                    internalOrders: this.state.internalOrders,
                                    user: DATA_OBJECT["user"],
                                    parlour: this.props.match.params.id || DATA_OBJECT.selected_parlour || null,
                                },
                            }}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    field: "id",
                                    label: "Numer zamówienia",
                                    filters: [
                                        {label: "Numer zamówienia (część lub pełny numer)", condition: "in", type: "text", objectField: "part_of_integer"},
                                        {label: "Numer zamówienia", condition: "in", type: "in"},
                                    ],
                                    width: 3,
                                    render: row => (
                                        <div className={"pickup-table-labels"}>
                                            <div className={"parlour-shipping-place"}>
                                                <React.Fragment>
                                                    <div className={"place-label"}>{TranslationController.translate("Numer zamówienia")}:</div>
                                                    <div className={"place-value"}>
                                                        <span>{row.id}</span>
                                                    </div>
                                                </React.Fragment>
                                            </div>

                                            <div className={"parlour-shipping-place"}>
                                                <React.Fragment>
                                                    <div className={"place-label"}>{TranslationController.translate("Źródło")}:</div>
                                                    <div className={"place-value"}>
                                                        <span>{row.brand} {row.country}</span>
                                                    </div>
                                                </React.Fragment>
                                            </div>

                                            <div className={"parlour-shipping-place"}>
                                                <React.Fragment>
                                                    <div className={"place-label"}>{TranslationController.translate("Wysyłka z")}:</div>
                                                    <div className={"place-value"}>
                                                        {row.sendFrom ? row.sendFrom.map((service, serviceKey) => {
                                                                return (
                                                                    <div
                                                                        onClick={() => {
                                                                            if (service.status === "send" || row.statusShipping === "sendToCustomer" || row.statusShipping === "send") {
                                                                                row.orderService.forEach(orderService => {
                                                                                    let pickupNumber = orderService.pickupNumber;

                                                                                    if (!pickupNumber) {
                                                                                        if (row.delivery && row.delivery.data && row.delivery.data.pickupNumber) {
                                                                                            pickupNumber = row.delivery.data.pickupNumber
                                                                                        }
                                                                                    }

                                                                                    if (orderService._id === service.orderServiceId && pickupNumber) {
                                                                                        window.open(`https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=${pickupNumber}`, '_blank')
                                                                                    }
                                                                                })
                                                                            }
                                                                        }}
                                                                        className={`place-value-wrapper ${service.status === "send" && "place-sended"}`} key={serviceKey}>
                                                                        <div>
                                                                            {service.status === "send" &&
                                                                            <IconSemantic name={"check"}/>
                                                                            }

                                                                            <span>
                                                                            {service.parlour}
                                                                        </span>

                                                                            {service.status === "send" &&
                                                                            <div className={"tracking-label"}>
                                                                                <IconSemantic name={"truck"}/>
                                                                                <div>Tracking</div>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) : "---"}
                                                    </div>
                                                </React.Fragment>
                                            </div>
                                        </div>
                                    )
                                },
                                {
                                    field: "parlour",
                                    label: "Data zamówienia",
                                    center: true,
                                    render: row => (
                                        <div>
                                            {formatDate(row.createdAt).dateTimeSystem}
                                        </div>
                                    )
                                },
                                {
                                    field: "parlour",
                                    label: "Salon",
                                    filters: (DATA_OBJECT["user"] && (DATA_OBJECT["user"].login === "root") || DATA_OBJECT["user"].accessGroup === "6041588d48f6e82945c60ded") ? [
                                        {label: "Salon", condition: "in", type: "text", forceField: "shippingPoint.name"},
                                    ] : null,
                                    center: true,
                                    render: row => (
                                        <div>
                                            {row.shippingPlace || (row.shippingPoint && row.shippingPoint.name)}
                                        </div>
                                    )
                                },
                                {
                                    field: "statusShipping",
                                    label: "Status",
                                    center: true,
                                    width: 1,
                                    filters: [
                                        {label: "Status", condition: "in", type: "checkbox", forceField: "statusShipping", options: [
                                                {
                                                    value: "notSend",
                                                    label: new OrderStatusesHelper().statuses.notSend.label
                                                },
                                                {
                                                    value: "partialSendedToParlour",
                                                    label: new OrderStatusesHelper().statuses.partialSendedToParlour.label
                                                },
                                                {
                                                    value: "sendToParlour",
                                                    label: new OrderStatusesHelper().statuses.sendToParlour.label
                                                },
                                                {
                                                    value: "claimedByCustomer",
                                                    label: new OrderStatusesHelper().statuses.claimedByCustomer.label
                                                },
                                                {
                                                    value: "readyForClaimByCustomer",
                                                    label: new OrderStatusesHelper().statuses.readyForClaimByCustomer.label
                                                },
                                                {
                                                    value: "notClaimed",
                                                    label: new OrderStatusesHelper().statuses.notClaimed.label
                                                }
                                            ]
                                        }
                                    ],
                                    render: row => (
                                        <div>
                                            <div className={"es-order-satus-display"}><span style={{background: new OrderStatusesHelper().getColor(row.statusShipping), color: new OrderStatusesHelper().getFontColor(row.statusShipping)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.statusShipping)}</span></div>
                                        </div>
                                    )
                                },
                                {
                                    field: "chunks",
                                    label: "Paczki",
                                    render: row => {
                                        let isParlourOrder = false;

                                        if (row.id.toString().charAt(0) === "2") {
                                            isParlourOrder = true;
                                        }

                                        if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse === "k99") {
                                            isParlourOrder = false;
                                        }

                                        if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse === "x99") {
                                            isParlourOrder = true;
                                        }

                                        if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse === "salonowe") {
                                            isParlourOrder = true;
                                        }

                                        let pickupNumber = 0;

                                        if (!isParlourOrder && row.delivery) {
                                            if (row.delivery.type === "inpost") {
                                                pickupNumber = row.delivery.tracking_number;
                                            }

                                            if (row.delivery.type === "dpd") {
                                                pickupNumber = row.delivery.pickupNumber;
                                            }
                                        }

                                        if (isParlourOrder && row.delivery) {
                                            if (row.delivery.type === "inpost") {
                                                pickupNumber = row.delivery.tracking_number;
                                            }

                                            if (row.delivery.type === "dpd") {
                                                pickupNumber = row.delivery.pickupNumber;
                                            }
                                        }

                                        if (isParlourOrder && row.delivery) {
                                            if (row.delivery.type === "gls") {
                                                pickupNumber = row.delivery.pickupNumber;
                                            }
                                        }

                                        if (!isParlourOrder && row.delivery) {
                                            if (row.delivery.type === "gls") {
                                                pickupNumber = row.delivery.pickupNumber;
                                            }
                                        }

                                        return (
                                            (!isParlourOrder && !row.delivery)
                                            ?
                                                <div>
                                                    {TranslationController.translate("Oczekuje na nadanie")}
                                                </div>
                                                :
                                                (isParlourOrder && row.orderService && !row.orderService.length)
                                                ?
                                                    <div>
                                                        {TranslationController.translate("Oczekuje na nadanie")}
                                                    </div>
                                                    :
                                            <div style={{position: "relative"}}>
                                                <Table compact>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell width={2}>Data wysłania</Table.HeaderCell>
                                                            <Table.HeaderCell>Numer listu przewozowego</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>

                                                    <Table.Body>
                                                        {
                                                            (!isParlourOrder && row.delivery)
                                                            ?
                                                                <Table.Row>
                                                                    <Table.Cell width={2}>
                                                                        {formatDate(row.delivery.data.pickupCreateDate).dateTimeSystem}
                                                                    </Table.Cell>
                                                                    <Table.Cell width={2}>
                                                                        {pickupNumber ? <div>
                                                                            <Button
                                                                                as='div'
                                                                                labelPosition='right'
                                                                                onClick={() => {
                                                                                    if (row.claimedByCustomer) {
                                                                                        return SnackBar("Zamówienie zostało już wydane klientowi. Nie możesz dokonać zmiany.", "warning")
                                                                                    }

                                                                                    if (!row.orderPickupDelivered || !row.orderPickupDelivered[0]) {
                                                                                        this.setState({
                                                                                            confirm: {
                                                                                                text: "Czy na pewno chcesz oznaczyć tę paczkę jako dostarczoną?",
                                                                                                data: {data: row, key: 0, type: "mark", isParlourOrder, orderId: row.id}
                                                                                            }
                                                                                        })
                                                                                    } else {
                                                                                        this.setState({
                                                                                            confirm: {
                                                                                                text: "Czy na pewno chcesz cofnąć oznaczenie jako dostarczona?",
                                                                                                data: {data: row, key: 0, type: "unmark", isParlourOrder, orderId: row.id}
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <Button basic color={row.orderPickupDelivered && row.orderPickupDelivered[0] ? "green" : "blue"}>
                                                                                    <Icon name={row.orderPickupDelivered && row.orderPickupDelivered[0] ? "checkmark" : "fork"} />
                                                                                    {pickupNumber}
                                                                                </Button>
                                                                                <Label as='a' basic color={row.orderPickupDelivered && row.orderPickupDelivered[0] ? "green" : "blue"} pointing='left'>
                                                                                    {row.orderPickupDelivered && row.orderPickupDelivered[0] ? `Dostarczono: ${formatDate(row.orderPickupDelivered[0]).dateTimeSystem}` : "Oznacz przesyłkę jako dostarczoną"}
                                                                                </Label>

                                                                                {row.orderPickupDelivered && row.orderPickupDelivered[0] &&
                                                                                <div style={{marginLeft: 5}}>
                                                                                    <Button basic color={"red"}>
                                                                                        <Icon name={"close"} />
                                                                                        Cofnij
                                                                                    </Button>
                                                                                </div>
                                                                                }
                                                                            </Button>
                                                                        </div> : "---"}
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                                : (isParlourOrder)
                                                                ?
                                                                    row.delivery
                                                                ? <Table.Row>
                                                                            <Table.Cell width={2}>
                                                                                {formatDate(row.delivery.data.pickupCreateDate).dateTimeSystem}
                                                                            </Table.Cell>
                                                                            <Table.Cell width={2}>
                                                                                {pickupNumber ? <div>
                                                                                    <Button
                                                                                        as='div'
                                                                                        labelPosition='right'
                                                                                        onClick={() => {
                                                                                            if (row.claimedByCustomer) {
                                                                                                return SnackBar("Zamówienie zostało już wydane klientowi. Nie możesz dokonać zmiany.", "warning")
                                                                                            }

                                                                                            if (!row.orderPickupDelivered || !row.orderPickupDelivered[0]) {
                                                                                                this.setState({
                                                                                                    confirm: {
                                                                                                        text: "Czy na pewno chcesz oznaczyć tę paczkę jako dostarczoną?",
                                                                                                        data: {data: row, key: 0, type: "mark", isParlourOrder, orderId: row.id}
                                                                                                    }
                                                                                                })
                                                                                            } else {
                                                                                                this.setState({
                                                                                                    confirm: {
                                                                                                        text: "Czy na pewno chcesz cofnąć oznaczenie jako dostarczona?",
                                                                                                        data: {data: row, key: 0, type: "unmark", isParlourOrder, orderId: row.id}
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <Button basic color={row.orderPickupDelivered && row.orderPickupDelivered[0] ? "green" : "blue"}>
                                                                                            <Icon name={row.orderPickupDelivered && row.orderPickupDelivered[0] ? "checkmark" : "fork"} />
                                                                                            {pickupNumber}
                                                                                        </Button>
                                                                                        <Label as='a' basic color={row.orderPickupDelivered && row.orderPickupDelivered[0] ? "green" : "blue"} pointing='left'>
                                                                                            {row.orderPickupDelivered && row.orderPickupDelivered[0] ? `Dostarczono: ${formatDate(row.orderPickupDelivered[0]).dateTimeSystem}` : "Oznacz przesyłkę jako dostarczoną"}
                                                                                        </Label>

                                                                                        {row.orderPickupDelivered && row.orderPickupDelivered[0] &&
                                                                                        <div style={{marginLeft: 5}}>
                                                                                            <Button basic color={"red"}>
                                                                                                <Icon name={"close"} />
                                                                                                Cofnij
                                                                                            </Button>
                                                                                        </div>
                                                                                        }
                                                                                    </Button>
                                                                                </div> : "---"}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                        : row.orderService && row.orderService.length &&
                                                                    row.orderService.map((orderService, key) => {
                                                                        return (
                                                                            <Table.Row key={key}>
                                                                                <Table.Cell width={2}>
                                                                                    {orderService.pickupCreateDate ? formatDate(orderService.pickupCreateDate).dateTimeSystem : TranslationController.translate("Oczekuje na wysłanie.")}
                                                                                </Table.Cell>
                                                                                <Table.Cell width={2}>
                                                                                    {orderService.pickupNumber ? <div>
                                                                                        <Button
                                                                                            as='div'
                                                                                            labelPosition='right'
                                                                                            onClick={() => {
                                                                                                if (row.claimedByCustomer) {
                                                                                                    return SnackBar("Zamówienie zostało już wydane klientowi. Nie możesz dokonać zmiany.", "warning")
                                                                                                }

                                                                                                if (!row.orderPickupDelivered || !row.orderPickupDelivered[orderService._id]) {
                                                                                                    this.setState({
                                                                                                        confirm: {
                                                                                                            text: "Czy na pewno chcesz oznaczyć tę paczkę jako dostarczoną?",
                                                                                                            data: {data: row, key: orderService._id, type: "mark", isParlourOrder, orderId: row.id}
                                                                                                        }
                                                                                                    })
                                                                                                } else {
                                                                                                    this.setState({
                                                                                                        confirm: {
                                                                                                            text: "Czy na pewno chcesz cofnąć oznaczenie jako dostarczona?",
                                                                                                            data: {data: row, key: orderService._id, type: "unmark", isParlourOrder, orderId: row.id}
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <Button basic color={row.orderPickupDelivered && row.orderPickupDelivered[orderService._id] ? "green" : "blue"}>
                                                                                                <Icon name={row.orderPickupDelivered && row.orderPickupDelivered[orderService._id] ? "checkmark" : "fork"} />
                                                                                                {orderService.pickupNumber}
                                                                                            </Button>
                                                                                            <Label as='a' basic color={row.orderPickupDelivered && row.orderPickupDelivered[orderService._id] ? "green" : "blue"} pointing='left'>
                                                                                                {row.orderPickupDelivered && row.orderPickupDelivered[orderService._id] ? `Dostarczono: ${formatDate(row.orderPickupDelivered[orderService._id]).dateTimeSystem}` : "Oznacz przesyłkę jako dostarczoną"}
                                                                                            </Label>

                                                                                            {row.orderPickupDelivered && row.orderPickupDelivered[orderService._id] &&
                                                                                            <div style={{marginLeft: 5}}>
                                                                                                <Button basic color={"red"}>
                                                                                                    <Icon name={"close"} />
                                                                                                    Cofnij
                                                                                                </Button>
                                                                                            </div>
                                                                                            }
                                                                                        </Button>
                                                                                    </div> : "---"}
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        )
                                                                    })
                                                                : null
                                                        }
                                                    </Table.Body>
                                                </Table>

                                                {this.state.loading &&
                                                <div className={"dimmer-table"}>
                                                    <Loader active={true}/>
                                                    <div className={"dimmer-table-text"}>Ładowanie</div>
                                                </div>
                                                }
                                            </div>
                                        )
                                    }
                                },
                                {
                                    field: "chunks",
                                    label: "Akcje",
                                    center: true,
                                    width: 3,
                                    render: row => {
                                        let isParlourOrder = false;

                                        if (row.id.toString().charAt(0) === "2") {
                                            isParlourOrder = true;
                                        }

                                        if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse === "k99") {
                                            isParlourOrder = false;
                                        }

                                        if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse === "x99") {
                                            isParlourOrder = true;
                                        }

                                        if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse === "salonowe") {
                                            isParlourOrder = true;
                                        }

                                        return (
                                            <div className={"cell-center"} center={true} width={2}>
                                                {row.claimedByCustomer ?
                                                    this.state.generatedPdf && this.state.generatedPdf.id === row.id && this.state.parlourData ?
                                                        <PDFDownloadLink ref={e => this.downloadLink = e} document={<PickupPDF parlourData={this.state.parlourData} order={this.state.generatedPdf} data={row}/>} fileName={`Potwierdzenie_odbioru_${row.orderId}`}>
                                                            {({ blob, url, loading, error }) => (loading ? <Button
                                                                color='blue'
                                                                loading={true}
                                                                label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                            /> : <div>
                                                                <Button
                                                                    color='blue'
                                                                    content='Pobierz'
                                                                    icon='download'
                                                                    label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();

                                                                        window.open(url, '_blank')

                                                                        return true
                                                                    }}
                                                                />
                                                            </div>)}
                                                        </PDFDownloadLink> :
                                                        <Button
                                                            color='blue'
                                                            content='Generuj potwierdzenie odbioru'
                                                            icon='download'
                                                            label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                            onClick={() => {
                                                                api().post("/api/orders/get", {orderId: row.id})
                                                                    .then(r => {
                                                                        return this.setState({
                                                                            generatedPdf: r.data.order,
                                                                        })
                                                                    })
                                                                    .then(() => api().post("/api/parlours/getParlourDetails", {parlour: row.shippingPlace || (row.shippingPoint && row.shippingPoint.name)}))
                                                                    .then(r => {
                                                                        this.setState({
                                                                            parlourData: r.data.data
                                                                        })
                                                                    })
                                                            }}
                                                        >

                                                        </Button>
                                                    : !row.readyForClamByCustomerInParlour
                                                        ? <Button
                                                            color={"green"}
                                                            onClick={() => {
                                                                if (!row.canBePickup) {
                                                                    return SnackBar("Zamówienie nie zostało jeszcze w pełni skompletowane.", "error")
                                                                }

                                                                let isParlourOrder = row.initSlug === "parlour";
                                                                let canBeProccesed = true;

                                                                if (row.status === "partialSendedToParlour") {
                                                                    return SnackBar("Nie można oznaczyć zamówienia. Status zam: Wysłano częściowo do salonu", "error")
                                                                }

                                                                if (isParlourOrder && !row.orderService) {
                                                                    return SnackBar("Nie oznaczono wszystkich przesyłek jako dostarczone. Er_1", "error")
                                                                }

                                                                if (isParlourOrder && row.orderService) {
                                                                    let everyPackageIsSend = true;

                                                                    row.orderService.forEach(service => {
                                                                        if (["accepted", "new"].includes(service.status)) {
                                                                            everyPackageIsSend = false;
                                                                        }
                                                                    })

                                                                    if (!everyPackageIsSend) {
                                                                        return SnackBar("Co najmniej jedna z paczek nie została jeszcze dostarczona. Er_4", "error")
                                                                    }
                                                                }

                                                                if (!isParlourOrder && !row.delivery) {
                                                                    return SnackBar("Nie oznaczono wszystkich przesyłek jako dostarczone. Er_2", "error")
                                                                }

                                                                if (!isParlourOrder && (!row.orderPickupDelivered || !Object.keys(row.orderPickupDelivered).length)) {
                                                                    canBeProccesed = false
                                                                }

                                                                if (isParlourOrder && !row.orderPickupDelivered) {
                                                                    canBeProccesed = false
                                                                }

                                                                if (isParlourOrder && !row.delivery && row.orderPickupDelivered) {
                                                                    for (let [key, value] of Object.entries(row.orderPickupDelivered)) {
                                                                        if (key !== "undefined" && !value && key.toString().length > 13) {
                                                                            canBeProccesed = false
                                                                        }
                                                                    }
                                                                }

                                                                if (!canBeProccesed) {
                                                                    return SnackBar("Nie oznaczono wszystkich przesyłek jako dostarczone. Er_3", "error")
                                                                } else {
                                                                    this.setState({
                                                                        markFullDelivered: {
                                                                            text: "Czy na pewno chcesz oznaczyć to zamówienie jako dostarczone do salonu?",
                                                                            data: {
                                                                                data: row,
                                                                                type: "mark",
                                                                                orderId: row.id
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            Oznacz zamówienie jako dostarczone do salonu
                                                        </Button>
                                                        : <div>
                                                            <Button
                                                                primary
                                                                onClick={() => {
                                                                    if (!row.canBePickup) {
                                                                        return SnackBar("Zamówienie nie zostało jeszcze w pełni skompletowane.", "error")
                                                                    }

                                                                    let isParlourOrder = false;

                                                                    if (row.id.toString().charAt(0) === "2") {
                                                                        isParlourOrder = true;
                                                                    }

                                                                    if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse === "k99") {
                                                                        isParlourOrder = false;
                                                                    }

                                                                    if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse === "x99") {
                                                                        isParlourOrder = true;
                                                                    }

                                                                    if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse === "salonowe") {
                                                                        isParlourOrder = true;
                                                                    }

                                                                    if (isParlourOrder && row.orderService) {
                                                                        let everyPackageIsSend = true;

                                                                        row.orderService.forEach(service => {
                                                                            if (["accepted", "new"].includes(service.status)) {
                                                                                everyPackageIsSend = false;
                                                                            }
                                                                        })

                                                                        if (!everyPackageIsSend) {
                                                                            return SnackBar("Co najmniej jedna z paczek nie została jeszcze dostarczona. Er_5", "error")
                                                                        }
                                                                    }

                                                                    this.setState({
                                                                        orderPickupModal: {
                                                                            isParlourOrder,
                                                                            ...row
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                Wydaj zamówienie klientowi
                                                            </Button>

                                                            {/*<Button*/}
                                                                {/*style={{*/}
                                                                    {/*marginTop: 10*/}
                                                                {/*}}*/}
                                                                {/*basic*/}
                                                                {/*color={"red"}*/}
                                                                {/*onClick={() => {*/}
                                                                    {/*row.packages.forEach(pack => {*/}
                                                                        {/*this.setState({*/}
                                                                            {/*markFullDelivered: {*/}
                                                                                {/*text: "Czy na pewno chcesz oznaczyć to zamówienie jako niedostarczone do salonu?",*/}
                                                                                {/*data: {*/}
                                                                                    {/*data: row,*/}
                                                                                    {/*type: "unmark",*/}
                                                                                    {/*isParlourOrder,*/}
                                                                                    {/*orderId: row.id*/}
                                                                                {/*}*/}
                                                                            {/*}*/}
                                                                        {/*})*/}
                                                                    {/*})*/}
                                                                {/*}}*/}
                                                            {/*>*/}
                                                                {/*Oznacz jako niedostarczone*/}
                                                            {/*</Button>*/}
                                                        </div>
                                                }

                                            </div>
                                        )
                                    }
                                },
                            ]}
                        />
                    </Paper>
                </Container>

                <Confirm
                    dimmer={"inverted"}
                    open={this.state.confirm}
                    content={this.state.confirm && this.state.confirm.text}
                    onCancel={() => this.setState({confirm: false})}
                    onConfirm={() => {
                        this.setState({confirm: false, dimmer: true})
                        this.markDelivered(this.state.confirm.data)
                    }}
                />

                <Confirm
                    dimmer={"inverted"}
                    open={this.state.markFullDelivered}
                    content={this.state.markFullDelivered && this.state.markFullDelivered.text}
                    onCancel={() => this.setState({markFullDelivered: false})}
                    onConfirm={() => {
                        this.setState({markFullDelivered: false, dimmer: true})
                        this.markFullDelivered(this.state.markFullDelivered.data)
                    }}
                />

                {this.state.orderPickupModal &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.orderPickupModal} onClose={() => this.setState({orderPickupModal: false})}>
                        <Modal.Header>
                            Odbiór zamówienia przez klienta
                        </Modal.Header>
                        <Modal.Content>
                            <div className={"order-pickup-modal-contents"}>
                                <div className={"order-pickup-modal-opt"}>Numer zamówienia: <span>{this.state.orderPickupModal.orderId}</span></div>
                                <div className={"order-pickup-modal-opt"}>Ilość paczek do wydania: <span>{!this.state.orderPickupModal.isParlourOrder ? 1 : this.state.orderPickupModal.orderService.length}</span></div>
                            </div>

                            <EsotiqInput
                                label={"Wydanie nr telefonu klienta"}
                                placeholder={"Nr. tel. użyty podczas składania zamówienia"}
                                width={"100%"}
                                onChange={(value) => {
                                    this.setState({
                                        orderPickupCustomerPhone: value
                                    })
                                }}
                            />

                            <div style={{
                                width: "100%",
                                textAlign: "center",
                                fontWeight: 500,
                                color: "#b9b9b9",
                                marginTop: 10,
                                marginBottom: 5
                            }}>lub</div>

                            <EsotiqInput
                                label={"Wydanie email klienta"}
                                placeholder={"Email użyty podczas składania zamówienia"}
                                width={"100%"}
                                onChange={(value) => {
                                    this.setState({
                                        orderPickupCustomerEmail: value
                                    })
                                }}
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                const order = this.state.orderPickupModal;
                                const {orderPickupCustomerEmail, orderPickupCustomerPhone} = this.state;

                                if (!orderPickupCustomerEmail && !orderPickupCustomerPhone) {
                                    return SnackBar("Wprowadź numer telefonu lub adres email klienta.", "error");
                                }

                                if ((orderPickupCustomerEmail && !orderPickupCustomerEmail.length) && (orderPickupCustomerPhone && !orderPickupCustomerPhone.length)) {
                                    return SnackBar("Wprowadź numer telefonu lub adres email klienta.", "error");
                                }

                                if ((orderPickupCustomerEmail && orderPickupCustomerEmail.length) && order.addressShipping && !order.addressShipping.email) {
                                    return SnackBar("Adres email nie został określony w danych zamówienia (adres wysyłki).", "error");
                                }

                                if ((orderPickupCustomerPhone && orderPickupCustomerPhone.length) && order.addressShipping && !order.addressShipping.telephone) {
                                    return SnackBar("Numer telefonu nie został określony w danych zamówienia (adres wysyłki).", "error");
                                }

                                if ((orderPickupCustomerEmail && orderPickupCustomerEmail.length > 1) && order.addressShipping.email !== orderPickupCustomerEmail) {
                                    return SnackBar(`Wprowadzony adres email nie jest zgodny z danymi zamówienia. (Order email: ${order.addressShipping.email})`, "error");
                                }

                                if ((orderPickupCustomerPhone && orderPickupCustomerPhone.length > 1) && order.addressShipping.telephone !== orderPickupCustomerPhone) {
                                    return SnackBar(`Wprowadzony nr telefonu nie jest zgodny z danymi zamówienia. (Order telephone: ${order.addressShipping.telephone})`, "error");
                                }

                                this.markPickup(this.state.orderPickupModal);
                                this.setState({
                                    orderPickupModal: false
                                })
                            }} positive>Zapisz</Button>
                            <Button onClick={() => {
                                this.setState({orderPickupModal: false})
                            }} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

            </React.Fragment>
        );
    }
}
