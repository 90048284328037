import React from "react"
import ActionPanel from "../../components/ActionPanel";
import Breadcrumb from "../../components/Breadcrumb";
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import UITable from "../../lib/table-v2";
import Button from "../../components/Button";
import {Modal, Icon, Menu, Segment, Loader, Table, Checkbox, Form, Comment, Dimmer} from 'semantic-ui-react'
import EsotiqInput from "../../components/EsotiqInput";
import {jsonCopy} from "../../helpers/lib";
import SnackBar from "../../components/Snack";
import {Download, exportToFile, saveToFile} from "../../controller/exportController";
import CsvImport from "../../components/CsvImport";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {formatDate} from "../../helpers/dateHelper";
import Confirm from "../../components/Confirm";
import {TranslationController} from "../../controller/menager";
import {DATA_OBJECT} from "../../controller/dataController";
import MenuButton from "../../lib/table-v2/menuButton";
import TableMenu from "../../components/TableMenu";
import SliderFilter from "../../components/SliderFilter";
import DataPresentation from "../../components/DataPresentation";

class Parlours extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null,
            websiteFilter: [],
            dataCount: null,
            modalStates: false,
            tableSelectableState: [],
            tableExportCols: [],
            comments: [],
            tableSelectable: false,
            sliderStatus: false,
            timingReportFrom: formatDate(new Date()).dateNowSystem,
            timingReportTo: formatDate(new Date()).dateNowSystem,
            orderPickerConfig: {}
        }

        this.exportFields = [
            {
                field: "id",
                label: "Id"
            },
            {
                field: "country",
                label: "Kraj"
            },
            {
                field: "excludedFromSync",
                label: "Wykl. z kompl."
            },
            {
                field: "isOnline",
                label: "Sprzedaż online"
            },
            {
                field: "isActive",
                label: "Aktywny"
            },
            {
                field: "isFr",
                label: "Franczyza"
            },
            {
                field: "canTakeOrder",
                label: "Może przyjąć zam. online"
            },
            {
                field: "canTakeInternalOrder",
                label: "Może przyjąć zam. wew"
            },
            {
                field: "multiOrderLimit",
                label: "Wspólny limit zamówień"
            },
            // {
            //     field: "todayOrders",
            //     label: "Przydzielone zam. dziś"
            // },
            // {
            //     field: "itemToday",
            //     label: "Przydzielone towary dziś"
            // },
            {
                field: "orderLimit",
                label: "Limit zamówień / dzień"
            },
            {
                field: "itemLimit",
                label: "Limit towarów / dzień"
            },
            {
                field: "name",
                label: "Nazwa"
            },
            {
                field: "city",
                label: "Miasto"
            },
            {
                field: "parlourPickup",
                label: "Odbiór w salonie"
            },
            {
                field: "visibleOnMap",
                label: "Widoczny na mapie"
            },
            {
                field: "geocode",
                label: "Geocode"
            },
            {
                field: "phone",
                label: "Telefon"
            },
            {
                field: "street",
                label: "Ulica"
            },
            {
                field: "zip",
                label: "Kod pocztowy"
            },
            {
                field: "opened_from_mon",
                label: "Poniedziałek od"
            },
            {
                field: "opened_to_mon",
                label: "Poniedziałek do"
            },
            {
                field: "opened_from_tues",
                label: "Wtorek od"
            },
            {
                field: "opened_to_tues",
                label: "Wtorek do"
            },
            {
                field: "opened_from_weds",
                label: "Środa od"
            },
            {
                field: "opened_to_weds",
                label: "Środa do"
            },
            {
                field: "opened_from_thurs",
                label: "Czwartek od"
            },
            {
                field: "opened_to_thurs",
                label: "Czwartek do"
            },
            {
                field: "opened_from_fri",
                label: "Piątek od"
            },
            {
                field: "opened_to_fri",
                label: "Piątek do"
            },
            {
                field: "opened_from_sat",
                label: "Sobota od"
            },
            {
                field: "opened_to_sat",
                label: "Sobota do"
            },
            {
                field: "opened_from_sun",
                label: "Niedziela od"
            },
            {
                field: "opened_to_sun",
                label: "Niedziela do"
            },
        ]

        if (process.env.REACT_APP_OP_V2_WAREHOUSE_CONFIG === "true") {
            this.exportFields.push({
                    field: "group",
                    label: "Grupa"
                },
                {
                    field: "isParlour",
                    label: "Magazyn salonowy"
                }
            )
        }


        this.update = {
            init: row => this.updateState = jsonCopy(row),
            prepare: (key, value) => {
                this.updateState[key] = value

                this.forceUpdate()
            },
            call: row => {

                Object.keys(row).forEach(key => row[key] = this.updateState[key])

                this.setState({
                    modalOpened: false
                })

                api().post("/api/parlours/update", {parlour: this.updateState})
                    .then((res) => res.data.error ? SnackBar(res.data.message, "error") : SnackBar("Zapisano pomyślnie."))
                    .catch(e => SnackBar("Error", "error"))
            }
        }
    }

    componentDidMount() {
        api().post("/api/parlours")
            .then(r => {
                const parlours = []

                r.data.data.forEach(parlour => {
                    parlours.push({
                        key: parlour.id, value: parlour.id, text: parlour.id
                    })
                })

                this.setState({
                    parloursList: parlours
                })
            })

        this.getOrderPickerConfig();
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api().post("/api/parlours")
                .then(r => {
                    return r.data.data
                })
        })

        return api().post("/api/parlours", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    getOrderPickerConfig() {
        return api().get("/api/warehouses-config")
            .then(r => {
                this.setState({orderPickerConfig: r.data || {}})
            })
    }

    saveOrderPickerConfig() {
        return api().post("/api/warehouses-config", this.state.orderPickerConfig || {})
            .then(r => {
                this.setState({editConfig: false})

                return SnackBar("Zapisano pomyślnie.")
            })
    }

    addComment(parlour) {
        if (!this.state.newCommentValue) {
            return SnackBar("Wprowadzona wartość nie może być pusta.", "error")
        }

        if (this.state.newCommentValue.length < 4) {
            return SnackBar("Wprowadzony tekst jest za krótki.", "error")
        }

        const complaint = this.state;

        if (!complaint.comments) {
            complaint.comments = []
        }

        complaint.comments.push({
            value: this.state.newCommentValue,
            timestamp: new Date().getTime(),
            user: DATA_OBJECT.user_login,
            orderId: parseInt(this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1])
        })

        api().post("/api/parlours/comments/add", {
            value: this.state.newCommentValue,
            timestamp: new Date().getTime(),
            user: DATA_OBJECT.user_login,
            parlour: parlour
        })
            .then(() => {
                this.setState({
                    newCommentValue: "",
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    removeComment(comment) {
        if (DATA_OBJECT.user_login !== comment.user) {
            return SnackBar("Możesz usuwać tylko własne komentarze", "error")
        }

        const complaint = this.state;

        complaint.comments = complaint.comments.filter(comm => comm !== comment)

        api().post("/api/parlours/comments/remove", {...comment})
            .then(() => {
                this.setState({
                    refresh: new Date().getTime(),
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    getComments(parlour) {
        this.setState({
            gettingComments: true
        })

        api().post("/api/parlours/comments/get", {parlour})
            .then((result) => {
                this.setState({
                    comments: result.data.comments || [],
                    gettingComments: false
                })
            })
    }

    getState(parlour) {
        api().post("/api/parlours/state", {parlour})
            .then(r => {
                this.setState({
                    parlourState: r.data.data ? r.data.data.state : []
                })
            })
    }

    uniExport() {
        if (!this.orderTable.getSelectedState().length) {
            return SnackBar("Nie wybrano żadnego wiersza", "error")
        }

        return exportToFile(this.orderTable.getSelectedState(), "uniExport", this.state.tableExportCols)
    }

    handleSliderStatusToggle (e) {
        e.preventDefault();
        this.setState({sliderStatus: !this.state.sliderStatus})
    }

    render() {
        const {data, websiteFilter} = this.state


        return (
            <React.Fragment>
                {!this.props.picker &&
                    <ActionPanel
                        actions={[
                        ]}
                    />
                }

                <Container>
                    {!this.props.picker &&
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `Salony ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                }
                            ]}
                        />
                    }
                        <div className="city-filter-button">
                        <Button style={{width: "140px", margin: "0"}} onClick={()=>this.setState({sliderStatus: !this.state.sliderStatus})} >DODATKOWE FILTRY</Button>
                        </div>
                    {this.state.sliderStatus ?
                        <SliderFilter
                            sliderStatus={this.state.sliderStatus}
                            handleStatusToggle={this.handleSliderStatusToggle.bind(this)}
                            orderTable={this.orderTable}
                        />
                        : "" }
                    <div style={{height: 10}}></div>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Export</TableMenu.Header>

                                <TableMenu.Item>
                                    <span className='text'>Raport uniwersalny</span>

                                    <TableMenu.Menu>
                                        <div className={"uni-report-container"}>
                                            <div className={"uni-report-header"}>Wybierz kolumny do raportu</div>

                                            <div className={"uni-report-content-box"}>
                                                <div className={"uni-report-column"}>
                                                    <ul>
                                                        {this.exportFields.map((fieldValues, key) => {
                                                            return (
                                                                <li>
                                                                    <Checkbox key={key} onClick={(e) => {
                                                                        const field = fieldValues.field

                                                                        return !this.state.tableExportCols.includes(field) ? this.state.tableExportCols.push(field) : this.state.tableExportCols = this.state.tableExportCols.filter(col => col != field)
                                                                    }} label={fieldValues.label}/>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className={"uni-report-actions"}>
                                                <Button
                                                    color='blue'
                                                    content='Pobierz'
                                                    icon='download'
                                                    label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                    onClick={() => this.uniExport()}
                                                />
                                            </div>
                                        </div>
                                    </TableMenu.Menu>

                                </TableMenu.Item>

                                <TableMenu.Item
                                    onClick={() => {
                                        api().get("/api/warehouses-config/parlour-status-report")
                                            .then((r) => {
                                                if (r.data.data.parlourStatusReport) {
                                                    exportToFile(r.data.data.parlourStatusReport, "uniExport",
                                                        ["id", "active", "dailyIndexCount", "dailyOrderCount", "group"],
                                                        ["id", "active", "dailyIndexCount", "dailyOrderCount", "group"],
                                                        "parlours_export.csv"
                                                    );
                                                }

                                                SnackBar("Rozpoczęto proces eksportu.")
                                            })
                                    }}
                                >
                                    <span className='text'>Raport pełny</span>
                                </TableMenu.Item>

                                {
                                    process.env.REACT_APP_OP_V2_WAREHOUSE_CONFIG === "true" &&
                                        <TableMenu.Item
                                            onClick={() => {
                                                api().post("/api/parlours/excludedStockUnits", {
                                                    isExport: true
                                                })
                                                    .then((r) => {
                                                        if (r.data) {
                                                            SnackBar("Rozpoczęto proces eksportu.")
                                                            saveToFile(r.data, null, null, null, "excluded_stock_units.csv");
                                                        } else {
                                                            SnackBar("Brak danych do eksportu.", "error")
                                                        }
                                                    })
                                                    .catch(() => SnackBar("Błąd serwera.", "error"))
                                            }}
                                        >
                                            <span className='text'>Wykluczone indeksy</span>
                                        </TableMenu.Item>
                                }

                                <TableMenu.Divider />
                                <TableMenu.Header>Import</TableMenu.Header>

                                <TableMenu.Item onClick={() => this.setState({
                                    editConfig: true
                                })}>Edytuj warunki</TableMenu.Item>

                                <TableMenu.Item>
                                    <CsvImport
                                        csvStructure={[
                                            {column: "id", placeholder: "L99"},
                                            {column: "multiOrderLimit", placeholder: 1},
                                            {column: "canTakeInternalOrder", placeholder: 1},
                                            {column: "canTakeOrder", placeholder: 1},
                                            {column: "country", placeholder: "pl"},
                                            {column: "internalOrderLimit", placeholder: 1},
                                            {column: "isOnline", placeholder: 1},
                                            {column: "itemInternalLimit", placeholder: 1},
                                            {column: "itemLimit", placeholder: 1},
                                            {column: "orderLimit", placeholder: 1},
                                            {column: "itemInternalToday", placeholder: 1},
                                            {column: "itemToday", placeholder: 1},
                                            {column: "todayInternalOrders", placeholder: 1},
                                            {column: "todayOrders", placeholder: 1},
                                            {column: "visibleOnMap", placeholder: 1},
                                        ]}
                                        onUpdate={(data) => {
                                            return api().post("/api/parlours/massUpdate", {data})
                                                .then((res) => {
                                                    if (res.data.error) {
                                                        SnackBar(res.data.message, "error");
                                                    }
                                                    this.orderTable.setState({
                                                        data: null
                                                    });

                                                    this.orderTable.init();

                                                    return res
                                                })
                                        }}
                                    />
                                </TableMenu.Item>
                                { process.env.REACT_APP_OP_V2_WAREHOUSE_CONFIG === "true" && <TableMenu.Item>
                                    <CsvImport
                                        csvStructure={[
                                            {column: "index", placeholder: "12345-00X-L"},
                                            {column: "parlour", placeholder: "K88"},
                                            {column: "status", placeholder: "false"},
                                        ]}
                                        onUpdate={(data) => {
                                            return api().post("/api/states/updateExcludedStockUnitsByWarehouse", {data})
                                                .then((res) => {
                                                    if (res.data.error) {
                                                        SnackBar(res.data.message, "error");
                                                    }
                                                    this.orderTable.setState({
                                                        data: null
                                                    });

                                                    this.orderTable.init();
                                                })
                                        }}
                                    />
                                </TableMenu.Item> }
                            </TableMenu.Menu>
                            
                        </TableMenu>

                        <Menu.Menu position='right'>
                            <MenuButton
                                icon={"sync"}
                                onClick={() => {
                                    Confirm("Czy na pewno chcesz rozpoczać proces aktualizacji? Może to potrwać około 2 minut zanim wszystkie dane będą widoczne.", "", () => {
                                        api().post("/api/parlours/webSync", {})
                                            .then(r => {
                                                SnackBar("Rozpoczęto proces aktualizacji. Dane będą widoczne w ciągu minuty.");

                                                setTimeout(() => {
                                                    this.orderTable.setState({
                                                        data: null
                                                    });

                                                    this.orderTable.init();
                                                }, 1000)
                                            })
                                    })
                                }}
                                label={"Aktualizuj salony"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/parlours",
                                options: {limit: 25, currentPage: 1}
                            }}
                            ref={table => this.orderTable = table}
                            selectable={this.state.tableSelectable}
                            provider={this}
                            columns={[
                                {
                                    label: "Salon",
                                    field: "id",
                                    width: 1,
                                    filters: [
                                        {label: "Salon", condition: "like", type: "text", uppercase: true}
                                    ],
                                },
                                process.env.REACT_APP_OP_V2_WAREHOUSE_CONFIG != "true" && {
                                    label: "Kraj",
                                    field: "country",
                                    center: true,
                                    filters: [
                                        {label: "Kraj", condition: "like", type: "text"}
                                    ],
                                    render: row => (
                                        <div className={"table-row-display-uppercase"}>{row.country}</div>
                                    )
                                },
                                process.env.REACT_APP_OP_V2_WAREHOUSE_CONFIG != "true" && {
                                    label: "Aktywny",
                                    field: "isActive",
                                    bool: true,
                                    filters: [
                                        {label: "Aktywny", condition: "eq", type: "toggle", matchValue: {"1": 1, "0": 0}}
                                    ],
                                },
                                process.env.REACT_APP_OP_V2_WAREHOUSE_CONFIG != "true" && {
                                    label: "Franczyza",
                                    field: "isFr",
                                    bool: true,
                                    filters: [
                                        {label: "Franczyza", condition: "eq", type: "toggle", matchValue: {"1": 1, "0": 0}}
                                    ],
                                },
                                {
                                    label: "Sprzedaż online",
                                    field: "isOnline",
                                    bool: true,
                                    filters: [
                                        {label: "Sprzedaż online", condition: "eq", type: "toggle", matchValue: {"1": 1, "0": 0}}
                                    ],
                                },
                                process.env.REACT_APP_OP_V2_WAREHOUSE_CONFIG != "true" && {
                                    label: "Wykl. z kompletacji",
                                    field: "excludedFromSync",
                                    bool: true,
                                    filters: [
                                        {label: "Wykl. z kompletacji", condition: "eq", type: "toggle", matchValue: {"1": 1, "0": 0}}
                                    ],
                                },
                                {
                                    label: "Przydzielone zam. dziś",
                                    field: "todayOrders",
                                    render: row => `(${row.totalOrders - row.todayOrders}) ${row.todayOrders}`
                                },
                                {
                                    label: "Przydzielone towary dziś",
                                    field: "itemToday",
                                    render: row => `(${row.totalItems - row.itemToday}) ${row.itemToday}`
                                },
                                {
                                    label: "Limit zamówień / dzień",
                                    field: "orderLimit",
                                },
                                {
                                    label: "Limit towaru / index",
                                    field: "itemLimit",
                                },
                                {
                                    label: "Może przyjąć zam. online",
                                    field: "canTakeOrder",
                                    bool: true
                                },
                                process.env.REACT_APP_OP_V2_WAREHOUSE_CONFIG === "true" && {
                                    label: "Grupa",
                                    field: "group",
                                    render: row => `${row.group || "Brak"}`
                                },
                                process.env.REACT_APP_OP_V2_WAREHOUSE_CONFIG === "true" && {
                                    label: "Salonowy?",
                                    field: "isParlour",
                                    bool: true,
                                    filters: [
                                        {label: "Magazyn salonowy", condition: "eq", type: "toggle", matchValue: {"1": 1, "0": 0}}
                                    ],
                                },
                                {
                                    label: "Edycja",
                                    selectable: true,
                                    center: true,
                                    render: row => {
                                        return (
                                            <div onClick={() => {
                                                this.setState({modalOpened: {row}})
                                                this.update.init(row)
                                            }}><Icon name={"edit"}/></div>
                                        )
                                    }
                                },
                                {
                                    label: "Stan tow.",
                                    selectable: true,
                                    center: true,
                                    render: row => {
                                        return (
                                            <div onClick={() => {
                                                this.setState({modalStates: {row}})
                                                this.getState(row.id)
                                            }}><Icon name={"search"}/></div>
                                        )
                                    }
                                },
                                {
                                    label: "Więcej",
                                    selectable: true,
                                    center: true,
                                    render: row => {
                                        return (
                                            <div onClick={() => {
                                                this.getComments(row.id);
                                                this.setState({modalAddInfo: {row}})
                                                this.update.init(row)
                                            }}><MoreHorizIcon/></div>
                                        )
                                    }
                                },
                                // {
                                //     label: "Usuń",
                                //     selectable: true,
                                //     center: true,
                                //     render: row => {
                                //         return (
                                //             <div onClick={() => {
                                //                 Confirm("Czy na pewno chcesz trwale usunąć ten salon?", null, () => {
                                //                     api().post("/api/parlours/delete", {parlour: row.id})
                                //                         .then(r => {
                                //                             this.orderTable.setState({
                                //                                 data: null
                                //                             })
                                //
                                //                             this.orderTable.init()
                                //
                                //                             return data
                                //                         })
                                //                 })
                                //             }}><Icon name={"trash"}/></div>
                                //         )
                                //     }
                                // }
                            ]}
                        />
                    </Segment>
                </Container>

                {this.state.modalAddInfo &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.modalAddInfo} onClose={() => this.setState({modalAddInfo: false})}>
                        <Modal.Header>Informacje</Modal.Header>
                        <Modal.Content>
                            <div className={"states-modal-info"}>
                                <div className={"state-modal-info-text"}>Salon: <span>{this.state.modalAddInfo.row.id}</span></div>
                            </div>

                            <div className={"input-row"}>
                                <EsotiqInput
                                    label={"Nazwa"}
                                    defValue={this.state.modalAddInfo.row.name}
                                    onChange={(value) => this.update.prepare("name", value)}
                                />

                                <EsotiqInput
                                    label={"Miasto"}
                                    defValue={this.state.modalAddInfo.row.city}
                                    onChange={(value) => this.update.prepare("city", value)}
                                />

                                <EsotiqInput
                                    label={"Odbiór w salonie"}
                                    defValue={this.state.modalAddInfo.row.parlourPickup}
                                    options={[
                                        {key: 0, value: 0, text: "Nie"},
                                        {key: 1, value: 1, text: "Tak"}
                                    ]}
                                    onChange={(value) => this.update.prepare("parlourPickup", value)}
                                />

                                <EsotiqInput
                                    label={"Widoczny na mapie"}
                                    defValue={this.state.modalAddInfo.row.visibleOnMap}
                                    options={[
                                        {key: 0, value: 0, text: "Nie"},
                                        {key: 1, value: 1, text: "Tak"}
                                    ]}
                                    onChange={(value) => this.update.prepare("visibleOnMap", value)}
                                />

                                <EsotiqInput
                                    label={"Telefon"}
                                    defValue={this.state.modalAddInfo.row.phone}
                                    onChange={(value) => this.update.prepare("phone", value)}
                                />
                            </div>

                            <div className={"input-row"}>
                                <EsotiqInput
                                    label={"Geocode"}
                                    defValue={this.state.modalAddInfo.row.geocode}
                                    onChange={(value) => this.update.prepare("geocode", value)}
                                />

                                <EsotiqInput
                                    label={"Kraj"}
                                    defValue={this.state.modalAddInfo.row.country}
                                    onChange={(value) => this.update.prepare("country", value)}
                                />

                                <EsotiqInput
                                    label={"Ulica"}
                                    defValue={this.state.modalAddInfo.row.street}
                                    onChange={(value) => this.update.prepare("street", value)}
                                />

                                <EsotiqInput
                                    label={"Kod pocztowy"}
                                    defValue={this.state.modalAddInfo.row.zip}
                                    onChange={(value) => this.update.prepare("zip", value)}
                                />
                            </div>

                            <div style={{marginTop: 20}} className={"states-modal-info"}>
                                <div className={"state-modal-info-text"}>Informacje:</div>
                            </div>

                            <div className={"input-row"}>
                                <EsotiqInput
                                    label={"Franczyza"}
                                    defValue={this.state.modalAddInfo.row.isFr}
                                    options={[
                                        {key: 0, value: 0, text: "Nie"},
                                        {key: 1, value: 1, text: "Tak"}
                                    ]}
                                    onChange={(value) => this.update.prepare("isFr", value)}
                                />

                                <EsotiqInput
                                    label={"RKS"}
                                    defValue={this.state.modalAddInfo.row.rks}
                                    onChange={(value) => this.update.prepare("rks", value)}
                                />

                                <EsotiqInput
                                    label={"RKS E-mail"}
                                    defValue={this.state.modalAddInfo.row.rksMail}
                                    onChange={(value) => this.update.prepare("rksMail", value)}
                                />

                                <EsotiqInput
                                    label={"RKS Telefon"}
                                    defValue={this.state.modalAddInfo.row.rksTel}
                                    onChange={(value) => this.update.prepare("rksTel", value)}
                                />
                            </div>

                            <div style={{marginTop: 20}} className={"states-modal-info"}>
                                <div className={"state-modal-info-text"}>Godziny otwarcia:</div>
                            </div>

                            <div className={"input-row"}>
                                <div>
                                    <div className={"input-row"}>
                                        <EsotiqInput
                                            label={"Poniedziałek od"}
                                            defValue={this.state.modalAddInfo.row.opened_from_mon}
                                            onChange={(value) => this.update.prepare("opened_from_mon", value)}
                                        />

                                        <EsotiqInput
                                            label={"Poniedziałek do"}
                                            defValue={this.state.modalAddInfo.row.opened_to_mon}
                                            onChange={(value) => this.update.prepare("opened_to_mon", value)}
                                        />
                                    </div>


                                    <div className={"input-row"}>
                                        <EsotiqInput
                                            label={"Wtorek od"}
                                            defValue={this.state.modalAddInfo.row.opened_from_tues}
                                            onChange={(value) => this.update.prepare("opened_from_tues", value)}
                                        />

                                        <EsotiqInput
                                            label={"Wtorek do"}
                                            defValue={this.state.modalAddInfo.row.opened_to_tues}
                                            onChange={(value) => this.update.prepare("opened_to_tues", value)}
                                        />
                                    </div>

                                    <div className={"input-row"}>
                                        <EsotiqInput
                                            label={"Środa od"}
                                            defValue={this.state.modalAddInfo.row.opened_from_weds}
                                            onChange={(value) => this.update.prepare("opened_from_weds", value)}
                                        />

                                        <EsotiqInput
                                            label={"Środa do"}
                                            defValue={this.state.modalAddInfo.row.opened_to_weds}
                                            onChange={(value) => this.update.prepare("opened_to_weds", value)}
                                        />
                                    </div>

                                    <div className={"input-row"}>
                                        <EsotiqInput
                                            label={"Czwartek od"}
                                            defValue={this.state.modalAddInfo.row.opened_from_thurs}
                                            onChange={(value) => this.update.prepare("opened_from_thurs", value)}
                                        />

                                        <EsotiqInput
                                            label={"Czwartek do"}
                                            defValue={this.state.modalAddInfo.row.opened_to_thurs}
                                            onChange={(value) => this.update.prepare("opened_to_thurs", value)}
                                        />
                                    </div>

                                    <div className={"input-row"}>
                                        <EsotiqInput
                                            label={"Piątek od"}
                                            defValue={this.state.modalAddInfo.row.opened_from_fri}
                                            onChange={(value) => this.update.prepare("opened_from_fri", value)}
                                        />

                                        <EsotiqInput
                                            label={"Piątek do"}
                                            defValue={this.state.modalAddInfo.row.opened_to_fri}
                                            onChange={(value) => this.update.prepare("opened_to_fri", value)}
                                        />
                                    </div>

                                    <div className={"input-row"}>
                                        <EsotiqInput
                                            label={"Sobota od"}
                                            defValue={this.state.modalAddInfo.row.opened_from_sat}
                                            onChange={(value) => this.update.prepare("opened_from_sat", value)}
                                        />

                                        <EsotiqInput
                                            label={"Sobota do"}
                                            defValue={this.state.modalAddInfo.row.opened_to_sat}
                                            onChange={(value) => this.update.prepare("opened_to_sat", value)}
                                        />
                                    </div>

                                    <div className={"input-row"}>
                                        <EsotiqInput
                                            label={"Niedziela od"}
                                            defValue={this.state.modalAddInfo.row.opened_from_sun}
                                            onChange={(value) => this.update.prepare("opened_from_sun", value)}
                                        />

                                        <EsotiqInput
                                            label={"Niedziela do"}
                                            defValue={this.state.modalAddInfo.row.opened_to_sun}
                                            onChange={(value) => this.update.prepare("opened_to_sun", value)}
                                        />
                                    </div>
                                </div>

                                <div style={{
                                    width: "100%",
                                    marginTop: 27
                                }}>
                                    {(this.state.comments && this.state.comments.length) ?
                                        <Comment.Group>
                                            {this.state.comments.map((comment, key) => {
                                                return (
                                                    <Comment key={key}>
                                                        <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
                                                        <Comment.Content>
                                                            <Comment.Author as='a'>{comment.user}</Comment.Author>
                                                            <Comment.Metadata>
                                                                <div>{formatDate(comment.timestamp).dateTimeSystem}</div>
                                                            </Comment.Metadata>
                                                            <Comment.Text>{comment.value}</Comment.Text>
                                                            <Comment.Actions>
                                                                <Comment.Action
                                                                    onClick={() => this.removeComment(comment)}
                                                                >{TranslationController.translate("Usuń")}</Comment.Action>
                                                            </Comment.Actions>
                                                        </Comment.Content>
                                                    </Comment>
                                                )
                                            })}
                                        </Comment.Group>
                                        : <div style={{
                                            textAlign: "center",
                                            color: "#c6c6c6",
                                            margin: 20
                                        }}>{this.state.gettingComments ? TranslationController.translate("Ładowanie...") : TranslationController.translate("Jeszcze nikt nie dodał żadnego komentarza")}</div>
                                    }

                                    <Form
                                        reply
                                        onSubmit={(value) => {
                                            this.addComment(this.state.modalAddInfo.row.id)
                                        }}
                                    >
                                        <Form.TextArea
                                            placeholder={TranslationController.translate("Treść...")}
                                            onChange={(e, v) => {
                                                this.state.newCommentValue = e.target.value;
                                            }}
                                        />
                                        <Button content={TranslationController.translate("Dodaj komentarz")} labelPosition='left' icon='edit' primary />
                                    </Form>

                                    <div style={{
                                        marginTop: 10
                                    }}>
                                        <Button
                                            content={TranslationController.translate("Aktualizuj informacje")}
                                            icon='edit'
                                            primary
                                            onClick={() => {
                                                Confirm("Czy na pewno chcesz zaktualizować informacje o salonie?", null, () => {
                                                    api().post("/api/parlours/webSync", {
                                                        parlourId: this.state.modalAddInfo.row.id
                                                    })
                                                        .then(r => {
                                                            SnackBar("Rozpoczęto proces aktualizacji. Dane będą widoczne w ciągu minuty.");

                                                            setTimeout(() => {
                                                                this.orderTable.setState({
                                                                    data: null
                                                                });

                                                                this.orderTable.init();
                                                            }, 1000)
                                                        })
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({modalAddInfo: false})} negative>Anuluj</Button>
                            <Button
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content='Zapisz'
                                onClick={() => {
                                    this.update.call(this.state.modalAddInfo.row);

                                    this.setState({
                                        comments: [],
                                        modalAddInfo: false
                                    })
                                }}
                            />
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.modalStates &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.modalStates} onClose={() => this.setState({modalStates: false, parlourState: null})}>
                        <Modal.Header>Stany towarowe</Modal.Header>
                        <Modal.Content>
                            {this.state.parlourState ?
                                <React.Fragment>
                                    <Table basic='very'>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>SKU</Table.HeaderCell>
                                                <Table.HeaderCell>Na stanie</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {this.state.parlourState && this.state.parlourState.map((e, k) => {
                                                return (
                                                    <Table.Row key={k}>
                                                        <Table.Cell>{e.sku}</Table.Cell>
                                                        <Table.Cell>{e.qty}</Table.Cell>
                                                    </Table.Row>
                                                )
                                            })}
                                        </Table.Body>
                                    </Table>
                                </React.Fragment>
                            : <Loader active></Loader>}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({modalStates: false, parlourState: null})}>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.modalOpened &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.modalOpened} onClose={() => this.setState({modalOpened: false})}>
                        <Modal.Header>Edycja salonu</Modal.Header>
                        <Modal.Content>
                            <div className={"states-modal-info"}>
                                <div className={"state-modal-info-text"}>Salon: <span>{this.state.modalOpened.row.id}</span></div>
                            </div>

                            <EsotiqInput
                                label={"Sprzedaż online"}
                                defValue={this.state.modalOpened.row.isOnline.toString()}
                                options={[
                                    {key: "0", value: "0", text: "Nie"},
                                    {key: "1", value: "1", text: "Tak"}
                                ]}
                                onChange={(value) => this.update.prepare("isOnline", value)}
                            />

                            {
                                process.env.REACT_APP_OP_V2_WAREHOUSE_CONFIG !== "true" && <EsotiqInput
                                    label={"Wykluczony z kompletacji"}
                                    defValue={this.state.modalOpened.row.excludedFromSync}
                                    options={[
                                        {key: "0", value: "0", text: "Nie"},
                                        {key: "1", value: "1", text: "Tak"}
                                    ]}
                                    onChange={(value) => this.update.prepare("excludedFromSync", value)}
                                />
                            }

                            <EsotiqInput
                                label={"Limit zamówień / dzień"}
                                defValue={this.state.modalOpened.row.orderLimit}
                                onChange={(value) => this.update.prepare("orderLimit", value)}
                            />

                            <EsotiqInput
                                label={"Limit towaru / index"}
                                defValue={this.state.modalOpened.row.itemLimit}
                                onChange={(value) => this.update.prepare("itemLimit", value)}
                            />
                            
                            {
                                process.env.REACT_APP_OP_V2_WAREHOUSE_CONFIG === "true" &&
                                <>
                                    <EsotiqInput
                                        label={"Grupa"}
                                        defValue={this.state.modalOpened.row.group}
                                        options={[
                                            {key: "0", value: "A", text: "A"},
                                            {key: "1", value: "B", text: "B"},
                                            {key: "2", value: "Z", text: "Z"}
                                        ]}
                                        onChange={(value) => this.update.prepare("group", value)}
                                    />
                                    <br/>
                                    <Checkbox
                                        label={"Czy jest magazynem salonowym?"}
                                        defaultChecked={this.state.modalOpened.row.isParlour}
                                        onChange={(e, v) => this.update.prepare("isParlour", v.checked ? 1 : 0)}
                                    />
                                </>
                            }
                            

                            {/*<EsotiqInput*/}
                            {/*    label={"Może przyjąć zam. online"}*/}
                            {/*    defValue={parseInt(this.state.modalOpened.row.canTakeOrder)}*/}
                            {/*    options={[*/}
                            {/*        {key: 0, value: 0, text: "Nie"},*/}
                            {/*        {key: 1, value: 1, text: "Tak"}*/}
                            {/*    ]}*/}
                            {/*    onChange={(value) => this.update.prepare("canTakeOrder", value)}*/}
                            {/*/>*/}

                            {/*<EsotiqInput*/}
                            {/*    label={"Może przyjąć zam. wew."}*/}
                            {/*    defValue={parseInt(this.state.modalOpened.row.canTakeInternalOrder)}*/}
                            {/*    options={[*/}
                            {/*        {key: 0, value: 0, text: "Nie"},*/}
                            {/*        {key: 1, value: 1, text: "Tak"}*/}
                            {/*    ]}*/}
                            {/*    onChange={(value) => this.update.prepare("canTakeInternalOrder", value)}*/}
                            {/*/>*/}

                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({modalOpened: false})} negative>Anuluj</Button>
                            <Button
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content='Zapisz'
                                onClick={() => {
                                    this.update.call(this.state.modalOpened.row)
                                }}
                            />
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.editConfig &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.editConfig} onClose={() => this.setState({editConfig: false})}>
                        <Modal.Header>Warunki magazynowe</Modal.Header>

                        <Modal.Content>
                            <DataPresentation
                                label={"Dopuszczalna ilość magazynów w kompletacji"}
                                data={this.state.orderPickerConfig["warehousePickingLimit"] || 0}
                                editable={true}
                                onChange={(e) => {
                                    this.state.orderPickerConfig["warehousePickingLimit"] = e;
                                    this.setState({
                                        refresh: new Date().getTime()
                                    })
                                }}
                            />

                        </Modal.Content>

                        <Modal.Actions>
                            <Button onClick={() => this.setState({editConfig: false})} negative>Anuluj</Button>
                            <Button
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content='Zapisz'
                                onClick={() => this.saveOrderPickerConfig()}
                            />
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.loading &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać...")}</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        )
    }
}

export default Parlours
