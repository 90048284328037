import React from "react"
import MuiTreeView from 'material-ui-treeview';

function Tree(props) {
    return (
        <div className={"app-tree"}>
            <MuiTreeView
                onLeafClick={props.callback}
                tree={props.data.nodes}
                className={"xxx"}
            />
        </div>
    )
}

// function Tree(props) {
//
//     function displayData(data) {
//         console.log(data, "DATA 1")
//         return (
//             <React.Fragment>
//                 {data && data.children.map((e, k) => {
//                     console.log(e, "data 2")
//                     return (
//                         <div key={k} className={"app-tree-child"}>
//                             {e.name}
//                             {e.children && displayData(e)}
//                         </div>
//                     )
//                 })}
//             </React.Fragment>
//         )
//     }
//
//     return (
//         <div>{displayData(props.data)}</div>
//     )
// }

export default Tree
