import Modal from "../../../components/Modal";
import Confirm from "../../../components/Confirm";

export default class LabelCreationWrapper {
    provider;
    orderData;

    canBeProceed = true;
    callbackAction;

    constructor({provider, orderData}) {
        this.provider = provider;
        this.orderData = orderData;
    }

    RegisterCallbackAction(f) {
        this.callbackAction = f;

        return this;
    }

    CheckForPersonalizedProducts() {
        if (this.IsPersonalizedProducts()) {
            this.canBeProceed = false;

            Modal("Zamówienie zawiera produkt z grawerem. Czy produkt został wygrawerowany i jest gotowy do wysyłki?",
                null, () => {
                    this.canBeProceed = true;

                    return this.Create();
                }
            )
        }

        return this;
    }

    Create() {
        if (this.canBeProceed) {
            return Confirm("Czy na pewno chcesz nadać przesyłkę?", "", () => {
                this.provider.setState({
                    dimmer: true
                });
                this.callbackAction()
            })
        }
    }

    IsPersonalizedProducts() {
        return this.orderData.itemConfigs && this.orderData.itemConfigs.length;
    }
}