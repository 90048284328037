import React from 'react';
import { ParlourDetailsUrls } from "../../api/urls";
import CRUDView from "../../components/CRUDTable/view";
import {Input} from "semantic-ui-react";
import _ from "lodash";

export default class Accountants extends CRUDView {
    resource = ParlourDetailsUrls.ACCOUNTANTS;

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            label: 'Księgowi',
            schema: {
                title: this.state.label,
                type: 'object',
                required: ['profile'],
                properties: {
                    profile: {
                        type: 'object',
                        title: 'Profil',
                        required: ['firstName', 'lastName'],
                        properties: {
                            firstName: {
                                type: 'string',
                                title: 'Imię',
                                default: '',
                            },
                            lastName: {
                                type: 'string',
                                title: 'Nazwisko',
                                default: '',
                            }
                        }
                    },
                    contacts: {
                        type: 'array',
                        title: 'Kontakty',
                        items: {
                            type: 'object',
                            required: ['type', 'value'],
                            properties: {
                                type: {
                                    type: 'string',
                                    title: 'Typ',
                                    enum: ['Email', 'Phone'],
                                },
                                value: {
                                    type: 'string',
                                    title: 'Wartość',
                                    default: '',
                                }
                            }
                        }
                    }
                }
            },
            tableFields: [
                {
                    label: 'Id',
                    getValue: (payload) => payload.id,
                },
                {
                    label: 'First Name',
                    getValue: (payload) => payload.profile?.firstName || '',
                    filters: [{
                        component: <Input
                            key={"fullname"}
                            onChange={_.debounce(
                                (e, {value}) => this.setState({filters: {...this.state.filters, fullname: value}}),
                                500
                            )}
                            icon={"search"}
                            placeholder={"Imię i nazwisko"}
                        />,
                        props: {
                            defaultValue: () => this.state.filters.fullname || ''
                        }
                    }

                    ]
                },
                {
                    label: 'Last Name',
                    getValue: (payload) => payload.profile?.lastName || '',
                    filters: [
                        {
                            component: <Input
                                key={"fullname"}
                                onChange={_.debounce(
                                    (e, {value}) => this.setState({filters: {...this.state.filters, fullname: value}}),
                                    500
                                )}
                                icon={"search"}
                                placeholder={"Imię i nazwisko"}
                            />,
                            props: {
                                defaultValue: () => this.state.filters.fullname || ''
                            }
                        }

                    ]
                },
                {
                    label: 'Email',
                    getValue: (payload) => payload.contacts.filter((item) => item.type === "Email").map((item) => <span >{item.value}<br/></span>)
                },
                {
                    label: 'Phone',
                    getValue: (payload) => payload.contacts.filter((item) => item.type === 'Phone').map((item) => <span >{item.value}<br/></span>)
                }
            ],
        }
    }
}
