import React from "react"
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {TranslationController} from "../../controller/menager";
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react'
import Button from "../../components/Button";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";
import {Paper} from "@material-ui/core";
import PromotionCreatorBoxRow from "../promotions/promotionCreatorBoxRow";
import EsotiqInput from "../../components/EsotiqInput";
import {countries} from "../../lib/currency";

export default class ClubUserScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            inputData: "",
            cardDetails: null,
            cashback: null
        }
    }

    getData() {
        if (!this.state.inputData) {
            SnackBar("Wpisz numer karty lub nr telefonu", "error")
            return;
        }

        let params = {};
        if (this.state.inputData.length === 20) {
            params = { cardNumber: this.state.inputData };
        } else {
            params = { phoneNumber: this.state.inputData };
        }

        this.setState({
            loading: true
        });

        Promise.all([
            api().get("/api/loyalty-card", { params }),
            api().get("/api/loyalty-card/bonus", { params })
        ])
            .then(([loyaltyCardResponse, bonusResponse]) => {
                const loyaltyCardData = loyaltyCardResponse.data.data;
                const bonusData = bonusResponse.data.data;

                this.setState({
                    loading: false,
                    cardDetails: loyaltyCardResponse.data.error ? null : {
                        cardnumber: loyaltyCardData[0]?.cardNumber,
                        firstname: loyaltyCardData[0]?.name,
                        email: loyaltyCardData[0]?.email,
                        cellphone: loyaltyCardData[0]?.phoneNumber
                    },
                    cashback: bonusResponse.data.error ? null : {
                        bonus: bonusData[0]?.bonus,
                        validFrom: bonusData[0]?.validFrom,
                        validTo: bonusData[0]?.validTo
                    }
                });

                if (loyaltyCardResponse.data.error) {
                    SnackBar("Wystąpił błąd podczas pobierania danych", "error");
                } else {
                    SnackBar("Pobrano dane użytkownika");
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                this.setState({
                    loading: false,
                    cardDetails: null,
                    cashback: null
                });
                SnackBar("Wystąpił błąd podczas pobierania danych", "error");
            });
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Esotiq CLUB")}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Segment className={"order-table-segment"} attached='bottom'>
                       <Paper>
                           <Message
                               header={"Esotiq CLUB"}
                               content={"Sprawdź dane użytkownika Esotiq CLUB"}
                               info
                           />

                           <PromotionCreatorBoxRow
                               label={"Numer karty lub nr tel"}
                               desc={"Wprowadź numer karty (20 cyfr) lub numer telefonu z prefiksem (11 cyfr np. 48123456789)"}
                               content={() => {
                                   return (
                                       <EsotiqInput
                                           width={"100%"}
                                           placeholder={"Wpisz tekst"}
                                           onChange={value => {
                                               this.state.inputData = value;
                                           }}
                                       />
                                   )
                               }}
                           />

                           <PromotionCreatorBoxRow
                               label={""}
                               content={() => {
                                   return (
                                       <Button
                                           primary={true}
                                           style={{
                                               marginRight: 20
                                           }}
                                           onClick={() => this.getData()}
                                       >
                                           {TranslationController.translate("Sprawdź")}
                                       </Button>
                                   )
                               }}
                           />

                           {this.state.cardDetails &&
                               <React.Fragment>
                                   <PromotionCreatorBoxRow
                                       label={"Nr karty"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cardDetails.cardnumber || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"Imie"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cardDetails.firstname || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"Nr telefonu"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cardDetails.cellphone || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"E-mail"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cardDetails.email || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"Cashback bonus"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cashback.bonus || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"Cashback ważny od"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cashback.validFrom || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"Cashback ważny od"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cashback.validTo || "---"}
                                               </div>
                                           )
                                       }}
                                   />
                               </React.Fragment>
                           }
                       </Paper>
                    </Segment>
                </Container>

                {this.state.loading &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać..")}</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        );
    }
}
