import  React, { useState } from "react";
import GalleryModal from "./galleryModal";

const  Gallery = (props) =>{
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(props.useService ? `https://service.esotiq.com/api/v1/${props.urlPath}/${item}` : `http://localhost:50005/${props.urlPath}/${item}`);
    };

    const handelRotationRight = () => {
        const totalLength = props.images.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = props.images[0];
            setClickedImg(props.useService ? `https://service.esotiq.com/api/v1/${props.urlPath}/${newUrl}` : `http://localhost:50005/${props.urlPath}/${newUrl}`);
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = props.images.filter((item) => {
            return props.images.indexOf(item) === newIndex;
        });

        setClickedImg(props.useService ? `https://service.esotiq.com/api/v1/${props.urlPath}/${newUrl[0]}` : `http://localhost:50005/${props.urlPath}/${newUrl[0]}`);
        setCurrentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totalLength = props.images.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = props.images[totalLength - 1];
            setClickedImg(props.useService ? `https://service.esotiq.com/api/v1/${props.urlPath}/${newUrl}` : `http://localhost:50005/${props.urlPath}/${newUrl}`);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = props.images.filter((item) => {
            return props.images.indexOf(item) === newIndex
        });
        setClickedImg(props.useService ? `https://service.esotiq.com/api/v1/${props.urlPath}/${newUrl[0]}` : `http://localhost:50005/${props.urlPath}/${newUrl[0]}`);
        setCurrentIndex(newIndex);
    };

    return (
        <div style={{display:"block", width: "100%", height:"100%", position:"relative"}}>
        <div className="wrapper-main-gallery">
            {props.images &&
                props.images.map((item, index) => (
                    // console.log(item)
                <div key={index} className="wrapper-images">
                    <img
                        style={{maxWidth: "100%", cursor: "pointer", maxHeight: "100%"}}
                        src={props.useService ? `https://service.esotiq.com/api/v1/${props.urlPath}/${item}` : `http://localhost:50005/${props.urlPath}/${item}`}
                        alt={"image small"}
                        onClick={() => handleClick(item, index)}
                    />
                </div>
            ))}
            <div>
                {clickedImg &&
                    <GalleryModal
                        clickedImg={clickedImg}
                        handelRotationRight={handelRotationRight}
                        setClickedImg={setClickedImg}
                        handelRotationLeft={handelRotationLeft}
                    />
                }
            </div>
        </div>
        </div>
    );
}

export default Gallery;