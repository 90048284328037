import React from "react"
import {Menu, Segment, Button, Checkbox, Dimmer, Loader} from 'semantic-ui-react'
import {api} from "../../lib/axios";
import Confirm from "../../components/Confirm";
import SnackBar from "../../components/Snack";
import MenuButton from "../../lib/table-v2/menuButton";
import Breadcrumb from "../../components/Breadcrumb";
import Container from "../../components/Container";
import UITable from "../../lib/table-v2";
import {formatDate} from "../../helpers/dateHelper";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dropdown from "../../components/Dropdown";
import {saveToFile} from "../../controller/exportController";
import TableMenu from "../../components/TableMenu";

export default class AllegroOfferts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            websiteFilter: [],
            dataCount: null,
            tableSelectable: false,
            tableSelectableState: [],
            tableExportCols: [],
            onlyWithProducts: true,
            currentTab: 0,
            brand: "esotiq"
        }

        this.statusMap = {
            ENDED: "Zakończona",
            ACTIVE: "Trwa"
        }

        this.exportFields = [
            {
                field: "id",
                label: "Id",
                toStr: true
            },
            {
                field: "publication.startedAt",
                label: "Data stworzenia"
            },
            {
                field: "publication.endedAt",
                label: "Data zakończenia"
            },
            {
                field: "external.id",
                label: "SKU",
            },
            {
                field: "name",
                label: "Nazwa"
            },
            {
                field: "sellingMode.price.amount",
                label: "Cena Allegro"
            },
            {
                field: "stock.available",
                label: "Stock"
            },
            {
                field: "inventoryState",
                label: "Magazyn"
            },
            {
                field: "publication.status",
                label: "Status Allegro"
            }
        ]
    }

    componentDidMount() {
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api(true).post("/api/allegro/getOffert", {brand: this.state.brand})
                .then(r => {
                    return r.data.data
                })
        })

        return api(true).post("/api/allegro/getOffert", {limit, currentPage, brand: this.state.brand, filter: this.state.appliedFilters || null, onlyWithProducts: this.state.onlyWithProducts})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    uniExport() {
        this.setState({
            loading: true
        });

        return api(true).post("/api/allegro/getOffert", {
            isExport: {
                columns: this.state.tableExportCols,
                fields: this.exportFields
            },
            limit: 1000,
            currentPage: 1,
            filter: this.orderTable.state.appliedFilters,
            brand: this.state.brand,
            onlyWithProducts: this.state.onlyWithProducts
        })
            .then(r => {
                this.setState({
                    loading: false
                });

                if (r.data.error) {
                    return SnackBar(r.data.message, "error")
                }

                return saveToFile(r.data);
            });
    }

    render() {
        return (
            <div>
                <React.Fragment>
                    <Container>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `Oferty Allegro`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>

                        <Tabs
                            className={"es-panel-tab-container"}
                            value={this.state.currentTab}
                            onChange={(e, v) => {
                                this.setState({
                                    currentTab: v,
                                    brand: v === 0 ? "esotiq" : v === 1 ? "henderson" : "finalsale"
                                }, () => {
                                    this.orderTable.setState({
                                        data: null
                                    })

                                    this.orderTable.init()
                                })
                            }}
                            style={{
                                marginBottom: 10
                            }}
                        >
                            <Tab label="Esotiq" />
                            <Tab label="Henderson" />
                            <Tab label="Finalsale" />
                        </Tabs>

                        <Menu className={"order-menu"} attached='top'>
                            <TableMenu item icon='wrench' simple>
                                <TableMenu.Menu>
                                    <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                    <TableMenu.Divider />
                                    <TableMenu.Header>Export</TableMenu.Header>
                                    <TableMenu.Item>
                                        <span className='text'>Raport uniwersalny</span>

                                        <TableMenu.Menu>
                                            <div className={"uni-report-container"}>
                                                <div className={"uni-report-header"}>Wybierz kolumny do raportu</div>

                                                <div className={"uni-report-content-box"}>
                                                    <div className={"uni-report-column"}>
                                                        <ul>
                                                            {this.exportFields.map((fieldValues, key) => {
                                                                return (
                                                                    <li key={key}>
                                                                        <Checkbox key={key} onClick={(e) => {
                                                                            const field = fieldValues.field

                                                                            return !this.state.tableExportCols.includes(field) ? this.state.tableExportCols.push(field) : this.state.tableExportCols = this.state.tableExportCols.filter(col => col != field)
                                                                        }} label={fieldValues.label}/>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className={"uni-report-actions"}>
                                                    <Button
                                                        color='blue'
                                                        content='Pobierz'
                                                        icon='download'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                        onClick={() => this.uniExport()}
                                                    />
                                                </div>
                                            </div>
                                        </TableMenu.Menu>
                                    </TableMenu.Item>
                                </TableMenu.Menu>
                            </TableMenu>

                            <Menu.Menu position='right'>
                                <MenuButton
                                    icon={"sync"}
                                    label={`Eksport aktywnych ${this.state.brand}`}
                                    onClick={() => {
                                        this.setState({
                                            loading: true
                                        });

                                        api(true).post(`/api/allegro/exportActive`, {
                                            brand: this.state.brand,
                                            columns: this.state.tableExportCols,
                                            fields: this.exportFields
                                        })
                                            .then((r) => {
                                                this.setState({
                                                    loading: false
                                                });

                                                if (r.data.error) {
                                                    return SnackBar(r.data.message, "error")
                                                }

                                                return saveToFile(r.data);
                                            })
                                    }}
                                />
                                <MenuButton
                                    icon={"sync"}
                                    label={`Autoryzacja ${this.state.brand}`}
                                    onClick={() => {
                                        api(true).get(`/api/allegro/auth?brand=${this.state.brand}`)
                                            .then((r) => {
                                                if (r && r.data && r.data.url) {
                                                    window.open(r.data.url, "_blank")

                                                    SnackBar("Autoryzacja")
                                                } else {
                                                    SnackBar("Autoryzacja nieudana", "error")
                                                }
                                            })
                                    }}
                                />
                                <MenuButton
                                    icon={"cloud download"}
                                    label={"Zakończ wybrane"}
                                    onClick={() => {
                                        if (!this.orderTable.getSelectedState().length) {
                                            return SnackBar("Nie wybrano żadnego draftu", "error")
                                        }

                                        Confirm("Czy na pewno chcesz zakończyć wybrane oferty?", "", () => {
                                            const offers = []

                                            this.orderTable.getSelectedState().forEach(e => {
                                                offers.push(e.id)
                                            })

                                            api(true).post("/api/allegro/manageOfferts", {offers, action: "END", brand: this.state.brand})
                                                .then(() => {
                                                    SnackBar("Zaktualizowano ofertę")
                                                    this.setState({
                                                        data: null,
                                                        websiteFilter: [],
                                                        dataCount: null,
                                                        tableSelectable: false,
                                                        tableSelectableState: [],
                                                        tableExportCols: [],
                                                        onlyWithProducts: !this.state.onlyWithProducts
                                                    }, () => {
                                                        this.orderTable.setState({
                                                            data: null
                                                        })

                                                        this.orderTable.init()
                                                    })
                                                })
                                        })
                                    }}
                                />
                            </Menu.Menu>
                        </Menu>

                        <Segment className={"order-table-segment"} attached='bottom'>
                            <UITable
                                dataFetch={{
                                    url: "/api/allegro/getOffert",
                                    useApi2: true,
                                    options: {
                                        limit: 25,
                                        currentPage: 1,
                                        brand: this.state.brand,
                                        onlyWithProducts: this.state.onlyWithProducts,
                                        isWeb: true
                                    }
                                }}
                                ref={table => this.orderTable = table}
                                selectable={this.state.tableSelectable}
                                provider={this}
                                columns={[
                                    {
                                        label: "Id",
                                        field: "offer.id",
                                        center: true,
                                        width: 1,
                                        render: row => (
                                            <div>
                                                <a
                                                    style={{
                                                        color: "#0075d6",
                                                        display: "block"
                                                    }}
                                                    target={"_blank"}
                                                    href={`http://allegro.pl/show_item.php?item=${row.id}`}
                                                >
                                                    {row.id}
                                                </a>
                                            </div>
                                        ),
                                        filters: [
                                            {label: "ID", condition: "like", type: "text"},
                                        ],
                                    },
                                    {
                                        label: "Status",
                                        field: "name",
                                        center: true,
                                        render: row => this.statusMap[row.publication.status] || row.publication.status,
                                        filters: [
                                            {label: "Status", condition: "in", type: "checkbox", forceField: "publication.status", options: [
                                                    {
                                                        value: "ENDED",
                                                        label: "Zakończona"
                                                    }, {
                                                        value: "ACTIVE",
                                                        label: "Trwa"
                                                    }, {
                                                        value: "INACTIVE",
                                                        label: "Nieaktywna"
                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        label: "Data",
                                        field: "date",
                                        render: row => (
                                            <div>
                                                <div style={{
                                                       padding: 5,
                                                        background: "#efefef",
                                                        borderRadius: 5,
                                                        marginBottom: 5
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#5e5e5e",
                                                        marginTop: 4
                                                    }}>
                                                        Stworzona:
                                                    </div>

                                                    <span
                                                        style={{
                                                            color: "#7d7d7d",
                                                            display: "block"
                                                        }}
                                                    >
                                                        {formatDate(row.publication.startedAt).dateTimeSystem}
                                                    </span>
                                                </div>

                                                <div style={{
                                                       padding: 5,
                                                        background: "#efefef",
                                                        borderRadius: 5,
                                                        marginBottom: 5
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#5e5e5e",
                                                        marginTop: 4
                                                    }}>
                                                        Zakończona:
                                                    </div>

                                                    <span
                                                        style={{
                                                            color: "#737373",
                                                            display: "block"
                                                        }}
                                                    >
                                                        {row.publication.endedAt ? formatDate(row.publication.endedAt).dateTimeSystem : "---"}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    },
                                    {
                                        label: "SKU",
                                        field: "sku",
                                        center: true,
                                        render: row => row.external ? row.external.id : "---",
                                        filters: [
                                            {label: "SKU", condition: "like", type: "text", forceField: "external.id"},
                                        ],
                                    },
                                    {
                                        label: "Nazwa",
                                        field: "name",
                                        center: true,
                                    },
                                    {
                                        label: "Cena Allegro",
                                        field: "id",
                                        center: true,
                                        width: 2,
                                        render: row => row.sellingMode.price.amount + " " + row.sellingMode.price.currency
                                    },
                                    {
                                        label: "Sztuk",
                                        field: "stock.available",
                                        center: true,
                                        editable: true,
                                        onEditableChange: (value, row) => {
                                            if (!Number.isNaN()) {
                                                row.stock.available = value
                                            }
                                        },
                                        onEditableConfirm: (row, tableData) => {
                                            api(true).post("/api/allegro/updateOffer", {offer: row, brand: this.state.brand})
                                                .then(() => {
                                                    this.orderTable.setState({
                                                        data: null
                                                    })

                                                    this.orderTable.init()
                                                })
                                        },
                                        render: row => (
                                            <div style={{
                                                color: "#737373",
                                                fontWeight: 600
                                            }}>
                                                {row.stock.available}
                                            </div>
                                        ),
                                        width: 1
                                    },
                                    {
                                        label: "Magazyn",
                                        field: "name",
                                        center: true,
                                        render: row => (
                                            <div style={{
                                                color: "#737373",
                                                fontWeight: 600
                                            }}>
                                                {row.inventoryState || "Brak danych"}
                                            </div>
                                        ),
                                        width: 1
                                    },
                                    {
                                        label: "Zapas",
                                        field: "name",
                                        center: true,
                                        render: row => (
                                            <div style={{
                                                color: "#737373",
                                                fontWeight: 600
                                            }}>
                                                {row.inventoryState ? row.inventoryState - row.stock.available : "---"}
                                            </div>
                                        ),
                                        width: 1
                                    },
                                ]}
                            />
                        </Segment>
                    </Container>

                    {this.state.loading &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>Proszę czekać...</Loader>
                    </Dimmer>
                    }
                </React.Fragment>
            </div>
        );
    }
}
