import React, {useRef, useState} from 'react';
import {Form, Message} from 'semantic-ui-react';
import Button from '../../components/Button'
import {Container} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {functionsApi} from "../../lib/axios";
import SnackBar from "../../components/Snack";

const UploadPrices = () => {
    const [files, setFiles] = useState(null);
    const fileInputRefs = {
        pricesDE: useRef(null),
        pricesPL: useRef(null),
        warehousesDE: useRef(null),
        warehousesPL: useRef(null),
    };
    const [formSucceded, setFormSucceded] = useState(false)
    const [formError, setFormError] = useState('')
    const handleFileUpload = async (event, fileType) => {
        const file = event.target.files[0];

        const fileInputRef = fileInputRefs[fileType];
        fileInputRef.current.value = '';

        setFiles(prev => ({...prev, [fileType]: file}));
    };

    const sendFile = async (file, path) => {
        const formData = new FormData();
        formData.append('file', file);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        await functionsApi.post(path, formData, {headers});
    }

    const onSubmit = async () => {
        setFormError('')
        setFormSucceded(false)
            try {
                if (files.pricesDE) {
                   await sendFile(files.pricesDE, '/zalando-update-prices-de')
                }

                if (files.pricesPL) {
                   await sendFile(files.pricesPL, '/zalando-update-prices-pl')
                }

                if (files.warehousesDE) {
                    await sendFile(files.warehousesDE, '/zalando-update-warehouses-availability-de')
                }

                if (files.warehousesPL) {
                   await sendFile(files.warehousesPL, '/zalando-update-warehouses-availability-pl')
                }
                SnackBar("Formularz wysłany");

                setFormSucceded(true)
                setFiles(null)

            } catch (error) {
                SnackBar(error.response.data.message, 'error');
                setFormError(error.response.data.message)
            }

    }


    return (
        <Container style={{marginTop: '50px'}}>
            <Form onSubmit={onSubmit} success={formSucceded} error={formError}>
                <Paper>
                    <Message
                        icon='inbox'
                        header='Dodaj ceny dla Zalando DE'
                        content='Kolumny w pliku .CSV powinny być oddzielone średnikiem'
                    />
                    <Button
                        primary
                        as="label"
                        htmlFor="fileDE"
                        icon="file"
                        content="Wybierz plik"
                        labelPosition="left"
                    />
                    <input
                        type="file"
                        accept=".csv"
                        style={{display: 'none'}}
                        onChange={e => handleFileUpload(e, 'pricesDE')}
                        ref={fileInputRefs.pricesDE}
                        id="fileDE"
                    />
                    {files?.pricesDE && <p>Wybrany plik {files.pricesDE.name} </p>}
                </Paper>

                <Paper>
                    <Message
                        icon='inbox'
                        header='Dodaj ceny dla Zalando PL'
                        content='Kolumny w pliku .CSV powinny być oddzielone średnikiem'
                    />
                    <Button
                        primary
                        as="label"
                        htmlFor="filePL"
                        icon="file"
                        content="Wybierz plik"
                        labelPosition="left"
                    />
                    <input
                        type="file"
                        accept=".csv"
                        style={{ display: 'none' }}
                        onChange={e => handleFileUpload(e, 'pricesPL')}
                        ref={fileInputRefs.pricesPL}
                        id="filePL"
                    />
                    {files?.pricesPL && <p>Wybrany plik {files.pricesPL.name} </p>}
                </Paper>

                <Paper>
                    <Message
                        icon='inbox'
                        header='Zaaktualizuj magazyny dla Zalando DE'
                        content='Kolumny w pliku .CSV powinny być oddzielone średnikiem'
                    />
                    <Button
                        primary
                        as="label"
                        htmlFor="warehousesDE"
                        icon="file"
                        content="Wybierz plik"
                        labelPosition="left"
                    />
                    <input
                        type="file"
                        accept=".csv"
                        style={{ display: 'none' }}
                        onChange={e => handleFileUpload(e, 'warehousesDE')}
                        ref={fileInputRefs.warehousesDE}
                        id="warehousesDE"
                    />
                    {files?.warehousesDE && <p>Wybrany plik {files.warehousesDE.name} </p>}
                </Paper>

                <Paper>
                    <Message
                        icon='inbox'
                        header='Zaaktualizuj magazyny dla Zalando PL'
                        content='Kolumny w pliku .CSV powinny być oddzielone średnikiem'
                    />
                    <Button
                        primary
                        as="label"
                        htmlFor="warehousesPL"
                        icon="file"
                        content="Wybierz plik"
                        labelPosition="left"
                    />
                    <input
                        type="file"
                        accept=".csv"
                        style={{ display: 'none' }}
                        onChange={e => handleFileUpload(e, 'warehousesPL')}
                        ref={fileInputRefs.warehousesPL}
                        id="warehousesPL"
                    />
                    {files?.warehousesPL && <p>Wybrany plik {files.warehousesPL.name} </p>}
                </Paper>
                {files !== null  && <Button
                    content="Wyślij"
                    primary
                /> }
            </Form>
        </Container>
    );
};

export default UploadPrices;
