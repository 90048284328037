import React from "react"
import {api} from "../../../lib/axios";
import {Loader, Table, Label, Icon, Accordion, Input} from "semantic-ui-react";
import Tooltip from '@material-ui/core/Tooltip';
import {jsonCopy} from "../../../helpers/lib";

export default class OrderParlourSimulationSummary extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            displayData: null
        }
    }

    componentDidMount() {
        api().post("/api/states/getByIndex", {index: this.props.cart})
            .then(r => {
                this.setState({
                    data: r.data.data,
                    displayData: jsonCopy(r.data.data)
                })
            })
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const { activeIndex } = this.state

        return (
            <div className={"sim-sum-box"}>
                {this.state.displayData ?
                <div>
                    <div>
                        <Table basic='very'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>SKU</Table.HeaderCell>
                                    <Table.HeaderCell>Dostępność</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {this.state.displayData.map((e, k) => {
                                    return (
                                        <Table.Row key={k}>
                                            <Table.Cell>{e.sku}</Table.Cell>
                                            <Table.Cell className={"prlour-preview-cell"}>
                                                <Accordion>
                                                    <Accordion.Title
                                                        active={activeIndex === k}
                                                        index={k}
                                                        onClick={this.handleClick}
                                                    >
                                                        <Icon name='dropdown' />
                                                        {this.state.data[k].state.length} salon(ów) sprzedaje ten towar
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndex === k}>
                                                        <div style={{marginBottom: 10}}>
                                                            <Input onChange={(el, val) => {
                                                                this.state.displayData[k].state = this.state.data[k].state.filter(x => x.parlour.toString().toLowerCase().includes(val.value.toLowerCase()))
                                                                this.setState({refresh: true})
                                                            }} icon='search' placeholder='Search...' />
                                                        </div>

                                                        {e.state.map((parlour, key) => {
                                                            return (
                                                                <Label key={key}>
                                                                    {parlour.parlour}
                                                                    <Label.Detail>
                                                                        <Tooltip title="Stan magazynowy">
                                                                            <div className={"onlabel-box"}>
                                                                                <Icon name={"box"}/>
                                                                                {parlour.state && parlour.state[0].qty}
                                                                            </div>
                                                                        </Tooltip>

                                                                        <Tooltip title="Sprzedaż online TAK/NIE">
                                                                            <div className={"onlabel-box"}>
                                                                                <Icon name={"usb"}/>
                                                                                {parlour.parlourDetails[0] && (parlour.parlourDetails[0].isOnline > 0 ? "T" : "N")}
                                                                            </div>
                                                                        </Tooltip>

                                                                        <Tooltip title="Dzisiejsze zamówienia / limit">
                                                                            <div className={"onlabel-box"}>
                                                                                <Icon name={"cart arrow down"}/>
                                                                                {parlour.parlourDetails[0] && parlour.parlourDetails[0].todayOrders + "/" + parlour.parlourDetails[0].orderLimit}
                                                                            </div>
                                                                        </Tooltip>

                                                                        <Tooltip title="Dzisiaj towarów / limit">
                                                                            <div className={"onlabel-box"}>
                                                                                <Icon name={"tag"}/>
                                                                                {parlour.parlourDetails[0] && parlour.parlourDetails[0].itemToday + "/" + parlour.parlourDetails[0].itemLimit}
                                                                            </div>
                                                                        </Tooltip>
                                                                    </Label.Detail>
                                                                </Label>
                                                            )
                                                        })}
                                                    </Accordion.Content>
                                                </Accordion>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
                    : <div className={"sim-loader-box"}><Loader active></Loader></div>}
            </div>
        )
    }
}
