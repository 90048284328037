import React from 'react';
import {RouterController} from "../controller/menager";
import { withRouter } from "react-router-dom";
import {Icon} from "semantic-ui-react";
import {inAppRoutes} from "./index";
import {DATA_OBJECT} from "../controller/dataController";
import {jsonCopy} from "../helpers/lib";

const icon = require("../resources/favicon.ico");

class AppTabs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabs: [],
            activeTab: 0
        }
    }

    componentDidMount() {
        RouterController.setTabsComponent(this);
    }

    setTab(pathname) {
        this.state.tabs.push({
            pathname,
            localState: {}
        })

        this.forceUpdate();
    }

    setLocalState(key, state) {
        if (this.state.tabs[this.state.activeTab]) {
            this.state.tabs[this.state.activeTab].localState[key] = state;
        }
    }

    updateCurrentTab(pathname) {
        this.state.tabs[this.state.activeTab].pathname = pathname;

        this.forceUpdate();
    }

    changeTab(key) {
        if (this.state.tabs[key]) {
            let rerenderTable = false;

            if (this.state.tabs[this.state.activeTab].pathname === this.state.tabs[key].pathname) {
                rerenderTable = true;
            }

            if (Object.keys(this.state.tabs[this.state.activeTab].localState).length) {
                for (let [k, v] of Object.entries(this.state.tabs[this.state.activeTab].localState)) {
                    localStorage.removeItem(k);
                }
            }

            if (Object.keys(this.state.tabs[key].localState).length) {
                for (let [k, v] of Object.entries(this.state.tabs[key].localState)) {
                    localStorage.setItem(k, JSON.stringify(v));
                }
            }

            this.state.activeTab = key;

            this.props.history.push(this.state.tabs[this.state.activeTab].pathname);

            if (rerenderTable) {
                if (RouterController.tableComponent) {
                    RouterController.tableComponent.refreshCachedFilters();
                    RouterController.tableComponent.setState({
                        data: null,
                        tabKey: key
                    });

                    RouterController.tableComponent.init();
                }
            }

            this.forceUpdate();
        }
    }

    addTab() {
        const initUrl = DATA_OBJECT["user"]["initUrl"];

        this.state.tabs.push({
            pathname: (initUrl && initUrl.length > 1) ? initUrl : "/",
            localState: {}
        })

        this.changeTab(this.state.tabs.length - 1);

        // this.state.activeTab = this.state.tabs.length - 1;
        // this.props.history.push(this.state.tabs[this.state.activeTab].pathname);

        this.forceUpdate();
    }

    closeTab(key) {
        this.state.tabs = this.state.tabs.filter((e, k) => k !== key);
        this.state.activeTab = this.state.tabs.length - 1;

        if (this.state.tabs[this.state.activeTab]) {
            this.props.history.push(this.state.tabs[this.state.activeTab].pathname);

            this.changeTab(this.state.activeTab);

            this.forceUpdate();
        }
    }

    getTabName(pathname) {
        let name = pathname;
        let searchValue = pathname.split("/");

        if (searchValue[1]) {
            searchValue = searchValue[1];
        } else {
            searchValue = searchValue[0];
        }

        for (let i = 0; i < inAppRoutes.length; i++) {
            let pathValue = inAppRoutes[i].path.split("/");

            if (pathValue[1]) {
                pathValue = pathValue[1];
            } else {
                pathValue = pathValue[0];
            }

            if (pathValue === searchValue) {
                name = inAppRoutes[i].name;

                break;
            }
        }

        return name;
    }

    render() {
        return (
            <div style={{position: "relative"}}>
                <div className={"app-tabs-container"}>
                    {
                        this.state.tabs.map((tab, key) => {
                            return (
                                <div
                                    key={key}
                                    className={`app-tab app-tab-simple ${key === this.state.activeTab && "app-tab-active"}`}
                                    onClick={() => this.changeTab(key)}
                                >
                                    <div className={"app-tab-name-wrapper"}>
                                        <img src={icon} alt=""/>

                                        <span>
                                    {this.getTabName(tab.pathname)}
                                </span>
                                    </div>

                                    {(this.state.tabs.length > 1) &&
                                    <div onClick={() => this.closeTab(key)}>
                                        <Icon className={"app-tab-close-icon"} name={"close"}/>
                                    </div>
                                    }
                                </div>
                            )
                        })
                    }

                    <div
                        className={"app-tab"}
                        onClick={() => this.addTab()}
                    >
                        +
                    </div>
                </div>
                <div className={"app-tabs-beam"}/>
            </div>
        );
    }
}

export default withRouter(AppTabs);