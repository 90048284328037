import React from  'react';
import {Button, Checkbox, Modal} from 'semantic-ui-react'
import {parlourDetailsApi} from "../../lib/axios";
import {ParlourDetailsUrls} from "../../api/urls";
import {TranslationController} from "../../controller/menager";

export default class EquipmentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            equipments: [],
            equipmentsInParlour: [],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.open !== this.state.open && this.state.open) {
            this.getEquipmentsList();
            this.getEquipmentByParlourId(this.props.parlourId);
        }
    }

    getEquipmentsList = () => {
        parlourDetailsApi.get(ParlourDetailsUrls.EQUIPMENTS).then(response => {
            this.setState({equipments: response.data.data})
        })
    }

    getEquipmentByParlourId = () => {
        const { parlourId } = this.props;
        parlourDetailsApi.get(ParlourDetailsUrls.EQUIPMENT_IN_PARLOUR + '/' + parlourId).then(response => {
            this.setState({equipmentsInParlour: response.data.map((equipment) => {return equipment.id})})
        })
    }

    addEquipmentToParlour = (equipmentId) => {
        const { parlourId } = this.props;
        parlourDetailsApi.post(ParlourDetailsUrls.EQUIPMENT_IN_PARLOUR, {
            parlourId,
            equipmentId,
        }).then(response => {
            this.getEquipmentByParlourId();
        })
    }

    removeEquipmentFromParlour = (equipmentId) => {
        const { parlourId } = this.props;
        parlourDetailsApi.delete(ParlourDetailsUrls.EQUIPMENT_IN_PARLOUR + '/' + parlourId + '/' + equipmentId).then(response => {
            this.getEquipmentByParlourId();
        })
    }

    toggleEquipmentInParlour = (value, equipmentId) => {
        if(value) {
            this.addEquipmentToParlour(equipmentId);
        }else{
            this.removeEquipmentFromParlour(equipmentId);
        }
    }

    render() {
        const { open } = this.state;

        return (
            <Modal
                onClose={() => this.setState({open: false})}
                onOpen={() => this.setState({open: true})}
                open={open}
                trigger={<Button >{ TranslationController.translate('Pokaż') }</Button>}
            >
                <Modal.Header>{TranslationController.translate('Wyposażenie')}</Modal.Header>
                <Modal.Content>
                    {
                        this.state.equipments.map((equipment) => {
                            return (<div key={equipment.id}>
                                <Checkbox toggle checked={this.state.equipmentsInParlour.includes(equipment.id)} label={equipment.name} onChange={(e,data)=>this.toggleEquipmentInParlour(data.checked, equipment.id)}/>
                            </div>)
                        })
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({open: false})}>{TranslationController.translate('Zamknij')}</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
