import React from "react";
import Button from "../../components/Button";
import {Menu, Label, Segment, Icon, Modal, Card, Accordion, Table, Pagination} from "semantic-ui-react";
import {api} from "../../lib/axios";
import Confirm from "../../components/Confirm";
import SnackBar from "../../components/Snack";
import {TranslationController} from "../../controller/menager";
import MenuButton from "../../lib/table-v2/menuButton";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import EsotiqInput from "../../components/EsotiqInput";
import {Colors} from "../../resources/colors";
import {jsonCopy, numberWithCommas, randomNumber} from "../../helpers/lib";
import CsvImport from "../../components/CsvImport";
import {formatDate} from "../../helpers/dateHelper";
import {exportToFile} from "../../controller/exportController";

export default class GlobalWarehouses extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            warehouses: null,
            exportLoading: {},
            whSync: {},
            openLoading: {},
            activeAccordions: {},
            activeAccordionsColors: {},
            warehouseNameMap: {
                "k99": "K99",
                "e99": "E99",
                "157": "157",
                "de": "Niemcy",
                "d11": "D11 [OP]",
                "salon": "Salony",
                "n32": "N32",
                "out": "Outlet"
            }
        }
    }

    componentDidMount() {
        this.getWarehoues()
    }

    getWarehoues() {
        api().post("/api/global-warehouses/get")
            .then(res => {
                if (res.data && res.data.warehouses.length) {
                    res.data.warehouses.map(e => e.color = Colors[randomNumber(0, Colors.length - 1)])

                    this.setState({
                        warehouses: res.data.warehouses
                    })
                }
            })
    }

    addWarehouse() {
        if (!this.state.newWarehouseName) {
            return SnackBar("Brak nazwy dla nowego magazynu", "error")
        }

        if (this.state.newWarehouseName.length < 3) {
            return SnackBar("Nazwa magazynu jest za krótka", "error")
        }

        if (this.state.warehouses && this.state.warehouses.find(e => e.id === this.state.newWarehouseId)) {
            return SnackBar("Magazyn o takim id już istnieje", "error")
        }

        if (!this.state.newWarehouseGeneralStateSource) {
            return SnackBar("Nie wybrałeś głównego źródła magazynu dla nowego magazynu", "error")
        }

        return new Promise(resolve => {
            Confirm("Czy na pewno chcesz utworzyć nowy magazyn?", "", () => {
                api().post("/api/global-warehouses/set", {
                    name: this.state.newWarehouseName,
                    id: this.state.newWarehouseId,
                    generalStateSource: this.state.newWarehouseGeneralStateSource
                })
                    .then(() => {
                        resolve();

                        this.setState({
                            warehouses: null
                        }, () => this.getWarehoues());

                        SnackBar("Nowy magazyn został stworzony.")
                    })
            })
        })
    }

    remove(id) {
        return Confirm("Czy na pewno chcesz trwale usunąć ten magazyn wraz z jego zawartością?", "", () => {
            api().post("/api/global-warehouses/remove", {
                id: id
            })
                .then(() => {
                    this.setState({
                        warehouses: null
                    }, () => this.getWarehoues());

                    SnackBar("Nowy magazyn został usunięty.")
                })
        })
    }

    quickSearch(value) {
        this.state.qucikSearchValue = value

        this.prepareWarehouseData(this.state.currOriWhState, this.state.currWhID, 1)
    }

    getPagesCount() {
        return Math.ceil(this.state.count / this.state.onPage)
    }

    pageChange(e, {activePage}) {
        window.scrollTo({top: 0, behavior: 'smooth'});

        this.prepareWarehouseData(this.state.currWhState, this.state.currWhID, activePage)
    }

    prepareWarehouseData(whState, warehouseId, pageNumber) {
        const page_size = 50
        let page_number = pageNumber || 1

        const qucikSearchValue = this.state.qucikSearchValue

        this.state.count = jsonCopy(whState).length
        this.state.onPage = page_size

        if (!this.state.currOriWhState) {
            this.state.currOriWhState = jsonCopy(whState)
        }

        this.state.currWhState = jsonCopy(whState)
        this.state.currWhID = warehouseId
        this.state.currentPage = page_number

        let warehouseState = whState

        if (qucikSearchValue && qucikSearchValue.length > 1) {
            warehouseState = warehouseState.filter(e => e.sku && (e.sku.includes(qucikSearchValue.toString()) || ( e.season && e.season.includes(qucikSearchValue.toString()) )) )

            this.state.count = jsonCopy(warehouseState).length
            this.state.currWhState = jsonCopy(warehouseState)
        }

        --page_number

        warehouseState = warehouseState.slice(page_number * page_size, (page_number + 1) * page_size);

        const flatData = {}

        //warehouseState = warehouseState.filter(e => e.sku === "36317-00X-65B")

        for (let i = 0; i < warehouseState.length; i++) {
            const state = warehouseState[i]

            const splittedSku = state.sku.split("-")
            const model = splittedSku[0]

            flatData[model] = {
                model: model,
                season: state.season,
                colors: {}
            }

            for (let y = 0; y < warehouseState.length; y++) {
                const e = warehouseState[y]

                if (!e.sku.includes(model)) {
                    continue;
                }

                const splittedSku = e.sku.split("-")

                let ref = flatData[model].colors[`${model}-${splittedSku[1]}`]

                if (!ref) {
                    flatData[model].colors[`${model}-${splittedSku[1]}`] = {sizes: {}}

                    ref = flatData[model].colors[`${model}-${splittedSku[1]}`]
                }

                ref.sizes[e.sku] = {qty: e.state || 0}
            }
        }

        this.setState({
            previewWarehouse: flatData,
            previewWarehouseId: warehouseId,
            previewWarehouseState: warehouseState
        })
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Sklep - Magazyny`
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <Menu className={"order-menu"} attached='top'>
                        <Menu.Menu position='right'>
                            <MenuButton
                                icon={"add"}
                                label={"Stwórz nowy magazyn"}
                                onClick={() => {
                                    this.setState({
                                        addWarehouse: true
                                    })
                                }}
                            />

                            <div className='ui right aligned category search item'>
                                <div className='ui transparent icon input'>
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault()

                                            this.orderTable.quickSearch(this.state.quickSearchValue)
                                        }}
                                    >
                                        <input
                                            className='prompt'
                                            type='text'
                                            placeholder={TranslationController.translate("Szybkie wyszukiwanie...")}
                                            onChange={e => {
                                                this.state.quickSearchValue = e.target.value
                                            }}
                                        />
                                    </form>

                                    <i className='search link icon' />
                                </div>
                                <div className='results' />
                            </div>
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <div className={"gw-wrapper"}>
                            {!this.state.warehouses &&
                                <div className={"gw-nwh-info"}>
                                    <div className={"nwh-info"}>
                                        <Icon name={"warehouse"}/>
                                        {TranslationController.translate("Zapełniam magazyny...")}
                                    </div>
                                </div>
                            }

                            {this.state.warehouses &&
                                <div
                                    className={"gw-wh-warpper"}
                                >
                                    {
                                        this.state.warehouses.map((warehouse, key) => {
                                            const Color = warehouse.color

                                            return (
                                                <div
                                                    key={key}
                                                    style={{
                                                        margin: "0px 10px 20px 10px"
                                                    }}
                                                >
                                                    <Card>
                                                        <Card.Content header={warehouse.name} />

                                                        <div className={"gw-wh-sync"}>
                                                            <Button
                                                                icon={true}
                                                                circular={true}
                                                                loading={this.state.whSync[warehouse.id]}
                                                                onClick={() => {
                                                                    if (!warehouse.stateFrom) {
                                                                        return SnackBar("Brak źródła stanu magazynowego.", "warning")
                                                                    }

                                                                    this.state.whSync[warehouse.id] = true
                                                                    this.forceUpdate()

                                                                    warehouse.stateFrom.forEach(stateSource => {
                                                                        api().post("/api/global-warehouses/webSync", {id: warehouse.id, stateSource})
                                                                            .then((r) => {
                                                                                this.state.whSync[warehouse.id] = false

                                                                                this.getWarehoues()

                                                                                if (r.data.error) {
                                                                                    return SnackBar(r.data.message, "error")
                                                                                } else {
                                                                                    SnackBar("Zakończono synchronizację")
                                                                                }
                                                                            })
                                                                    })
                                                                }}
                                                            >
                                                                <Icon name={"sync"} />
                                                            </Button>
                                                        </div>

                                                        <Card.Content>
                                                            <div className={"gw-wh-content-warpper"}>
                                                                <div
                                                                    className={"gw-wh-auto-label"}
                                                                    style={{
                                                                        background: Color
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {warehouse.id
                                                                            .split("_")[warehouse.id.split("_").length - 1]
                                                                            .charAt(0)
                                                                        }
                                                                        {warehouse.id
                                                                            .split("_")[warehouse.id.split("_").length - 1]
                                                                            .slice(-2)
                                                                        }
                                                                    </span>
                                                                </div>

                                                                <div className={"gw-wh-stats"}>
                                                                    <div className={"gw-wh-stat-wapper"}>
                                                                        <div className={"gw-wh-stat-box"}>
                                                                            <div className={"gw-wh-stat-label"}>
                                                                                <Icon name='world' />
                                                                                {TranslationController.translate("Stworzono")}
                                                                            </div>
                                                                            <div className={"gw-wh-stat-value"}>
                                                                                {warehouse.created ? formatDate(warehouse.created).dateTimeSystem : "---"}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={"gw-wh-stat-wapper"}>
                                                                        <div className={"gw-wh-stat-box"}>
                                                                            <div className={"gw-wh-stat-label"}>
                                                                                <Icon name='sync' />
                                                                                {TranslationController.translate("Synchronizacja")}
                                                                            </div>
                                                                            <div className={"gw-wh-stat-value"}>
                                                                                {warehouse.lastSync ? formatDate(warehouse.lastSync).dateTimeSystem : "---"}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={"gw-wh-stat-wapper"}>
                                                                        <div className={"gw-wh-stat-box"}>
                                                                            <div className={"gw-wh-stat-label"}>
                                                                                <Icon name='warehouse' />
                                                                                {TranslationController.translate("Il. produktów")}
                                                                            </div>
                                                                            <div className={"gw-wh-stat-value"}>
                                                                                {warehouse.productQty ? numberWithCommas(warehouse.productQty) : "---"}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={"gw-wh-stat-wapper"}>
                                                                        <div className={"gw-wh-stat-box"}>
                                                                            <div className={"gw-wh-stat-label"}>
                                                                                <Icon name='warehouse' />
                                                                                {TranslationController.translate("ID AppChance")}
                                                                            </div>
                                                                            <div className={"gw-wh-stat-value"}>
                                                                                {warehouse.appChanceId || "---"}

                                                                                <Icon
                                                                                    style={{
                                                                                        marginLeft: 5,
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                    name={"edit"}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            appchaneId: warehouse.id
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={"gw-wh-stat-wapper"}>
                                                                        <div className={"gw-wh-source-label"}>
                                                                            <Icon name={"world"}/>
                                                                            {TranslationController.translate("Główne źródło stanu magazynowego:")}
                                                                        </div>

                                                                        {!warehouse.generalStateSource ?
                                                                            <div className={"gw-wh-nosource-label"}>
                                                                                - {TranslationController.translate("Brak źródła")} -
                                                                            </div>
                                                                            :
                                                                            <div className={"gw-wh-source-list"}>
                                                                                <div
                                                                                    className={"gw-wg-source-list-item"}
                                                                                >
                                                                                    <Icon name={"circle"}/>

                                                                                    <div
                                                                                        className={"gw-wh-auto-label list-item-object"}
                                                                                        style={{
                                                                                            background: Color
                                                                                        }}
                                                                                    >
                                                                                                <span>
                                                                                                    {warehouse.generalStateSource.toUpperCase()}
                                                                                                </span>
                                                                                    </div>

                                                                                    <div className={"list-item-label"}>
                                                                                        {warehouse.generalStateSource}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        <Button
                                                                            className={"gw-wh-base-btn"}
                                                                            basic={true}
                                                                            color={"teal"}
                                                                            style={{
                                                                                marginTop: 15,
                                                                            }}
                                                                            loading={this.state.openLoading[warehouse.id]}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    addWarehouseGeneralStateSourceModal: warehouse.id
                                                                                })
                                                                            }}
                                                                        >
                                                                            {TranslationController.translate("Zmień źródło")}
                                                                        </Button>
                                                                    </div>

                                                                    <div className={"gw-wh-stat-wapper"}>
                                                                        <div className={"gw-wh-source-label"}>
                                                                            <Icon name={"world"}/>
                                                                            {TranslationController.translate("Źródło stanu magazynowego:")}
                                                                        </div>

                                                                        {(warehouse.stateFrom && warehouse.stateFrom.length)
                                                                            ?
                                                                            <div className={"gw-wh-source-list"}>
                                                                                {warehouse.stateFrom.map((stateWh, index) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={index}
                                                                                            className={"gw-wg-source-list-item"}
                                                                                        >
                                                                                            <Icon name={"circle"}/>

                                                                                            <div
                                                                                                className={"gw-wh-auto-label list-item-object"}
                                                                                                style={{
                                                                                                    background: Color
                                                                                                }}
                                                                                            >
                                                                                                <span>
                                                                                                    {stateWh
                                                                                                        .charAt(0)
                                                                                                    }
                                                                                                    {stateWh
                                                                                                        .charAt(1)
                                                                                                    }
                                                                                                    {stateWh
                                                                                                        .charAt(2)
                                                                                                    }
                                                                                                </span>
                                                                                            </div>

                                                                                            <div className={"list-item-label"}>
                                                                                                {this.state.warehouseNameMap[stateWh] || stateWh}
                                                                                            </div>

                                                                                            <div
                                                                                                className={"list-del-item"}
                                                                                                onClick={() => {
                                                                                                    Confirm("Czy na pewno chcesz usunąć stan magazynowy?", "", () => {
                                                                                                        api().post("/api/global-warehouses/simpleUpdate", {data: {
                                                                                                                stateFrom: warehouse.stateFrom.filter(e => e !== stateWh)
                                                                                                            }, id: warehouse.id})
                                                                                                            .then((data) => {
                                                                                                                this.getWarehoues()
                                                                                                            })
                                                                                                            .then(() => {
                                                                                                                this.state.whSync[warehouse.id] = true

                                                                                                                api().post("/api/global-warehouses/webSync", {id: warehouse.id, stateSource: "CLEAR"})
                                                                                                                    .then((r) => {
                                                                                                                        this.state.whSync[warehouse.id] = false

                                                                                                                        this.getWarehoues()

                                                                                                                        if (r.data.error) {
                                                                                                                            return SnackBar(r.data.message, "error")
                                                                                                                        } else {
                                                                                                                            SnackBar("Zakończono synchronizację")
                                                                                                                        }
                                                                                                                    })
                                                                                                            })
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                                <Icon name={"trash"}/>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                            :
                                                                            <div className={"gw-wh-nosource-label"}>
                                                                                - {TranslationController.translate("Brak źródła")} -
                                                                            </div>
                                                                        }

                                                                        <Button
                                                                            className={"gw-wh-base-btn"}
                                                                            basic={true}
                                                                            color={"teal"}
                                                                            style={{
                                                                                marginTop: 15,
                                                                            }}
                                                                            loading={this.state.openLoading[warehouse.id]}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    addWarehouseStateModal: warehouse.id
                                                                                })
                                                                            }}
                                                                        >
                                                                            {TranslationController.translate("Dodaj źródło")}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className={"gw-wh-actions"}>
                                                                <Button
                                                                    className={"gw-wh-base-btn"}
                                                                    color={"teal"}
                                                                    loading={this.state.openLoading[warehouse.id]}
                                                                    onClick={() => {
                                                                        this.state.openLoading[warehouse.id] = true

                                                                        this.forceUpdate()

                                                                        this.state.count = null
                                                                        this.state.onPage = null
                                                                        this.state.currOriWhState = null
                                                                        this.state.currWhState = null
                                                                        this.state.currWhID = null
                                                                        this.state.currentPage = null
                                                                        this.state.qucikSearchValue = null

                                                                        api().post("/api/global-warehouses/get", {id: warehouse.id})
                                                                            .then(res => {
                                                                                this.state.openLoading[warehouse.id] = false

                                                                                this.forceUpdate()

                                                                                if (res.data && res.data.state && res.data.state.length) {
                                                                                    this.prepareWarehouseData(res.data.state, warehouse.id, 1)
                                                                                } else {
                                                                                    SnackBar("Brak wyników", "warning")
                                                                                }
                                                                            })
                                                                    }}
                                                                >
                                                                    {TranslationController.translate("Otwórz")}
                                                                </Button>

                                                                <div className={"gw-wh-fast-actions"}>
                                                                    <div style={{
                                                                        color: "#a5a5a5",
                                                                        fontSize: "0.8em"
                                                                    }}>
                                                                        {TranslationController.translate("Szybkie akcje:")}
                                                                    </div>

                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignItems: "center"
                                                                    }}>
                                                                        <Button
                                                                            color='blue'
                                                                            style={{
                                                                                marginTop: 5
                                                                            }}
                                                                            content={TranslationController.translate("Importuj")}
                                                                            icon='download'
                                                                            label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    importModal: warehouse.id
                                                                                })
                                                                            }}
                                                                        />

                                                                        <Button
                                                                            color='blue'
                                                                            style={{
                                                                                marginTop: 5
                                                                            }}
                                                                            content={TranslationController.translate("Eksportuj")}
                                                                            icon='download'
                                                                            loading={this.state.exportLoading[warehouse.id]}
                                                                            label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                                            onClick={() => {
                                                                                this.state.exportLoading[warehouse.id] = true

                                                                                this.forceUpdate()

                                                                                api().post("/api/global-warehouses/get", {id: warehouse.id})
                                                                                    .then(res => {
                                                                                        this.state.exportLoading[warehouse.id] = false

                                                                                        this.forceUpdate()

                                                                                        if (res.data && res.data.state && res.data.state.length) {
                                                                                            exportToFile(
                                                                                                res.data.state,
                                                                                                "uniExport",
                                                                                                ["sku", "brand", "season", "state"],
                                                                                                ["sku", "brand", "season", "state"].map(e => ({
                                                                                                    field: e,
                                                                                                    label: e
                                                                                                })),
                                                                                            )
                                                                                        } else {
                                                                                            SnackBar("Brak wyników", "warning")
                                                                                        }
                                                                                    })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <Button
                                                                    style={{
                                                                        marginTop: 10
                                                                    }}
                                                                    className={"gw-wh-base-btn"}
                                                                    onClick={() => this.remove(warehouse.id)}
                                                                >
                                                                    {TranslationController.translate("Usuń")}
                                                                </Button>
                                                            </div>
                                                        </Card.Content>

                                                        <Card.Content extra>
                                                            <Icon name={"warehouse"}/> ID: {warehouse.id}
                                                        </Card.Content>
                                                    </Card>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </Segment>

                    {this.state.addWarehouseStateModal &&
                    <Modal dimmer={"inverted"}
                           size={"mini"}
                           open={this.state.addWarehouseStateModal}
                           onClose={() => this.setState({addWarehouseStateModal: false})}>
                        <Modal.Header>
                            {TranslationController.translate("Dodaj stan magazynowy")}
                        </Modal.Header>

                        <Modal.Content>
                            <div className={"gw-nw-modal"}>

                                <EsotiqInput
                                    options={[
                                        {key: "k99", value: "k99", text: "K99"},
                                        {key: "e99", value: "e99", text: "E99"},
                                        {key: "157", value: "157", text: "157"},
                                        {key: "n32", value: "n32", text: "N32"},
                                        {key: "out", value: "out", text: "Outlet"},
                                        {key: "de", value: "de", text: "Niemcy"},
                                        {key: "d11", value: "d11", text: "D11 [OP]"},
                                        {key: "salon", value: "salon", text: "Salony"},
                                        {key: "prib", value: "prib", text: "PRIB"},
                                        {key: "ukr", value: "ukr", text: "UKR"},
                                        {key: "vos", value: "vos", text: "VOS"},
                                    ]}
                                    onChange={value => {
                                        console.log(value)
                                        this.setState({
                                            selectedToStateWarehouse: value
                                        })
                                    }}
                                />

                                <Button
                                    color={"teal"}
                                    style={{
                                        marginTop: 10
                                    }}
                                    onClick={() => {
                                        if (!this.state.selectedToStateWarehouse) {
                                            return SnackBar("Nie wybrałeś magazynu.", "error")
                                        }

                                        Confirm("Czy na pewno chcesz dodać i synchronizować stan magazynu?", "", () => {
                                            api().post("/api/global-warehouses/simpleUpdate", {data: {
                                                    stateFrom: [this.state.selectedToStateWarehouse]
                                                }, id: this.state.addWarehouseStateModal})
                                                .then(() => {
                                                    this.state.whSync[this.state.addWarehouseStateModal] = true

                                                    const whID = jsonCopy(this.state.addWarehouseStateModal)
                                                    const sourceID = jsonCopy(this.state.selectedToStateWarehouse)

                                                    this.setState({
                                                        selectedToStateWarehouse: null,
                                                        addWarehouseStateModal: false
                                                    });

                                                    api().post("/api/global-warehouses/webSync", {id: whID, stateSource: sourceID})
                                                        .then((data) => {
                                                            this.state.whSync[whID] = false

                                                            this.getWarehoues()

                                                            SnackBar("Zakończono synchronizację")
                                                        })
                                                })
                                        })
                                    }}
                                >
                                    Dodaj i synchronizuj stan
                                </Button>
                            </div>
                        </Modal.Content>
                    </Modal>
                    }

                    {this.state.addWarehouseGeneralStateSourceModal &&
                        <Modal dimmer={"inverted"}
                               size={"mini"}
                               open={this.state.addWarehouseGeneralStateSourceModal}
                               onClose={() => this.setState({addWarehouseGeneralStateSourceModal: false})}>
                            <Modal.Header>
                                {TranslationController.translate("Zmień źródło stanu magazynowego")}
                            </Modal.Header>

                            <Modal.Content>
                                <div className={"gw-nw-modal"}>

                                    <EsotiqInput
                                        options={[
                                            {key: "CP", value: "CP", text: "CP"},
                                            {key: "OP", value: "OP", text: "OP"}
                                        ]}
                                        onChange={value => {
                                            this.setState({
                                                selectedGeneralStateSource: value
                                            })
                                        }}
                                    />

                                    <Button
                                        color={"teal"}
                                        style={{
                                            marginTop: 10
                                        }}
                                        onClick={() => {
                                            if (!this.state.selectedGeneralStateSource) {
                                                return SnackBar("Nie wybrałeś źródła stanu.", "error")
                                            }

                                            Confirm("Czy na pewno chcesz zaaktualizować źródło stanu?", "", () => {
                                                api().post("/api/global-warehouses/simpleUpdate", {data: {
                                                        generalStateSource: this.state.selectedGeneralStateSource
                                                    }, id: this.state.addWarehouseGeneralStateSourceModal})
                                                    .then(() => {
                                                        this.setState({
                                                            selectedToStateWarehouse: null,
                                                            addWarehouseGeneralStateSourceModal: false
                                                        }, () => {
                                                            this.setState({
                                                                warehouses: null
                                                            }, () => this.getWarehoues());
                                                        });
                                                    })
                                            })
                                        }}
                                    >
                                        Zapisz
                                    </Button>
                                </div>
                            </Modal.Content>
                        </Modal>
                    }

                    {this.state.importModal &&
                    <Modal dimmer={"inverted"}
                           size={"mini"}
                           open={this.state.importModal}
                           onClose={() => this.setState({importModal: false})}>
                        <Modal.Header>
                            {TranslationController.translate("Importuj produkty")}
                        </Modal.Header>

                        <Modal.Content>
                            <div className={"gw-nw-modal"}>
                                <CsvImport
                                    csvStructure={[
                                        {column: "sku"},
                                        {column: "brand"},
                                        {column: "season"},
                                    ]}
                                    onUpdate={(data) => {
                                        return api().post("/api/global-warehouses/update", {data, id: this.state.importModal})
                                            .then((data) => {
                                                this.setState({
                                                    warehouses: null,
                                                    importModal: false
                                                }, () => this.getWarehoues());
                                            })
                                    }}
                                />
                            </div>
                        </Modal.Content>
                    </Modal>
                    }

                    {this.state.addWarehouse &&
                        <Modal dimmer={"inverted"}
                               size={"mini"}
                               open={this.state.addWarehouse}
                               onClose={() => this.setState({addWarehouse: false})}>
                            <Modal.Header>
                                {TranslationController.translate("Stwórz nowy magazyn")}
                            </Modal.Header>

                            <Modal.Content>
                                <div className={"gw-nw-modal"}>
                                    <div className={"gw-aid-wrapper"}>
                                        <div className={"gw-aid-label"}>
                                            {TranslationController.translate("ID magazynu (generowane automatycznie)")}
                                        </div>

                                        <div className={"gw-aid-value"}>
                                            {this.state.newWarehouseId || "---"}
                                        </div>
                                    </div>

                                    <EsotiqInput
                                        label={"Nazwa magazynu"}
                                        onChange={(value) => {
                                            this.state.newWarehouseName = value
                                            this.state.newWarehouseId = value.toString().toLowerCase().replace(" ", "_")

                                            this.forceUpdate()
                                        }}
                                    />

                                    <EsotiqInput
                                        label={"Główne źródło stanu magazynowego"}
                                        options={[
                                            {key: "CP", value: "CP", text: "CP"},
                                            {key: "OP", value: "OP", text: "OP"}
                                        ]}
                                        onChange={value => {
                                            this.state.newWarehouseGeneralStateSource = value

                                            this.forceUpdate()
                                        }}
                                    />
                                </div>

                            </Modal.Content>

                            <Modal.Actions>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            newWarehouseName: null,
                                            newWarehouseId: null,
                                            addWarehouse: false,
                                            newWarehouseGeneralStateSource: null
                                        })
                                    }}
                                    negative
                                >
                                    {TranslationController.translate("Anuluj")}
                                </Button>

                                <Button
                                    onClick={() => {
                                        this.addWarehouse()
                                            .then(() => {
                                                this.setState({
                                                    newWarehouseName: null,
                                                    newWarehouseId: null,
                                                    addWarehouse: false,
                                                    newWarehouseGeneralStateSource: null
                                                })
                                            })
                                    }}
                                    primary
                                >
                                    {TranslationController.translate("Zapisz")}
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    }

                    {this.state.previewWarehouse &&
                        <Modal dimmer={"inverted"}
                               size={"medium"}
                               open={this.state.previewWarehouse}
                               onClose={() => this.setState({previewWarehouse: false, activeAccordions: {}, previewWarehouseId: null, previewWarehouseState: null})}>
                            <Modal.Header>
                                {TranslationController.translate("Produkty dostępne w magazynie")}
                            </Modal.Header>

                            <Modal.Content>

                                <Menu className={"order-menu"} attached='top'>
                                    <Menu.Menu position='right'>
                                        <div className='ui right aligned category search item'>
                                            <div className='ui transparent icon input'>
                                                <form
                                                    onSubmit={e => {
                                                        e.preventDefault()

                                                        this.quickSearch(this.state.quickSearchValue)
                                                    }}
                                                >
                                                    <input
                                                        className='prompt'
                                                        type='text'
                                                        placeholder={TranslationController.translate("Szybkie wyszukiwanie...")}
                                                        onChange={e => {
                                                            this.state.quickSearchValue = e.target.value
                                                        }}
                                                    />
                                                </form>

                                                <i className='search link icon' />
                                            </div>
                                            <div className='results' />
                                        </div>
                                    </Menu.Menu>
                                </Menu>

                                <Segment className={"order-table-segment"} attached='bottom'>
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Produkty</Table.HeaderCell>
                                                <Table.HeaderCell>Sezon</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {Object.keys(this.state.previewWarehouse).map((model, k) => {
                                                return (
                                                    <Table.Row
                                                        key={k}
                                                    >
                                                        <Table.Cell>
                                                            <Accordion styled>
                                                                <div
                                                                    style={{
                                                                        position: "relative"
                                                                    }}
                                                                >
                                                                    <Accordion.Title
                                                                        active={this.state.activeAccordions[k]}
                                                                        onClick={(e, titleProps) => {
                                                                            this.state.activeAccordions[k] = this.state.activeAccordions[k] ? false : true

                                                                            this.forceUpdate()
                                                                        }}
                                                                    >
                                                                        <Icon name='dropdown' />

                                                                        <Label color='blue' content={model}/>
                                                                    </Accordion.Title>

                                                                    <div
                                                                        className={"gw-ea-remove"}
                                                                        onClick={() => {
                                                                            Confirm("Czy na pewno chcesz trwale usunąć ten model?", "", () => {
                                                                                delete this.state.previewWarehouse[model]

                                                                                this.forceUpdate()

                                                                                return api().post("/api/global-warehouses/removeState", {state: model, id: this.state.previewWarehouseId})
                                                                                    .then((data) => {
                                                                                        this.setState({
                                                                                            warehouses: null
                                                                                        }, () => this.getWarehoues());
                                                                                    })
                                                                            })
                                                                        }}
                                                                    >
                                                                        <Icon name={"trash"}/>
                                                                    </div>

                                                                    <Accordion.Content active={this.state.activeAccordions[k]}>
                                                                        {Object.keys(this.state.previewWarehouse[model].colors).map((color, k) => {
                                                                            return (
                                                                                <div
                                                                                    key={k}
                                                                                    style={{
                                                                                        position: "relative"
                                                                                    }}
                                                                                >
                                                                                    <Accordion.Title
                                                                                        active={this.state.activeAccordionsColors[k]}
                                                                                        onClick={(e, titleProps) => {
                                                                                            this.state.activeAccordionsColors[k] = this.state.activeAccordionsColors[k] ? false : true

                                                                                            this.forceUpdate()
                                                                                        }}
                                                                                        style={{
                                                                                            position: "relative"
                                                                                        }}
                                                                                    >
                                                                                        <Icon name='dropdown' />

                                                                                        <Label color='teal' content={color}/>
                                                                                    </Accordion.Title>

                                                                                    <div
                                                                                        className={"gw-ea-remove"}
                                                                                        onClick={() => {
                                                                                            Confirm("Czy na pewno chcesz trwale usunąć ten kolor?", "", () => {
                                                                                                delete this.state.previewWarehouse[model].colors[color]

                                                                                                if (Object.keys(this.state.previewWarehouse[model].colors) < 1) {
                                                                                                    delete this.state.previewWarehouse[model]
                                                                                                }

                                                                                                this.forceUpdate()

                                                                                                return api().post("/api/global-warehouses/removeState", {state: color, id: this.state.previewWarehouseId})
                                                                                                    .then((data) => {
                                                                                                        this.setState({
                                                                                                            warehouses: null
                                                                                                        }, () => this.getWarehoues());
                                                                                                    })
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <Icon name={"trash"}/>
                                                                                    </div>

                                                                                    <Accordion.Content active={this.state.activeAccordionsColors[k]}>
                                                                                        {Object.keys(this.state.previewWarehouse[model].colors[color].sizes).map((size, k) => {
                                                                                            return (
                                                                                                <div
                                                                                                    key={k}
                                                                                                    style={{
                                                                                                        position: "relative"
                                                                                                    }}
                                                                                                >
                                                                                                    <Accordion.Title
                                                                                                        active={true}
                                                                                                    >
                                                                                                        <Icon name='caret right' />
                                                                                                        <Label content={size}/>
                                                                                                        <Label icon={"warehouse"} content={this.state.previewWarehouse[model].colors[color].sizes[size].qty}/>
                                                                                                    </Accordion.Title>

                                                                                                    <div
                                                                                                        className={"gw-ea-remove"}
                                                                                                        onClick={() => {
                                                                                                            Confirm("Czy na pewno chcesz trwale usunąć ten rozmiar?", "", () => {
                                                                                                                delete this.state.previewWarehouse[model].colors[color].sizes[size]

                                                                                                                if (Object.keys(this.state.previewWarehouse[model].colors[color].sizes) < 1) {
                                                                                                                    delete this.state.previewWarehouse[model]
                                                                                                                }

                                                                                                                this.forceUpdate()

                                                                                                                return api().post("/api/global-warehouses/removeState", {state: size, id: this.state.previewWarehouseId})
                                                                                                                    .then((data) => {
                                                                                                                        this.setState({
                                                                                                                            warehouses: null
                                                                                                                        }, () => this.getWarehoues());
                                                                                                                    })
                                                                                                            })
                                                                                                        }}
                                                                                                    >
                                                                                                        <Icon name={"trash"}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </Accordion.Content>
                                                                                </div>
                                                                            )
                                                                        })}

                                                                    </Accordion.Content>
                                                                </div>
                                                            </Accordion>
                                                        </Table.Cell>

                                                        <Table.Cell>
                                                            {this.state.previewWarehouse[model].season || "---"}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })}
                                        </Table.Body>

                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='7'>
                                                    {this.getPagesCount() > 1 &&
                                                    <Pagination
                                                        onPageChange={(e, page) => this.pageChange(e, page)}
                                                        defaultActivePage={1}
                                                        totalPages={this.getPagesCount()}
                                                    />
                                                    }
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                </Segment>
                            </Modal.Content>

                            <Modal.Actions>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            previewWarehouse: false,
                                            activeAccordions: {},
                                            activeAccordionsColors: {},
                                            previewWarehouseId: null,
                                            previewWarehouseState: null
                                        })
                                    }}
                                    negative
                                >
                                    {TranslationController.translate("Zamknij")}
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    }

                    {this.state.appchaneId &&
                        <Modal dimmer={"inverted"}
                               size={"mini"}
                               open={this.state.appchaneId}
                               onClose={() => this.setState({appchaneId: false})}>
                            <Modal.Header>
                                {TranslationController.translate("Przypisz Appchance ID")}
                            </Modal.Header>

                            <Modal.Content>
                                <div className={"gw-nw-modal"}>
                                    <EsotiqInput
                                        label={"ID magazynu w AppChance Panel"}
                                        placeholder={"ID magazynu"}
                                        type={"number"}
                                        onChange={(value) => {
                                            this.state.newAppchanceId = value
                                        }}
                                    />
                                </div>
                            </Modal.Content>

                            <Modal.Actions>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            newAppchanceId: null,
                                            appchaneId: null,
                                        })
                                    }}
                                    negative
                                >
                                    {TranslationController.translate("Zamknij")}
                                </Button>

                                <Button
                                    onClick={() => {
                                        if (!this.state.newAppchanceId) {
                                            return SnackBar("Nie podałeś ID", "error")
                                        }

                                        api().post("/api/global-warehouses/simpleUpdate", {data: {appChanceId: this.state.newAppchanceId}, id: this.state.appchaneId})
                                            .then((data) => {
                                                this.setState({
                                                    newAppchanceId: null,
                                                    appchaneId: false
                                                }, () => this.getWarehoues());
                                            })
                                    }}
                                    positive
                                >
                                    {TranslationController.translate("Zapisz")}
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    }
                </Container>
            </React.Fragment>
        );
    }
}
