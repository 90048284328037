import {api, baseXHR} from "../lib/axios";

export let DATA_OBJECT = {}
export let INITIATOR = null
export let APP_READY = false

export const getProductList = () => baseXHR().get(`catalog_product_entity?join=catalog_product_entity_varchar&join=catalog_product_entity_decimal&join=catalog_product_entity_int`)
export const getProductCategory = (productId) => baseXHR().get(`catalog_category_product?filter=product_id,in,${productId}`)
export const getCategory = (categoryId) => baseXHR().get(`catalog_category_entity?filter=entity_id,eq,${categoryId}&join=catalog_category_entity_varchar`)
export const getPromotionsList = () => baseXHR().get(`salesrule?join=salesrule_coupon`)
export const getPromotion = (promotionId) => baseXHR().get(`salesrule?filter=rule_id,eq,${promotionId}&join=salesrule_coupon`)

export function StateController() {
    // Ustawia stan i controller zawsze w takiej samej kolejności - values -> setValues
    this.states = []
    this.statesController = []
}

StateController.prototype = {
    setStates: function (states) {
        this.states = states
    },
    setStatesController: function (controllers) {
        this.statesController = controllers
    },
    clearStates: function () {
        this.states = []
        this.statesController = []
    }
}

export async function getTree(table) {
    let tree = []

    async function getChild(category) {
        let categoryData = category

        if (categoryData.children_count) {
            let children = null

            await baseXHR().get(`catalog_category_entity?filter=parent_id,in,${category.entity_id}&join=catalog_category_entity_varchar`)
                .then((req) => {
                    children = req.data.records

                    children.forEach((e, k) => {
                        const categoryName = e.catalog_category_entity_varchar.find(item => item.attribute_id === 45).value
                        children[k]["value"] = categoryName
                        children[k]["name"] = categoryName

                    })

                    categoryData["nodes"] = children

                    children.forEach((child) => {
                        if (child.children_count) {
                            getChild(child)
                        }
                    })
                })
                .then(() => {
                    // console.log(categoryData, "main category data")
                    // console.log(children, "childrens")
                })

            return categoryData
        }
    }

    await baseXHR().get(`${table}`)
        .then((r) => {
            const tableData = r.data.records
            let treeNodes = getChild(tableData[0])
                .then((r) => {
                    return r
                })
            return treeNodes
        })
        .then((treeNode) => tree = treeNode)

    if (tree) {
        tree.toggled = true
        console.log(tree)
    }

    return tree
}


export const collectData = async (initiator = null) => {
    console.log("APP COLLECTING", "from dataController.js")

    if (localStorage.getItem("selected_parlour")) {
        DATA_OBJECT["selected_parlour"] = localStorage.getItem("selected_parlour")
    }

    if (localStorage.getItem("selected_lang")) {
        DATA_OBJECT["selected_lang"] = localStorage.getItem("selected_lang")
    }

    if (initiator) {
        console.log("INITAIATOR COLLECTED", "from dataController.js")
        INITIATOR = initiator
    }

    if (INITIATOR) {
        INITIATOR.setState({
            renderReady: false
        })
    }


    APP_READY = true;

    if (INITIATOR.state.renderReady === false) {
        // INITIATOR.setState({
        //     renderReady: true
        // })
    }
}



export const visibilityMap = {
    1: "Not Visible Individually",
    2: "Catalog",
    3: "Search",
    4: "Catalog, Search"
}
