import React from "react";
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import {TranslationController} from "../../controller/menager";
import Breadcrumb from "../../components/Breadcrumb";
import {Segment, Menu, Button} from 'semantic-ui-react'
import SnackBar from "../../components/Snack";
import UITable from "../../lib/table-v2";
import CsvImport from "../../components/CsvImport";
import TableMenu from "../../components/TableMenu";
import {DATA_OBJECT} from "../../controller/dataController";

export default class ParlourPriority extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            appliedFilters: null
        };
    }

    init() {
        return api().post("/api/parlours/priority/get")
            .then(r => {
                this.setState({data: r.data.data});
                return r.data.data
            })
    }

    render() {
        return (
            <div>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Priorytety salonów")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Import</TableMenu.Header>
                                <TableMenu.Item>
                                    <CsvImport
                                        csvStructure={[
                                            {column: "id"},
                                            {column: "priority"},
                                        ]}
                                        onUpdate={(data) => {
                                            const parlourPriorities = data.filter(row => row.id && row.priority)
                                            return api().post("/api/parlours/priority/update", { parlourPriorities, user: DATA_OBJECT.user.login })
                                                .then((data) => {
                                                    if(data.error) {
                                                        SnackBar(data.error, "error")
                                                        return
                                                    }
                                                    SnackBar("Priorytety zaktualizowane poprawnie.")

                                                    this.orderTable.setState({
                                                        data: null
                                                    });

                                                    this.orderTable.init();

                                                    return data
                                                })
                                        }}
                                    />
                                </TableMenu.Item>
                            </TableMenu.Menu>
                        </TableMenu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/parlours/priority/get",
                                options: {pageSize: 300, currentPage: 1},
                            }}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    field: "id",
                                    label: "Salon",
                                },
                                {
                                    field: "priority",
                                    label: "Priorytet",
                                    render: row => row.priority
                                },
                            ]}
                        />
                    </Segment>
                </Container>
            </div>
        );
    }
}
