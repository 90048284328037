import React from "react"
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {Modal, Icon, Segment, Menu} from 'semantic-ui-react'
import MenuButton from "../../lib/table-v2/menuButton";
import EsotiqInput from "../../components/EsotiqInput";
import {TranslationController} from "../../controller/menager";
import Button from "../../components/Button";
import UITable from "../../lib/table-v2";
import {capitalizeFirstLetter} from "../../helpers/dateHelper";
import Confirm from "../../components/Confirm";
import SnackBar from "../../components/Snack";
import {DATA_OBJECT} from "../../controller/dataController";
import Dropdown from "../../components/Dropdown";
import {paymentMethodTypes, paymentMethodTypesMap} from "../../lib/paymentMethodTypes";
import TableMenu from "../../components/TableMenu";

export default class PaymentMethodsScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentMethods: [
                {name: "Przelew na konto", id: "transfer"},
                {name: "PayPal DE", id: "paypal-de"},
                {name: "PayPal", id: "paypal"},
                {name: "Za pobraniem", id: "payment-on-delivery"},
                {name: "BlueMedia Web", id: "bluemedia-web"},
                {name: "BlueMedia", id: "bluemedia"},
                {name: "Payment method 1", id: "payment_method_1"},
                {name: "Payment method 2", id: "payment_method_2"},
                {name: "Payment method 3", id: "payment_method_3"},
                {name: "Payment method 4", id: "payment_method_4"},
                {name: "Payment method 5", id: "payment_method_5"}
            ],
        }
    }

    componentDidMount() {

    }

    init(limit, currentPage) {
        return api().post("/api/system/payment-methods/get", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data});

                return r.data.data
            })
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    {!this.props.simple &&
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Metody płatności")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>
                    }


                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>
                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                            <MenuButton
                                icon={"user"}
                                onClick={() => {
                                    this.setState({
                                        addMethod: true,
                                    })
                                }}
                                label={"Dodaj metodę"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/system/payment-methods/get",
                                options: {limit: 25, currentPage: 1}
                            }}
                            ref={table => this.userTable = table}
                            provider={this}
                            columns={[
                                {
                                    width: 4,
                                    field: "name",
                                    label: "Metoda",
                                    filters: [
                                        {label: "Nazwa", condition: "like", type: "text"},
                                        {label: "Id zewnętrzne", condition: "like", type: "text", forceField: "externalId"}
                                    ],
                                    render: row => {
                                        return (
                                            <div className={"shipping-method-present-wrapper"}>
                                                <img src={row.iconUrl} alt=""/>

                                                <div className={"smpw-label"}>
                                                    <span>{TranslationController.translate("Nazwa")}:</span> {row.name}
                                                </div>

                                                <div className={"smpw-label"}>
                                                    <span>{TranslationController.translate("Zewnętrzne ID")}</span>: {row.externalId}
                                                </div>

                                                <div className={"smpw-label"}>
                                                    <span>{TranslationController.translate("Typ")}</span>: {paymentMethodTypesMap()[row.paymentType] || "---"}
                                                </div>

                                                <div className={"smpw-label"}>
                                                    <span>{TranslationController.translate("Pozycja")}</span>: {row.position || "---"}
                                                </div>
                                            </div>
                                        )
                                    }
                                },

                                {
                                    field: "price",
                                    label: "Koszt dodatkowy",
                                    center: true,
                                    width: 1,
                                    filters: [
                                        {label: "Koszt dodatkowy", condition: "like", type: "text"},
                                    ],
                                    render: row => {
                                        return (
                                            <div className={"shipping-method-brand-wrapper"}>
                                                <div className={"smbw-label"}>{row.price}</div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    label: "Edytuj",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                this.setState({
                                                    addMethod: true,
                                                    methodId: row._id,
                                                    name: row.name,
                                                    externalId: row.externalId,
                                                    price: row.price,
                                                    paymentType: row.paymentType,
                                                    position: row.position
                                                })
                                            }}>
                                                <i className='edit link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                                {
                                    label: "Usuń",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                Confirm("Czy na pewno chcesz trwale usunąć tą metodę płatności?", null, () => {
                                                    api().post("/api/system/payment-methods/delete", {id: row._id})
                                                        .then(() => {
                                                            SnackBar("Usunięto")

                                                            this.userTable.setState({
                                                                data: null
                                                            })

                                                            this.userTable.init()
                                                        })
                                                })
                                            }}>
                                                <i className='trash link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                            ]}
                        />
                    </Segment>

                    {this.state.addMethod &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.addMethod}
                           onClose={() => this.setState({addMethod: false})}>
                        <Modal.Header>{this.state.methodId ? TranslationController.translate("Edytuj metodę płatności") : TranslationController.translate("Dodaj metodę płatności")}</Modal.Header>
                        <Modal.Content>
                            <div>
                                <EsotiqInput
                                    label={"Nazwa"}
                                    placeholder={"Nazwa metody wysyłki"}
                                    onChange={v => {
                                        let value = v;

                                        this.setState({
                                            name: value
                                        })
                                    }}
                                    defValue={this.state.name}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Typ"}
                                    placeholder={"Wybierz..."}
                                    options={paymentMethodTypes}
                                    onChange={v => {
                                        let value = v;

                                        this.setState({
                                            paymentType: value
                                        })
                                    }}
                                    defValue={this.state.paymentType}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Zewnętrzne ID"}
                                    placeholder={"Wpisz ID z systemu AppChance"}
                                    onChange={v => {
                                        let value = v;

                                        this.setState({
                                            externalId: value
                                        })
                                    }}
                                    defValue={this.state.externalId}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Price"}
                                    placeholder={"Wprowadź koszt dodatkowy"}
                                    onChange={v => {
                                        let value = v;

                                        value = value.toLowerCase();

                                        this.setState({
                                            price: value
                                        })
                                    }}
                                    defValue={this.state.price}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Pozycja"}
                                    placeholder={"Wprowadź pozycję w filtrze"}
                                    onChange={v => {
                                        let value = v;

                                        this.setState({
                                            position: value
                                        })
                                    }}
                                    defValue={this.state.position}
                                    width={300}
                                />
                            </div>
                        </Modal.Content>

                        <Modal.Actions>
                            <Button onClick={() => {
                                if (!this.state.name) {
                                    return SnackBar("Nie podałeś nazwy", "error")
                                }

                                if (this.state.name.length < 4) {
                                    return SnackBar("Nazwa jest za krótka", "error")
                                }

                                api().post(this.state.methodId ? "/api/system/payment-methods/update" : "/api/system/payment-methods/create", {
                                    methodId: this.state.methodId,
                                    name: this.state.name,
                                    externalId: this.state.externalId,
                                    price: parseFloat(this.state.price),
                                    paymentType: this.state.paymentType,
                                    position: this.state.position
                                })
                                    .then((res) => {
                                        if (res.data.error) {
                                            return SnackBar(res.data.message, "error")
                                        }

                                        SnackBar(this.state.method ? "Zaktualizowano metodę płatności" : "Dodano nową metodę płatności");

                                        this.setState({
                                            methodId: null,
                                            addMethod: false,
                                            name: null,
                                            externalId: null,
                                            price: null,
                                            paymentType: null,
                                            position: null
                                        });

                                        this.userTable.setState({
                                            data: null
                                        });

                                        this.userTable.init()
                                    })
                            }} positive>{TranslationController.translate("Zapisz i zamknij")}</Button>
                            <Button onClick={() => this.setState({addMethod: false})} negative>{TranslationController.translate("Zamknij")}</Button>
                        </Modal.Actions>
                    </Modal>
                    }
                </Container>
            </React.Fragment>
        );
    }
}
