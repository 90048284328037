import CRUDView from "../../components/CRUDTable/view";
import {ParlourDetailsUrls} from "../../api/urls";
import React from "react";
import {parlourDetailsApi} from "../../lib/axios";
import {Checkbox, Input, Select} from "semantic-ui-react";
import EquipmentModal from "./equipmentModal";
import {TranslationController} from "../../controller/menager";
import _ from "lodash";

export default class ParloursManagement extends CRUDView {
    resource = ParlourDetailsUrls.PARLOURS;
    categories = [
        'TOPXXL',
        'XXL',
        'XL',
        'L',
        'M',
        'S',
    ]

    managementTypes = [
        'SELF',
        'FRANCHISEE',
    ]

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            label: TranslationController.translate('Zarządzanie salonami'),
            schema: {
                title: TranslationController.translate('Salon'),
                type: 'object',
                required: ['name'],
                properties: {
                    name: {
                        type: 'string',
                        title: TranslationController.translate('Nazwa'),
                        default: '',
                    },
                    nr: {
                        type: 'string',
                        title: TranslationController.translate('Nr'),
                        default: '',
                    },
                    category: {
                        type: 'string',
                        title: TranslationController.translate('Kategoria'),
                        default: 'S',
                        enum: this.categories
                    },
                    managementType: {
                        type: 'string',
                        title: TranslationController.translate('Typ zarządzania'),
                        default: 'OWN',
                        enum: this.managementTypes,
                        enumNames: [
                            TranslationController.translate('SELF'),
                            TranslationController.translate('FRANCHISEE')
                        ]
                    },
                    area: {
                        type: 'string',
                        title: TranslationController.translate('Powierzchnia'),
                        default: '0.00',
                    },
                    openingDate: {
                        type: ['string', 'null'],
                        format: 'date',
                        title: TranslationController.translate('Data otwarcia'),
                        default: null,
                    },
                    closingDate: {
                        type: ['string', 'null'],
                        format: 'date',
                        title: TranslationController.translate('Data zamknięcia'),
                        default: null,
                    },
                    address: {
                        type: 'object',
                        title: TranslationController.translate('Adres'),
                        properties: {
                            shoppingMall: {
                                type: 'string',
                                title: TranslationController.translate('Galeria'),
                                default: '',
                            },
                            street: {
                                type: 'string',
                                title: TranslationController.translate('Ulica'),
                                default: '',
                            },
                            zipCode: {
                                type: 'string',
                                title: TranslationController.translate('Kod pocztowy'),
                                default: '',
                            },
                            city: {
                                type: 'string',
                                title: 'Miasto',
                                default: '',
                            },
                            country: {
                                type: 'string',
                                title: TranslationController.translate('Kraj'),
                                default: 'Polska',
                            },
                            latitude: {
                                type: 'string',
                                title: TranslationController.translate('Szerokość geograficzna'),
                                default: '0',
                            },
                            longitude: {
                                type: 'string',
                                title: TranslationController.translate('Długość geograficzna'),
                                default: '0',
                            }
                        }
                    },
                    contacts: {
                        type: 'array',
                        title: 'Kontakty',
                        items: {
                            type: 'object',
                            required: ['type', 'value'],
                            properties: {
                                type: {
                                    type: 'string',
                                    title: 'Typ',
                                    enum: ['Email', 'Phone'],
                                },
                                value: {
                                    type: 'string',
                                    title: 'Wartość',
                                    default: '',
                                }
                            }
                        }
                    },
                    openHours: {
                        type: 'array',
                        title: TranslationController.translate('Godziny otwarcia'),
                        items: {
                            type: 'object',
                            properties: {
                                dayOfWeek: {
                                    type: 'string',
                                    title: TranslationController.translate('Dzień'),
                                    enumNames: [
                                        TranslationController.translate('Poniedziałek'),
                                        TranslationController.translate('Wtorek'),
                                        TranslationController.translate('Środa'),
                                        TranslationController.translate('Czwartek'),
                                        TranslationController.translate('Piątek'),
                                        TranslationController.translate('Sobota'),
                                        TranslationController.translate('Niedziela')
                                    ],
                                    enum: [
                                        'Monday',
                                        'Tuesday',
                                        'Wednesday',
                                        'Thursday',
                                        'Friday',
                                        'Saturday',
                                        'Sunday'
                                    ],
                                },
                                openAt: {
                                    type: 'string',
                                    title: TranslationController.translate('Otwarcie'),
                                    format: 'time',
                                    default: '00:00:00'
                                },
                                closeAt: {
                                    type: 'string',
                                    title: TranslationController.translate('Zamknięcie'),
                                    format: 'time',
                                    default: '00:00:00'
                                }
                            }
                        }
                    },
                    region: {
                        type: 'number',
                        title: TranslationController.translate('Region'),
                        enum: [],
                        enumNames: [],
                    },
                    accountant: {
                        type: 'number',
                        title: TranslationController.translate('Księgowy'),
                        enum: [],
                        enumNames: [],
                    }
                }
            },
            tableFields: [
                {
                    label: "Id",
                    getValue: (payload) => payload.id,
                },
                {
                    label: "Nazwa",
                    getValue: (payload) => payload.name,
                },
                {
                    label: "Adres",
                    getValue: (payload) => this.formatAddress(payload.address),
                    filters: [
                        {
                            component: <Input
                                key={'city'}
                                placeholder={`${TranslationController.translate('Miasto')}...`}
                                icon={'search'}
                                onChange={_.debounce((e, data) => {
                                    this.setState({filters: {...this.state.filters, city: data.value || null }})}, 500)}
                            />,
                            props: {
                                defaultValue: () => this.state.filters.city || ''
                            }
                        },
                    ]
                },
                {
                    label: 'Telefon',
                    getValue: (payload) => payload.contacts.filter((item) => item.type === 'Phone').map((item, index) => <span key={index} >{item.value}<br/></span>)
                },
                {
                    label: 'Nr',
                    getValue: (payload) => payload.nr,
                },
                {
                    label: "Kategoria",
                    getValue: (payload) => payload.category,
                    filters: [
                        {
                            component: <Select
                                placeholder={TranslationController.translate('Kategoria')}
                                options={this.categories.map(category => {
                                    return {key: category, text: TranslationController.translate(category), value: category}
                                })}
                                onChange={(e, data) => {
                                    this.setState({filters: {...this.state.filters, 'filter.category': data.value || null} })}
                                }
                            />,
                            props: {
                                defaultValue: () => this.state.filters['filter.category'] || null
                            }
                        }

                    ]
                },
                {
                    label: "Typ zarządzania",
                    getValue: (payload) => TranslationController.translate(payload.managementType),
                    filters: [
                        {
                            component: <Select
                                placeholder={TranslationController.translate('Typ zarządzania')}
                                options={this.managementTypes.map(type => {
                                    return {key: type, text: TranslationController.translate(type), value: type}
                                })}
                                onChange={(e, data) => {
                                    this.setState({filters: {...this.state.filters, 'filter.managementType': data.value || null} })
                                }}
                            />,
                            props: {
                                defaultValue: () => this.state.filters['filter.managementType'] || null
                            }
                        }

                    ]
                },
                {
                    label: "Regionalny",
                    getValue: (payload) => payload.regionManagerProfile?.firstName + ' ' + payload.regionManagerProfile?.lastName,
                    filters: [{
                        component: <Input
                            key={'regionManager'}
                            placeholder={`${TranslationController.translate('Imię i nazwisko')}...`}
                            icon={'search'}
                            onChange={_.debounce((e, data) => {
                                this.setState({filters: {...this.state.filters, 'regional-manager': data.value || null }})}, 500)}
                        />,
                        props: {
                            defaultValue: () => this.state.filters['regional-manager'] || ''
                        }
                    }

                    ]
                },
                {
                    label: "Księgowy",
                    getValue: (payload) => payload.accountantProfile?.firstName + ' ' + payload.accountantProfile?.lastName,
                    filters: [{
                        component: <Input
                            key={'accountant'}
                            placeholder={`${TranslationController.translate('Imię i nazwisko')}...`}
                            icon={'search'}
                            onChange={_.debounce((e, data) => {
                                this.setState({filters: {...this.state.filters, accountant: data.value || null }})}, 500)}
                        />,
                        props: {
                            defaultValue: () => this.state.filters.accountant || ''
                        }
                    }

                    ]
                },
                {
                    label: 'Wyposażenie',
                    getValue: (payload) => (<EquipmentModal parlourId={payload.id} />)
                },
                {
                    label: "Aktywny",
                    getValue: (payload) => (<Checkbox toggle checked={payload.isActive} onChange={(e, data) => this.toggleActive(data.checked, payload.id)}/>),
                    filters: [{
                        component: <Checkbox
                            key={'isActive'}
                            label={TranslationController.translate('Aktywny')}
                            onChange={(e, data) => {
                                this.setState({filters: {...this.state.filters, 'filter.isActive': data.checked || null }})}
                            } />,
                        props: {
                            defaultValue: () => this.state.filters['filter.isActive'] || null
                        }
                    }]
                },
                {
                    label: "Zwroty Online",
                    getValue: (payload) => (<Checkbox toggle checked={payload.online.onlineReturn} onChange={(e, data) => this.toggleOnlineReturn(data.checked, payload.id)}/>)
                },
                {
                    label: "Punkt odbioru",
                    getValue: (payload) => (<Checkbox toggle checked={payload.online.pickupPoint} onChange={(e, data) => this.togglePickupPoint(data.checked, payload.id)}/>)
                },
                {
                    label: "Mapa ecommerce",
                    getValue: (payload) => (
                        <Checkbox toggle checked={this.onMap(payload.id)} onChange={(e, data) => this.toggleShowOnMap(data.checked, payload.id)}/>
                    )
                }
            ],
            ecommerceMap: []
        }
    }

    onMap(parlourId) {
        return this.state.ecommerceMap.includes(parlourId)
    }

    formatAddress(address) {
        return (
            <div>
                <span>{address.shoppingMall}<br/></span>
                <span>{address.zipCode} {address.city}<br/></span>
            </div>
        )
    }

    componentDidMount() {
        this.getAll();
        this.getRegions();
        this.getAccountants();
        this.getEcommerceMap();
    }

    getAll() {
        parlourDetailsApi.get(this.resource, {params: this.getParams()}).then((response) => {
            const profile = {
                firstName: '',
                lastName: '',
            }
            const data = response.data.data.map((parlour) => {
                return {
                    ...parlour,
                    accountantProfile: parlour.accountant?.profile || profile,
                    regionManagerProfile: parlour.region?.regionManager?.profile || profile,
                    accountant: parlour.accountant?.id,
                    region: parlour.region?.id,
                }
            });
            this.setState({
                data: data,
            });
            this.setState({...response.data.meta});
        });
    }

    getRegions() {
        parlourDetailsApi.get(ParlourDetailsUrls.REGIONS).then((response) => {
            response.data.data.forEach((region) => {
                this.state.schema.properties.region.enum.push(region.id);
                this.state.schema.properties.region.enumNames.push(region.regionManager.profile.firstName + ' ' + region.regionManager.profile.lastName);
            });
        });

    }

    getAccountants() {
        parlourDetailsApi.get(ParlourDetailsUrls.ACCOUNTANTS).then((response) => {
            response.data.data.forEach((accountant) => {
                this.state.schema.properties.accountant.enum.push(accountant.id);
                this.state.schema.properties.accountant.enumNames.push(accountant.profile.firstName + ' ' + accountant.profile.lastName);
            });
        });
    }

    getEcommerceMap() {
        parlourDetailsApi.get(ParlourDetailsUrls.PARLOURS_ECOM_MAP).then((response) => {
            this.setState({ecommerceMap: response.data});
        });
    }

    toggleShowOnMap(value, parlourId) {
        if (value) {
            parlourDetailsApi.get(ParlourDetailsUrls.PARLOURS_ECOM_SHOW + parlourId).then((response) => {
                this.getEcommerceMap();
            });
        } else {
            parlourDetailsApi.get(ParlourDetailsUrls.PARLOURS_ECOM_HIDE + parlourId).then((response) => {
                this.getEcommerceMap();
            });
        }
    }

    toggleActive(value, parlourId) {
        parlourDetailsApi.patch(ParlourDetailsUrls.PARLOURS + '/' + parlourId, {isActive: value}).then((response) => {
            this.getAll();
        });
    }

    toggleOnlineReturn(value, parlourId) {
        parlourDetailsApi.patch(ParlourDetailsUrls.PARLOURS + '/' + parlourId, {online: {onlineReturn: value}}).then((response) => {
            this.getAll();
        });
    }

    togglePickupPoint(value, parlourId) {
        parlourDetailsApi.patch(ParlourDetailsUrls.PARLOURS + '/' + parlourId, {online: {pickupPoint: value}}).then((response) => {
            this.getAll();
        });
    }
}
