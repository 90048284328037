import React from "react";
import {Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Font, Image} from '@react-pdf/renderer';
import {formatDate} from "../../helpers/dateHelper";

const sourceLight = require("../../resources/fonts/OpenSans-Light.ttf")
const sourceRegular = require("../../resources/fonts/OpenSans-Regular.ttf")

Font.register({family: 'OpenSansLight', src: sourceLight});
Font.register({family: 'OpenSans', src: sourceRegular});

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: "40px 20px",
        fontFamily: 'OpenSans',
        height: 50000
    },
    section: {
        margin: 10,
        padding: 10,
    },
    title: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 700,
        marginTop: 40,
        marginBottom: 40
    },
    subinfo: {
        fontSize: 10,
        textAlign: "right",
        marginTop: 5,
        fontFamily: 'OpenSansLight'
    },
    subTitle: {
        fontSize: 14,
        fontWeight: 700,
        marginTop: 40,
        marginBottom: 10
    },
    bigBox: {
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderColor: "#000",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    columnAllign: {
        flexDirection: "row",
        justifyContent: "space-between"
    },
    columnField: {
        fontWeight: 700,
        fontSize: 10,
        width: 150
    },
    columnInput: {
        fontSize: 10,
    },
    tableBox: {},
    tableHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableHeaderCol: {
        padding: 3,
        borderWidth: 1,
        borderColor: "#000000",
        flex: 1,
    },
    tableHeaderColNoWidth: {
        padding: 3,
        borderWidth: 1,
        borderColor: "#000000",
    },
    tableHeaderColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableBody: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableBodyCol: {
        padding: 3,
        borderWidth: 1,
        borderColor: "#000000",
        flex: 1,
    },
    tableBodyColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableHeaderColTextLeft: {
        fontSize: 10,
    },
    tableBodyColTextLittle: {
        fontSize: 10,
        color: "#898989"
    },
    tableBodyColNoWidth: {
        padding: 3,
        borderWidth: 1,
        borderColor: "#000000",
    },
    tableBoxEnd: {
        width: "50%",
        marginTop: 30
    },
    tableBodyColCustom: {
        padding: 3,
        borderWidth: 1,
        borderColor: "#000000",
        flex: 1,
        height: 80
    },
    bottomBox: {
        borderWidth: 1,
        borderColor: "#000000",
        borderTopWidth: 0,
        flex: 1,
        justifyContent: "flex-end",
        width: "100%",
        padding: 5
    },
    bottomBoxText: {
        fontSize: 10,
        textAlign: "right"
    },
    infoBox: {
        width: 200,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    infoLabel: {
        fontSize: 10
    },
    infoValue: {
        fontSize: 10
    },
    intableLittleInfo: {
        fontSize: 9,
        color: "#8a8a8a"
    }
});

// Create Document Component
export default class PDFComplaint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            x: 0
        }
    }

    componentDidMount() {

    }

    renderPages(data) {
        const pages = []
        let lastPagePushed = false

        let i,j,temparray,chunk = 2;


        for (i=0,j=data.revertData.length; i<j; i+=chunk) {
            temparray = data.revertData.slice(i,i+chunk);

            if (i === 0) {
                pages.push(this.firstPage(data, temparray))
            } else {
                pages.push((
                    <Page style={styles.page}>
                        <View style={styles.tableBox}>
                            <View style={styles.tableHeader}>
                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "50%"}}}>
                                    <Text style={styles.tableHeaderColText}>Informacje</Text>
                                </View>
                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "50%"}}}>
                                    <Text style={styles.tableHeaderColText}>Żądanie reklamującego</Text>
                                </View>
                            </View>

                            {temparray.map((revertItem, key) => {
                                if (key > 1) return false
                                return (
                                    <View key={key} style={styles.tableBody}>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "50%"}}}>
                                            <Text style={{...styles.tableHeaderColTextLeft, ...{borderBottomWidth: 1,
                                                    borderBottomColor: "#d2d2d2", paddingBottom: 5, marginBottom: 5}}}>1. Nazwa: {revertItem.name}</Text>
                                            <Text style={{...styles.tableHeaderColTextLeft, ...{borderBottomWidth: 1,
                                                    borderBottomColor: "#d2d2d2", paddingBottom: 5, marginBottom: 5}}}>2. Data stwierdzenia wady: {formatDate(data.meta ? data.meta.created : new Date().getTime()).dateTimeSystem}</Text>
                                            <Text style={{...styles.tableHeaderColTextLeft, ...{borderBottomWidth: 1,
                                                    borderBottomColor: "#d2d2d2", paddingBottom: 5, marginBottom: 5}}}>3. Opis niezgodności towaru z umową:</Text>
                                            <Text style={styles.tableHeaderColTextLeft}>{revertItem.revertAdditionalInfo}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "50%"}}}>
                                            {this.props.complaintReasons.map((reason, index) => {
                                                return (
                                                    <View>
                                                        <Text key={index} style={{...styles.tableHeaderColTextLeft, ...{
                                                                textDecoration: revertItem.complaintForm === reason.value ? "none" : "line-through"
                                                            }}}>{reason.value}</Text>
                                                    </View>
                                                )
                                            })}

                                            <View>
                                                <Text style={styles.intableLittleInfo}>* możliwe tylko gdy</Text>
                                                <Text style={styles.intableLittleInfo}>- naprawa lub wymiana są niemożliwe lub wymagają nadmiernych kosztów,</Text>
                                                <Text style={styles.intableLittleInfo}>- sprzedawca nie wymienił rzeczy na nowa albo rzeczy nie naprawił w odpowiednimczasie,</Text>
                                                <Text style={styles.intableLittleInfo}>- wymiana lub naprawa narażałaby reklamującego na znaczne niedogodnośc</Text>
                                                <Text style={styles.intableLittleInfo}>** gdy wada lub usterki danej części lub jej brak nie wpływa istotnie na całośćużytkowa zakupionego produktu</Text>
                                            </View>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>

                        {temparray.length < 2 &&
                            this.bottomPageContent()
                        }
                    </Page>
                ))
            }
        }

        if (temparray.length < 2) {
            lastPagePushed = true
        }

        if (!lastPagePushed) {
            pages.push(
                <Page style={styles.page}>
                    {this.bottomPageContent()}
                </Page>
            )
        }

        return pages
    }

    bottomPageContent() {
        return (
            <React.Fragment>
                <View style={{
                    marginTop: 10
                }}>
                    <Text style={{
                        fontSize: 10,
                        textAlign: "center",
                        fontWeight: 100
                    }}>W przypadku braku możliwości dokonania naprawy lub wymiany, zwrot nastąpi tym samym kanałem płatności. Dla płatnościprzelewem lub za pobraniem proszę podać nazwę banku oraz nr konta bankowego, na który ma nastąpić przelew wartościzwracanych produktów:</Text>

                    <View style={{
                        marginTop: 10
                    }}>
                        <Text
                            style={{
                                fontSize: 10
                            }}
                        >Nazwa banku:</Text>
                        <Text
                            style={{
                                fontSize: 10
                            }}
                        >{this.props.data.revertCustomerData && this.props.data.revertCustomerData.revertBankName && this.props.data.revertCustomerData.revertBankName.length ? this.props.data.revertCustomerData.revertBankName : "---"}</Text>
                    </View>

                    <View style={{
                        marginTop: 10
                    }}>
                        <Text
                            style={{
                                fontSize: 10
                            }}
                        >Numer konta (26 cyfr)</Text>
                        <Text
                            style={{
                                fontSize: 10
                            }}
                        >{this.props.data.revertCustomerData && this.props.data.revertCustomerData.revertBankAccount && this.props.data.revertCustomerData.revertBankAccount.length ? this.props.data.revertCustomerData.revertBankAccount : "---"}</Text>
                    </View>
                </View>

                <View style={{
                    marginTop: 50,
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    flexDirection: "row"
                }}>
                    <View>
                        <Text
                            style={{
                                fontSize: 10
                            }}
                        >Adres do odesłania towaru:</Text>

                        <View style={{
                            marginTop: 10
                        }}>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: "#ff2f19"
                                }}
                            > </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: "#ff2f19"
                                }}
                            > </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: "#ff2f19"
                                }}
                            > </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: "#ff2f19"
                                }}
                            > </Text>
                        </View>
                    </View>

                    <View>
                        <View>
                            <Text>................................</Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                }}
                            >podpis konsumenta</Text>
                        </View>
                    </View>
                </View>
            </React.Fragment>
        )
    }

    firstPage(data, temparray) {
        return (
            <Page style={styles.page}>
                <View style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Image
                        src={require("../../resources/esologo.png")}
                        style={{
                            width: 150,
                            margin: "0 auto"
                        }}
                    />
                </View>

                <View style={{
                    marginTop: 10
                }}>
                    <Text style={{
                        fontSize: 13,
                        textAlign: "center"
                    }}>FORMULARZ REKLAMACJI</Text>
                </View>

                <View style={{
                    marginTop: 10,
                    justifyContent: "space-between",
                    flexDirection: "row"
                }}>
                    <View>
                        <View style={styles.infoBox}>
                            <Text style={styles.infoLabel}>Imię:</Text>
                            <Text style={styles.infoValue}>{data.orderData.data.customerName}</Text>
                        </View>

                        <View style={styles.infoBox}>
                            <Text style={styles.infoLabel}>Nazwisko:</Text>
                            <Text style={styles.infoValue}>{data.orderData.data.customerLastname}</Text>
                        </View>

                        <View style={styles.infoBox}>
                            <Text style={styles.infoLabel}>Data zakupu:</Text>
                            <Text style={styles.infoValue}>{data.orderData.data.createdAt}</Text>
                        </View>

                        <View style={styles.infoBox}>
                            <Text style={styles.infoLabel}>Nr zamówienia:</Text>
                            <Text style={styles.infoValue}>{data.orderData.orderId}</Text>
                        </View>
                    </View>

                    <View>
                        <View style={styles.infoBox}>
                            <Text style={styles.infoLabel}>Kod pocztowy:</Text>
                            <Text style={styles.infoValue}>{data.orderData.data.addressBilling.postcode}</Text>
                        </View>

                        <View style={styles.infoBox}>
                            <Text style={styles.infoLabel}>Miasto:</Text>
                            <Text style={styles.infoValue}>{data.orderData.data.addressBilling.city}</Text>
                        </View>

                        <View style={styles.infoBox}>
                            <Text style={styles.infoLabel}>Ulica:</Text>
                            <Text style={styles.infoValue}>{data.orderData.data.addressBilling.street}</Text>
                        </View>

                        <View style={styles.infoBox}>
                            <Text style={styles.infoLabel}>E-mail:</Text>
                            <Text style={styles.infoValue}>{data.orderData.data.customerEmail}</Text>
                        </View>
                    </View>
                </View>

                <View style={{marginTop: 10}}>
                    <Text break style={{
                        fontSize: 10,
                        textAlign: "center",
                        marginBottom: 10,
                        fontWeight: 300
                    }}>Informacje o przedmiocie reklamacji:</Text>

                    <View style={styles.tableBox}>
                        <View style={styles.tableHeader}>
                            <View style={{...styles.tableHeaderColNoWidth, ...{width: "50%"}}}>
                                <Text style={styles.tableHeaderColText}>Informacje</Text>
                            </View>
                            <View style={{...styles.tableHeaderColNoWidth, ...{width: "50%"}}}>
                                <Text style={styles.tableHeaderColText}>Żądanie reklamującego</Text>
                            </View>
                        </View>

                        {temparray.map((revertItem, key) => {
                            if (key > 1) return false
                            return (
                                <View key={key} style={styles.tableBody}>
                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "50%"}}}>
                                        <Text style={{...styles.tableHeaderColTextLeft, ...{borderBottomWidth: 1,
                                                borderBottomColor: "#d2d2d2", paddingBottom: 5, marginBottom: 5}}}>1. Nazwa: {revertItem.name}</Text>
                                        <Text style={{...styles.tableHeaderColTextLeft, ...{borderBottomWidth: 1,
                                                borderBottomColor: "#d2d2d2", paddingBottom: 5, marginBottom: 5}}}>2. Data stwierdzenia wady: {formatDate(data.meta ? data.meta.created : new Date().getTime()).dateTimeSystem}</Text>
                                        <Text style={{...styles.tableHeaderColTextLeft, ...{borderBottomWidth: 1,
                                                borderBottomColor: "#d2d2d2", paddingBottom: 5, marginBottom: 5}}}>3. Opis niezgodności towaru z umową:</Text>
                                        <Text style={styles.tableHeaderColTextLeft}>{revertItem.revertAdditionalInfo}</Text>
                                    </View>
                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "50%"}}}>
                                        {this.props.complaintReasons.map((reason, index) => {
                                            return (
                                                <View>
                                                    <Text key={index} style={{...styles.tableHeaderColTextLeft, ...{
                                                            textDecoration: revertItem.complaintForm === reason.value ? "none" : "line-through"
                                                        }}}>{reason.value}</Text>
                                                </View>
                                            )
                                        })}

                                        <View>
                                            <Text style={styles.intableLittleInfo}>* możliwe tylko gdy</Text>
                                            <Text style={styles.intableLittleInfo}>- naprawa lub wymiana są niemożliwe lub wymagają nadmiernych kosztów,</Text>
                                            <Text style={styles.intableLittleInfo}>- sprzedawca nie wymienił rzeczy na nowa albo rzeczy nie naprawił w odpowiednimczasie,</Text>
                                            <Text style={styles.intableLittleInfo}>- wymiana lub naprawa narażałaby reklamującego na znaczne niedogodnośc</Text>
                                            <Text style={styles.intableLittleInfo}>** gdy wada lub usterki danej części lub jej brak nie wpływa istotnie na całośćużytkowa zakupionego produktu</Text>
                                        </View>
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                </View>

                {temparray.length < 2 &&
                this.bottomPageContent()
                }
            </Page>
        )
    }

    render() {
        const {data} = this.props

        if (data.orderData && !data.orderData.data) {
            data.orderData.data = data.orderData;
        }

        return (
            <Document>
                {data.orderData && data.orderData.data &&
                    <React.Fragment>
                        {this.renderPages(data)}
                    </React.Fragment>
                }
            </Document>
        );
    }
}
