import React from 'react'
import { Button, Modal } from 'semantic-ui-react'


export default class RemoveConfirm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        open: false
    }
  }

  closeModal = () => {
    this.setState({open: false})
  }

  approved = () => {
    this.props.onClick();
    this.closeModal();
  }

  rejected = () => {
    this.closeModal();
  }

  render() {
    const { open } = this.state;
    const { id } = this.props;

    return (
        <Modal
        onClose={() => this.setState({open: false})}
        onOpen={() => this.setState({open: true})}
        open={open}
        trigger={<Button color='red'>{ 'Usuń' }</Button>}
      >
        <Modal.Header>Element o id: {id} zostanie usunięty</Modal.Header>
        <Modal.Content>
          <p>Czy napewno chcesz usunąć ten record</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.closeModal}>
            Nie
          </Button>
          <Button positive onClick={this.approved}>
            Tak
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
