import React from "react"
import {Icon} from 'semantic-ui-react'
import {Menu} from "semantic-ui-react/dist/commonjs/collections/Menu";
import CsvImport from "../../components/CsvImport";
import {AccessController, TranslationController} from "../../controller/menager";
import Button from "../../components/Button";
import { withRouter } from "react-router-dom";

export let closeDropdown = () => true;

class MenuButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            importState: false,
            visible: false,
            isOpen: false
        }
    }

    async componentDidMount() {
        let actionAllowed = true
        const buttonLabel = this.props.children || this.props.content || this.props.label

        if (!this.props.hideInAccessMap) {
            AccessController.setActionPoint(this.props.match, buttonLabel)

            actionAllowed = await AccessController.checkActionAccess(this.props.match, buttonLabel)
        }

        if (!buttonLabel) {
            actionAllowed = true
        }

        if (actionAllowed) {
            this.setState({
                visible: true
            })
        }
    }

    render() {
        return (
            this.state.visible ? <div onClick={() => {
                if (this.props.options) {
                    closeDropdown = () => {
                        this.setState({
                            isOpen: false
                        });

                        const element = document.querySelector("#table-filter-layer");

                        if (element) {
                            document.querySelector("#table-filter-layer").classList.remove("table-on-hover")
                        }
                    }

                    const element = document.querySelector("#table-filter-layer");

                    if (this.state.isOpen) {
                        if (element) {
                            document.querySelector("#table-filter-layer").classList.remove("table-on-hover")
                        }
                    } else {
                        if (element) {
                            document.querySelector("#table-filter-layer").classList.add("table-on-hover")
                        }
                    }

                    this.setState({
                        isOpen: !this.state.isOpen
                    })

                    return;
                }

                if (this.props.csvImport) {
                    if (!this.state.importState) {
                        this.setState({
                            importState: true
                        })
                    }
                } else {
                    return this.props.onClick ? this.props.onClick() : false
                }
            }} className={"order-menu-button"}>
                {this.props.options
                    ?
                    <div className={"order-menu-button-label-wrapper"} style={this.props.style}>
                        {this.props.icon &&
                        <div className={"order-menu-button-icon"}>
                            <Icon name={this.props.icon}/>
                        </div>
                        }

                        {this.props.label &&
                        <div className={"order-menu-button-label"}>
                            {TranslationController.translate(this.props.label)}
                        </div>
                        }

                        <div style={{
                            left: 3,
                            top: 1,
                            position: "relative"
                        }} className={"order-menu-button-icon"}>
                            <Icon name={"chevron down"}/>
                        </div>

                        {this.state.isOpen &&
                            <div
                                style={{
                                    height: (this.props.optionsHeight || 30) * this.props.options.length,
                                    bottom: -((this.props.optionsHeight || 30) * this.props.options.length)
                                }}
                                className={"order-menu-options"}
                            >
                                {this.props.options.map((e, k) => {
                                    if(e.hidden){
                                        return null
                                    }
                                    return (
                                        <div
                                            className={"order-menu-options-item"}
                                            key={k}
                                            style={{height: (this.props.optionsHeight || 30)}}
                                            onClick={() => {
                                                e.onClick();

                                                closeDropdown();

                                                this.setState({
                                                    isOpen: false
                                                })
                                            }}
                                        >
                                            <Icon style={{
                                                position: "relative",
                                                top: -2,
                                                color: "#b4b4b4"
                                            }} name={"chevron right"}/>
                                            {e.label}
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                    :
                    <div className={"order-menu-button-label-wrapper"}>
                        {this.props.icon &&
                        <div className={"order-menu-button-icon"}>
                            <Icon name={this.props.icon}/>
                        </div>
                        }

                        {this.props.label &&
                        <div className={"order-menu-button-label"}>
                            {TranslationController.translate(this.props.label)}
                        </div>
                        }
                    </div>
                }

                {this.props.csvImport &&
                    <div>
                        {this.state.importState &&
                            <div className={"action-panel-csv-import-handler"}>
                                <CsvImport
                                    csvStructure={this.props.csvStructure}
                                    onUpdate={(data) => {
                                        return this.props.csvImportOnUpdate(data)
                                    }}
                                />
                                <Button
                                    style={{
                                        marginTop: 10
                                    }}
                                    negative={true}
                                    onClick={() => {
                                        this.setState({
                                            importState: false
                                        })
                                    }}
                                >{TranslationController.translate("Zamknij")}</Button>
                            </div>
                        }
                    </div>
                }
            </div> : null
        );
    }
}

export default withRouter(MenuButton)
