import React from "react"
import EsotiqInput from "./promotionCreator";

export default class PromotionCreatorBoxRow extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className={"promotion-box-row"}>
                <div className={"promotion-label-handler"}>
                    <div className={"promotion-label"}>
                        {this.props.label.length ? this.props.label + ":" : ""}
                    </div>
                    {this.props.desc &&
                        <div className={"promotion-desc"}>
                            ({this.props.desc})
                        </div>
                    }

                </div>

                <div className={"promotion-content-wrapper"}>
                    <div className={"promotion-content-handler"}>
                        {this.props.content()}
                    </div>
                </div>
            </div>
        );
    }
}
