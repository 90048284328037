import React from "react";
import PaperComponent from "../../../components/Paper";
import {api} from "../../../lib/axios";
import UITable from "../../../lib/table-v2";
import SnackBar from "../../../components/Snack";
import {Loader} from "semantic-ui-react";
import {TranslationController} from "../../../controller/menager";
import {formatDate} from "../../../helpers/dateHelper";

export default class ReservationHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reservationHistory: [],
            isFetchingData: true
        }

        this.reservationStateNamesMap = {
            "READY_TO_BE_INVOICED": "Gotowy",
            "RESERVED": "Zarezerwowany",
            "WAITING_FOR_PRODUCT_IN_INVOICING_WAREHOUSES": "Oczekuje"
        }

        this.reservationStateColorsMap = {
            "READY_TO_BE_INVOICED": "#00af0b",
            "RESERVED": "#ffde00",
            "WAITING_FOR_PRODUCT_IN_INVOICING_WAREHOUSES": "#f50000"
        }
    }

    componentDidMount() {
        this.getReservationHistory();
    }

    getReservationHistory() {
        return api().get(`/api/orders/${this.props.orderData.id}/reservationHistory`)
            .then(r => {
                if (r.data.errorMessage && r.data.errorMessage.length > 1) {
                    SnackBar(r.data.errorMessage, "error");
                    this.setState({
                        isFetchingData: false
                    });
                    return;
                }

                this.setState({
                    reservationHistory: r.data.reservations,
                    isFetchingData: false
                });
            })
    }

    render() {
        return (
            <PaperComponent>
                <div>
                    {this.state.isFetchingData
                        ?
                        <div style={{
                            position: "relative",
                            top: 250
                        }}><Loader active></Loader></div>
                        :
                        <UITable
                            data={this.state.reservationHistory}
                            provider={this}
                            columns={[
                                {
                                    label: "Wersja",
                                    field: "version",
                                    center: true,
                                    width: 1
                                },
                                {
                                    label: "Magazyn",
                                    field: "warehouseId",
                                    center: true,
                                    width: 1
                                },
                                {
                                    label: "SKU",
                                    field: "productSku",
                                    center: true,
                                    width: 1
                                },
                                {
                                    label: "Ilość",
                                    field: "quantity",
                                    center: true,
                                    width: 1
                                },
                                {
                                    label: "Status",
                                    field: "state",
                                    center: true,
                                    width: 1,
                                    render: row => <div style={{
                                        color: this.reservationStateColorsMap[row.state],
                                        fontSize: "0.9em",
                                        fontWeight: 600,
                                        textTransform: "uppercase"
                                    }}>
                                        {(this.reservationStateNamesMap[row.state] ?? row.state)}
                                    </div>
                                },
                                {
                                    label: "Aktywny",
                                    field: "active",
                                    center: true,
                                    width: 1,
                                    bool: true
                                },
                                {
                                    label: "Data",
                                    field: "createdAt",
                                    center: true,
                                    width: 1,
                                    render: row => formatDate(row.createdAt).dateTimeSystem
                                }
                            ]}
                        />
                    }
                </div>
            </PaperComponent>
        )
    }
}