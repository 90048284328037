import React, { Component } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Breadcrumb from "../../components/Breadcrumb";
import Container from "../../components/Container";
import PaperTitle from "../../components/PaperTitle";
import EsotiqInput from "../../components/EsotiqInput";
import {TranslationController} from "../../controller/menager";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import pl from "date-fns/locale/en-GB";
import Button from "../../components/Button";
import {formatDate} from "../../helpers/dateHelper";
import {api} from "../../lib/axios";
import {DATA_OBJECT} from "../../controller/dataController";
import SnackBar from "../../components/Snack";

const category = {
    bra: [
        { text: 'wychodząca fiszbina', value: 'wychodząca fiszbina' },
        { text: 'pęknieta fiszbina', value: 'pęknieta fiszbina' },
        { text: 'odkształcenie miski', value: 'odkształcenie miski' },
        { text: 'urwane ramiączko', value: 'urwane ramiączko' },
        { text: 'urwany zaczep od ramiączka przy misce', value: 'urwany zaczep od ramiączka przy misce' },
        { text: 'dziurki na szwach', value: 'dziurki na szwach' },
        { text: 'odprucie', value: 'odprucie' },
        { text: 'dyskomfort w noszeniu', value: 'dyskomfort w noszeniu' },
        { text: 'ocierające ciało fiszbiny,przebicie/rozdarcie', value: 'ocierające ciało fiszbiny,przebicie/rozdarcie' },
        { text: 'ogólne zniszczenie', value: 'ogólne zniszczenie'},
        { text: 'źle dobrany rozmiar', value: 'źle dobrany rozmiar' },
        { text: 'zmechacenie', value: 'zmechacenie' },
        { text: 'odbarwienie', value: 'odbarwienie' },
        { text: 'plamy', value: 'plamy' },
        { text: 'rozciągnięcie', value: 'rozciągnięcie' },
        { text: 'inne', value: 'inne' },
        ],
    panties:[
        { text: 'rozpruta koronka', value: 'rozpruta koronka' },
        { text: 'zmechacenie', value: 'zmechacenie' },
        { text: 'odbarwienie', value: 'odbarwienie' },
        { text: 'plamy', value: 'plamy' },
        { text: 'inne', value: 'inne' },
        ],
    clothes: [
        { text: 'dziury', value: 'dziury' },
        { text: 'plamy', value: 'plamy' },
        { text: 'odbarwienia', value: 'odbarwienia' },
        { text: 'przetarcie', value: 'przetarcie' },
        { text: 'rozciągnięcie', value: 'rozciągnięcie' },
        { text: 'rozejście szwów', value: 'rozejście szwów' },
        { text: 'wyrwanie zatrzasku', value: 'wyrwanie zatrzasku' },
        { text: 'zmechacenie', value: 'zmechacenie' },
        { text: 'spranie nadruku', value: 'spranie nadruku' },
        { text: 'inne', value: 'inne' },
    ],
    beachwear: [
        { text: 'dziury', value: 'dziury' },
        { text: 'odbarwienia', value: 'odbarwienia' },
        { text: 'pozaciągany materiał', value: 'pozaciągany materiał' },
        { text: 'spranie nadruku', value: 'spranie nadruku' },
        { text: 'farbowanie', value: 'farbowanie' },
        { text: 'zmechacenie', value: 'zmechacenie' },
        { text: 'inne', value: 'inne' },
    ]

}


const mainCategory = [
    {text: "kostiumy plażowe", value: "beachwear"},
    {text: "piżamy/odzież", value: "clothes"},
    {text: "Figi/Stringi", value: "panties"},
    {text: "Biustonosz", value: "bra"},
]



class AddComplaint extends Component {
    constructor(props) {
        super(props);

        this.state = {
            frontId: "",
            id: null,
            receiptNumber: "",
            price: 0,
            index: "",
            status: "complaintParlourNew",
            date: "",
            description: "",
            categoryMain: "",
            categoryMainIsSet: false,
            subCategory: "",
            demands: "",
            address: "",
            city: "",
            postCode: "",
            names: "",
            prefContact: "",
            email: "",
            phoneNumber: "",
            indexData: [],
            indexOptions: [],
            parlourData: [],
            parloursData: [],
            userParlourId: "",
            rks: null,
            rksMail: null,
            historyDefaultValue: "Utworzono zgłoszenie",
            idString: '',

        }
    }

    componentDidMount() {
        this.init();
        this.setState({ userParlourId: DATA_OBJECT['user'].parlour !== 'root' ?  DATA_OBJECT['user'].parlour : "K48" })
    }

    async init() {
        await  api().post("/api/products/getEan", {})
                .then(r => {
                    this.setState({indexData: r.data.data})
                    this.state.indexData.map(row => {
                        this.state.indexOptions.push({ text: row.sku, value: row.sku })
                    })
                });
        await api().post('/api/parlours', {})
                .then(r => this.setState({parloursData: r.data.data}));
        await this.getParlourData();
    }

    getParlourData() {
        const arr = this.state.parloursData.filter(parlour => {
            return parlour.id === this.state.userParlourId
        })
        this.setState({
            parlourData: arr[0]
        })
    }

    toggleCategoryMainIsSet() {
        this.state.categoryMain !== "" ? this.setState({ categoryMainIsSet: true}) : this.setState({ categoryMainIsSet: false})
    }

    inputValidation() {
        if(this.state.receiptNumber === null || this.state.receiptNumber === "") return false;
        if(this.state.price === null || this.state.price === "") return false;
        if(this.state.description === null || this.state.description === "") return false;
        if(this.state.index === null || this.state.index === "") return false;
        if(this.state.categoryMain === null || this.state.categoryMain === "") return false;
        if(this.state.subCategory === null || this.state.subCategory === "") return false;
        if(this.state.demands === null || this.state.demands === "") return false;
        if(this.state.names === null || this.state.names === "") return false;
        if(this.state.phoneNumber === null || this.state.phoneNumber === "") return false;
        if(this.state.email === null || this.state.email === "") return false;
        if(this.state.address === null || this.state.address === "") return false;
        if(this.state.city===null || this.state.city === "") return false;
        if(this.state.postCode === null || this.state.postCode === "") return false;
        if(this.state.date === null || this.state.date === "") return false;
        if(new Date(this.state.date).getTime() > new Date().getTime()) return false;


        return true
    }

    async addNewComplaint() {
        const date = new Date();
        const nowDate = new Date().getTime();
        const purchaseDate = new Date(this.state.date).getTime();
        const resolutionDate = date.setDate(date.getDate() + 14);
        const resolutionDateTimestamp = new Date(resolutionDate).getTime();

        const validResult =  this.inputValidation();
        if(validResult) {
             api(undefined, "http://localhost:50005").post('/complaints/addComplaint', {
                    data: {
                        complaintData: {
                            receiptNumber: this.state.receiptNumber,
                            complaintDate: nowDate,
                            resolutionDate: resolutionDateTimestamp,
                            purchaseDate: purchaseDate,
                            price: this.state.price,
                            description: this.state.description,
                            index: this.state.index,
                            mainCategory: this.state.categoryMain,
                            subCategory: this.state.subCategory,
                            demands: this.state.demands,
                            appeal: 0,
                            status: {
                                mainStatus: this.state.status,
                            }
                        },
                        clientData: {
                            names: this.state.names,
                            phoneNumber: this.state.phoneNumber,
                            email: this.state.email,
                            address: this.state.address,
                            city: this.state.city,
                            postCode: this.state.postCode,
                        },
                        parlourData: {
                            parlourId: this.state.userParlourId,
                            rks: this.state.parlourData.rks,
                            rksMail: this.state.parlourData.rksMail,
                            street: this.state.parlourData.street,
                            city: this.state.parlourData.city
                        },
                        history: [
                            {
                                value: this.state.historyDefaultValue,
                                timestamp: nowDate,
                                subValue: DATA_OBJECT['user'].login
                            },
                            {
                                value: `Wysłano informację do ${this.state.parlourData.rks} [${this.state.parlourData.rksMail}]`,
                                timestamp: nowDate,
                                subValue: DATA_OBJECT['user'].login
                            }
                        ],

                    }
                }
            )
                .then(r => {
                        api(undefined, undefined, undefined, true).post("/complaints/sendMail",
                            {
                                complaintId: r.data.data,
                                rksMail: "pbrzezinski@esotiq.com",//this.state.parlourData.rksMail,
                                appeal: false
                            })
                            .then(result => {
                                if (result.data.success) {
                                    SnackBar("Wysłano powiadomienie e-mail");
                                } else {
                                    SnackBar("Błąd podczas wysyłania powiadomienia. Sprawdź konfigurację.", "error");
                                }
                            });
                    window.open(`#/complaints/complaintSummary/${r.data.data.split("/").join("-")}`, '_blank')
                })
        } else {
            SnackBar("Wypełnij wszystkie pola poprawnie!", "error")
        }
    }

    render() {
        return(
            <>
                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Dodaj reklamacje`
                            }
                        ]
                        }
                    />
                </Container>
                <Container>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper>
                                    <PaperTitle title={"Dane reklamacji"}/>
                                       <div className="complaint-row--container">
                                        <div className="complaint-input-container">
                                            <EsotiqInput width="100%" label="Numer paragonu" onChange={(v)=> this.state.receiptNumber = v }/>
                                        </div>
                                        <div style={{width: "20%", marginLeft: "5%"}}>
                                            <span>{TranslationController.translate("Data zakupu")}</span>
                                            <div style={{width: "100"}}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    format="dd-MM-yyyy"
                                                    value={this.state.date || null}
                                                    onChange={(date) => {
                                                        const dateValue = formatDate(date).dateNowSystem + "T00:00:00";

                                                        this.setState({
                                                            refresh: true,
                                                            date: dateValue
                                                        })
                                                    }
                                                }
                                                />
                                            </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                       </div>
                                        <div className="complaint-row--container">
                                            <div className="complaint-input-container">
                                                <EsotiqInput width="100%" type="number" label="Cena reklamowanej rzeczy" onChange={(v)=> this.state.price = v }/>
                                            </div>
                                            <div className="complaint-input-container">
                                                <EsotiqInput options={this.state.indexOptions} label="Index" onChange={(v)=> this.state.index = v}/>
                                            </div>
                                        </div>
                                        <div style={{width: "85%", marginLeft: "5%"}}>
                                            <EsotiqInput textArea label="Opis wady" onChange={(v)=> this.state.description = v }/>
                                        </div>
                                        <div className="complaint-row--container">
                                            <div className="complaint-input-container">
                                                <EsotiqInput options={mainCategory} label="Kategoria rzeczy" onChange={(v)=> (this.state.categoryMain = v,  this.toggleCategoryMainIsSet() )}/>
                                            </div>
                                            <div className="complaint-input-container">
                                                <EsotiqInput options={[
                                                    {text: "Nieodpłatna naprawa", value: "Nieodpłatna naprawa"},
                                                    {text: "Wymiana na nowy", value: "Wymiana na nowy"},
                                                    {text: "Zwrot zapłaconej ceny*", value: "Zwrot zapłaconej ceny*"},
                                                    {text: "Obniżenie zapłaconej ceny*", value: "Obniżenie zapłaconej ceny*"}
                                                    ]
                                                }
                                                             label="Żądania klienta"
                                                             onChange={(v)=> (this.state.demands =v, this.forceUpdate() ) }/>
                                                {(this.state.demands === "Zwrot zapłaconej ceny*" || this.state.demands === "Obniżenie zapłaconej ceny*") ? <div>* tylko w gdy naprawa albo wymiana nie są możliwe</div> : "" }
                                            </div>
                                        </div>
                                    <div className="complaint-row--container">
                                        {this.state.categoryMainIsSet &&
                                            <div className="complaint-input-container">
                                                <EsotiqInput options={category[this.state.categoryMain]} label="Kategoria wady"
                                                             onChange={(v) => (this.state.subCategory = v, console.log(v))}/>
                                            </div>
                                        }
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                </Container>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <PaperTitle title={"Dane klienta"}  />
                                <div className="complaint-row--container">
                                    <div className="complaint-input-container">
                                        <EsotiqInput width="100%"
                                                     options={[
                                                         {text: "email", value: "email"},
                                                         {text: "telefon", value: "phoneNumber"},
                                                         {text: "poczta", value: "letter"}]}
                                                     label="Preferowany kontakt"
                                                     onChange={(v)=> this.state.prefContact = v }/>
                                    </div>
                                    <div className="complaint-input-container">
                                        <EsotiqInput width="100%" label="Imię i nazwisko" onChange={(v)=> this.state.names = v }/>
                                    </div>
                                </div>
                                <div className="complaint-row--container">
                                    <div className="complaint-input-container">
                                        <EsotiqInput width="100%"  label="Telefon" onChange={(v)=> this.state.phoneNumber = v }/>
                                    </div>
                                    <div className="complaint-input-container">
                                        <EsotiqInput width="100%" label="Email" onChange={(v)=> this.state.email = v }/>
                                    </div>
                                </div>
                                <div className="complaint-row--container">
                                    <div className="complaint-input-container">
                                        <EsotiqInput width="100%" label="Adres" onChange={(v)=> this.state.address = v }/>
                                    </div>
                                    <div className="complaint-input-container">
                                        <EsotiqInput width="100%" label="Miasto" onChange={(v)=> this.state.city = v }/>
                                    </div>
                                </div>
                                <div className="complaint-row--container">
                                    <div className="complaint-input-container">
                                        <EsotiqInput width="100%" label="Kod pocztowy" onChange={(v)=> this.state.postCode = v }/>
                                    </div>
                                </div>
                                <div className="button-wrapper">
                                    <Button primary onClick={()=> (this.addNewComplaint())}>Dodaj</Button>
                                    <Button onClick={() => window.open("#/complaints", "_self")}>Anuluj</Button>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </>
        )
    }
}

export default AddComplaint;