import React from "react"
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import {DATA_OBJECT} from "../../controller/dataController";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    rootBar: {
        minHeight: "55px!important",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: "#000000!important",
        color: "#f2f2f2!important",
        maxHeight: "55px!important",
        boxShadow: "none"
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: "36px!important",
        background: "#0078d7!important"
    },
    menuIcon: {
        fontSize: "0.8em!important"
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(1) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
    },
}));

export const InitialScene = () => {
    document.body.classList.add("logo-shink")

    const classes = useStyles();

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: false,
            })}
        >
            <Toolbar className={classes.rootBar}>
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={() => true}
                    edge="start"
                    className={clsx(classes.menuButton, false && classes.hide)}
                >
                    <MenuIcon className={classes.menuIcon} />
                </IconButton>
                <Typography variant="h6" noWrap>
                    Esotiq
                </Typography>
            </Toolbar>

            <div className={"app-drawer-user"}>
                <div className={"app-version"}>
                    <div>{process.env.REACT_APP_NAME} v.{process.env.REACT_APP_VERSION}</div>
                </div>
                <div className={"app-drawer-login"}>
                    <Tooltip title={DATA_OBJECT.user_login}>
                        <Icon>account_circle</Icon>
                    </Tooltip>
                </div>
                <div className={"app-drawer-logout"}
                     onClick={() => DATA_OBJECT.logout()}
                >
                    <Tooltip title="Wylouj się">
                        <Icon>exit_to_app</Icon>
                    </Tooltip>
                </div>
            </div>

            <div style={{
                position: "absolute",
                right: 0,
                left: 0,
                top: "45vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "9999"
            }} className={"app-loading"}>
                        <span style={{
                            position: "relative",
                            color: "#acacac",
                            fontWeight: 100
                        }}>Dashboard initialization... Please wait...</span>

            </div>
        </AppBar>

    )
}
