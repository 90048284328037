import React from "react"
import {TranslationController} from "../../controller/menager";

function OrderStatus(props) {
    return (
        <div style={{
            padding: "0 5px",
        }} className={"es-order-satus-display"}>
            <span style={{
                fontSize: "0.9em",
                fontWeight: 600,
            }} className={"label"}>{TranslationController.translate(props.status)}</span>
            <span style={{
                background: props.bg,
                color: props.font
            }} className={"value"}>{TranslationController.translate(props.label) || "---"}</span>
        </div>
    )
}

export default OrderStatus
