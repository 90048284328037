import {notificationClient} from "../../lib/axios";
import {DATA_OBJECT} from "../dataController";
import SnackBar from "../../components/Snack";

export function notificationcontroller() {
    this.get = () => {
        notificationClient
            .post("/api/notification/get", {user: DATA_OBJECT["user"]})
            .then(result => {
                if (result && result.data.data) {
                    const priorityMap = {
                        "high": "error",
                        "mid": "warning",
                        "low": "info"
                    }

                    const priorityTextMap = {
                        "high": "Ważna informacja:",
                        "mid": "Ważna informacja:",
                        "low": "Informacja:"
                    }

                    SnackBar(
                        `${priorityTextMap[result.data.data.priority]} ${result.data.data.text}`,
                        priorityMap[result.data.data.priority],
                        null,
                        null,
                        true
                    )
                }
            })
    }
}
