import React from "react";
import {Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Font, Image} from '@react-pdf/renderer';
import {formatDate} from "../../helpers/dateHelper";

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: "40px 20px",
        fontFamily: 'OpenSans'
    },
    section: {
        margin: 10,
        padding: 10,
    },
    title: {
        textAlign: "center",
        fontSize: 14,
        marginTop: 40,
        marginBottom: 40,
        fontFamily: 'OpenSansBold'
    },
    subinfo: {
        fontSize: 10,
        textAlign: "right",
        marginTop: 5,
        fontFamily: 'OpenSansLight'
    },
    subTitle: {
        fontSize: 14,
        fontWeight: 700,
        marginTop: 40,
        marginBottom: 10
    },
    bigBox: {
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderColor: "#000",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    columnAllign: {
        flexDirection: "row",
        justifyContent: "space-between"
    },
    columnField: {
        fontWeight: 700,
        fontSize: 11,
        width: 150
    },
    columnInput: {
        fontSize: 11,
    },
    tableBox: {},
    tableHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableHeaderCol: {
        padding: 6,
        flex: 1,
    },
    tableHeaderColNoWidth: {
        padding: 3,
    },
    tableHeaderColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableBody: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableBodyCol: {
        padding: 3,
        flex: 1,
    },
    tableBodyColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableHeaderColTextLeft: {
        fontSize: 10,
    },
    tableBodyColTextLittle: {
        fontSize: 10,
        color: "#898989"
    },
    tableBodyColNoWidth: {
        padding: 3,

    },
    tableBoxEnd: {
        width: "50%",
        marginTop: 30
    },
    tableBodyColCustom: {
        padding: 3,
        flex: 1,
        height: 80
    },
    bottomBox: {
        flex: 1,
        justifyContent: "flex-end",
        width: "100%",
        padding: 3
    },
    bottomBoxText: {
        fontSize: 10,
        textAlign: "right"
    },
    infoBox: {
        width: 200,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    infoLabel: {
        fontSize: 10
    },
    infoValue: {
        fontSize: 10
    },
    infoDate: {
        fontSize: 10,

    }
});


export default class PDFcomplaintParlour extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }


    render(){
        const { data } = this.props;
    return(
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Image
                        src={require("../../resources/esologo.png")}
                        style={{
                            width: 150,
                            margin: "0 auto"
                        }}
                    />
                </View>
                <View style={{
                    marginTop: 10
                }}>
                    <Text style={{
                        fontSize: 13,
                        textAlign: "center"
                    }}></Text>
                </View>

                <View style={{
                    marginTop: 10,
                    justifyContent: "space-between",
                    flexDirection: "row"
                }}>
                    <View>
                        <View style={styles.infoBox}>
                            <Text style={styles.infoLabel}>Salon Esotiq</Text>
                        </View>
                        <View style={styles.infoBox}>
                            <Text style={styles.infoLabel}>Sklep internetowy</Text>
                        </View>
                        <View style={styles.infoBox}>
                            <Text style={styles.infoLabel}>{data.parlourData.city}</Text>
                        </View>
                    </View>
                    <View>
                        <View style={styles.infoBox}>
                            <Text style={styles.infoDate}></Text>
                            <Text style={styles.infoDate}>{formatDate(data.complaintData.complaintDate).dateNow}</Text>
                        </View>
                    </View>
                </View>
                <View style={{marginTop: 10}}>
                    <Text style={{
                        fontSize: 10,
                    }}
                    ></Text>
                </View>
                <View style={{marginTop: 10}}>
                    <Text style={{
                        fontSize: 10,
                    }}
                    > </Text>
                </View>
                <View style={{
                    marginTop: 10
                }}>
                    <Text style={{
                        fontSize: 14,
                        textAlign: "center",
                        fontWeight: "bold"
                    }}>{data.complaintData.appeal  ? `Odwołanie od decyzji ` : `Zgłoszenie reklamacyjne ` }</Text>
                    <Text style={{
                        fontSize: 12,
                        textAlign: "center",
                    }}> Niezgodność towaru z umową</Text>
                    <Text style={{fontFamily: "OpenSansBold",
                        fontSize: 14,
                        textAlign: "center",}}>{data.complaintId}</Text>
                    <View style={{
                        marginTop: 10
                    }}>

                    </View>

                    <View style={{
                        marginTop: 20
                    }}>
                        <Text
                            style={{
                                fontSize: 10
                            }}
                        >{`Sporządzono w dniu `}<Text style={{fontFamily: "OpenSansBold"}}>{formatDate(data.complaintData.complaintDate).dateNow}</Text>
                            {` w sklepie Esotiq w `}<Text style={{fontFamily: "OpenSansBold"}}>{data.parlourData.city+" "+data.parlourData.street}</Text></Text>
                    </View>
                </View>
                <View style={{marginTop: 10}}>
                    <Text style={{
                        fontSize: 10,
                        marginLeft: 10,
                        marginTop: 10,
                    }}>1. Dane klienta:</Text>
                    <View style={{ flexDirection: "row", justifyContent: "flex-start"}}>
                        <View style={{
                            fontSize: 10,
                            marginLeft: 30,
                        }}>
                            <Text style={{marginTop: 10}}>a. imię i nazwisko: </Text>
                            <Text style={{marginTop: 10}}>b. adres: </Text>
                            <Text style={{marginTop: 10}}>c. numer telefonu: </Text>
                            <Text style={{marginTop: 10}}>d. adres email: </Text>
                        </View>
                        <View style={{
                            fontSize: 10,
                            marginLeft: 30,
                        }}>
                            <Text style={{marginTop: 10, fontFamily: "OpenSansBold"}}> {data.clientData.names}</Text>
                            <Text style={{marginTop: 10, fontFamily: "OpenSansBold"}}> {data.clientData.address}</Text>
                            <Text style={{marginTop: 10, fontFamily: "OpenSansBold"}}> {data.clientData.phoneNumber}</Text>
                            <Text style={{marginTop: 10, fontFamily: "OpenSansBold"}}> {data.clientData.email}</Text>
                        </View>
                    </View>
                    <Text style={{
                        fontSize: 10,
                        marginLeft: 10,
                        marginTop: 10,
                    }}>
                        {`2. Data nabycia towaru: `}<Text style={{fontFamily: "OpenSansBold"}}>{formatDate(data.complaintData.purchaseDate).dateNow}</Text>
                    </Text>
                    <Text style={{
                        fontSize: 10,
                        marginLeft: 10,
                        marginTop: 10,
                    }}>
                        {`3. Nazwa towaru i jego oznaczenie: `} <Text style={{fontFamily: "OpenSansBold"}}>{data.complaintData.index}</Text>
                    </Text>
                    <Text style={{
                        fontSize: 10,
                        marginLeft: 10,
                        marginTop: 10,
                    }}>
                        {`4. Dokument potwierdzający zakup towaru: `} <Text style={{fontFamily: "OpenSansBold"}}>{data.complaintData.receiptNumber}</Text>
                    </Text>
                    <Text style={{
                        fontSize: 10,
                        marginLeft: 10,
                        marginTop: 10,
                    }}>
                        {`5. Cena towaru: `} <Text style={{fontFamily: "OpenSansBold"}}>{data.complaintData.price+" PLN"}</Text>
                    </Text>
                    <Text style={{
                        fontSize: 10,
                        marginLeft: 10,
                        marginTop: 10,
                    }}>
                        {`6. Opis niezgodności towaru: `} <Text style={{fontFamily: "OpenSansBold"}}>{data.complaintData.description}</Text>
                    </Text>
                    <Text style={{
                        fontSize: 10,
                        marginLeft: 10,
                        marginTop: 10,
                    }}>
                        {`7. Sposób rozwiązania reklamacji: `} <Text style={{fontFamily: "OpenSansBold"}}>{data.complaintData.demands}</Text>
                    </Text>
                    <Text style={{
                        fontSize: 10,
                        marginLeft: 10,
                        marginTop: 10,
                    }}>
                        8. <Text> W związku z ustawą z dnia 29 sierpnia 1997 r. o ochronie danych osobowych
                        (Dz. U. z 2002r. Nr 101, poz. 926, z późn. </Text><Text>zm.), wyrażam zgodę na przetwarzanie
                        moich danych osobowych w celu umożliwienia kontaktu ze mną.</Text>
                    </Text>
                    <Text style={{
                        fontSize: 10,
                        marginLeft: 10,
                        marginTop: 10,
                    }}>
                        9. Wyrażam zgodę na kontakt: <Text style={{fontFamily: "OpenSansBold"}}>{data.clientData.email}</Text>
                    </Text>
                </View>


                    <View style={{flexDirection:"row", justifyContent: "space-between", marginTop: 80}}>
                        <View>
                            <Text>................................</Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                }}
                            >podpis klienta</Text>
                        </View>
                        <View>
                            <Text>................................</Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                }}
                            >podpis sprzedawcy</Text>
                        </View>
                    </View>
            </Page>
        </Document>
    )
    }
}