import React from "react"
import {api} from "../../../lib/axios";
import { Confirm, Progress, Loader} from "semantic-ui-react";
import SnackBar from "../../../components/Snack";
import Button from "../../../components/Button";

export default class ProductsStateRefresh extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            confirm: false,
            status: null,
            progressStatus: null
        }
    }

    init() {
        this.setState({confirm: false})

        if (!this.state.status) {
            api().get("/api/products/restart")
                .then(r => {
                    this.setState({
                        status: "Rozpoczynanie..."
                    })

                    console.log(r, "Rararo")

                    SnackBar("Rozpoczęto proces.")

                    setTimeout(() => {
                        return this.check()
                    }, 2000)
                })
        } else {
            return SnackBar("Proces w trakcie.", "error")
        }
    }

    check() {
        if (this.state.progressStatus) {
            if ((this.state.progressStatus.split("/")[0]) >= this.state.progressStatus.split("/")[1]) {
                this.setState({
                    status: "Instalowanie...",
                })
            }
        }

        return api().get("/api/products/restart")
            .then(r => {
                this.setState({
                    status: r.data.status,
                    progressStatus: r.data.downloadStatus
                })

                if (r.data.status != "Ukończono pomyślnie. Ponowne użycie dostępne za minutę.")  {
                    setTimeout(() => this.check(), 1000)
                }
            })
    }

    render() {
        return (
            <div>
                {this.state.progressStatus &&
                    <div className={"progress-box"}>
                        <div className={"progres-status"}>
                            <span>Status: <span>{this.state.status}</span></span>
                        </div>
                        <Progress color={((this.state.progressStatus.split("/")[0]) >= this.state.progressStatus.split("/")[1]) ? "green" : "blue"} value={(this.state.progressStatus.split("/")[0] - 1)} total={this.state.progressStatus.split("/")[1]} progress='ratio' />
                    </div>
                }

                {(!this.state.progressStatus && this.state.status) &&
                    <div className={"loader-box"}>
                        <Loader active>Przygotowywanie plików...</Loader>
                    </div>
                }

                {!this.state.status &&
                    <Button onClick={() => this.setState({confirm: true})} primary>Rozpocznij</Button>
                }

                <Confirm
                    dimmer={"inverted"}
                    open={this.state.confirm}
                    content='Czy na pewno rozpocząć odświeżanie produktów?'
                    onCancel={() => this.setState({confirm: false})}
                    onConfirm={() => this.init()}
                />
            </div>
        )
    }
}
