import React from 'react';
import CRUDTable from "./table";
import {controlPanelApi, parlourDetailsApi} from "../../lib/axios";
import {TranslationController} from "../../controller/menager";
import SnackBar from "../Snack";

export default class CRUDView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            tableFields: [],
            tableActions: [],
            itemPerPage: 0,
            totalItems: 0,
            currentPage: 1,
            totalPages: 0,
            filters: {},
            searchColumns: null,
            label: '',
            loading: false,
        }
    }

    componentDidMount() {
        this.getAll();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.filters !== this.state.filters) {
            this.setState({currentPage: 1})
            if(prevState.currentPage === this.state.currentPage) {
                this.getAll();
            }
        }

        if(prevState.currentPage !== this.state.currentPage) {
            this.getAll();
        }
    }

    updateCurrentPage = (currentPage) => {
        if(currentPage !== this.state.currentPage) {
            this.setState({currentPage: currentPage})
        }
    }

    edit(row) {
        parlourDetailsApi.patch(this.resource + '/' + row.id, row).then((response) => {
            SnackBar('Zmieniono rekord.', 'success' )
            this.getAll();
        }).catch((error) => {
            SnackBar(TranslationController.translate(error.response.data.message), 'error')
        })
    }

    delete(row) {
        parlourDetailsApi.delete(this.resource + '/' + row.id).then((response) => {
            this.getAll();
        })
    }

    create(row) {
        parlourDetailsApi.post(this.resource, row).then((response) => {
            SnackBar('Dodano rekord.', 'success' )
            this.getAll();
        }).catch((error) => {
            SnackBar(TranslationController.translate(error.response.data.message), 'error')
        })
    }

    getParams() {
        let params = {
            page: this.state.currentPage,
        }

        if(this.state.filters) {
            params = {...params, ...this.state.filters}
        }

        return params;
    }

    getAll() {
        parlourDetailsApi.get(this.resource, {params: this.getParams()}).then((response) => {
            this.setState({data: response.data.data})
            this.setState({...response.data.meta})
        })
    }

    search = (search) => {
        this.setState({filters: {...this.state.filters, search: search}})
    }

    clearFilter = (key) => {
        this.setState({filters: {...this.state.filters, [key]: null}})
    }

    render() {
        const { data,  totalPages, currentPage } = this.state;
        return (

            <CRUDTable
                label={TranslationController.translate(this.state.label)}
                tableFields={this.state.tableFields}
                crudActions={this.state.crudActions}
                filters={this.state.filters}
                tableActions={this.state.tableActions}
                schema={this.state.schema}
                removeWarning={this.state.removeWarning}
                uiSchema={this.state.uiSchema}
                onSearch={this.search.bind(this)}
                onClearFilter={this.clearFilter.bind(this)}
                data={data}
                totalPages={totalPages}
                currentPage={currentPage}
                createCallback={this.create.bind(this)}
                editCallback={this.edit.bind(this)}
                deleteCallback={this.delete.bind(this)}
                onPageChange={this.updateCurrentPage.bind(this)}
            />
        )
    }
}
