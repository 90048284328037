import React from "react"
import {Dropdown as SemanticDropdown} from 'semantic-ui-react'
import {AccessController, TranslationController} from "../../controller/menager";
import { withRouter } from "react-router-dom";

const Menu = ({children}) => <SemanticDropdown.Menu>{children}</SemanticDropdown.Menu>;
const Divider = ({children}) => <SemanticDropdown.Divider/>;
const Header = ({children}) => <SemanticDropdown.Header>{TranslationController.translate(children)}</SemanticDropdown.Header>;

class Item extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        }
    }

    async componentDidMount() {
        let actionAllowed = true
        const buttonLabel = this.props.children || this.props.content

        if (!this.props.hideInAccessMap) {
            AccessController.setActionPoint(this.props.match, buttonLabel)

            actionAllowed = await AccessController.checkActionAccess(this.props.match, buttonLabel)
        }

        if (!buttonLabel) {
            actionAllowed = true
        }

        if (actionAllowed) {
            this.setState({
                visible: true
            })
        }
    }

    render() {
        return (
            this.state.visible ? <SemanticDropdown.Item {...this.props}>{TranslationController.translate(this.props.children)}</SemanticDropdown.Item> : null
        );
    }
}

export default class Dropdown extends React.Component {
    static Menu = Menu;
    static Divider = Divider;
    static Header = Header;
    static Item = withRouter(Item);

    render() {
        return (
            <div>
                <SemanticDropdown
                    onMouseEnter={() => {
                        if (this.props.icon === "wrench") {
                            const element = document.querySelector(".order-table-segment");

                            if (element) {
                                document.querySelector(".order-table-segment").classList.add("table-on-hover")
                            }
                        }
                    }}
                    onMouseLeave={() => {
                        if (this.props.icon === "wrench") {
                            const element = document.querySelector(".order-table-segment");

                            if (element) {
                                document.querySelector(".order-table-segment").classList.remove("table-on-hover")
                            }
                        }
                    }}
                    {...this.props}
                >
                    {this.props.children}
                </SemanticDropdown>
            </div>
        );
    }
}
