import React, { Component } from 'react';
import PaperTitle from "../../components/PaperTitle";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DataPresentation from "../../components/DataPresentation";
import {Button as SemanticButton, Comment, Form, Icon, Loader, Menu, Modal, Table} from "semantic-ui-react";
import Confirm from "../../components/Confirm";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import MenuButton from "../../lib/table-v2/menuButton";
import {formatDate} from "../../helpers/dateHelper";
import Button from "../../components/Button";
// import {PDFDownloadLink} from "@react-pdf/renderer";
// import PDFcomplaintParlour from "./PDFcomplaintParlour";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";
import Uploader from "../../components/UploadFile";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {DATA_OBJECT} from "../../controller/dataController";
import EsotiqInput from "../../components/EsotiqInput";
import Gallery from "../../components/Gallery";


const statusMap = {
    defectNew: [
        {status: "defectAccepted",label: "Zaakceptowana"},
    ],
    defectAccepted : [
        {status: "defectRepair", label: "Naprawa"},
        {status: "defectChange", label: "Wymiana"},
        {status: "defectRefund", label: "Zwrot pieniędzy/karta podarunkowa"},
        {status: "defectPriceChange", label: "Zmiana ceny"},
        {status: "defectShift", label: "Przesunięcie na inny magazyn"}
    ],
    defectRepair: [
        {status: "handOverForRepair", label: "Przekazano do naprawy"},
        {status: "handOverFromRepair", label: "Przyjęto z naprawy"},
        {status: "customerNotified", label: "Klient powiadomiony"},
        {status: "complaintEnd", label: "Reklamacja zakończona"}
    ],
    complaintChange: [
        {status: "customerNotified", label: "Klient powiadomiony"},
        {status: "complaintEnd", label: "Reklamacja zakończona"}
    ],
    complaintRefund: [
        {status: "customerNotified", label: "Klient powiadomiony"},
        {status: "complaintEnd", label: "Reklamacja zakończona"}
    ],
    defectPriceChange: [
        {status: "customerNotified", label: "Klient powiadomiony"},
        {status: "complaintEnd", label: "Reklamacja zakończona"}
    ],
    defectShift: [
        {status: "customerNotified", label: "Klient powiadomiony"},
        {status: "complaintEnd", label: "Reklamacja zakończona"}
    ],
}

class DefectDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showStatusModal: false,
            loading: false,
            isEditableState: false,
            changeStatus: null,
            selectedStatus: null,
            selectedSubStatus: null,
            subStatus: null,
            setSubStatus: false,
            commentValue: "",
            statusDescription: "",
            rksMail: "",
            indexData: {},
            indexOptions: [],
            path: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1]
        }
    }

    componentDidMount() {
        api(undefined, undefined, undefined, true).post('/complaints/defects/get', {
            currentPage: 1,
            limit: 25,
            filter:[{
                condition: 'in',
                field: 'defectId',
                Label: 'defectId',
                value:  this.state.path.split("-").join("/"),
            }]
        }).then( r => this.setState({data: r.data.data[0]}))
        this.getIndex();
    }

    getIndex() {
        api().post("/api/products/getEan", {})
            .then(r => {
                this.setState({indexData: r.data.data})
                this.state.indexData.map(row => {
                    this.state.indexOptions.push({text: row.sku, value: row.sku})
                })
            })

    }

    changeStatus() {

        const defect = this.state.data
        let historyValue;
        if(defect.defectData.status.mainStatus === "defectNew" ) {
            if (this.state.data.defectData.status.mainStatus !== "defectAccepted" ) {
                historyValue = "Zmieniono status zgłoszenia z " + new OrderStatusesHelper().getLabel(defect.defectData.status.mainStatus) + " na " + new OrderStatusesHelper().getLabel(this.state.selectedSubStatus) + "."
                defect.defectData.status.subStatus = this.state.selectedSubStatus;
                defect.defectData.status.mainStatus = "defectAccepted";
            }
        }


        if(this.state.statusDescription){
            historyValue += " Wada odrzucona z powodu: " + this.state.statusDescription
        }

        defect.history.push({
            value: historyValue, timestamp: new Date().getTime(), subValue: DATA_OBJECT["user"].login
        })

        api(undefined, undefined, undefined, true).post("/complaints/defects/update", {
                data: {
                    id: defect._id,
                    update: {
                        defectData: defect.defectData,
                        history: defect.history
                    }
                }
            }
        ).then(() =>
                this.setState({
                    changeStatus: null,
                    selectedStatus: null,
                    selectedSubStatus: null,
                }),
            SnackBar("Pomyślnie zaktualizowano.")
        )
    }

    addComment() {
        if (!this.state.commentValue) {
            return SnackBar("Wprowadzona wartość nie może być pusta.", "error")
        }

        if (this.state.commentValue.length < 4) {
            return SnackBar("Wprowadzony tekst jest za krótki.", "error")
        }

        const defect = this.state.data
        if (!defect.comments) {
            defect.comments = []
        }

        defect.comments.push({
            value: this.state.commentValue,
            timestamp: new Date().getTime(),
            user: DATA_OBJECT.user_login,
        })

        api(undefined, undefined, undefined, true).post("/complaints/defects/comments/add", {
            data: {
                id: defect._id,
                update: {
                    comments: defect.comments
                }
            }
        })
            .then(() => {
                this.setState({
                    commentValue: "",
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    removeComment(comment) {
        if (DATA_OBJECT.user_login !== comment.user) {
            return SnackBar("Możesz usuwać tylko własne komenatrze", "error")
        }

        const defect = this.state.data

        defect.comments = defect.comments.filter(comm => comm !== comment)

        api(undefined, undefined, undefined, true).post("/complaints/defects/comments/remove", {
            data: {
                id: defect._id,
                update: {
                    comments: defect.comments
                }
            }
        })
            .then(() => {
                this.setState({
                    refresh: new Date().getTime(),
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    update() {

        api(undefined, undefined, undefined, true).post("/complaints/defects/update", {
            data: {
                id: this.state.data._id,
                update: {
                    defectData: this.state.data.defectData,
                },
            }
        })
            .then(() => {
                this.setState({
                    changeStatus: null,
                    selectedStatus: null,
                    rejectedReason: null
                })
                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    galleryRefresh() {
        api(undefined, undefined, undefined, true).post('/complaints/defects/get', {
            currentPage: 1,
            limit: 25,
            filter:[{
                condition: 'in',
                field: 'defectId',
                Label: 'defectId',
                value:  this.state.path.split("-").join("/"),
            }]
        }).then( r => this.setState({data: r.data.data[0]}))
    }

    changeSteps(step) {
        let historyValue;
        const defect = this.state.data
        if(!defect.defectData.status.steps || defect.defectData.status.steps.length === 0) {
            historyValue = "Zmieniono status zgłoszenia z " + new OrderStatusesHelper().getLabel(defect.defectData.status.subStatus) + " na " + new OrderStatusesHelper().getLabel(step) + "."
            if(!defect.defectData.status.steps) {
                defect.defectData.status.steps = [];
            }
            defect.defectData.status.steps.push(step);
        } else {
            historyValue = "Zmieniono status zgłoszenia z " + new OrderStatusesHelper().getLabel(defect.defectData.status.steps[defect.defectData.status.steps.length-1]) + " na " + new OrderStatusesHelper().getLabel(step) + "."
            if(!defect.defectData.status.steps) {
                defect.defectData.status.steps = [];
            }
            defect.defectData.status.steps.push(step);
        }

        defect.history.push({
            value: historyValue, timestamp: new Date().getTime(), subValue: DATA_OBJECT["user"].login
        })

        api(undefined, undefined, undefined, true).post("/complaints/defects/update", {
                data: {
                    id: defect._id,
                    update: {
                        defectData: defect.defectData,
                        history: defect.history
                    }
                }
            }
        ).then(() =>
                this.setState({
                    changeStatus: null,
                    selectedStatus: null,
                }),
            SnackBar("Pomyślnie zaktualizowano.")
        )

    }


    render() {
        const {data} = this.state;
        return(
            <>
                {data && this.state.data &&
                    <Container>
                        < Breadcrumb
                            crumbs={[
                                {
                                    label: "Podgląd zgłoszenia"
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>

                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Paper>
                                    <PaperTitle title={"Dane podstawowe"}/>
                                    <div style={{display: "flex"}}>
                                        <div>
                                            {!this.state.isEditableState ?
                                                <Button
                                                    style={{
                                                        marginBottom: 5,
                                                        marginRight: 5,
                                                        position: "relative",
                                                        top: -10
                                                    }}
                                                    onClick={() => (this.setState({isEditableState: true}))}
                                                >
                                                    {"Edycja"}
                                                </Button>
                                                :
                                                <>
                                                    <Button
                                                        style={{
                                                            marginBottom: 5,
                                                            marginRight: 5,
                                                            position: "relative",
                                                            top: -10
                                                        }}
                                                        onClick={() => (this.setState({isEditableState: false}), this.update())}
                                                    >
                                                        {"Zapisz"}
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            marginBottom: 5,
                                                            marginRight: 5,
                                                            position: "relative",
                                                            top: -10
                                                        }}
                                                        onClick={() => this.setState({isEditableState: false})}
                                                    >
                                                        {"Anuluj"}
                                                    </Button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <Grid container spacing={2}>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    label={"Id"}
                                                    data={data.defectId}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    label={"Status"}
                                                    data={
                                                        <div style={{display: "flex", flexDirection: "row"}}>
                                                            {this.state.data.defectData.status.mainStatus === "defectNew" &&
                                                                <div className={"es-order-satus-display"}><span
                                                                    className={"value"}
                                                                    style={{
                                                                        background: new OrderStatusesHelper().getColor(data.defectData.status.mainStatus),
                                                                        color: new OrderStatusesHelper().getFontColor(data.defectData.status.mainStatus)
                                                                    }}>
                                                                {new OrderStatusesHelper().getLabel(data.defectData.status.mainStatus)}</span>
                                                                </div>
                                                            }
                                                            {this.state.data.defectData.status.subStatus &&
                                                                <div className={"es-order-satus-display"}><span
                                                                    className={"value"}
                                                                    style={{
                                                                        background: new OrderStatusesHelper().getColor(data.defectData.status.subStatus),
                                                                        color: new OrderStatusesHelper().getFontColor(data.defectData.status.subStatus)
                                                                    }}>
                                                        {new OrderStatusesHelper().getLabel(data.defectData.status.subStatus)}</span>
                                                                </div>}</div>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} style={{position: "relative"}}>
                                                    <Button style={{position: "absolute", top: "25px", right: "30px"}}
                                                            onClick={() => (this.setState({showStatusModal: true}))}>Zmień
                                                        status</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid xs={6}>
                                                <DataPresentation label="Kolejne kroki: " data={
                                                    this.state.data.defectData.status.steps &&
                                                        <div className={"es-order-satus-display"}><span
                                                            className={"value"}
                                                            style={{
                                                                background: new OrderStatusesHelper().getColor(data.defectData.status.steps[data.defectData.status.steps.length-1]),
                                                                color: new OrderStatusesHelper().getFontColor(data.defectData.status.steps[data.defectData.status.steps.length-1])
                                                            }}>
                                                        {new OrderStatusesHelper().getLabel(data.defectData.status.steps[data.defectData.status.steps.length-1])}</span>
                                                        </div>}/>
                                            </Grid>
                                            <Grid xs={6}>
                                                {this.state.data.defectData.status.subStatus &&
                                                    <div className="nextStep-list" style={{width: "100%"}}>
                                                        <div>
                                                            <SemanticButton.Group style={{
                                                                display: "flex", flexDirection: "column", maxWidth:"100%", marginTop: "25px"
                                                            }}>
                                                                {
                                                                    statusMap[data.defectData.status.subStatus]?.map((e, k) => {
                                                                        return (
                                                                            <SemanticButton
                                                                                active={ data.defectData.status.steps ? data.defectData.status.steps[k]=== e.status : ""}
                                                                                key={k}
                                                                                onClick={() => Confirm("Czy na pewno chcesz zmienić status", "", ()=>
                                                                                    this.changeSteps(e.status)
                                                                                )}

                                                                            >{e.label}
                                                                            </SemanticButton>
                                                                        )
                                                                    })
                                                                }
                                                            </SemanticButton.Group>
                                                        </div>
                                                    </div>}
                                            </Grid>
                                        </Grid>

                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    options={this.state.indexOptions}
                                                    onChange={(v) => this.state.data.defectData.index = v}
                                                    label="Index"
                                                    data={data.defectData.index}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    label="Zgłoszono"
                                                    data={formatDate(data.defectData.createdAt).dateNow}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={(v) => this.state.data.defectData.description = v}
                                                    inputType="textArea"
                                                    label="Opis"
                                                    data={data.defectData.description}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    onChange={(v) => this.state.rksMail = v}
                                                    label="RKS"
                                                    data={data.parlourData.rksMail}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Paper>
                                <Paper>
                                    <PaperTitle title={"Dołączone pliki"}/>
                                    {data.images &&
                                        <Gallery images={this.state.data.images } />
                                    }
                                    <PaperTitle title={"Załącz pliki"}/>
                                    <Uploader id={data.defectId} data={data} refresh={()=>(this.galleryRefresh())} url={"/complaints/defects/upload"}/>
                                </Paper>
                            </Grid>

                            <Grid item xs={6}>
                                <Paper>
                                    <PaperTitle title={"Komentarze"}/>
                                    {data.comments ?
                                        <Comment.Group>
                                            {data.comments.map((comment, key) => {
                                                return (
                                                    <Comment key={key}>
                                                        <Comment.Avatar
                                                            src='https://react.semantic-ui.com/images/avatar/small/matt.jpg'/>
                                                        <Comment.Content>
                                                            <Comment.Author as='a'>{comment.user}</Comment.Author>
                                                            <Comment.Metadata>
                                                                <div>{formatDate(comment.timestamp).dateTimeSystem}</div>
                                                            </Comment.Metadata>
                                                            <Comment.Text>{comment.value}</Comment.Text>
                                                            <Comment.Actions>
                                                                <Comment.Action
                                                                    onClick={() => this.removeComment(comment)}
                                                                >Usuń</Comment.Action>
                                                            </Comment.Actions>
                                                        </Comment.Content>
                                                    </Comment>
                                                )
                                            })}
                                        </Comment.Group>
                                        : <div style={{
                                            textAlign: "center",
                                            color: "#c6c6c6",
                                            margin: 20
                                        }}>Jeszcze nikt nie dodał żadnego komentarza</div>
                                    }


                                    <Form
                                        reply
                                        onSubmit={() => {
                                            (this.addComment())
                                        }}
                                    >
                                        <Form.TextArea
                                            placeholder={"Treść..."}
                                            onChange={(e, v) =>
                                                this.state.commentValue = e.target.value
                                            }
                                        />
                                        <Button content='Dodaj komentarz' labelPosition='left' icon='edit' primary/>
                                    </Form>
                                </Paper>
                                <Paper>
                                    <PaperTitle title={"Historia"}/>
                                    {data.history ?
                                        data.history.sort((a, b) => b.timestamp - a.timestamp).map((history, key) => {
                                            return (
                                                <div className={"complaint-preview-history-box"} key={key}>
                                                    <div className={"complaint-preview-history-box-icon"}>
                                                        <Icon name={"caret right"}/>
                                                    </div>
                                                    <div className={"complaint-preview-history-box-data"}>
                                                        <div
                                                            className={"complaint-preview-history-box-data-date"}>{formatDate(history.timestamp).dateTimeSystem}</div>
                                                        <div className={"complaint-preview-history-box-data-value"}>
                                                            {history.subValue &&
                                                                <span>({history.subValue})</span>
                                                            }

                                                            {history.value}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <div style={{
                                            textAlign: "center",
                                            color: "#c6c6c6",
                                            margin: 20
                                        }}>Brak danych</div>}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                }
                {this.state.showStatusModal &&
                    <Modal dimmer={"inverted"} size={"small"} open={this.state.showStatusModal} onClose={() => this.setState({showStatusModal: false, statusDescription: ""})}>
                        <Modal.Header>Stany towarowe</Modal.Header>
                        <Modal.Content>
                            <div>
                                <SemanticButton.Group style={{
                                    display: "flex", flexDirection: "column", maxWidth:"100%"
                                }}>
                                    { this.state.data &&
                                        statusMap["defectNew"]?.map((e, k) => {
                                            return (<>
                                                    <SemanticButton
                                                        primary

                                                        key={k}
                                                        onClick={() => {
                                                            this.setState({
                                                                selectedStatus: e.status
                                                            })
                                                        }}

                                                    >{e.label}
                                                    </SemanticButton>
                                                    <SemanticButton.Group style={{
                                                        display: "flex", flexDirection: "column", maxWidth:"100%"
                                                    }}>
                                                        {
                                                            statusMap[e.status]?.map((e, k) => {
                                                                return (
                                                                    <SemanticButton
                                                                        active={ this.state.data.defectData.status.subStatus === e.status || this.state.selectedSubStatus === e.status}
                                                                        key={k}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                selectedSubStatus: e.status
                                                                            })
                                                                        }}

                                                                    >{e.label}
                                                                    </SemanticButton>
                                                                )
                                                            })
                                                        }
                                                    </SemanticButton.Group>
                                                </>
                                            )
                                        })
                                    }
                                </SemanticButton.Group>

                            </div>
                            <div>
                                <EsotiqInput label="Opis" textArea placeholder="Wymagany tylko w przypadku odmowy"
                                             onChange={(v) => this.state.statusDescription = v}/>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => (this.setState({showStatusModal: false}), this.changeStatus())
                            }>Zapisz</Button>
                            <Button onClick={() => this.setState({showStatusModal: false, statusDescription: ""})}>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </>
        )
    }
}

export default DefectDetails;