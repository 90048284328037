import React from "react";
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import {TranslationController} from "../../controller/menager";
import Breadcrumb from "../../components/Breadcrumb";
import {Segment, Menu, Button} from 'semantic-ui-react'
import SnackBar from "../../components/Snack";
import UITable from "../../lib/table-v2";
import CsvImport from "../../components/CsvImport";
import MenuButton from "../../lib/table-v2/menuButton";
import {saveToFile} from "../../controller/exportController";
import TableMenu from "../../components/TableMenu";

export default class AttributesScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            appliedFilters: null
        };

        this.exportFields = [
            {
                field: "model",
            },
            {
                field: "attribute",
            },
            {
                field: "value",
            }
        ];
    }

    init(limit, currentPage) {
        return api(undefined, undefined, false, true).post("/attributes", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data});

                return r.data.data
            })
    }

    render() {
        return (
            <div>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Atrybuty")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Import</TableMenu.Header>
                                <TableMenu.Item>
                                    <CsvImport
                                        csvStructure={[
                                            {column: "model"},
                                            {column: "attribute"},
                                            {column: "value"},
                                        ]}
                                        onUpdate={(data) => {
                                            return api(undefined, undefined, undefined, true).post("/attributes/massUpdate", {data})
                                                .then((data) => {
                                                    api(undefined, undefined, undefined, true).post("/attributes/syncAttributes", {})
                                                        .then(() => {
                                                            SnackBar("Atrybuty zsynchronizowano poprawnie.")
                                                        });

                                                    this.orderTable.setState({
                                                        data: null
                                                    });

                                                    this.orderTable.init();

                                                    return data
                                                })
                                        }}
                                    />
                                </TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Eksport</TableMenu.Header>

                                <TableMenu.Item>
                                    <Button
                                        color='blue'
                                        content='Pobierz wyniki'
                                        icon='download'
                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                        onClick={() => {
                                            this.setState({
                                                loading: true
                                            });

                                            return api(undefined, undefined, undefined, true).post("/attributes", {
                                                isExport: {
                                                    columns: ["model", "attribute", "value"],
                                                    fields: this.exportFields
                                                },
                                                limit: 99000,
                                                currentPage: 1,
                                                filter: this.orderTable.state.appliedFilters,
                                                internalOrders: this.state.internalOrders
                                            })
                                                .then(r => {
                                                    this.setState({
                                                        loading: false
                                                    });

                                                    if (r.data.error) {
                                                        return SnackBar(r.data.message, "error")
                                                    }

                                                    return saveToFile(r.data);
                                                });
                                        }}
                                    />
                                </TableMenu.Item>

                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                            <MenuButton
                                icon={"sync"}
                                onClick={() => {
                                    api(undefined, undefined, undefined, true).get("/products/sync-attributes", {})
                                        .then(() => {
                                            SnackBar("Atrybuty zsynchronizowano poprawnie.")
                                        })
                                }}
                                label={"Synchronizuj atrybuty w produktach"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/attributes",
                                options: {limit: 25, currentPage: 1},
                                useService: true
                            }}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    field: "model",
                                    label: "Model",
                                    filters: [
                                        {label: "Model", condition: "in", type: "in", regexp: true},
                                    ]
                                },
                                {
                                    field: "attribute",
                                    label: "Cecha",
                                    filters: [
                                        {label: "Cecha", condition: "in", type: "in", regexp: true},
                                    ],
                                    editable: true,
                                    preventDisablePaste: true,
                                    onEditableChange: (value, row) => {
                                        row.attribute = value;
                                    },
                                    onEditableConfirm: (row, tableData) => {
                                        if (!row.attribute) {
                                            return false
                                        }

                                        if (!row.attribute.length) {
                                            return false
                                        }

                                        return api(undefined, undefined, undefined, true).post("/attributes/update", {row})
                                            .then((data) => {
                                                api().post("/attributes/syncAttributes", {})
                                                    .then(() => {
                                                        SnackBar("Atrybuty zsynchronizowano poprawnie.")
                                                    });

                                                this.orderTable.setState({
                                                    data: null
                                                });

                                                this.orderTable.init();

                                                return data
                                            })
                                    },
                                    render: row => row.attribute
                                },
                                {
                                    field: "value",
                                    label: "Wartość",
                                    filters: [
                                        {label: "Wartość", condition: "in", type: "in", regexp: true},
                                    ],
                                    editable: true,
                                    preventDisablePaste: true,
                                    onEditableChange: (value, row) => {
                                        row.value = value;
                                    },
                                    onEditableConfirm: (row, tableData) => {
                                        if (!row.value) {
                                            return false
                                        }

                                        if (!row.value.length) {
                                            return false
                                        }

                                        return api(undefined, undefined, undefined, true).post("/attributes/update", {row})
                                            .then((data) => {
                                                api(undefined, undefined, undefined, true).post("/attributes/syncAttributes", {})
                                                    .then(() => {
                                                        SnackBar("Atrybuty zsynchronizowano poprawnie.")
                                                    });

                                                this.orderTable.setState({
                                                    data: null
                                                });

                                                this.orderTable.init();

                                                return data
                                            })
                                    },
                                    render: row => row.value
                                }
                            ]}
                        />
                    </Segment>
                </Container>
            </div>
        );
    }
}
