import React from "react"
import {api} from "../../../lib/axios";
import {Icon, Label, Loader, Message, Table, Item, Accordion} from "semantic-ui-react";
import Tooltip from '@material-ui/core/Tooltip';

export default class OrderParlourComplatationSimulationResult extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            activeIndex: false
        }
    }

    componentDidMount() {
        api().post("/api/states/collect", {order: this.props.cart})
            .then(r => {
                this.setState({
                    data: r.data.data,
                    type: r.data.type,
                    propositions: r.data.propositions
                })
            })
    }

    //33623-MLC
    //37401-00X-M

    handleClick = (e, titleProps) => {
        this.setState({ activeIndex: this.state.activeIndex ? false : true })
    }

    render() {
        const { activeIndex } = this.state

        return (
            <div>
                {this.state.data ?
                    <div className={"sim-result-wrapper-box"}>
                        <div className={"sim-order-preview"}>
                            <span>Zamówienie:</span>
                            {this.props.cart.map((e, k) => {
                                return (
                                    <Label key={k}>
                                        {e.sku}
                                        <Label.Detail>
                                            {e.qty}
                                        </Label.Detail>
                                    </Label>
                                )
                            })}
                        </div>

                        {this.state.type === "solved" ?
                            <Message
                                info
                                header={`Wysyłka z jednego magazynu`}
                                content=""
                            />
                        : this.state.type === "chunks" ?
                            <Message
                                warning
                                header={`${this.state.data.length} salon(ów) które mogą wysłać w parach`}
                                content="Sortowane od najlepszego wyniku (nieaktywne)"
                            />
                        :  <Message
                                error
                                header={`Brak możliwości kompletacji`}
                                content="Zamówienie potrzebuje operatora"
                            />
                        }

                        {/*<Accordion>*/}
                            {/*<Accordion.Title*/}
                                {/*active={activeIndex}*/}
                                {/*onClick={this.handleClick}*/}
                            {/*>*/}
                                {/*<Icon name='dropdown' />*/}
                                {/*Rozwiń listę*/}
                            {/*</Accordion.Title>*/}
                            {/*<Accordion.Content active={activeIndex}>*/}
                                {/*<div className={"sim-result-wrapper"}>*/}
                                    {/*{this.state.activeIndex && <Table basic='very'>*/}
                                        {/*<Table.Header>*/}
                                            {/*<Table.Row>*/}
                                                {/*<Table.HeaderCell>Salon</Table.HeaderCell>*/}
                                                {/*<Table.HeaderCell>Może wysłać</Table.HeaderCell>*/}
                                                {/*<Table.HeaderCell>Na stanie</Table.HeaderCell>*/}
                                                {/*{this.state.type === "chunks" &&*/}
                                                {/*<Table.HeaderCell>Dostępne pary</Table.HeaderCell>*/}
                                                {/*}*/}
                                            {/*</Table.Row>*/}
                                        {/*</Table.Header>*/}

                                        {/*<Table.Body>*/}
                                            {/*{this.state.data && this.state.data.map((e, k) => {*/}
                                                {/*return (*/}
                                                    {/*<Table.Row key={k}>*/}
                                                        {/*<Table.Cell>{e.parlour}</Table.Cell>*/}
                                                        {/*<Table.Cell className={"prlour-preview-cell"}>*/}
                                                            {/*{e.canSend.map((i, key) => {*/}
                                                                {/*return (*/}
                                                                    {/*<Label key={key}>*/}
                                                                        {/*{i.sku}*/}
                                                                        {/*<Label.Detail>*/}
                                                                            {/*{i.qty}*/}
                                                                        {/*</Label.Detail>*/}
                                                                    {/*</Label>*/}
                                                                {/*)*/}
                                                            {/*})}*/}
                                                        {/*</Table.Cell>*/}
                                                        {/*<Table.Cell>*/}
                                                            {/*{e.canSend.map((i, key) => {*/}
                                                                {/*const state = e.state.find(s => s.sku === i.sku)*/}
                                                                {/*return (*/}
                                                                    {/*<Label key={key}>*/}
                                                                        {/*{i.sku}*/}
                                                                        {/*<Label.Detail>*/}
                                                                            {/*{state ? state.qty : "0"}*/}
                                                                        {/*</Label.Detail>*/}
                                                                    {/*</Label>*/}
                                                                {/*)*/}
                                                            {/*})}*/}
                                                        {/*</Table.Cell>*/}
                                                        {/*{this.state.type === "chunks" &&*/}
                                                        {/*<Table.Cell>*/}
                                                            {/*{e.availablePairs.map((i, key) => {*/}
                                                                {/*return (*/}
                                                                    {/*<Label key={key}>*/}
                                                                        {/*{i.parlour}*/}
                                                                        {/*<Label.Detail>*/}
                                                                            {/*{i.canSend.map(item => item.qty).reduce((a, b) => a + b)}*/}
                                                                        {/*</Label.Detail>*/}
                                                                    {/*</Label>*/}
                                                                {/*)*/}
                                                            {/*})}*/}
                                                        {/*</Table.Cell>*/}
                                                        {/*}*/}
                                                    {/*</Table.Row>*/}
                                                {/*)*/}
                                            {/*})}*/}
                                        {/*</Table.Body>*/}
                                    {/*</Table>}*/}
                                {/*</div>*/}
                            {/*</Accordion.Content>*/}
                        {/*</Accordion>*/}


                        {this.state.propositions &&
                            <React.Fragment>
                                <Message
                                    info
                                    header={`Propozycje kompletacji`}
                                    content="Program wybrał najlepszą opcję."
                                />

                                <Item.Group divided>
                                    {this.state.propositions.map((p, k) => {
                                        return (
                                            <div key={k} style={{
                                                boxShadow: k === 0 ? "0 0 0 1px #b4f1b7 inset, 0 0 0 0 transparent" : "0 0 0 1px #f1f1f1 inset, 0 0 0 0 transparent"
                                            }} className={"prop-divided"}>
                                                <Item>
                                                    <Table basic='very'>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell>Salon</Table.HeaderCell>
                                                                <Table.HeaderCell>Do wysyłki</Table.HeaderCell>
                                                                <Table.HeaderCell>Stan magazynowy</Table.HeaderCell>
                                                            </Table.Row>
                                                        </Table.Header>

                                                        <Table.Body>
                                                            {p.map((prop, key) => {
                                                                return (
                                                                    <Table.Row key={key}>
                                                                        <Table.Cell width={2}>
                                                                            <Label key={key + key}>
                                                                                {prop.parlour}
                                                                                <Label.Detail>
                                                                                    <Tooltip title="Sprzedaż online TAK/NIE">
                                                                                        <div className={"onlabel-box"}>
                                                                                            <Icon name={"usb"}/>
                                                                                            {prop.parlourDetails[0] && (prop.parlourDetails[0].isOnline > 0 ? "T" : "N")}
                                                                                        </div>
                                                                                    </Tooltip>

                                                                                    <Tooltip title="Dzisiejsze zamówienia / limit">
                                                                                        <div className={"onlabel-box"}>
                                                                                            <Icon name={"cart arrow down"}/>
                                                                                            {prop.parlourDetails[0] && prop.parlourDetails[0].todayOrders + "/" + prop.parlourDetails[0].orderLimit}
                                                                                        </div>
                                                                                    </Tooltip>

                                                                                    <Tooltip title="Dzisiaj towarów / limit">
                                                                                        <div className={"onlabel-box"}>
                                                                                            <Icon name={"tag"}/>
                                                                                            {prop.parlourDetails[0] && prop.parlourDetails[0].itemToday + "/" + prop.parlourDetails[0].itemLimit}
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                </Label.Detail>
                                                                            </Label>
                                                                        </Table.Cell>

                                                                        <Table.Cell>
                                                                            <div className={"prop-divided divided-selected"}>
                                                                                <Table basic='very'>
                                                                                    <Table.Header>
                                                                                        <Table.Row>
                                                                                            <Table.HeaderCell>SKU</Table.HeaderCell>
                                                                                            <Table.HeaderCell>Ilość</Table.HeaderCell>
                                                                                        </Table.Row>
                                                                                    </Table.Header>

                                                                                    <Table.Body>
                                                                                        {Object.keys(prop.sending).map((key, index) => {
                                                                                            return (
                                                                                                <Table.Row key={index}>
                                                                                                    <Table.Cell width={3}>
                                                                                                        {key}
                                                                                                    </Table.Cell>
                                                                                                    <Table.Cell width={2}>
                                                                                                        {prop.sending[key]}
                                                                                                    </Table.Cell>
                                                                                                </Table.Row>
                                                                                            )
                                                                                        })}
                                                                                    </Table.Body>
                                                                                </Table>
                                                                            </div>
                                                                        </Table.Cell>

                                                                        <Table.Cell>
                                                                            <div className={"prop-divided"}>
                                                                                <Table basic='very'>
                                                                                    <Table.Header>
                                                                                        <Table.Row>
                                                                                            <Table.HeaderCell>SKU</Table.HeaderCell>
                                                                                            <Table.HeaderCell>Stan</Table.HeaderCell>
                                                                                        </Table.Row>
                                                                                    </Table.Header>

                                                                                    <Table.Body>
                                                                                        {prop.canSend.map((canSend, canSendKey) => {
                                                                                            return (
                                                                                                <Table.Row key={canSendKey}>
                                                                                                    <Table.Cell width={3}>
                                                                                                        {canSend.sku}
                                                                                                    </Table.Cell>
                                                                                                    <Table.Cell width={2}>
                                                                                                        {canSend.qty}
                                                                                                    </Table.Cell>
                                                                                                </Table.Row>
                                                                                            )
                                                                                        })}
                                                                                    </Table.Body>
                                                                                </Table>
                                                                            </div>
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                )
                                                            })}
                                                        </Table.Body>
                                                    </Table>

                                                    {k === 0 &&
                                                        <Item.Extra>
                                                            <Icon color='green' name='check' /> Zalecana propozycja kompletacji
                                                        </Item.Extra>
                                                    }
                                                </Item>
                                            </div>
                                        )
                                    })}
                                </Item.Group>
                            </React.Fragment>
                        }
                    </div>
                : <div className={"sim-loader-box"}><Loader active></Loader></div>}
            </div>
        );
    }
}
