import {ParlourDetailsUrls} from "../../api/urls";
import CRUDView from "../../components/CRUDTable/view";

export default class ParloursEquipments extends CRUDView {
    resource = ParlourDetailsUrls.EQUIPMENTS;
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            label: 'Wyposażenie salonu',
            schema: {
                title: 'Wyposażenie salonu',
                type: 'object',
                required: ['name'],
                properties: {
                    name: {
                        type: 'string',
                        title: 'Nazwa',
                        default: '',
                    },
                }
            },
            tableFields: [
                {
                    label: 'Id',
                    getValue: (payload) => payload.id,
                },
                {
                    label: 'Nazwa',
                    getValue: (payload) => payload.name,
                }
            ]
        }
    }
}
