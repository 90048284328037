import React from "react";
import {Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Font, Image} from '@react-pdf/renderer';
import {formatDate} from "../../helpers/dateHelper";
import {jsonCopy} from "../../helpers/lib";

const sourceLight = require("../../resources/fonts/OpenSans-Light.ttf")
const sourceRegular = require("../../resources/fonts/OpenSans-Regular.ttf")

Font.register({family: 'OpenSansLight', src: sourceLight});
Font.register({family: 'OpenSans', src: sourceRegular});

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: "40px 20px",
        fontFamily: 'OpenSans'
    },
    section: {
        margin: 10,
        padding: 10,
    },
    title: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 700,
        marginTop: 40,
        marginBottom: 40
    },
    subinfo: {
        fontSize: 10,
        textAlign: "right",
        marginTop: 5,
        fontFamily: 'OpenSansLight'
    },
    subTitle: {
        fontSize: 14,
        fontWeight: 700,
        marginTop: 40,
        marginBottom: 10
    },
    bigBox: {
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderColor: "#000",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    columnAllign: {
        flexDirection: "row",
        justifyContent: "space-between"
    },
    columnField: {
        fontWeight: 700,
        fontSize: 11,
        width: 150
    },
    columnInput: {
        fontSize: 11,
    },
    tableBox: {},
    tableHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableHeaderCol: {
        padding: 6,
        flex: 1,
    },
    tableHeaderColNoWidth: {
        padding: 3,
    },
    tableHeaderColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableBody: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableBodyCol: {
        padding: 3,
        flex: 1,
    },
    tableBodyColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableHeaderColTextLeft: {
        fontSize: 10,
    },
    tableBodyColTextLittle: {
        fontSize: 10,
        color: "#898989"
    },
    tableBodyColNoWidth: {
        padding: 3,

    },
    tableBoxEnd: {
        width: "50%",
        marginTop: 30
    },
    tableBodyColCustom: {
        padding: 3,
        flex: 1,
        height: 80
    },
    bottomBox: {
        flex: 1,
        justifyContent: "flex-end",
        width: "100%",
        padding: 3
    },
    bottomBoxText: {
        fontSize: 10,
        textAlign: "right"
    },
    infoBox: {
        width: 200,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    infoLabel: {
        fontSize: 10
    },
    infoValue: {
        fontSize: 10
    }
});

// Create Document Component
export default class PDFRevert extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            maxItemsOnPage: 10,
            pages: [],
            currentPage: 0
        }
    }


    componentDidMount() {
        this.createValidEntryData();
        this.renderPages();
    }

    createValidEntryData() {
        let {data} = this.props;

        if (data.orderData && data.orderData.data && data.orderData.data.items) {
            return this.setState({data});
        }

        try {
            const rawData = jsonCopy(data.orderData);

            if (data.orderData && !data.orderData.data) {
                data.orderData.data = rawData;
                return this.setState({data});
            }

            if (data.orderData && data.orderData.data && !data.orderData.data.addressShipping) {
                data.orderData.data = rawData;
                return this.setState({data});
            }

            return this.setState({data});
        } catch (e) {
            alert("Nie można utworzyć pliku, zgłoś do administracji!")
        }
    }

    convertDate(date) {
        const orderDate =  new Date(date);
        const day = orderDate.getDate();
        const month = (orderDate.getMonth()+1) < 10 ? `0${(orderDate.getMonth()+1)}` : (orderDate.getMonth()+1);;

        const year = orderDate.getFullYear();

        return `${day}-${month}-${year}`;
    }




    renderPages() {
        const pages = [];
        const data = this.props.data.revertData;
        const length = this.props.data.revertData.length;
        const maxItems = 10;

        for(let i = 0; i < length; i += maxItems){
            if(pages.length > 1){

                const tab = data.slice(i, (maxItems*2)+i);
                pages.push(tab)

            } else {

                const tab = data.slice(i,maxItems+i);
                pages.push(tab)

            }

       }
        this.setState({pages});
    }





    render() {
        const {data} = this.state;
        if (!data) return null;
        return (
            <Document>
                {data.orderData && data.orderData.data &&
                    <>
                    <Page size="A4" style={styles.page}>
                        <View style={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Image
                                src={require("../../resources/esologo.png")}
                                style={{
                                    width: 150,
                                    margin: "0 auto"
                                }}
                            />
                        </View>

                        <View style={{
                            marginTop: 10
                        }}>
                            <Text style={{
                                fontSize: 13,
                                textAlign: "center"
                            }}>FORMULARZ ODSTĄPIENIA OD UMOWY</Text>
                        </View>

                        <View style={{
                            marginTop: 10,
                            justifyContent: "space-between",
                            flexDirection: "row"
                        }}>
                            <View>
                                <View style={styles.infoBox}>
                                    <Text style={styles.infoLabel}>Imię:</Text>
                                    <Text style={styles.infoValue}>{data.orderData.data.customerName}</Text>
                                </View>

                                <View style={styles.infoBox}>
                                    <Text style={styles.infoLabel}>Nazwisko:</Text>
                                    <Text style={styles.infoValue}>{data.orderData.data.customerLastname}</Text>
                                </View>

                                <View style={styles.infoBox}>
                                    <Text style={styles.infoLabel}>Data zakupu:</Text>
                                    <Text style={styles.infoValue}>{this.convertDate(data.orderData.data.createdAt)}</Text>
                                </View>

                                <View style={styles.infoBox}>
                                    <Text style={styles.infoLabel}>Nr zamówienia:</Text>
                                    <Text style={styles.infoValue}>{data.orderData.orderId}</Text>
                                </View>
                            </View>

                            <View>
                                <View style={styles.infoBox}>
                                    <Text style={styles.infoLabel}>Kod pocztowy:</Text>
                                    <Text style={styles.infoValue}>{data.orderData.data.addressBilling.postcode}</Text>
                                </View>

                                <View style={styles.infoBox}>
                                    <Text style={styles.infoLabel}>Miasto:</Text>
                                    <Text style={styles.infoValue}>{data.orderData.data.addressBilling.city}</Text>
                                </View>

                                <View style={styles.infoBox}>
                                    <Text style={styles.infoLabel}>Ulica:</Text>
                                    <Text style={styles.infoValue}>{data.orderData.data.addressBilling.street}</Text>
                                </View>

                                <View style={styles.infoBox}>
                                    <Text style={styles.infoLabel}>E-mail:</Text>
                                    <Text style={styles.infoValue}>{data.orderData.data.customerEmail}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={{
                            marginTop: 10
                        }}>
                            <Text style={{
                                fontSize: 10,
                                textAlign: "center",
                                fontWeight: 100
                            }}>W przypadku płatności poprzez platformę Bluemedia zwrot nastąpi tym samym kanałem. Dla
                                płatności przelewem lub za pobraniem proszę podać nazwę banku oraz nr konta bankowego,
                                na
                                który ma nastąpić przelew wartości zwracanych produktów:</Text>

                            <View style={{
                                marginTop: 10
                            }}>
                                <Text
                                    style={{
                                        fontSize: 10
                                    }}
                                >Nazwa banku:</Text>
                                <Text
                                    style={{
                                        fontSize: 10
                                    }}
                                >{this.props.data.revertCustomerData && this.props.data.revertCustomerData.revertBankName && this.props.data.revertCustomerData.revertBankName.length ? this.props.data.revertCustomerData.revertBankName : "---"}</Text>
                            </View>

                            <View style={{
                                marginTop: 10
                            }}>
                                <Text
                                    style={{
                                        fontSize: 10
                                    }}
                                >Numer konta (26 cyfr)</Text>
                                <Text
                                    style={{
                                        fontSize: 10
                                    }}
                                >{this.props.data.revertCustomerData && this.props.data.revertCustomerData.revertBankAccount && this.props.data.revertCustomerData.revertBankAccount.length ? this.props.data.revertCustomerData.revertBankAccount : "---"}</Text>
                            </View>
                            <View style={{
                                marginTop: 10
                            }}>
                                <Text
                                    style={{
                                        fontSize: 10
                                    }}
                                >Adres do odesłania towaru:</Text>
                            </View>
                        </View>

                        <View style={{marginTop: 15}}>
                            <Text style={{
                                fontSize: 10,
                                textAlign: "center",
                                marginBottom: 10,
                                fontWeight: 300
                            }}>Niniejszym informuję o moim odstąpieniu od umowy sprzedaży następujących rzeczy:</Text>
                            <View style={styles.tableBox}>
                                <View style={styles.tableHeader}>
                                    <View style={{...styles.tableHeaderColNoWidth, ...{width: "6%"}}}>
                                        <Text style={styles.tableHeaderColText}>L.p</Text>
                                    </View>
                                    <View style={{...styles.tableHeaderColNoWidth, ...{width: "50%"}}}>
                                        <Text style={styles.tableHeaderColText}>Nazwa</Text>
                                    </View>
                                    <View style={{...styles.tableHeaderColNoWidth, ...{width: "10%"}}}>
                                        <Text style={styles.tableHeaderColText}>Ilość</Text>
                                    </View>
                                    <View style={{...styles.tableHeaderColNoWidth, ...{width: "16%"}}}>
                                        <Text style={styles.tableHeaderColText}>Cena</Text>
                                    </View>
                                    <View style={{...styles.tableHeaderColNoWidth, ...{width: "16%"}}}>
                                        <Text style={styles.tableHeaderColText}>Wartość</Text>
                                    </View>
                                </View>

                                {data.revertData.filter( (rItem, key) => {

                                    if(key < this.state.maxItemsOnPage) {

                                        return rItem;

                                    }
                                }).map((revertItem, key) => {

                                    return (

                                        <View key={key} style={styles.tableBody}>
                                            <View style={{...styles.tableBodyColNoWidth, ...{width: "6%"}}}>
                                                <Text
                                                    style={styles.tableBodyColText}>{key + 1}</Text>
                                            </View>
                                            <View style={{...styles.tableBodyColNoWidth, ...{width: "50%"}}}>
                                                <Text style={styles.tableHeaderColTextLeft}>{revertItem.name}</Text>
                                                <Text style={styles.tableBodyColTextLittle}>{revertItem.sku}</Text>
                                            </View>
                                            <View style={{...styles.tableBodyColNoWidth, ...{width: "10%"}}}>
                                                <Text style={styles.tableBodyColText}>{revertItem.revertQty}</Text>
                                            </View>
                                            <View style={{...styles.tableBodyColNoWidth, ...{width: "16%"}}}>
                                                <Text
                                                    style={styles.tableBodyColText}>{revertItem.revertProduct.price}</Text>
                                            </View>
                                            <View style={{...styles.tableBodyColNoWidth, ...{width: "16%"}}}>
                                                <Text
                                                    style={styles.tableBodyColText}>{(parseFloat(revertItem.revertProduct.price * revertItem.revertQty).toFixed(2))}</Text>
                                            </View>
                                        </View>

                                        )
                                    }
                                )
                                }

                            </View>

                        </View>

                        <View style={{
                            marginTop: 15,
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            flexDirection: "row"
                        }}>
                            <View>

                                <View style={{
                                    marginTop: 10
                                }}>
                                    <Text
                                        style={{
                                            fontSize: 10,
                                            color: "#ff2f19"
                                        }}
                                    > </Text>
                                    <Text
                                        style={{
                                            fontSize: 10,
                                            color: "#ff2f19"
                                        }}
                                    > </Text>
                                    <Text
                                        style={{
                                            fontSize: 10,
                                            color: "#ff2f19"
                                        }}
                                    > </Text>
                                    <Text
                                        style={{
                                            fontSize: 10,
                                            color: "#ff2f19"
                                        }}
                                    > </Text>
                                </View>
                            </View>

                            <View>
                                <View>
                                    <Text>................................</Text>
                                    <Text
                                        style={{
                                            fontSize: 10,
                                        }}
                                    >podpis konsumenta</Text>
                                </View>
                            </View>
                        </View>

                    </Page>
                        { this.state.data.revertData && this.state.pages.length > 1 &&
                            this.state.pages.map((page, key) => {

                                this.state.currentPage = key;

                                if(key === 0) return null

                                return (
                                <Page size="A4" style={styles.page}>
                                    <View style={{
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Image
                                            src={require("../../resources/esologo.png")}
                                            style={{
                                                width: 150,
                                                margin: "0 auto"
                                            }}
                                        />
                                    </View>

                                    <View style={{marginTop: 15}}>
                                        <Text style={{
                                            fontSize: 10,
                                            textAlign: "center",
                                            marginBottom: 10,
                                            fontWeight: 300
                                            }}>Niniejszym informuję o moim odstąpieniu od umowy sprzedaży następujących rzeczy:</Text>

                                        <View style={styles.tableBox}>
                                            <View style={styles.tableHeader}>
                                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "6%"}}}>
                                                    <Text style={styles.tableHeaderColText}>L.p</Text>
                                                </View>
                                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "50%"}}}>
                                                    <Text style={styles.tableHeaderColText}>Nazwa</Text>
                                                </View>
                                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "10%"}}}>
                                                    <Text style={styles.tableHeaderColText}>Ilość</Text>
                                                </View>
                                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "16%"}}}>
                                                    <Text style={styles.tableHeaderColText}>Cena</Text>
                                                </View>
                                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "16%"}}}>
                                                    <Text style={styles.tableHeaderColText}>Wartość</Text>
                                                </View>
                                            </View>

                                            {page.map((revertItem, key) => {

                                                 return (

                                                    <View key={key} style={styles.tableBody}>
                                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "6%"}}}>
                                                            <Text style={styles.tableBodyColText}>{key + (this.state.maxItemsOnPage * this.state.currentPage) + 1}</Text>
                                                        </View>
                                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "50%"}}}>
                                                            <Text style={styles.tableHeaderColTextLeft}>{revertItem.name}</Text>
                                                            <Text style={styles.tableBodyColTextLittle}>{revertItem.sku}</Text>
                                                        </View>
                                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "10%"}}}>
                                                            <Text style={styles.tableBodyColText}>{revertItem.revertQty}</Text>
                                                        </View>
                                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "16%"}}}>
                                                            <Text style={styles.tableBodyColText}>{revertItem.revertProduct.price}</Text>
                                                        </View>
                                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "16%"}}}>
                                                            <Text style={styles.tableBodyColText}>{(parseFloat(revertItem.revertProduct.price * revertItem.revertQty).toFixed(2))}</Text>
                                                        </View>
                                                    </View>

                                                    )
                                                })}
                                            </View>
                                    </View>

                                    <View style={{
                                            marginTop: 10
                                    }}>


                                        <View style={{
                                            marginTop: 15,
                                            justifyContent: "space-between",
                                            alignItems: "flex-end",
                                            flexDirection: "row"
                                        }}>
                                            <View>

                                                <View style={{
                                                    marginTop: 10
                                                }}>
                                                    <Text
                                                        style={{
                                                            fontSize: 10,
                                                            color: "#ff2f19"
                                                        }}
                                                    > </Text>
                                                    <Text
                                                        style={{
                                                            fontSize: 10,
                                                            color: "#ff2f19"
                                                        }}
                                                    > </Text>
                                                    <Text
                                                        style={{
                                                            fontSize: 10,
                                                            color: "#ff2f19"
                                                        }}
                                                    > </Text>
                                                    <Text
                                                        style={{
                                                            fontSize: 10,
                                                            color: "#ff2f19"
                                                        }}
                                                    > </Text>
                                                </View>
                                            </View>

                                            <View>
                                                <View>
                                                    <Text>................................</Text>
                                                    <Text
                                                        style={{
                                                            fontSize: 10,
                                                        }}
                                                    >podpis konsumenta</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                </Page>
                                )
                                }
                            )
                        }
                    </>
                }
            </Document>
        );
    }
}
