export const ControlPanelUrls = {
    SIGN_IN: "/auth/sign-in",
    USERS: "/users",
}

export const MonobackendUrls = {
    ME: '/api/users/me',
}

export const ParlourDetailsUrls = {
    EQUIPMENT_IN_PARLOUR: "/equipment-in-parlour",
    EQUIPMENTS: "/equipments",
    ACCOUNTANTS: "/accountants",
    REGIONS: "/regions",
    PARLOURS: "/parlours",
    PARLOURS_ECOM_MAP: "/parlours-map/all",
    PARLOURS_ECOM_SHOW: '/parlours-map/show/',
    PARLOURS_ECOM_HIDE: '/parlours-map/hide/',
}
