import {translateController} from "./controllers/translate";
import {navigationController} from "./controllers/navigation";
import {accessController} from "./controllers/access";
import {routerController} from "./controllers/routerController";
import {notificationcontroller} from "./controllers/notification";

export let TranslationController;
export let NavigationController;
export let AccessController;
export let RouterController;
export let NotificationController;

export const InitControllers = () => {
    TranslationController = new translateController();
    NavigationController = new navigationController();
    AccessController = new accessController();
    RouterController = new routerController();
    NotificationController = new notificationcontroller();
}
