import React, {Component} from "react";
import {TranslationController} from "../../controller/menager";
import Button from "../Button";
import EsotiqInput from "../EsotiqInput";

class SliderFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cityValue: "",
            nameValue: "",
        }
    }

    handleStatusToggle = (e) => {
        this.props.handleStatusToggle(e);
    }

    setFilter(e) {
        const filters = [];

        if (this.state.nameValue && this.state.nameValue.length) {
            filters.push({
                condition: "custom",
                deepFilter: undefined,
                field: "name",
                label: "Nazwa",
                pattern: undefined,
                patternFunc: undefined,
                value: this.state.nameValue,
                customFilter: {
                    "name": `regexp_${this.state.nameValue}`
                }
            })
        }

        if (this.state.cityValue && this.state.cityValue.length) {
            filters.push({
                condition: "custom",
                deepFilter: undefined,
                field: "city",
                label: "City",
                pattern: undefined,
                patternFunc: undefined,
                value: this.state.cityValue,
                customFilter: {
                    "city": `regexp_${this.state.cityValue}`
                }
            })
        }

        this.handleStatusToggle(e);

        if (this.props.orderTable) {
            this.props.orderTable.applyFilter([
                ...this.props.orderTable.state.appliedFilters,
                ...filters
            ]);
        }
    }

    render() {
        return (
            <>
                <div className={this.props.sliderStatus ? "slider-blocker slider-blocker-active" : "slider-blocker"}
                     onClick={(e) => {
                         this.handleStatusToggle(e);
                         this.setState({
                             cityValue: "",
                             nameValue: "",
                         })
                     }}>
                </div>
                <div className={this.props.sliderStatus ? "slider-wrapper slider-wrapper-active" : "slider-wrapper"}>
                    <form className="slider-form">
                        <Button
                            onClick={(e) => {
                                this.setFilter(e);
                                this.setState({
                                    cityValue: "",
                                    nameValue: "",
                                })
                            }}
                            primary
                        >
                            FILTRUJ
                        </Button>

                        <label htmlFor="city-name-value">
                            <span>{TranslationController.translate("Wyszukaj po mieście")}</span>
                            <EsotiqInput
                                onChange={v => this.setState({cityValue: v})}
                                id="city-value"
                                width="100%"
                                defValue={this.state.cityValue}
                            />

                        </label>
                        <label htmlFor="name-value">
                            <span>{TranslationController.translate("Wyszukaj po nazwie")}</span>
                            <EsotiqInput
                                onChange={v => this.setState({nameValue: v})}
                                width="100%"
                                defValue={this.state.nameValue}
                            />
                        </label>
                    </form>
                </div>
            </>
        )
    }
}

export default SliderFilter;