import React from "react"
import { Menu, Segment, Checkbox, Button } from 'semantic-ui-react'
import {api} from "../../lib/axios";
import MenuButton from "../../lib/table-v2/menuButton";
import Breadcrumb from "../../components/Breadcrumb";
import Container from "../../components/Container";
import UITable from "../../lib/table-v2";
import Dropdown from "../../components/Dropdown";
import TableMenu from "../../components/TableMenu";

export default class EANScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            websiteFilter: [],
            dataCount: null,
            tableSelectable: false,
            tableSelectableState: [],
            tableExportCols: [],
            onlyWithProducts: true
        }

    }

    componentDidMount() {
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api().post("/api/products/getEan", {})
                .then(r => {
                    return r.data.data
                })
        })

        return api().post("/api/products/getEan", {limit, currentPage, filter: this.state.appliedFilters || null, onlyWithProducts: this.state.onlyWithProducts})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    render() {
        return (
            <div>
                <React.Fragment>
                    <Container>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `EAN`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>

                        <Menu className={"order-menu"} attached='top'>
                            <TableMenu item icon='wrench' simple>
                                <TableMenu.Menu>
                                    <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                    <TableMenu.Divider />
                                    <TableMenu.Header>Export</TableMenu.Header>
                                    <TableMenu.Item>
                                        <span className='text'>Raport uniwersalny</span>

                                        <TableMenu.Menu>
                                            <div className={"uni-report-container"}>
                                                <div className={"uni-report-header"}>Wybierz kolumny do raportu</div>

                                                <div className={"uni-report-content-box"}>
                                                    <div className={"uni-report-column"}>
                                                        <ul>

                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className={"uni-report-actions"}>
                                                    <Button
                                                        color='blue'
                                                        content='Pobierz'
                                                        icon='download'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                        onClick={() => this.uniExport()}
                                                    />
                                                </div>
                                            </div>
                                        </TableMenu.Menu>
                                    </TableMenu.Item>
                                </TableMenu.Menu>
                            </TableMenu>

                            <Menu.Menu position='right'>
                                <MenuButton
                                    icon={"cloud download"}
                                    label={"Import [SKU || EAN]"}
                                    csvImport={true}
                                    csvImportOnUpdate={(data) => api().post("/api/products/importEan", {data})
                                        .then((data) => {
                                            this.setState({
                                                data: null,
                                                websiteFilter: [],
                                                dataCount: null,
                                                tableSelectable: false,
                                                tableSelectableState: [],
                                                tableExportCols: []
                                            })

                                            this.orderTable.setState({
                                                data: null
                                            })

                                            this.orderTable.init()

                                            return data
                                        })}
                                />
                            </Menu.Menu>
                        </Menu>

                        <Segment className={"order-table-segment"} attached='bottom'>
                            <UITable
                                dataFetch={{
                                    url: "/api/products/getEan",
                                    options: {limit: 25, currentPage: 1, onlyWithProducts: this.state.onlyWithProducts}
                                }}
                                ref={table => this.orderTable = table}
                                selectable={this.state.tableSelectable}
                                provider={this}
                                columns={[
                                    {
                                        label: "SKU",
                                        field: "sku",
                                        center: true,
                                        width: 1,
                                        filters: [
                                            {label: "SKU", condition: "like", type: "text"},
                                        ],
                                    },
                                    {
                                        label: "EAN",
                                        field: "ean",
                                        center: true,
                                        width: 1,
                                        filters: [
                                            {label: "EAN", condition: "like", type: "text"},
                                        ],
                                    },
                                ]}
                            />
                        </Segment>
                    </Container>
                </React.Fragment>
            </div>
        );
    }
}
