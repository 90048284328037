import React from "react"
import Button from "../../components/Button";
import {Modal, Table, Icon, Confirm, Label, Message, Icon as SemanticIcon} from "semantic-ui-react";
import SnackBar from "../../components/Snack";
import EsotiqInput from "../../components/EsotiqInput";
import Dropzone from 'react-dropzone'
import {jsonCopy} from "../../helpers/lib";
import {getCurrencyString} from "../../lib/currency";
import {formatDate} from "../../helpers/dateHelper";
import {copyToClipboard} from "../../lib/globals";

export default class OnlineComplaintOrderDisplay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpened: false,
            revertQty: null,
            revertReason: null,
            revertAdditionalInfo: null,
            files: null,
            complaintForm: null,
            revertData: false,
            revertProduct: null,
            revertType: null,
            revertOrderType: null,

            revertedData: []
        }

        this.scrollRefs = {};
    }

    componentDidMount() {
        this.onScanner();
    }

    onScanner() {
        let isEvent = false;
        let input = document.getElementById("scanner");

        input.addEventListener("focus", () => {
            if (!isEvent) {
                isEvent = true;

                input.addEventListener("keypress", (e) => {
                    setTimeout(() => {
                        if (e.keyCode == 13) {
                            this.scanner(input.value);
                            input.select();
                        }
                    }, 500)
                })
            }
        });

        document.addEventListener("keypress", (e) => {
            if (e.target.tagName !== "INPUT") {
                input.focus();
            }
        });
    }

    scanner(value) {
        if (value && value.length) {
            this.props.orders.forEach(order => {
                let founded = null;

                order.items.forEach((item, k) => {
                    if (item.ean === value && !item.isScanned && !founded) {
                        founded = item;
                        item.isScanned = true;

                        this.forceUpdate();

                        this.scrollRefs[k].scrollIntoView({behavior: 'smooth'});
                    }
                })

                if (!founded) {
                    SnackBar("Nie znaleziono pasującego kodu EAN.")
                }
            })
        }
    }

    clear(pure, key) {
        this.state.revertedData = this.state.revertedData.filter((e, k) => k !== key)

        this.setState({
            revertQty: null,
            revertReason: null,
            revertAdditionalInfo: null,
            files: null,
            complaintForm: null,
            revertData: false,
            revertProduct: null,
            revertType: null,
            revertOrderType: null,

            itemsRevertType: null
        })

        if (!pure) {
            this.props.clear(key)
        }
    }

    render() {
        const revertData = this.props.revertData || this.state.revertedData;

        if (revertData && !this.state.itemsRevertType) {
            if (revertData[0]) {
                this.state.itemsRevertType = revertData[0].revertType;
            }
        }

        return (
            <div>
                <input style={{
                    position: "absolute",
                    opacity: 0
                }} type="text" id={"scanner"}/>
                {revertData.map((revertedProduct, key) => {
                    return (
                        <div key={key} className={"revert-data-wrapper"}>
                            <div className={"revert-data-box"}>
                                <div className={"revert-data-key"}>Produkt</div>
                                <div className={"revert-data-value"}>{revertedProduct.name}</div>
                            </div>

                            <div className={"revert-data-box"}>
                                <div className={"revert-data-key"}>SKU</div>
                                <div className={"revert-data-value"}>{revertedProduct.sku}</div>
                            </div>

                            <div className={"revert-data-box"}>
                                <div className={"revert-data-key"}>Forma zwrotu</div>
                                <div className={"revert-data-value"}>{revertedProduct.revertType === "revert" ? "Zwrot" : "Reklamacja"}</div>
                            </div>

                            <div className={"revert-data-box"}>
                                <div className={"revert-data-key"}>Zwracana ilość</div>
                                <div className={"revert-data-value"}>{revertedProduct.revertQty}</div>
                            </div>

                            {revertedProduct.revertType === "revert" ?
                                <div className={"revert-data-box"}>
                                    <div className={"revert-data-key"}>Powód zwrotu</div>
                                    <div className={"revert-data-value"}>{revertedProduct.revertReason || "---"}</div>
                                </div> :
                                <div className={"revert-data-box"}>
                                    <div className={"revert-data-key"}>Forma reklamacji</div>
                                    <div className={"revert-data-value"}>{revertedProduct.complaintForm || "---"}</div>
                                </div>
                            }

                            <div className={"revert-data-box"}>
                                <div className={"revert-data-key"}>Dodatkowe informacje</div>
                                <div className={"revert-data-value"}>{revertedProduct.revertAdditionalInfo || "---"}</div>
                            </div>

                            {revertedProduct.revertTok99 &&
                                <div className={"revert-data-box"} style={{
                                    marginTop: 15,
                                    paddingTop: 5,
                                    color: "#ffa265",
                                    borderTop: "1px solid " +
                                        "#e4e4e4"
                                }}>
                                    <div className={"revert-data-key"}>Informacje dla salonu:</div>
                                    <div className={"revert-data-value"}>Towar należy odesłać na magazyn K99</div>
                                </div>
                            }


                            <div className={"revert-data-action"}>
                                <Button
                                    onClick={() => this.clear(false, key)}
                                >Wyczyść</Button>
                            </div>
                        </div>
                    )
                })
                }

                <Message
                    info
                    icon='info'
                    header='Zeskanuj towar'
                    content={"Możesz użyć skanera aby łatwo wybrać towar z listy."}
                />

                <div style={{
                    display: "flex",
                    justifyContent: "space-between"
                }} className={"complaint-button-revert-all"}>
                    <Button
                        onClick={() => {
                            if ((!this.props.revertReasons || !this.props.revertReasons[0]) || (!this.props.complaintReasons || !this.props.complaintReasons[0])) {
                                return SnackBar("Brak ustalonych powodów zwrotów i reklamacji", "error")
                            }

                            let products = jsonCopy(this.props.orders[0].items)

                            products = products.filter(p => !this.props.prevComplaints[p.sku])

                            this.setState({
                                revertAll: {products, modal: true, complaintProducts: jsonCopy(this.props.orders[0].items.filter(item => !item.isGiftCard))}
                            })
                        }}
                    >
                        Zwróć wszystko
                    </Button>

                    <Button onClick={() => this.props.nextStep()} primary>Dalej</Button>
                </div>

                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Numer zamówienia</Table.HeaderCell>
                            <Table.HeaderCell>Data</Table.HeaderCell>
                            <Table.HeaderCell>Klient</Table.HeaderCell>
                            <Table.HeaderCell>Wartość</Table.HeaderCell>
                            <Table.HeaderCell>Produkty</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.props.orders.map((order, k) => {
                            return (
                                <Table.Row key={k}>
                                    <Table.Cell center={true}>
                                        {order.id}
                                    </Table.Cell>

                                    <Table.Cell>
                                        {formatDate(order.createdAt).dateTimeSystem}
                                    </Table.Cell>

                                    <Table.Cell>
                                        {order.addressShipping.firstname}
                                    </Table.Cell>

                                    <Table.Cell>
                                        {parseInt(order.price).toFixed(2)} <span>{order.currency}</span>
                                    </Table.Cell>

                                    <Table.Cell>
                                        <div>
                                            <Table basic='very'>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell></Table.HeaderCell>
                                                        <Table.HeaderCell>SKU</Table.HeaderCell>
                                                        <Table.HeaderCell>Produkt</Table.HeaderCell>
                                                        <Table.HeaderCell>Ilość</Table.HeaderCell>
                                                        <Table.HeaderCell>Wartość</Table.HeaderCell>
                                                        <Table.HeaderCell></Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {order.items.filter(item => !item.isGiftCard).map((item, index) => {
                                                        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

                                                        const a = new Date()
                                                        const b = new Date(order.createdAt)

                                                        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
                                                        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

                                                        const diffDays =  Math.abs(Math.floor((utc2 - utc1) / _MS_PER_DAY));

                                                        const afterRevertTime = this.props.revertAllowTime - diffDays < 0;
                                                        let selected = !!revertData.find(e => e.uniqId === item.uniqId);

                                                        if (item.isScanned) {
                                                            selected = true;
                                                        }

                                                        return (
                                                            <Table.Row
                                                                key={index}
                                                                style={{
                                                                    background: selected ? "rgba(85,255,0,0.3)" : null
                                                                }}
                                                            >
                                                                <Table.Cell>
                                                                    <div ref={y => this.scrollRefs[index] = y}/>

                                                                    {selected &&
                                                                        <Label ribbon><Icon name={"check"}/></Label>
                                                                    }
                                                                    <div style={{
                                                                        cursor: "pointer"
                                                                    }} onClick={() => {
                                                                        if (!item.images) return false

                                                                        this.setState({
                                                                            imageModal: `${item.images[0].file}`
                                                                        })
                                                                    }}>
                                                                        {item.images ? <img style={{
                                                                            width: 160
                                                                        }} src={`${item.images[0].small || item.images[0].file}`} alt=""/> : "---"}
                                                                    </div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center"
                                                                        }}
                                                                        className={"hover-lb"}
                                                                        onClick={() => copyToClipboard(item.sku)}
                                                                    >
                                                                        <SemanticIcon style={{
                                                                            marginRight: 3,
                                                                            top: -3,
                                                                            position: "relative"
                                                                        }} name={"copy"}/>

                                                                        <div>
                                                                            {item.sku}
                                                                        </div>
                                                                    </div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center"
                                                                        }}
                                                                        className={"hover-lb"}
                                                                        onClick={() => copyToClipboard((item.name))}
                                                                    >
                                                                        <SemanticIcon style={{
                                                                            marginRight: 3,
                                                                            top: -3,
                                                                            position: "relative"
                                                                        }} name={"copy"}/>

                                                                        <div>
                                                                            {item.name}
                                                                        </div>
                                                                    </div>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {item.qty}
                                                                    {this.props.prevComplaints[item.sku + "_" + item.finalQtyPrice] && <span style={{
                                                                        color: "#ff1a3a",
                                                                        fontWeight: 600
                                                                    }}> / {this.props.prevComplaints[item.sku + "_" + item.finalQtyPrice].qty}</span>}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {parseFloat(item.finalQtyPrice).toFixed(2)} <span>{order.currency}</span>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {/*<div style={{*/}
                                                                    {/*    fontWeight: 600,*/}
                                                                    {/*    margin: "5px 0",*/}
                                                                    {/*    fontSize: "0.9em",*/}
                                                                    {/*    color: afterRevertTime ? "#ff6428" : "#898989"*/}
                                                                    {/*}}>*/}
                                                                    {/*    Zamówienie zostało zrealizowane {diffDays} dni temu.*/}
                                                                    {/*</div>*/}

                                                                    <div style={{
                                                                        fontSize: "0.85em",
                                                                        color: "#ff1a3a",
                                                                        marginTop: 8
                                                                    }}>
                                                                        { ( this.props.prevComplaints[item.sku]
                                                                            && (this.props.prevComplaints[item.sku].qty === item.qty) )
                                                                            ? "Zwrot niemożliwy. Ten towar został już zwrócony."
                                                                            : ( this.props.prevComplaints[item.sku]
                                                                                && (this.props.prevComplaints[item.sku].qty !== item.qty) )
                                                                                ? "Uwaga! Możliwy częsciowy zwrot towaru."
                                                                                : ""
                                                                        }
                                                                    </div>

                                                                    <div>
                                                                        {this.state.itemsRevertType === "complaint" &&
                                                                        <div style={{
                                                                            fontSize: "0.85em",
                                                                            color: "#858585",
                                                                            marginTop: 8,
                                                                            marginBottom: 5
                                                                        }}>
                                                                            Wybrano typ zgłoszenia jako reklamacja. Aby dokonać zwrotu, utwórz nowe zgłoszenie.
                                                                        </div>
                                                                        }

                                                                        <div>
                                                                            <Button
                                                                                label={"Zwrot"}
                                                                                disabled={ this.state.itemsRevertType === "complaint" ? true : ( this.props.prevComplaints[item.sku] && (this.props.prevComplaints[item.sku].qty === item.qty) )
                                                                                    ? true
                                                                                    : false
                                                                                }
                                                                                icon={"retweet"}
                                                                                style={{
                                                                                    marginBottom: 10,
                                                                                }}
                                                                                onClick={() => {
                                                                                    if (afterRevertTime) {
                                                                                        this.setState({
                                                                                            confirm: {
                                                                                                days: diffDays,
                                                                                                afterDays: afterRevertTime,
                                                                                                cb: () => this.setState({
                                                                                                    modalOpened: {
                                                                                                        title: "Dane do zwrotu produktu",
                                                                                                        type: "revert",
                                                                                                        product: item,
                                                                                                    },
                                                                                                    revertType: "revert",
                                                                                                    revertProduct: item,
                                                                                                    revertOrderType: order.paymentMethod
                                                                                                })
                                                                                            }
                                                                                        })
                                                                                    } else {
                                                                                        this.setState({
                                                                                            modalOpened: {
                                                                                                title: "Dane do zwrotu produktu",
                                                                                                type: "revert",
                                                                                                product: item,
                                                                                            },
                                                                                            revertType: "revert",
                                                                                            revertProduct: item,
                                                                                            revertOrderType: order.paymentMethod
                                                                                        })
                                                                                    }

                                                                                }}
                                                                            />
                                                                        </div>

                                                                        {this.state.itemsRevertType === "revert" &&
                                                                            <div style={{
                                                                                fontSize: "0.85em",
                                                                                color: "#858585",
                                                                                marginTop: 8,
                                                                                marginBottom: 5
                                                                            }}>
                                                                                Wybrano typ zgłoszenia jako zwrot. Aby dokonać reklamacji, utwórz nowe zgłoszenie.
                                                                            </div>
                                                                        }

                                                                        <div>
                                                                            <Button
                                                                                label={"Reklamacja"}
                                                                                icon={"redo"}
                                                                                disabled={ this.state.itemsRevertType === "revert" ? true : ( this.props.prevComplaints[item.sku] && (this.props.prevComplaints[item.sku].qty === item.qty) ) ? true : false}
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        modalOpened: {
                                                                                            title: "Dane do reklamacji produktu",
                                                                                            type: "complaint",
                                                                                            product: item,
                                                                                        },
                                                                                        revertType: "complaint",
                                                                                        revertProduct: item,
                                                                                        revertOrderType: order.paymentMethod
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div>
                                                                            { ( this.props.prevComplaints[item.sku] && (this.props.prevComplaints[item.sku].qty === item.qty) ) &&
                                                                            <Button
                                                                                label={"Kolejna reklamacja"}
                                                                                icon={"redo"}
                                                                                style={{marginTop: 10}}
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        modalOpened: {
                                                                                            title: "Dane do reklamacji produktu",
                                                                                            type: "complaint",
                                                                                            product: item,
                                                                                        },
                                                                                        revertType: "complaint",
                                                                                        revertProduct: item,
                                                                                        revertOrderType: order.paymentMethod
                                                                                    })
                                                                                }}
                                                                            />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>

                <Confirm
                    dimmer={"inverted"}
                    open={this.state.revertAllConfirm}
                    content='Czy na pewno chcesz zwrócić wszystkie produkty?'
                    onCancel={() => this.setState({
                        revertAll: null,
                        revertAllConfirm: null
                    })}
                    onConfirm={() => {
                        this.state.revertAllConfirm()

                        this.setState({
                            revertAllConfirm: null
                        })
                    }}
                />

                {this.state.revertAll && this.state.revertAll.modal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.revertAll.modal} onClose={() => this.setState({revertAll: false})}>
                        <Modal.Header>Zwrot całości zamówienia</Modal.Header>
                        <Modal.Content>
                            <div style={{width: "100%", height: 1, background: "#cbcbcb", marginTop: 20, marginBottom: 20}}></div>

                            <EsotiqInput
                                label={"Wybierz formę zwrotu"}
                                options={[{key: 0, value: "revert", text: "Zwrot"}, {
                                    key: 1, value: "complaint", text: "Reklamacja"
                                }].map((e, k) => ({
                                    key: k, value: e.value, text: e.text
                                }))}
                                placeholder={"Wybierz formę"}
                                onChange={(value) => {
                                    this.state.revertAll.type = value
                                    this.setState({
                                        refresh: new Date().getTime()
                                    })
                                }}
                            />

                            {this.state.revertAll.type &&
                            (this.state.revertAll.type === "complaint" ? this.state.revertAll.complaintProducts : this.state.revertAll.products).map((orderItem, key) => {

                                    orderItem.revertProduct = jsonCopy(orderItem)
                                    orderItem.revertQty = orderItem.qty
                                    orderItem.revertReason = this.state.revertAll.type === "revert" ? this.props.revertReasons ? this.props.revertReasons[0].value : null : null
                                    orderItem.complaintForm = this.state.revertAll.type === "complaint" ? this.props.complaintReasons ? this.props.complaintReasons[0].value : null : null
                                    orderItem.revertType = this.state.revertAll.type
                                    orderItem.revertOrderType = this.props.orders[0].paymentMethod

                                    const PrevReverted = this.props.prevComplaints[orderItem.sku] ? ( this.props.prevComplaints[orderItem.sku].qty < orderItem.qty ? this.props.prevComplaints[orderItem.sku].qty : 0 ) : 0

                                    return (
                                        <div key={key} className={"revert-all-box-product"}>
                                            <div>
                                                <div style={{fontWeight: 600}}>Produkt:</div>
                                                <div style={{fontSize: 14, color: "#606060"}}>{orderItem.name}</div>
                                                <div style={{fontWeight: 600, fontSize: 12, color: "#b3b3b3"}}>{orderItem.sku}</div>
                                            </div>

                                            <div style={{width: "100%", height: 1, background: "#cbcbcb", marginTop: 20, marginBottom: 20}}></div>

                                            {this.state.revertAll.type === "revert"
                                                ? <React.Fragment>
                                                    <EsotiqInput
                                                        label={"Ilość zwracanych sztuk"}
                                                        options={Array.from({length: orderItem.qty - PrevReverted}, (v, k) => k + 1).map((e, k) => ({
                                                            key: k, value: e, text: e
                                                        }))}
                                                        defValue={orderItem.qty}
                                                        placeholder={"Wybierz ilość"}
                                                        onChange={(value) => {
                                                            orderItem.revertQty = value
                                                        }}
                                                    />

                                                    <EsotiqInput
                                                        label={"Powód zwrotu"}
                                                        options={this.props.revertReasons.map((e, k) => ({
                                                            key: k, value: e.value, text: e.value
                                                        }))}
                                                        defValue={this.props.revertReasons ? this.props.revertReasons[0].value : null}
                                                        placeholder={"Wybierz powód zwrotu"}
                                                        onChange={(value) => orderItem.revertReason = value}
                                                    />

                                                    <EsotiqInput
                                                        label={"Dodatkowy opis"}
                                                        textArea={true}
                                                        placeholder={"Wpisz dodatkowe informacje"}
                                                        onChange={(value) => orderItem.revertAdditionalInfo = value}
                                                    />
                                                </React.Fragment>
                                                : <React.Fragment>
                                                    <EsotiqInput
                                                        label={"Ilość zwracanych sztuk"}
                                                        options={Array.from({length: orderItem.qty - PrevReverted}, (v, k) => k + 1).map((e, k) => ({
                                                            key: k, value: e, text: e
                                                        }))}
                                                        defValue={orderItem.qty}
                                                        placeholder={"Wybierz ilość"}
                                                        onChange={(value) => orderItem.revertQty = value}
                                                    />

                                                    <EsotiqInput
                                                        label={"Preferowana opcja reklamacji"}
                                                        options={this.props.complaintReasons.map((e, k) => ({
                                                            key: k, value: e.value, text: e.value
                                                        }))}
                                                        defValue={this.props.complaintReasons ? this.props.complaintReasons[0].value : null}
                                                        placeholder={"Wybierz forme reklmacji"}
                                                        onChange={(value) => orderItem.complaintForm = value}
                                                    />

                                                    {/*<Dropzone onDrop={acceptedFiles => {*/}
                                                        {/*this.setState({*/}
                                                            {/*files: acceptedFiles*/}
                                                        {/*})*/}
                                                    {/*}}>*/}
                                                        {/*{({getRootProps, getInputProps}) => (*/}
                                                            {/*<div className={"dropzone-area"}>*/}
                                                                {/*<div className={"dropzone-area-content"} {...getRootProps()}>*/}
                                                                    {/*<input {...getInputProps()} />*/}

                                                                    {/*<Icon name={"dropbox"}/>*/}

                                                                    {/*<span>Przeciągnij i upuść pliki lub kliknij aby wybrać.</span>*/}
                                                                {/*</div>*/}

                                                                {/*<div>*/}
                                                                    {/*{this.state.files && this.state.files.map(file => {*/}
                                                                        {/*return (*/}
                                                                            {/*<div className={"dropzone-area-file"}>*/}
                                                                                {/*<div>*/}
                                                                                    {/*<Icon name={"file image outline"}/>*/}
                                                                                {/*</div>*/}

                                                                                {/*<div className={"dropzone-area-file-name"}>*/}
                                                                                    {/*{file.name}*/}
                                                                                {/*</div>*/}
                                                                            {/*</div>*/}
                                                                        {/*)*/}
                                                                    {/*})}*/}
                                                                {/*</div>*/}
                                                            {/*</div>*/}
                                                        {/*)}*/}
                                                    {/*</Dropzone>*/}

                                                    <EsotiqInput
                                                        label={"Dodatkowy opis"}
                                                        textArea={true}
                                                        placeholder={"Wpisz dodatkowe informacje"}
                                                        onChange={(value) => orderItem.revertAdditionalInfo = value}
                                                    />
                                                </React.Fragment>
                                            }
                                        </div>
                                    )
                                })
                            }


                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({
                                revertAll: null
                            })
                            } negative>Anuluj</Button>
                            <Button
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content='Zapisz'
                                onClick={() => {
                                    this.state.revertAll.modal = false

                                    this.setState({
                                        revertAllConfirm: () => {
                                            this.props.reset();
                                            this.state.revertedData = [];

                                            (this.state.revertAll.type === "complaint" ? this.state.revertAll.complaintProducts : this.state.revertAll.products).forEach((revertItem, i) => {
                                                let revertedData = this.state.revertedData;

                                                revertedData = revertedData.filter((e, k) => e.uniqId !== revertItem.uniqId)

                                                revertedData.push({
                                                    revertQty: revertItem.revertQty,
                                                    revertReason: revertItem.revertReason,
                                                    revertAdditionalInfo: revertItem.revertAdditionalInfo,
                                                    revertTok99: revertItem.revertType === "complaint" ? true : revertItem.revertProduct.additionalData && revertItem.revertProduct.additionalData.attributes && revertItem.revertProduct.additionalData.attributes["zwrot_na_magazyn"] && (revertItem.revertProduct.additionalData.attributes["zwrot_na_magazyn"] == "0" ? false : true),
                                                    files: revertItem.files,
                                                    complaintForm: revertItem.complaintForm,
                                                    revertProduct: revertItem.revertProduct,
                                                    revertType: revertItem.revertType,
                                                    revertOrderType: revertItem.revertOrderType,
                                                    name: revertItem.name,
                                                    sku: revertItem.sku,
                                                    qty: revertItem.qty,
                                                    uniqId: revertItem.uniqId
                                                })

                                                this.props.accept({
                                                    revertQty: revertItem.revertQty,
                                                    revertReason: revertItem.revertReason,
                                                    revertAdditionalInfo: revertItem.revertAdditionalInfo,
                                                    revertTok99: revertItem.revertType === "complaint" ? true : revertItem.revertProduct.additionalData && revertItem.revertProduct.additionalData.attributes && revertItem.revertProduct.additionalData.attributes["zwrot_na_magazyn"] && (revertItem.revertProduct.additionalData.attributes["zwrot_na_magazyn"] == "0" ? false : true),
                                                    files: revertItem.files,
                                                    complaintForm: revertItem.complaintForm,
                                                    revertProduct: revertItem.revertProduct,
                                                    revertType: revertItem.revertType,
                                                    revertOrderType: revertItem.revertOrderType,
                                                    name: revertItem.name,
                                                    sku: revertItem.sku,
                                                    qty: revertItem.qty,
                                                    uniqId: revertItem.uniqId
                                                }, true)

                                                this.setState({
                                                    revertAll: false,
                                                    revertedData
                                                })

                                                this.clear(true)
                                            })
                                        }
                                    })
                                }}
                            />
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.modalOpened &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.modalOpened} onClose={() => this.setState({modalOpened: false})}>
                        <Modal.Header>{this.state.modalOpened.title}</Modal.Header>
                        <Modal.Content>
                            <div>
                                <div style={{fontWeight: 600}}>Produkt:</div>
                                <div style={{fontSize: 14, color: "#606060"}}>{this.state.modalOpened.product.name}</div>
                                <div style={{fontWeight: 600, fontSize: 12, color: "#b3b3b3"}}>{this.state.modalOpened.product.sku}</div>
                            </div>

                            <div style={{width: "100%", height: 1, background: "#cbcbcb", marginTop: 20, marginBottom: 20}}></div>

                            {this.state.modalOpened.type === "revert"
                                ? <React.Fragment>
                                    <EsotiqInput
                                        label={"Ilość zwracanych sztuk"}
                                        options={Array.from({length: this.state.modalOpened.product.qty - (this.props.prevComplaints[this.state.modalOpened.product.sku] ? ( this.props.prevComplaints[this.state.modalOpened.product.sku].qty < this.state.modalOpened.product.qty ? this.props.prevComplaints[this.state.modalOpened.product.sku].qty : 0 ) : 0)}, (v, k) => k + 1).map((e, k) => ({
                                            key: k, value: e, text: e
                                        }))}
                                        placeholder={"Wybierz ilość"}
                                        onChange={(value) => this.setState({
                                            revertQty: value
                                        })}
                                    />

                                    <EsotiqInput
                                        label={"Powód zwrotu"}
                                        options={this.props.revertReasons.map((e, k) => ({
                                            key: k, value: e.value, text: e.value
                                        }))}
                                        placeholder={"Wybierz powód zwrotu"}
                                        onChange={(value) => this.setState({
                                            revertReason: value
                                        })}
                                    />

                                    <EsotiqInput
                                        label={"Dodatkowy opis"}
                                        textArea={true}
                                        placeholder={"Wpisz dodatkowe informacje"}
                                        onChange={(value) => this.setState({
                                            revertAdditionalInfo: value
                                        })}
                                    />
                                </React.Fragment>
                                : <React.Fragment>
                                    <EsotiqInput
                                        label={"Ilość zwracanych sztuk"}
                                        options={Array.from({length: this.state.modalOpened.product.qty - (this.props.prevComplaints[this.state.modalOpened.product.sku] ? ( this.props.prevComplaints[this.state.modalOpened.product.sku].qty < this.state.modalOpened.product.qty ? this.props.prevComplaints[this.state.modalOpened.product.sku].qty : 0 ) : 0)}, (v, k) => k + 1).map((e, k) => ({
                                            key: k, value: e, text: e
                                        }))}
                                        placeholder={"Wybierz ilość"}
                                        onChange={(value) => this.setState({
                                            revertQty: value
                                        })}
                                    />

                                    <EsotiqInput
                                        label={"Preferowana opcja reklamacji"}
                                        options={this.props.complaintReasons.map((e, k) => ({
                                            key: k, value: e.value, text: e.value
                                        }))}
                                        placeholder={"Wybierz forme reklmacji"}
                                        onChange={(value) => this.setState({
                                            complaintForm: value
                                        })}
                                    />

                                    <Dropzone onDrop={acceptedFiles => {
                                        this.setState({
                                            files: acceptedFiles
                                        })
                                    }}>
                                        {({getRootProps, getInputProps}) => (
                                            <div className={"dropzone-area"}>
                                                <div className={"dropzone-area-content"} {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    <Icon name={"dropbox"}/>

                                                    <span>Przeciągnij i upuść pliki lub kliknij aby wybrać.</span>
                                                </div>

                                                <div>
                                                    {this.state.files && this.state.files.map(file => {
                                                        return (
                                                            <div className={"dropzone-area-file"}>
                                                                <div>
                                                                    <Icon name={"file image outline"}/>
                                                                </div>

                                                                <div className={"dropzone-area-file-name"}>
                                                                    {file.name}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>

                                    <EsotiqInput
                                        label={"Dodatkowy opis"}
                                        textArea={true}
                                        placeholder={"Wpisz dodatkowe informacje"}
                                        onChange={(value) => this.setState({
                                            revertAdditionalInfo: value
                                        })}
                                    />
                                </React.Fragment>
                            }
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({
                                modalOpened: false,
                                revertQty: null,
                                revertReason: null,
                                revertAdditionalInfo: null,
                                revertTok99: null,
                                files: null,
                                complaintForm: null,
                                revertProduct: null,
                                revertType: null,
                                revertOrderType: null
                            })
                            } negative>Anuluj</Button>

                            <Button
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content='Zapisz'
                                onClick={() => {
                                    if (!this.state.revertQty) {
                                        return SnackBar("Podaj ilość zwracanych sztuk", "error")
                                    }

                                    let revertedData = this.state.revertedData
                                    revertedData = revertedData.filter((e, i) => e.uniqId !== this.state.modalOpened.product.uniqId )

                                    revertedData.push({
                                        revertQty: this.state.revertQty,
                                        revertReason: this.state.revertReason,
                                        revertAdditionalInfo: this.state.revertAdditionalInfo,
                                        revertTok99: this.state.revertType === "complaint" ? true : this.state.revertProduct.additionalData && this.state.revertProduct.additionalData.attributes && this.state.revertProduct.additionalData.attributes["zwrot_na_magazyn"] && (this.state.revertProduct.additionalData.attributes["zwrot_na_magazyn"] == "0" ? false : true),
                                        files: this.state.files,
                                        complaintForm: this.state.complaintForm,
                                        revertData: this.state.revertData,
                                        revertProduct: this.state.revertProduct,
                                        revertType: this.state.revertType,
                                        revertOrderType: this.state.revertOrderType,
                                        name: this.state.modalOpened.product.name,
                                        sku: this.state.modalOpened.product.sku,
                                        qty: this.state.modalOpened.product.qty,
                                        uniqId: this.state.modalOpened.product.uniqId
                                    })
                                    this.setState({
                                        modalOpened: false,
                                        revertedData
                                    })

                                    this.props.accept({
                                        revertQty: this.state.revertQty,
                                        revertReason: this.state.revertReason,
                                        revertAdditionalInfo: this.state.revertAdditionalInfo,
                                        revertTok99: this.state.revertType === "complaint" ? true : this.state.revertProduct.additionalData && this.state.revertProduct.additionalData.attributes && this.state.revertProduct.additionalData.attributes["zwrot_na_magazyn"] && (this.state.revertProduct.additionalData.attributes["zwrot_na_magazyn"] == "0" ? false : true),
                                        files: this.state.files,
                                        complaintForm: this.state.complaintForm,
                                        revertData: this.state.revertData,
                                        revertProduct: this.state.revertProduct,
                                        revertType: this.state.revertType,
                                        revertOrderType: this.state.revertOrderType,
                                        name: this.state.modalOpened.product.name,
                                        sku: this.state.modalOpened.product.sku,
                                        qty: this.state.modalOpened.product.qty,
                                        uniqId: this.state.modalOpened.product.uniqId
                                    }, true)

                                    this.clear(true)
                                }}
                            />
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.imageModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal} onClose={() => this.setState({imageModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <img style={{
                                    maxWidth: 500,
                                    margin: "0 auto",
                                    display: "block"
                                }} src={this.state.imageModal} alt=""/>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.confirm &&
                    <Confirm
                        dimmer={"inverted"}
                        open={this.state.confirm}
                        content={`To zamówienie zostało zrealizowane ${this.state.confirm.days} dni temu. Ustalony czas zwrotów towarów przez klienta to ${this.props.revertAllowTime} dni. Czy pomimo tego chcesz dokonać zwrotu?`}
                        onCancel={() => this.setState({confirm: false})}
                        onConfirm={() => {
                            this.setState({
                                confirm: false
                            }, this.state.confirm.cb())
                        }}
                    />
                }

            </div>
        );
    }
}
