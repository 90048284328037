import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PaperTitle from "../../components/PaperTitle";
import Container from "../../components/Container";
import {api} from "../../lib/axios";
import {PDFDownloadLink} from "@react-pdf/renderer";
import PDFcomplaintParlour from "./PDFcomplaintParlour";
import Button from "../../components/Button";


class complaintSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            path: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1],
        }
    }

    componentDidMount() {
        this.init()
    }
    init() {
        api(undefined, undefined, undefined, true).post("/complaints/get", {
            currentPage: 1,
            limit: 25,
            filter: [{
                condition: 'in',
                field: 'complaintId',
                Label: 'complaintId',
                value: this.state.path.split("-").join("/"),
            }]
        })
            .then(r => this.setState({data: r.data.data[0]}))

    }

    render() {
        return(

            <Container>
                {this.state.data &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper>
                            <PaperTitle title={"Podsumowanie reklamacji"}/>
                            <p>{`Stworzono nową reklamację: ${this.state.path.split("-").join("/")}`}</p>
                            <div style={{marginBottom: 10, display: "flex", flexDirection: "row"}}>
                                {
                                    <PDFDownloadLink ref={e => this.downloadLink = e}
                                                     document={<PDFcomplaintParlour data={this.state.data}/>}
                                                     fileName={`Formularz reklamacji`}>
                                        {({blob, url, loading, error}) => (loading ? <Button
                                            color='blue'
                                            loading={true}
                                            label={{
                                                basic: true,
                                                color: 'blue',
                                                pointing: 'left',
                                                content: '.pdf'
                                            }}
                                        /> : <div>
                                            <Button
                                                color='blue'
                                                content='Drukuj formularz reklamacji'
                                                icon='download'
                                                label={{
                                                    basic: true,
                                                    color: 'blue',
                                                    pointing: 'left',
                                                    content: '.pdf'
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    window.open(url, '_blank')

                                                    return true
                                                }}
                                            />
                                        </div>)}
                                    </PDFDownloadLink>
                                }
                                <Button onClick={()=>window.open(`#/complaints/complaintDetails/${this.state.data.complaintId.split("/").join("-")}`, "_self")}>Pokaż szczegóły</Button>
                                <Button onClick={()=>window.open("#/complaints", "_self")}>Wróć do listy</Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                }
            </Container>
        )
    }
}

export default complaintSummary;