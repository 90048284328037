import CRUDView from "../../components/CRUDTable/view";
import {ParlourDetailsUrls} from "../../api/urls";
import React from "react";
import RegionParloursModal from "./regionParlours";
import {Input} from "semantic-ui-react";
import _ from "lodash";
import {TranslationController} from "../../controller/menager";

export default class RegionCRUDView extends CRUDView {
    resource = ParlourDetailsUrls.REGIONS;
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            label: TranslationController.translate('Regionalny'),
            schema: {
                title: TranslationController.translate("Region"),
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                        title: TranslationController.translate('Nazwa'),
                        default: '',
                    },
                    regionManager: {
                        type: 'object',
                        title: TranslationController.translate('Regionalny'),
                        properties: {
                            login: {
                                type: ['string', 'null'],
                                title: 'Login',
                            },
                            profile: {
                                type: 'object',
                                title: TranslationController.translate('Profil'),
                                properties: {
                                    firstName: {
                                        type: 'string',
                                        title: TranslationController.translate('Imię'),
                                        default: '',
                                    },
                                    lastName: {
                                        type: 'string',
                                        title: TranslationController.translate('Nazwisko'),
                                        default: '',
                                    }
                                }
                            },
                            contacts: {
                                type: 'array',
                                title: TranslationController.translate('Kontakty'),
                                items: {
                                    type: 'object',
                                    required: ['type', 'value'],
                                    properties: {
                                        type: {
                                            type: 'string',
                                            title: TranslationController.translate('Typ'),
                                            enum: ['Email', 'Phone'],
                                        },
                                        value: {
                                            type: 'string',
                                            title: TranslationController.translate('Wartość'),
                                            default: '',
                                        }
                                    }
                                }
                            }
                        }
                    },
                }
            },
            tableFields: [
                {
                    label: 'Id',
                    getValue: (payload) => payload.id,
                },
                {
                    label: 'Nazwa regionu',
                    getValue: (payload) => payload.name,
                },
                {
                    label: 'Imię',
                    getValue: (payload) => payload.regionManager?.profile?.firstName || '',
                    filters: [
                        {
                            component: <Input
                                key={"regionManager"}
                                onChange={_.debounce(
                                    (e, {value}) => this.setState({filters: {...this.state.filters, manager: value}}),
                                    500
                                )}
                                icon={"search"}
                                placeholder={"Imię i nazwisko"}
                            />,
                            props: {
                                defaultValue: () => this.state.filters.manager || ''
                            }
                        }

                    ]
                },
                {
                    label: 'Nazwisko',
                    getValue: (payload) => payload.regionManager?.profile?.lastName || '',
                    filters: [{
                        component: <Input
                            key={"regionManager"}
                            onChange={_.debounce(
                                (e, {value}) => this.setState({filters: {...this.state.filters, manager: value}}),
                                500
                            )}
                            icon={"search"}
                            placeholder={"Imię i nazwisko"}
                        />,
                        props: {
                            defaultValue: () => this.state.filters.manager || ''
                        }
                    }]
                },
                {
                    label: 'Email',
                    getValue: (payload) => payload.regionManager.contacts.filter((item) => item.type === "Email").map((item) => <span >{item.value}<br/></span>)
                },
                {
                    label: 'Telefon',
                    getValue: (payload) => payload.regionManager.contacts.filter((item) => item.type === 'Phone').map((item) => <span >{item.value}<br/></span>)
                },
                {
                    label: "Salony",
                    getValue: (payload) => (<RegionParloursModal regionId={payload.id}/>)
                }
            ]
        }
    }
}
