import React from  'react';
import { Button, Modal, Card } from 'semantic-ui-react'
import {parlourDetailsApi} from "../../lib/axios";
import {ParlourDetailsUrls} from "../../api/urls";
import {TranslationController} from "../../controller/menager";

export default class RegionParloursModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            region: {
                parlours: []
            },
        }
    }

    componentDidMount() {
        this.getParloursByRegionId();
    }

    getParloursByRegionId = () => {
        const { regionId } = this.props;
        parlourDetailsApi.get(ParlourDetailsUrls.REGIONS + '/' + regionId).then(response => {
            this.setState({region: response.data})
        })
    }

    formatAddress = (address) => {
        return (
            <div>
                <span>{address.shoppingMall}<br/></span>
                <span>{address.zipCode} {address.city}<br/></span>
            </div>
        )
    }

    render() {
        const { open } = this.state;

        return (
            <Modal
                onClose={() => this.setState({open: false})}
                onOpen={() => this.setState({open: true})}
                open={open}
                trigger={<Button >{ TranslationController.translate('Pokaż') }</Button>}
            >
                <Modal.Header>{TranslationController.translate('Salony')}</Modal.Header>
                <Modal.Content>
                    {
                        <Card.Group items={this.state.region.parlours.map((parlour)=> {
                            return {
                                header: parlour.name,
                                meta: parlour.nr,
                                description: this.formatAddress(parlour.address),
                            }
                        })} />
                    }

                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({open: false})}>{TranslationController.translate('Zamknij')}</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
