import React from "react"
import {Menu, Table} from 'semantic-ui-react'
import {api} from "../../lib/axios";
import Confirm from "../../components/Confirm";
import SnackBar from "../../components/Snack";
import MenuButton from "../../lib/table-v2/menuButton";
import Breadcrumb from "../../components/Breadcrumb";
import Container from "../../components/Container";
import UITable from "../../lib/table-v2";
import {exportToFile} from "../../controller/exportController";
import {formatDate} from "../../helpers/dateHelper";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import TableMenu from "../../components/TableMenu";

export default class AllegroCheckoutForms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            websiteFilter: [],
            dataCount: null,
            tableSelectable: false,
            tableSelectableState: [],
            tableExportCols: [],
            onlyWithProducts: true
        }

        this.statusMap = {
            ENDED: "Zakończona",
            ACTIVE: "Trwa"
        }
    }

    componentDidMount() {
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api(true).post("/api/allegro/checkoutForms", {})
                .then(r => {
                    return r.data.data
                })
        })

        return api(true).post("/api/allegro/checkoutForms", {limit, currentPage, filter: this.state.appliedFilters || null, onlyWithProducts: this.state.onlyWithProducts})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    render() {
        return (
            <div>
                <React.Fragment>
                    <Container>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `Formularze pozakupowe Allegro`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>

                        <Menu className={"order-menu"} attached='top'>
                            <TableMenu item icon='wrench' simple>
                                <TableMenu.Menu>
                                    <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                    <TableMenu.Divider />
                                    <TableMenu.Header>Export</TableMenu.Header>
                                    <TableMenu.Item>
                                        <span className='text'>Raport uniwersalny</span>

                                        <TableMenu.Menu>
                                            <div className={"uni-report-container"}>
                                                <div className={"uni-report-header"}>Wybierz kolumny do raportu</div>

                                                <div className={"uni-report-content-box"}>
                                                    <div className={"uni-report-column"}>
                                                        <ul>

                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className={"uni-report-actions"}>
                                                    <Button
                                                        color='blue'
                                                        content='Pobierz'
                                                        icon='download'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                        onClick={() => this.uniExport()}
                                                    />
                                                </div>
                                            </div>
                                        </TableMenu.Menu>
                                    </TableMenu.Item>
                                </TableMenu.Menu>
                            </TableMenu>

                            <Menu.Menu position='right'>
                                <MenuButton
                                    icon={"cloud download"}
                                    label={"Zakończ wybrane"}
                                    onClick={() => {
                                        if (!this.orderTable.getSelectedState().length) {
                                            return SnackBar("Nie wybrano żadnego draftu", "error")
                                        }

                                        Confirm("Czy na pewno chcesz zakończyć wybrane oferty?", "", () => {
                                            const offers = []

                                            this.orderTable.getSelectedState().forEach(e => {
                                                offers.push(e.id)
                                            })

                                            api(true).post("/api/allegro/manageOfferts", {offers, action: "END"})
                                                .then(() => {
                                                    SnackBar("Zaktualizowano ofertę")
                                                    this.setState({
                                                        data: null,
                                                        websiteFilter: [],
                                                        dataCount: null,
                                                        tableSelectable: false,
                                                        tableSelectableState: [],
                                                        tableExportCols: [],
                                                        onlyWithProducts: !this.state.onlyWithProducts
                                                    }, () => {
                                                        this.orderTable.setState({
                                                            data: null
                                                        })

                                                        this.orderTable.init()
                                                    })
                                                })
                                        })
                                    }}
                                />
                            </Menu.Menu>
                        </Menu>

                        <UITable
                            dataFetch={{
                                url: "/api/allegro/checkoutForms",
                                useApi2: true,
                                options: {limit: 25, currentPage: 1, onlyWithProducts: this.state.onlyWithProducts}
                            }}
                            ref={table => this.orderTable = table}
                            selectable={this.state.tableSelectable}
                            provider={this}
                            columns={[
                                {
                                    label: "Dane",
                                    field: "id",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div>
                                                <div style={{
                                                    padding: 5,
                                                    background: "#efefef",
                                                    borderRadius: 5,
                                                    marginBottom: 5
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#898989",
                                                        display: "flex"
                                                    }}>
                                                        Id płatności:
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#003731",
                                                        display: "flex"
                                                    }}>
                                                        {row.payment.id}
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    },
                                },
                                {
                                    label: "Status",
                                    field: "id",
                                    center: true,
                                    width: 1,
                                    render: row => (<div>
                                        <div style={{
                                            fontSize: "0.9em",
                                            color: "#898989",
                                            fontWeight: 600
                                        }}>
                                            {formatDate(row.updatedAt).dateTimeSystem}
                                        </div>
                                        {row.status}
                                    </div>),
                                },
                                {
                                    label: "Suma",
                                    field: "id",
                                    center: true,
                                    width: 1,
                                    render: row => (<div>
                                        <div style={{
                                            fontSize: "0.9em",
                                            color: "#898989",
                                            fontWeight: 600
                                        }}>
                                            {row.payment.provider}
                                        </div>
                                        {row.payment.paidAmount && `${row.payment.paidAmount.amount} ${row.payment.paidAmount.currency}`}
                                    </div>),
                                },
                                {
                                    label: "Towary",
                                    field: "id",
                                    width: 4,
                                    render: row => {
                                        return (
                                            <div>
                                                <Table compact>
                                                    <Table.Body>
                                                        {row.lineItems.map((item, key) => {
                                                            return (
                                                                <Table.Row key={key}>
                                                                    <Table.Cell width={3}>
                                                                        <div style={{
                                                                            color: "#787878",
                                                                        }}>
                                                                            {formatDate(item.boughtAt).dateTimeSystem}

                                                                            <div>
                                                                                <div style={{
                                                                                    fontSize: "0.8em",
                                                                                    color: "#898989",
                                                                                    marginTop: 4
                                                                                }}>
                                                                                    Id aukcji:
                                                                                </div>

                                                                                <a
                                                                                    style={{
                                                                                        color: "#0075d6",
                                                                                        display: "block"
                                                                                    }}
                                                                                    target={"_blank"}
                                                                                    href={`http://allegro.pl/show_item.php?item=${item.offer.id}`}
                                                                                >
                                                                                    {item.offer.id}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </Table.Cell>
                                                                    <Table.Cell width={1}>
                                                                        <div>
                                                                            {item.quantity}
                                                                        </div>
                                                                    </Table.Cell>
                                                                    <Table.Cell width={4}>
                                                                        <div>
                                                                            <div>
                                                                                <div style={{
                                                                                    fontSize: "0.8em",
                                                                                    color: "#898989",
                                                                                    marginTop: 4
                                                                                }}>
                                                                                    EAN:
                                                                                </div>

                                                                                <span
                                                                                    style={{
                                                                                        color: "#0075d6",
                                                                                        display: "block"
                                                                                    }}
                                                                                >
                                                                                    {row.offerData[item.offer.id].ean}
                                                                                </span>
                                                                            </div>

                                                                            {item.offer.name}
                                                                        </div>
                                                                    </Table.Cell>
                                                                    <Table.Cell width={3}>
                                                                        <div>
                                                                            {`${item.price.amount} ${item.price.currency}`}
                                                                        </div>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })}
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        )
                                    }
                                }
                            ]}
                        />
                    </Container>
                </React.Fragment>
            </div>
        );
    }
}
